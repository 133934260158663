import { Footer } from '../../CommonComponents'
import Header from '../../CommonComponents/Header/HeaderContainer'
import './Layout.scss'
import {useSelector} from 'react-redux'

interface IProps {
  children?: any,
  showMenu?: any
  toggleDropdown?: any,
  wrapperRef?:any
}
const Layout = ({ children, showMenu, toggleDropdown,wrapperRef }: IProps) => {

  const userMenuList = [
    {
      link: '/',
      text: 'Branch',
      icon: 'branch'
    },
    {
      link: '/',
      text: 'Settings',
      icon: 'settings'
    }
  ]
  const activeNavigation = useSelector((state:any) => state.nav.height)

  return (
    <div className='main-wrapper'>
      <Header heading={'LEADR  VISION'} showMenu={showMenu} toggleDropdown={toggleDropdown} userMenuList={userMenuList}/>
      <div className='someClass' ref={wrapperRef}>
      <div className={`content-wrapper ${ activeNavigation !== null ? 'home' : 'content-wrapper' }`}>
        { children }
      </div>
      <Footer />
      </div>
    </div>
  )
}

export default Layout

import React from 'react';
import {Breadcrumb,Card,Row,Col,DropdownBox,Label,Datepicker,Checkbox,Quantity,OutlineButton,SofturaTable,PrimaryButton,InputSpinner,ModalPopup, Alert, SearchSelectBox, MultiSelectoption, Tooltip, RadioContainer} from '../../../CommonComponents';
import ProductCategoryEntry from '../ProductCategoryEntry';
import ProductSubCategoryEntry from '../ProductSubCategoryEntry';

import ProductEntry from '../ProductEntry';
import SupplierEntry from '../SupplierEntry';
import AddEditPopup from './AddEditPopup';
import AddEditContract from './AddEditContract';
import AddTierDescription from './AddTierDescription'
import RenewContract from './RenewContract';
import { isAuthorized } from 'client/utils/Permissions';

export interface IBreadcrumb {
    text: string,
    key: string,
    href?: string,
    onClick?: React.MouseEventHandler<HTMLDivElement>,
}
interface IData {
    Tier: string,
    MinValue: string,
    MaxValue: string,
    Percentage: string
}
interface IColumn {
    key: string,
    title?: string,
    width: string,
    style?: any
}
interface IDropdown {
    key: string,
    value: string,
    text: string
}
interface Iprops {
    breadcrumlist: IBreadcrumb[],
    increment: React.MouseEventHandler<HTMLDivElement>,
    decrement: React.MouseEventHandler<HTMLDivElement>,
    count: number,
    onMonthIncrement: React.MouseEventHandler<HTMLDivElement>,
    monthList: string | undefined,
    onMonthIncrementEnd: React.MouseEventHandler<HTMLDivElement>,
    monthListEnd: string | undefined,
    onMonthDecrement: React.MouseEventHandler<HTMLDivElement>,
    onMonthDecrementEnd: React.MouseEventHandler<HTMLDivElement>,
    productDisabled: boolean,
    percentage: any,
    productSubCategoryDisabled:boolean,
    percentageIncrement: React.MouseEventHandler<HTMLDivElement>,
    salesGoal: any,
    percentageDecrement: React.MouseEventHandler<HTMLDivElement>
    salesGoalIncrement: React.MouseEventHandler<HTMLDivElement>,
    salesGoalDecrement: React.MouseEventHandler<HTMLDivElement>,
    data: IData[],
    column: IColumn[]
    handleHome: Function,
    productCategoryDisabled: boolean,
    addEditPopup:boolean,
    onShowAddEdit:Function
    onHideAddEdit:Function,
    programOptions: IDropdown[],
    supplierOptions: IDropdown[],
    handleInputChange: Function,
    checkforTempExtendPopup: Function,
    handleDateChange: Function
    values: any,
    RebatePeriodOption:any,
    enableAllSpend: boolean,
    onAllSpendCheck:Function,
    AllSpend: boolean,
    product:boolean,
    productCategory:boolean
    productSubCategory:boolean,
    growthTarget:boolean,
    onChangeProduct:Function
    onChangeproductCategory:Function
    onChangeProductSubCategory:Function
    onChangeGrowthTarget:Function,
    productOptions:IDropdown[],
    productCategoryOptions:IDropdown[],
    productSubCategoryOption:IDropdown[],
    percentageDisabled:boolean,
    salesForceDisabled:boolean,
    disabledPercentageText:boolean,
    disabledSalesGoalText:boolean,
    onChangePercentage:Function
    onChangeSalesGoal:Function,
    percentageCheck: boolean,
    salesGoalCheck: boolean,
    calculationFrequency:IDropdown[],
    paymentMethod:any,
    calculationType:IDropdown[]
    calculationMethod:IDropdown[],
    onAddEditProgram:Function,
    onAddEditContract: Function;
    handleAddEditInputChange:Function
    onAdd:React.MouseEventHandler<HTMLDivElement>,
    onDelete:Function,
    addEditList:any,
    addText:string,
    onChangeSalesGoals: React.ChangeEventHandler<HTMLInputElement>,
    onBlurSalesGoal:React.FocusEventHandler<HTMLInputElement>,
    onChangePercentageValue: React.ChangeEventHandler<HTMLInputElement>,
    getUpdatedValue:Function,
    showSupplier:boolean,
    showSupplierModal:Function,
    hideSupplierModal: Function,
    showProduct:boolean,
    hideProductModal:Function,
    showProductModal:Function,
    showProductCategory:boolean,
    showProductCategoryModal:Function,
    hideProductCategoryModal:Function,
    showProductSubCategory:boolean
    showProductSubCategoryModal:Function
    hideProductSubCategoryModal:Function,
    submitClick:Function,
    onSearch: any,
    submitClicked:boolean
    errors:any,
    submitAddEditClick: Function,
    onEdit:React.MouseEventHandler<HTMLImageElement>,
    programSaved: boolean,
    programDeleted: boolean,
    handleMessage:Function,
    cancelClick: Function,
    submitFlag?: boolean;
    gridData?: any;
    gridColumn?: any;
    isEditMode?: boolean;
    year?: any;
    onYearIncrement?: React.MouseEventHandler<HTMLDivElement>;
    onYearDecrement?: React.MouseEventHandler<HTMLDivElement>;
    addEditContract?: boolean;
    onHideAddEditContract?: Function;
    deadLineDays?: any;
    incrementDeadLine: React.MouseEventHandler<HTMLDivElement>,
    decrementDeadLine: React.MouseEventHandler<HTMLDivElement>,
    dollorAmount?: any;
    onChangeNoOfYears: React.ChangeEventHandler<HTMLInputElement>;
    noOfyearsIncrement: React.MouseEventHandler<HTMLDivElement>;
    noOfyearsDecrement: React.MouseEventHandler<HTMLDivElement>;
    noOfYears?: any;
    onChangeDollorAmount: React.ChangeEventHandler<HTMLInputElement>;
    dollorAmountIncrement: React.MouseEventHandler<HTMLDivElement>;
    dollorAmountDecrement: React.MouseEventHandler<HTMLDivElement>;
    allContractDetails?: any;
    handleAddEditContractInputChange?: any;
    onAddContract: React.MouseEventHandler<HTMLDivElement>;
    onDeleteContract: Function;
    addEditContractList?: any;
    addContractText?: any;
    onSearchContract?: any;
    onEditContract: React.MouseEventHandler<HTMLImageElement>;
    submitAddEditContactClick: any;
    basedOnOptions?: any;
    onAddNewProgram?: any;
    newProgram?: any;
    otherRebatePeriodDisabled?: boolean;
    handleProgramYearChange?: any;
    handleDeadLineDays?: any;
    handleNetDays?: any;
    programError?: any;
    handleAddRebateTier?: any;
    addTier?: boolean;
    onHideAddTier?: any;
    handleAddTierSave?: any;
    handleTierDescriptionInput?: any;
    tierErrors?: any;
    saveTierBtn?: any;
    tierDescription?: any;
    selectedCalculationType?: any;
    validateTableData?: any;
    getValue?: any;
    getError?: any;
    getExternalErrorMessage?: any;
    showAlert?: any;
    getHeader?: any;
    addEditSupplierLink?: any;
    customDelete?: any;
    rebatePercentage?: any;
    onChangeRebatePercentageValue?: any;
    rebatePercentageIncrement?: any;
    rebatePercentageDecrement?: any;
    showGrid?: any;
    handleProduct?: any;
    handleCategory?: any;
    handleSubCategory?: any;
    selectedProducts?: any;
    selectedCategory?: any;
    selectedSubCategory?: any;
    contractByIdLoading?: boolean;
    tableDataLoading?:boolean;
    AddEditProgramLinkText?:any;
    checkReceivedOptions?:any;
    fieldInfoDescription?:any;
    handleCheckReceivedRadio?:any;
    isView?:any;
    renewContract: Function;
    renewContractClicked?:boolean;
    closeRenewContract: Function;
    excludeCreditCardSpendChange?: any;
    isExcludeCreditCardSpend?: any;
    isShowDisabled?: any
    setIsShowDisabled?: any
    tempExtendPopup?: any
    setTempExtendPopup?: any
    allActiveContracts?: any
    setContractWarning?: any
    contractWarning?: any
}

const RebateProgramEntry = (props:Iprops) => {
    const {fieldInfoDescription} = props;
    const getEditModeContent = () => {
        return (
            <>
            <fieldset className='add-edit-view-container-fieldset' disabled={props.isView}>
                <div>
                {props.contractByIdLoading && <div className='loader-wrapper'><div className='loading-spinner'></div></div> }
                    <Row className='mb-3'>
                        <Col xs={12} md={4} lg={3} className='mb-3'>
                            <div className='clearfix'>
                                <Label className="float-left" required>Vendor </Label>
                                {!props.isView && props.addEditSupplierLink()} <Tooltip content={`${fieldInfoDescription.Vendor ? fieldInfoDescription.Vendor : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                            </div>   
                            <div className={(props.isView || props.renewContractClicked) ? "disable-dropdown" : ""}>
                            <SearchSelectBox
                                placeholder="Select"
                                options={props.supplierOptions}
                                filterOptionValue={3}
                                onChange={(item: any, e: any) => props.handleInputChange(e, item)}
                                value={props.getValue("supplierId") ? props.supplierOptions?.find((i: any) => i.key === props.getValue("supplierId")): ""}
                                name='supplierId'
                                className="search-dropdown"
                            />
                            </div>
                        </Col>
                        <Col xs={12} md={4} lg={3}>
                            <div className='clearfix'>
                                <Label className="float-left" required>Program</Label> 
                                <Tooltip content={`${fieldInfoDescription.Program ? fieldInfoDescription.Program : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                                {!props.isView && <a href="/" onClick={e => {e.preventDefault();props.onAddEditProgram()}} className={`float-right addEdit ${props.newProgram && !props.getValue("supplierId") && 'disableLink'}`}>{props.AddEditProgramLinkText()}</a>}
                            </div>
                            <div className={props.newProgram && !props.getValue("supplierId") || (props.isView || props.renewContractClicked) ? "disable-dropdown" : ""}>
                                <SearchSelectBox
                                    placeholder="Select Existing"
                                    options={props.programOptions}
                                    filterOptionValue={3}
                                    onChange={(item: any, e: any) => props.handleInputChange(e, item)}
                                    value={props.programOptions?.find((i: any) => i.key === props.getValue("program")) || ''}
                                    name='program'
                                    className="search-dropdown"
                                />
                            </div>
                        </Col>
                        <Col xs={12} md={4} lg={3}>
                            <div className='clearfix'>
                                <Label className="float-left" required>Contract Details</Label>
                                <Tooltip content={`${fieldInfoDescription.contractDetails ? fieldInfoDescription.contractDetails : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                                {!props.isView && <a href="/" onClick={e => {e.preventDefault();props.onAddEditContract()}} className={`float-right addEdit ${!(props.getValue("supplierId") && props.getValue("program")) && 'disableLink'}`}>{props.AddEditProgramLinkText()}</a>}
                            </div>
                            <div className={(!(props.getValue("supplierId") && props.getValue("program"))) || props.isView ? "disable-dropdown" : ""}>
                                <SearchSelectBox
                                    placeholder="Select Existing"
                                    options={props.allActiveContracts}
                                    filterOptionValue={3}
                                    onChange={(item: any, e: any) => props.handleInputChange(e, item)}
                                    value={props.allActiveContracts?.find((i: any) => i.key === props.getValue("contract")) || ''}
                                    name='contract'
                                    className="search-dropdown"
                                />
                            </div>
                        </Col>
                        <Col xs={12} md={4} lg={3} className='renew-contract-button'>
                        {!props.isView && <PrimaryButton text={'Renew Contract'} disabled={!props.getValue("contract") || !isAuthorized("RebateAgreements", "create")} onClick={props.renewContract}></PrimaryButton>}
                        <Checkbox name='tempExtendContract' label="Temporarily Extend" disabled={!props.getValue("contract") || !!props.getValue("evergreen")} className="mt-2 ms-3" checked={!!props.getValue("tempExtendContract")} onChange={(e:any, item:any)=>{
                            props.handleInputChange(e, item);
                            !item && props?.checkforTempExtendPopup()} 
                        }/>
                        <Tooltip content="When this setting is not enabled, the contract will no longer be included in calculations after the end date has passed. When it is enabled, the contract will continue to be included in spend & rebate calculations, beyond the end date, until the flag is unchecked, the contract is renewed or the contract is no longer marked active."><span className="fa fa-info-circle ms-2"></span></Tooltip>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4} lg={3} xl={3} className='mb-3'>
                            <div className="clearfix">
                                <Label className="float-left" required>Start Date</Label>
                                <Tooltip content={`${fieldInfoDescription.startDate ? fieldInfoDescription.startDate : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                            </div>
                            <Datepicker value={props.getValue("startDate") || "" } onChangeDate={(date:Date)=> {props.handleDateChange(date, 'startDate')}} allowTextInput disabled={!props.getValue("contract") || props.isView} placeholder="Select Start Date"/>
                            {props.getExternalErrorMessage("startDate")}
                        </Col>
                        <Col xs={12} md={4} lg={3} xl={3} className='mb-3'>
                            <div className="clearfix">
                                <Label className="float-left" required={!props.getValue("evergreen")}>End Date</Label>
                                <Tooltip content= {`${fieldInfoDescription.endDate ? fieldInfoDescription.endDate : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                            </div>
                            <Datepicker value={props.getValue("endDate")} minDate={props.getValue("startDate")} onChangeDate={(date:Date)=> {props.handleDateChange(date, 'endDate')}} allowTextInput disabled={!props.getValue("contract") || props.getValue("evergreen") || props.isView} placeholder="Select End Date"/>
                            {props.getExternalErrorMessage("endDate")}
                        </Col>
                        <Col xs={12} md={3} lg={2} xl={1} className='mb-3 mt-4 pt-3 px-xl-0'>
                            <div className="clearfix">
                            <Checkbox name='evergreen' checked={!!props.getValue("evergreen")} onChange={props.handleInputChange } label='Evergreen' type='small' disabled={!props.getValue("contract") || !!props.getValue("tempExtendContract")} className="float-left"/>
                            <Tooltip content={`${fieldInfoDescription.Evergreen ? fieldInfoDescription.Evergreen : ''}`}><span className="fa fa-info-circle ms-1" /></Tooltip>
                            </div>
                        </Col>
                        <Col xs={12} md={3} lg={2} xl={2} className='mb-3'>
                            <div className="clearfix">
                                <Label className="float-left" required>Based On</Label>
                                <Tooltip content={`${fieldInfoDescription.basedOn ? fieldInfoDescription.basedOn : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                            </div>
                            <DropdownBox placeholder='Select' selectedKey={props.getValue("basedOn")} name={'basedOn'} options={props.basedOnOptions} onChange={props.handleInputChange} disabled={!props.getValue("contract") || props.isView} errorMessage={props.getError("basedOn")}/>
                        </Col>
                        <Col xs={12} md={2} lg={2} xl={1} className='px-xl-0'>
                            <div className="clearfix">
                                <Label className="float-left" required>Program Year</Label>
                                <Tooltip content={`${fieldInfoDescription.programYear ? fieldInfoDescription.programYear : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                            </div>
                            <InputSpinner type='number' value={props.year} onChange={props.handleProgramYearChange} onIncrement={props.onYearIncrement} onDecrement={props.onYearDecrement} disabled={!props.getValue("contract")} placeholder='YYYY'/>
                            {props.getExternalErrorMessage("programYear") || ((props.getValue("startDate") || props.getValue("endDate")) && props.year && props?.errors?.programYear && <p className='mt-1 requiredText'>{props.errors.programYear}</p>)}
                        </Col>
                        <Col xs={12} md={3} lg={2} xl={2} className='mb-3'>
                            <div className="clearfix">
                                <Label className="float-left" >End of year Days to Pay</Label>
                                <Tooltip content={`${fieldInfoDescription.endofyearDaystoPay ? fieldInfoDescription.endofyearDaystoPay : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                            </div>
                            <Quantity type='number' increment={props.incrementDeadLine} decrement={props.decrementDeadLine} onChange={props.handleDeadLineDays} value={props.deadLineDays} disableFlg={!props.getValue("contract")}/>
                        </Col>
                    </Row>
                </div>
                <div className='mb-3'>
                    <p className='heading'>Billing Terms</p>
                    <div className='cardBody'>
                        <Row>
                            <Col xs={12} md={4} lg={3} className='mb-3'>
                                <div className="clearfix">
                                    <Label className="float-left" required>Effective Date</Label>
                                    <Tooltip content={`${fieldInfoDescription.effectiveDate ? fieldInfoDescription.effectiveDate : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                                </div>
                                
                                <Datepicker value={props.getValue("billingDate")} minDate={props.getValue("startDate")} maxDate={props.getValue("endDate")} onChangeDate={(date:Date)=> {props.handleDateChange(date, 'billingDate')}} allowTextInput disabled={!props.getValue("contract") || props.isView} placeholder="Select Effective Date"/>
                                {props.getExternalErrorMessage("billingDate")}
                            </Col>
                            <Col xs={12} md={4} lg={3} className='mb-3 mt-4 pt-3'>
                                <div className="clearfix">
                                    <Checkbox name='withinTerms' checked={!!props.getValue("withinTerms")} onChange={props.handleInputChange } label='Within Terms' type='small' disabled={!props.getValue("contract")} className="float-left"/>
                                    <Tooltip content={`${fieldInfoDescription.withinTerms ? fieldInfoDescription.withinTerms : ''}`}><span className="fa fa-info-circle ms-1" /></Tooltip>
                                </div>
                            </Col>
                            <Col xs={12} md={4} lg={3} className='mb-3'>
                                <div className="clearfix">
                                    <Label className="float-left" required={props.getValue("withinTerms")}>NET Days</Label>
                                    <Tooltip content={`${fieldInfoDescription.netDays ? fieldInfoDescription.netDays : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                                </div>
                                <Quantity type='number' increment={props.increment} decrement={props.decrement} onChange={props.handleNetDays} value={props.count} disableFlg={!props.getValue("contract")}/>
                                {props.getExternalErrorMessage("Quantity")}
                            </Col>
                            <Col xs={12} md={4} lg={3} className='mb-3 mt-4'>
                                <div className="clearfix">
                                    <Checkbox name='eop' checked={!!props.getValue("eop")} onChange={props.handleInputChange } label='Early Order Program (EOP)' type='small' disabled={!props.getValue("contract")} className="float-left"/>
                                    <Tooltip content={`${fieldInfoDescription.earlyOrderProgram ? fieldInfoDescription.earlyOrderProgram : ''}`}><span className="fa fa-info-circle ms-1" /></Tooltip>
                                </div>
                            </Col>
                            <Col xs={12} md={4} lg={3}>
                                <div className="clearfix">
                                    <Label className="float-left" required>Rebate Period</Label>
                                    <Tooltip content={`${fieldInfoDescription.rebatePeriod ? fieldInfoDescription.rebatePeriod : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                                </div>
                                <DropdownBox placeholder='Select' name={'rebatePeriodId'} selectedKey={props.getValue("rebatePeriodId")} onChange={props.handleInputChange } options={props.RebatePeriodOption} disabled={!props.getValue("contract") || props.isView} errorMessage={props.getError("rebatePeriodId")}/>
                            </Col>
                            <Col xs={12} md={4} lg={3}>
                                <div className="clearfix">
                                    <Label className="float-left" required>Rebate Period Start Month</Label>
                                    <Tooltip content={`${fieldInfoDescription.rebatePeriodStartMonth ? fieldInfoDescription.rebatePeriodStartMonth : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                                </div>
                               
                                <InputSpinner type='text' value={props.monthList} readOnly onIncrement={props.onMonthIncrement} onDecrement={props.onMonthDecrement} disabled={!props.getValue("contract") || props.otherRebatePeriodDisabled}/>
                            </Col>
                            <Col xs={12} md={4} lg={3}>
                                <div className="clearfix">
                                    <Label className="float-left" required>Rebate Period End Month</Label>
                                    <Tooltip content={`${fieldInfoDescription.rebatePeriodEndMonth ? fieldInfoDescription.rebatePeriodEndMonth : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                                </div>
                                
                                <InputSpinner type='text' value={props.monthListEnd} readOnly onIncrement={props.onMonthIncrementEnd} onDecrement={props.onMonthDecrementEnd} disabled={!props.getValue("contract") || props.otherRebatePeriodDisabled}/>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='mb-3'>
                    <p className='heading'>Rebate Type</p>
                    <div className='cardBody'>
                        <Row>
                            <Col xs={12} md={4} lg={3}>
                                <div className='clearfix'>
                                    <Checkbox defaultChecked={false}  label='All Spend' type='small' checked={!!props.AllSpend} onChange={props.onAllSpendCheck} disabled={!props.getValue("contract")} className="float-left"/>
                                    <Tooltip content={`${fieldInfoDescription.allSpendRebateType ? fieldInfoDescription.allSpendRebateType : ''}`}><span className="fa fa-info-circle ms-1 float-right" /></Tooltip>
                                </div>
                            </Col>
                        </Row>
                        <Row className="multi-select-width">
                            <Col xs={12} md={4} lg={4} className='mb-3 selected-products'>
                                <Checkbox defaultChecked={false} label='Product' checked={!!props.product} onChange={props.onChangeProduct} className={'mb-3'} type='small' disabled={props.enableAllSpend || !props.getValue("contract") || props.isView}/>
                                <div className='clearfix'>
                                    <div className="clearfix">
                                        <div className="float-left labelLeft">
                                            <Label className="float-left">Product</Label>
                                            <Tooltip content={`${fieldInfoDescription.Product ? fieldInfoDescription.Product : ''}`}><span className="fa fa-info-circle ms-1"></span></Tooltip>
                                        </div>
                                        <div className="float-right">
                                        {!props.isView && <a href="/" onClick={e => {e.preventDefault();props.showProductModal()}} className={`float-right addEdit ${!props.product || !props.getValue("contract") && 'disableLink'}`}>{props.AddEditProgramLinkText()}</a>}
                                        </div>
                                    </div>
                                </div>   
                                <Tooltip content={(props.selectedProducts.length > 1 && props.selectedProducts.length < props.productOptions.length) 
                                    ? `Total selected: ${props.selectedProducts.length}
                                    \n${props.selectedProducts.map((ele:any) => `\u00A0${ele.label}` )}` 
                                    : '' }
                                    >
                                    <div className={`${props.isView ? 'disable-dropdown': ''}`}>
                                        <MultiSelectoption value={props.selectedProducts}  options={props.productOptions} onChange={props.handleProduct} disabled={!props.product || !props.getValue("contract") || props.isView} overrideStrings={{allItemsAreSelected: "All Product are selected"}}/>
                                    </div>                              
                                </Tooltip>
                                {props.getExternalErrorMessage("productId")}
                            </Col>
                            <Col xs={12} md={4} lg={4} className='mb-3 selected-product-categories'>
                                <Checkbox defaultChecked={false} checked={!!props.productCategory} label='Product Categories' className={'mb-3'} onChange={props.onChangeproductCategory}  type='small' disabled={props.enableAllSpend || !props.getValue("contract") || props.isView}/>
                                <div className='clearfix'>
                                    <div className="clearfix">
                                        <div className="float-left product-categories-labelLeft">
                                            <Label className="float-left">Product Categories</Label>
                                            <Tooltip content={`${fieldInfoDescription.productCategories ? fieldInfoDescription.productCategories : ''}`}><span className="fa fa-info-circle ms-1"></span></Tooltip>
                                        </div>
                                        <div className="float-right">
                                        </div>
                                    </div>
                                </div>   
                                <Tooltip content={(props.selectedCategory.length > 1 && props.selectedCategory.length < props.productCategoryOptions.length) 
                                    ? `Total selected: ${props.selectedCategory.length} 
                                    \n${props.selectedCategory.map((ele:any) => `\u00A0${ele.label}`)}` 
                                    : '' }
                                    >
                                <div className={`${props.isView ? 'disable-dropdown': ''}`}>
                                    <MultiSelectoption value={props.selectedCategory} options={props.productCategoryOptions} onChange={props.handleCategory} disabled={!props.productCategory || !props.getValue("contract") || props.isView} overrideStrings={{allItemsAreSelected: "All Product Categories are selected"}}/>
                                </div>
                                </Tooltip>
                                {props.getExternalErrorMessage("categoryId")}
                            </Col>
                            <Col xs={12} md={4} lg={4} className='mb-3 selected-subcategories'>
                                <Checkbox defaultChecked={false} checked={!!props.productSubCategory} label='Product Subcategories' className={'mb-3'} onChange={props.onChangeProductSubCategory} type='small' disabled={props.enableAllSpend || !props.getValue("contract") || props.isView}/>
                                <div className='clearfix'>
                                    <div className="clearfix">
                                        <div className="float-left product-subcategories-labelLeft">
                                            <Label className="float-left">Product Subcategories</Label>
                                            <Tooltip content={`${fieldInfoDescription.productSubcategories ? fieldInfoDescription.productSubcategories : ''}`}><span className="fa fa-info-circle ms-1"></span></Tooltip>
                                        </div>
                                        <div className="float-right">
                                        </div>
                                    </div>
                                </div>   
                                <Tooltip 
                                content={(props.selectedSubCategory.length > 1 && props.selectedSubCategory.length < props.productSubCategoryOption.length) 
                                ? `Total selected: ${props.selectedSubCategory.length}
                                \n${props.selectedSubCategory.map((ele:any) => `\u00A0${ele.label}` )}` 
                                : '' }
                                >
                                <div className={`${props.isView ? 'disable-dropdown': ''}`}>
                                    <MultiSelectoption value={props.selectedSubCategory} options={props.productSubCategoryOption} onChange={props.handleSubCategory } disabled={!props.productSubCategory || !props.getValue("contract") || props.isView} overrideStrings={{allItemsAreSelected: "All Product Subcategories are selected"}}/>
                                </div>
                                </Tooltip>
                                {props.getExternalErrorMessage("subCategoryId")}
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='mb-3'>
                    <p className='heading'>Restrictions</p>
                    <div className='cardBody'>
                        <Row>
                            <Col xs={12} md={6} lg={3}>
                                <div className='clearfix'>
                                    <Checkbox label='Exclude Credit Card Spend' type='small' checked={props?.isExcludeCreditCardSpend} onChange={props.excludeCreditCardSpendChange} disabled={!props.getValue("contract") || props?.isView} className="float-left"/>
                                    <Tooltip content={`${fieldInfoDescription.excludeCreditCardSpend ? fieldInfoDescription.excludeCreditCardSpend : ''}`}><span className="fa fa-info-circle ms-1 float-right" /></Tooltip>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='mb-3'>
                    <div className="calcType clearfix">
                        <div className="calcTypeField">
                            <div className="clearfix">
                                <Label className="float-left" required>Calculation Type</Label>
                                <Tooltip content={`${fieldInfoDescription.calculationsType ? fieldInfoDescription.calculationsType : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                            </div>
                            <DropdownBox placeholder='Select' selectedKey={props.getValue("calculationTypeId")} options={props.calculationType} name={'calculationTypeId'} onChange={props.handleInputChange } disabled={!props.getValue("contract") || props.isView} errorMessage={props.getError("calculationTypeId")}/>
                        </div>
                        {
                            (props?.values?.calculationTypeId == 3 || props?.values?.calculationTypeId == 4) &&
                                <div className='mb-3 calcTypeFieldval'>
                                     <div className='clearfix'>
                                         <Checkbox defaultChecked={true} label='Percentage' checked={!!props.percentageCheck}  onChange={props.onChangePercentage} className={'mb-2 float-left ' + (props?.values?.calculationTypeId == 4 && "mt-4")} type='small' disabled={!props.getValue("contract")}/>
                                         <Tooltip content={`${fieldInfoDescription.Percentage ? fieldInfoDescription.Percentage : ''}`} ><span className={`fa fa-info-circle ms-1 ${ props?.values?.calculationTypeId == 4 && 'mt-4'}`}></span></Tooltip>
                                    </div>
                                    {props?.values?.calculationTypeId == 3 &&
                                        <InputSpinner type='number' value={props.percentage} onChange={props.onChangePercentageValue} onIncrement={props.percentageIncrement} onDecrement={props.percentageDecrement} disabled={!props.getValue("contract") || props.isView}/>
                                    }
                                    {props.getExternalErrorMessage("Percentage")}
                                </div>
                        }
                        {
                            props?.values?.calculationTypeId == 2 &&
                                <div className='mb-3 calcTypeFieldval'>
                                    <div className='clearfix'>
                                    <Label className='float-left'>Percentage</Label>
                                    <Tooltip content={`${fieldInfoDescription.Percentage ? fieldInfoDescription.Percentage : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                                    </div>
                                    <InputSpinner type='text' value={props.percentage} onChange={props.onChangePercentageValue} onIncrement={props.percentageIncrement} onDecrement={props.percentageDecrement} disabled={!props.getValue("contract")}/>
                                    {props.getExternalErrorMessage("flatPercentage")}
                                </div>
                        }
                        {
                            (props?.values?.calculationTypeId == 3 || props?.values?.calculationTypeId == 4) &&
                                <div className='mb-3 calcTypeFieldval'>
                                    <div className='clearfix'>
                                        <Checkbox defaultChecked={false} label='Sales Goal' checked={!!props.salesGoalCheck} onChange={props.onChangeSalesGoal} className={'mb-2 float-left ' + (props?.values?.calculationTypeId == 4 && "mt-4")} type='small' disabled={!props.getValue("contract")}/>
                                        <Tooltip content={`${fieldInfoDescription.salesGoal ? fieldInfoDescription.salesGoal : ''}`} ><span className={`fa fa-info-circle ms-1 ${ props?.values?.calculationTypeId == 4 && 'mt-4'}`}></span></Tooltip>
                                    </div>
                                    {props?.values?.calculationTypeId == 3 &&
                                        <InputSpinner type='number' onChange={props.onChangeSalesGoals} onBlur={props.onBlurSalesGoal} value={props.salesGoal} onIncrement={props.salesGoalIncrement} onDecrement={props.salesGoalDecrement} disabled={!props.getValue("contract") || props.isView}/>
                                    }
                                    {props.getExternalErrorMessage("SalesGoal")}
                                </div>
                        }
                        {
                            props?.values?.calculationTypeId == 3 &&
                                <div className='mb-3 calcTypeFieldval'>
                                    <div className='clearfix'>  
                                         <Label className='float-left'>Rebate Percentage</Label>
                                         <Tooltip content={`${fieldInfoDescription.rebatePercentage ? fieldInfoDescription.rebatePercentage : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                                    </div>  
                                    <InputSpinner type='text' value={props.rebatePercentage} onChange={props.onChangeRebatePercentageValue} onIncrement={props.rebatePercentageIncrement} onDecrement={props.rebatePercentageDecrement} disabled={!props.getValue("contract")}/>
                                    {props.getExternalErrorMessage("rebatePercentage")}
                                </div>
                        }
                        {
                            (props?.values?.calculationTypeId === 5 || props?.values?.calculationTypeId === 6 || props?.values?.calculationTypeId == 7) &&
                                <div className='mb-3 calcTypeFieldval DollarLabel'>
                                    <div className={`clearfix`}>
                                        <Label className='float-left'>Dollar Amount</Label>
                                        <Tooltip content={`${fieldInfoDescription.dollarAmount ? fieldInfoDescription.dollarAmount : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                                    </div>
                                    <InputSpinner type='number' value={props.dollorAmount} onChange={props.onChangeDollorAmount} onIncrement={props.dollorAmountIncrement} onDecrement={props.dollorAmountDecrement} disabled={!props.getValue("contract")}/>
                                    {props.getExternalErrorMessage("dollor") || (props?.errors?.dollor && <p className='mt-1 requiredText'>{props.errors.dollor}</p>)}
                                    
                                </div>
                                

                        }
                        {
                            props?.values?.calculationTypeId === 6 &&
                                <div className='mb-3 calcTypeFieldval'>
                                     <div className={`clearfix`}>
                                        <Label className='float-left'>No.of Years</Label>
                                        <Tooltip content={`${fieldInfoDescription.NumberOfYears ? fieldInfoDescription.NumberOfYears : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                                    </div>
                                    <InputSpinner type='number' value={props.noOfYears} onChange={props.onChangeNoOfYears} onIncrement={props.noOfyearsIncrement} onDecrement={props.noOfyearsDecrement} disabled={!props.getValue("contract")}/>
                                    {props.getExternalErrorMessage("noOfYears") || (props?.errors?.noOfYears && <p className='mt-1 requiredText'>{props.errors.noOfYears}</p>)}
                                    
                                </div>
                                

                        }
                    </div>
                </div>
                {
                    props.data && (props?.values?.calculationTypeId == 4 || props?.values?.calculationTypeId == 1) &&
                    <div className='mb-4'>
                        <p className='heading'>TIER DESCRIPTION</p>
                        <Row>
                            <Col xs={12} md={4} lg={3} className="mb-3">
                            <div className="clearfix">
                                <Label className="float-left" required>Calculation Method</Label>
                                <Tooltip content={`${fieldInfoDescription.calculationMethod ? fieldInfoDescription.calculationMethod : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                            </div>   
                                <DropdownBox placeholder='Select' selectedKey={props.getValue("calculationMethodId")} options={props.calculationMethod} name={'calculationMethodId'} onChange={props.handleInputChange } disabled={!props.getValue("contract") || props.isView} errorMessage={props.getError("calculationMethodId")}/>
                            </Col>
                        </Row>
                        {
                            props.getValue("contract") && props.showGrid &&
                            <div className={`editable-table rebate-table TierTable ${props.isView && 'disableLink'}`}>
                                <SofturaTable data={props.data} columns={props.column} sort={false} filter={true} editable={true}
                                    validation = {props.validateTableData}
                                    customAddRow={props.handleAddRebateTier}
                                    nonEditableFields={['Tier','editColumn']}
                                    getUpdatedValue={props.getUpdatedValue}
                                    pagination={false}
                                    customDelete={props.customDelete}
                                />
                                {props.getExternalErrorMessage("tierDescription")}
                            </div>
                        }
                    </div>
                }
            
                <div className='mb-3'>
                    <Row>
                        <Col xs={12} md={4} lg={3}>
                            <div className="clearfix">
                                <Label className="float-left" required>Collection Frequency</Label>
                                <Tooltip content={`${fieldInfoDescription.collectionFrequency ? fieldInfoDescription.collectionFrequency : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                            </div>
                            <DropdownBox placeholder='Select' selectedKey={props.getValue("rebateFrequencyId")} options={props.calculationFrequency} name={'rebateFrequencyId'} onChange={props.handleInputChange } disabled={!props.getValue("contract") || props.isView || (props.getValue("calculationTypeId") === 6)} errorMessage={props.getError("rebateFrequencyId")}/>
                        </Col>
                        <Col xs={12} md={4} lg={3}>
                            <div className="clearfix">
                                <Label className="float-left" required>Payment Method</Label>
                                <Tooltip content={`${fieldInfoDescription.paymentMethod ? fieldInfoDescription.paymentMethod : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                            </div>
                            
                            <DropdownBox placeholder='Select' selectedKey={props.getValue("rebatePayMethodId")} options={props.paymentMethod} name={'rebatePayMethodId'} onChange={props.handleInputChange } disabled={!props.getValue("contract") || props.isView} errorMessage={props.getError("rebatePayMethodId")}/>
                        </Col>
                        <div hidden>
                        <Col xs={12} md={4} lg={3}>
                            <div className="clearfix">
                                <Label className="float-left">Expected Payment Date</Label>
                                <Tooltip content={`${fieldInfoDescription.expectedPaymentDate ? fieldInfoDescription.expectedPaymentDate : ''}`}><span className="fa fa-info-circle ms-1"></span></Tooltip>
                            </div>
                           
                            <Datepicker value={props.getValue("expectedPaymentDate")} onChangeDate={(date:Date)=>{props.handleDateChange(date, 'expectedPaymentDate')}} allowTextInput disabled={!props.getValue("contract") || props.isView} placeholder="Select Expected Payment Date"/>
                        </Col>   
                        <Col xs={12} md={4} lg={3} className={`check-received-radio-container ${!props.getValue("contract") || props.isView ? 'disableLink' : ''}`}>
                            <div className="clearfix">
                                <Label className="float-left" required>Check Received</Label>
                                <Tooltip content={`${fieldInfoDescription.checkReceived ? fieldInfoDescription.checkReceived : ''}`}><span className="fa fa-info-circle ms-1"></span></Tooltip>
                            </div>
                            <RadioContainer selectedKey={props.getValue('checkReceived')} options={props.checkReceivedOptions} onChange={props.handleCheckReceivedRadio} id='checkReceived'/>
                            {props.getExternalErrorMessage("checkReceived")}
                        </Col>
                        { props.getValue('checkReceived') === 'yes' &&
                        <Col xs={12} md={4} lg={3} className="mt-2 StatusControl mb-3">
                            <div className="clearfix">
                                <Label className="float-left" required>Check Received Date</Label>
                                <Tooltip content={`${fieldInfoDescription.checkReceivedDate ? fieldInfoDescription.Active : ''}`}><span className="fa fa-info-circle ms-1"></span></Tooltip>
                            </div>
                           <Datepicker value={props.getValue("rebateCheckdate")} onChangeDate={(date:Date)=> {props.handleDateChange(date, 'rebateCheckdate')}} allowTextInput disabled={!props.getValue("contract") || props.isView} placeholder="Select Check Received Date"/>
                           {props.getExternalErrorMessage("rebateCheckdate")}
                        </Col>
                        }
                        </div>
                        <Col xs={12} md={4} lg={12} className="mt-2 StatusControl">
                            <div className='clearfix'>
                                <Checkbox checked={!!props.getValue("active")} label='Enabled' onChange={props.handleInputChange} className='autoCorrect float-left' name="active" disabled={!props.getValue("contract") || !isAuthorized("RebateAgreements", "delete")} />
                                <Tooltip content={`${fieldInfoDescription.Active ? fieldInfoDescription.Active : ''}`}><span className="fa fa-info-circle ms-1"></span></Tooltip>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='clearfix'>
                    <div className='float-right'>
                        <OutlineButton text={'Cancel'} onClick={props.cancelClick} className='mr-2'></OutlineButton>
                       {!props.isView && <PrimaryButton text={'Submit'} disabled={!props.getValue("contract") || !isAuthorized("RebateAgreements", "create")} onClick={props.submitClick}></PrimaryButton>}
                    </div>
                </div>
                </fieldset>
            </>
        )
    }

    return <div>
        <Breadcrumb items={props.breadcrumlist} maxDisplayedItems='5' />
        <Card>
            <Alert showAlert={props.showAlert(props.programError)} text={'Something went wrong please try after sometime'} dismissAlert={props.handleMessage} type="error" className="mb-2" />
            <Alert showAlert={props.showAlert(props.programSaved)} text='Saved Successfully' dismissAlert={props.handleMessage} type="success" className="mb-3" />
            <Row>
                <Col xs={12} md={8} lg={8}>
                    <p className='heading pt-2'>
                        {props.getHeader()}
                    </p>
                </Col>
                <Col xs={12} md={4} lg={4}>
                    <div className="showCheckboxSection mb-2">
                        {!props.isEditMode && <div className="showCheckbox me-3">
                            <Checkbox
                                checked={props.isShowDisabled}
                                onChange={() => props.setIsShowDisabled(!props.isShowDisabled)}
                            />
                            <p className="checkBoxLabel">Show Disabled</p>
                        </div>}
                        <div>
                            {
                                !props.isEditMode &&
                                <div>
                                    <PrimaryButton text={'Add New Rebate Program'} onClick={props.onAddNewProgram} disabled={!isAuthorized("RebateAgreements", "create")}></PrimaryButton>
                                </div>
                            }
                        </div>
                    </div>
                </Col>
            </Row>
             <div className = {`${!props.isEditMode ? 'ShowEditProgram' : 'HideEditProgram'}`}>
                {
                <>
                <div className='rebate-table'>
                    <SofturaTable data={props.gridData} columns={props.gridColumn} sort={true} filter={true} rowClick={false} loading={props.tableDataLoading} pageSizes={[10, 25, 50, 100]}/>
                </div>
                </>
                } 
            </div>
            <div className={`${props.isEditMode ? 'ShowEditProgram' : 'HideEditProgram'}`}>
                { getEditModeContent()}
            </div>
          
        </Card>
        {
            props.addEditPopup
                && <ModalPopup
                ShowModalPopupFooter={true}
                ModalPopupTitle="Program Entry"
                ModalPopupType="medium"
                closeModalPopup={props.onHideAddEdit}
                FooterSecondaryBtnTxt='Cancel'
                SecondryBtnOnclick={props.onHideAddEdit}
                >
                   <AddEditPopup
                           handleAddEditInputChange={props.handleAddEditInputChange}
                           onAdd={props.onAdd}
                           onDelete={props.onDelete}
                           addEditList={props.addEditList}
                           addText={props.addText}
                           onSearch={props.onSearch}
                           submitAddEditClick={props.submitAddEditClick}
                           programSaved={props.programSaved}
                           programDeleted={props.programDeleted}
                           handleMessage={props.handleMessage}
                           programError={props.programError}
                   />
                </ModalPopup>
        }
        {
            props.addEditContract
                && <ModalPopup
                ShowModalPopupFooter={true}
                ModalPopupTitle="Add Contract"
                ModalPopupType="medium"
                closeModalPopup={props.onHideAddEditContract}
                FooterSecondaryBtnTxt='Cancel'
                SecondryBtnOnclick={props.onHideAddEditContract}
                >
                   <AddEditContract
                        handleAddEditContractInputChange={props.handleAddEditContractInputChange}
                        onAddContract={props.onAddContract}
                        onDeleteContract={props.onDeleteContract}
                        addEditContractList={props.addEditContractList}
                        addContractText={props.addContractText}
                        onSearchContract={props.onSearchContract}
                        submitAddEditContactClick={props.submitAddEditContactClick}
                        programSaved={props.programSaved}
                        programDeleted={props.programDeleted}
                        handleMessage={props.handleMessage}
                        programError={props.programError}
                   />
                </ModalPopup>
        }
        {
            props.addTier &&
                <ModalPopup
                    ShowModalPopupFooter={true}
                    ModalPopupTitle="Add Tier Description"
                    ModalPopupType="small"
                    closeModalPopup={props.onHideAddTier}
                    FooterPrimaryBtnTxt="Add"
                    PrimaryBtnOnclick={props.handleAddTierSave}
                >
                    <AddTierDescription
                        handleTierDescriptionInput={props.handleTierDescriptionInput}
                        tierErrors={props.tierErrors}
                        saveTierBtn={props.saveTierBtn}
                        tierDescription={props.tierDescription}
                        data={props.data}
                        fieldInfoDescription={fieldInfoDescription}
                    />
                </ModalPopup>
        }
        {
            props.tempExtendPopup &&
                <ModalPopup
                    ShowModalPopupFooter={true}
                    ModalPopupName="ConfirmationPopup"
                    ModalPopupTitle="Confirmation"
                    ModalPopupType="small"
                    closeModalPopup={()=>{props.setTempExtendPopup(false); props.handleInputChange({name: 'tempExtendContract'}, true)}}
                    FooterPrimaryBtnTxt="Confirm"
                    FooterSecondaryBtnTxt='Cancel'
                    PrimaryBtnOnclick={()=>props.setTempExtendPopup(false)}
                    SecondryBtnOnclick={()=>{props.setTempExtendPopup(false); props.handleInputChange({name: 'tempExtendContract'}, true)}}
                >
                    <p>
                        Contract is expired and no replacement contract has been entered. Changing this setting will exempt this contract from future calculations.
                    </p>
                </ModalPopup>
        }
        {
            props.contractWarning &&
                <ModalPopup
                    ShowModalPopupFooter={true}
                    ModalPopupName="ConfirmationPopup"
                    ModalPopupTitle="Note"
                    ModalPopupType="small"
                    closeModalPopup={()=>props.setContractWarning(false)}
                    FooterPrimaryBtnTxt="Ok"
                    PrimaryBtnOnclick={()=>props.setContractWarning(false)}
                >
                    <p>
                    Cannot create contract because a contract with the same Program Year, or an enabled Evergreen contract already exists for this vendor. Please select a different program year, or ensure no other contract is active for the selected program year, to complete setup of this contract.
                    </p>
                </ModalPopup>
        }
        {
            props.showSupplier &&
                <SupplierEntry showSupplier={props.showSupplier} hideSupplierModal={props.hideSupplierModal} />
        }
        {
            props.showProduct &&
                <ProductEntry showProduct={props.showProduct} hideProductModal={props.hideProductModal} />
        }
        {
            props.showProductCategory &&
                <ProductCategoryEntry showProductCategory={props.showProductCategory} hideProductCategoryModal={props.hideProductCategoryModal} />
        }
        {
            props.showProductSubCategory &&
                <ProductSubCategoryEntry showProductSubCategory={props.showProductSubCategory} hideProductSubCategoryModal={props.hideProductSubCategoryModal} />
        }

        {props.renewContractClicked && <RenewContract values={props.values} closeRenewContract={props.closeRenewContract}/>}
    </div>
}

export default RebateProgramEntry;
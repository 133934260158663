import React, {useEffect, useState} from 'react';
import ProductEntryModal from './ProductEntryModal';
import { useSelector, useDispatch } from 'react-redux';
import productEntrySelector from '../../../../../state/features/RebateManagement/ProductEntry/selectors';
import productEntryOperations from '../../../../../state/features/RebateManagement/ProductEntry/operations';
import supplierEntrySelectors from '../../../../../state/features/RebateManagement/SupplierEntry/selectors';
import supplierEntryOperations from '../../../../../state/features/RebateManagement/SupplierEntry/operations';
import categoryEntrySelector from '../../../../../state/features/RebateManagement/ProductCategoryEntry/selectors';
import categoryEntryOperations from '../../../../../state/features/RebateManagement/ProductCategoryEntry/operations';
import subCategoryEntrySelector from '../../../../../state/features/RebateManagement/ProductSubCategoryEntry/selectors';
import subCategoryEntryOperations from '../../../../../state/features/RebateManagement/ProductSubCategoryEntry/operations';
import { isAuthorized } from 'client/utils/Permissions';

interface IProps {
    isSaveClicked?: boolean;
    handleInputChange?: any;
    values?: any;
    errors?: any;
    showSupplierModal:Function;
    showCategoryModal:Function;
    showSubCategoryModal:Function;
    handleProduct?: Function;
    errorProduct?: any;
    setValues?: any;
    popupType?: any;
    handleMessage?: Function;
    isNew?: boolean;
}

const ProductEntryModalContainer = (props: IProps) => {
    const dispatch = useDispatch();
    const [productOption, setProductOption] = useState([]);
    const [vendorOption, setVendorOption] = useState([]);
    const [categoryOption, setCategoryOption] = useState([]);
    const [subCategoryOption, setSubCategoryOption] = useState([]);
    const [manufacturerOption, setManufacturerOption] = useState([]);
    
    const dispatchGetProductData = productEntryOperations.dispatchGetProductData(dispatch);
    const dispatchGetSupplierdata = supplierEntryOperations.dispatchGetSupplierdata(dispatch);
    const dispatchGetCategoryData = categoryEntryOperations.dispatchGetCategoryData(dispatch);
    const dispatchGetSubCategoryData = subCategoryEntryOperations.dispatchGetSubCategoryData(dispatch);
    const dispatchGetManufacturerData = supplierEntryOperations.dispatchGetManufacturerData(dispatch);
    
    const  {
        productData,
        suppliersData,
        supplierName,
        categoryData,
        categoryName,
        subCategoryData,
        subCategoryName,
        manufacturerData,
        productSaved
    }:any  = useSelector(
        (state:any) => ({
            productData: productEntrySelector.getProductData(state),
            suppliersData: supplierEntrySelectors.getSuppliersData(state),
            supplierName: supplierEntrySelectors.getSupplierName(state),
            categoryData: categoryEntrySelector.getCategoryData(state),
            categoryName: categoryEntrySelector.getCategoryName(state),
            subCategoryData: subCategoryEntrySelector.getSubCategoryData(state),
            subCategoryName: subCategoryEntrySelector.getSubCategoryName(state),
            manufacturerData: supplierEntrySelectors.getManufacturer(state),
            productSaved: productEntrySelector.getProductSaved(state),
        })
    );

    useEffect(() => {
        dispatchGetProductData();
        dispatchGetSupplierdata();
        dispatchGetCategoryData();
        dispatchGetSubCategoryData();
        dispatchGetManufacturerData();
    }, [])

    useEffect(() => {
        if (productData?.length > 0) {
            const options = productData.map((item: any) => ({
                ...item,
                label: item.productName,
                value: item.productName,
                key: parseInt(item.productId)
            }))
            const addNew = {
                label: "Add New",
                value: "Add New",
                key: "addNew"
            }
            options.unshift(addNew)
            setProductOption(options);
        }
    }, [productData]);

    const getVendorOptions = (item: any) => ({
        ...item,
        label: item.supplierName,
        value: item.supplierName,
        key: parseInt(item.supplierId)
    })

    useEffect(() => {
        if (suppliersData?.length > 0) {
            const options = suppliersData.filter((i: any) => i.isActive).map((item: any) => ({
                ...item,
                label: item.supplierName,
                value: item.supplierName,
                key: parseInt(item.supplierId)
            }))
            setVendorOption(options);
        }
    }, [suppliersData]);

    useEffect(() => {
        if (manufacturerData?.length > 0) {
            const options = manufacturerData.filter((i: any) => i.isActive).map((item: any) => (getVendorOptions(item)))
            setManufacturerOption(options);
        }
    }, [manufacturerData]);

    useEffect(() => {
        if (categoryData?.length > 0) {
            const options = categoryData.filter((i: any) => i.isActive).map((item: any) => ({
                ...item,
                label: item.categoryName,
                value: item.categoryName,
                key: parseInt(item.categoryId)
            }))
            setCategoryOption(options);
        }
    }, [categoryData]);

    useEffect(() => {
        if (subCategoryData?.length > 0) {
            const options = subCategoryData.filter((i: any) => i.isActive).map((item: any) => ({
                ...item,
                label: item.subCategoryName,
                value: item.subCategoryName,
                key: parseInt(item.subCategoryId)
            }))
            setSubCategoryOption(options);
        }
    }, [subCategoryData]);

    useEffect(() => {
        if(subCategoryName) {
            const subCategoryItem = subCategoryData.find((i: any) => i.subCategoryName === subCategoryName);
            if (subCategoryItem) {
                props.setValues({
                    ...props.values,
                    subCategoryId: Number(subCategoryItem.subCategoryId)
                })
            }
        }
    }, [subCategoryName, subCategoryData])

    useEffect(() => {
        if(categoryName) {
            const categoryItem = categoryData.find((i: any) => i.categoryName === categoryName);
            if (categoryItem) {
                props.setValues({
                    ...props.values,
                    categoryId: Number(categoryItem.categoryId)
                })
            }
        }
    }, [categoryName, categoryData])

    useEffect(() => {
        if(supplierName && props.popupType === "vendor") {
            const supplierItem = suppliersData.find((i: any) => i.supplierName === supplierName);
            if (supplierItem) {
                props.setValues({
                    ...props.values,
                    supplierId: Number(supplierItem.supplierId)
                })
            }
        } else {
            const manufacturerItem = manufacturerData.find((i: any) => i.supplierName === supplierName);
            if (manufacturerItem) {
                props.setValues({
                    ...props.values,
                    manufacturerId: Number(manufacturerItem.supplierId)
                })
            }
        }
    }, [supplierName, suppliersData, manufacturerData])

    const SKUOption = [{ key: 'SKUOption', text: 'SKUOption', value: 'SKU Option'}];

    const getValue = (name: any) => {
        if(props.values[name]) {
            return props.values[name];
        } else {
            return "";
        }
    }

    const getError = (name: any) => {
        if (!props.isSaveClicked) {
            return "";
        }
        if (props.errors[name]) {
            return props.errors[name];
        } else {
            return "";
        }
    }

    const AddEditSupplierLinkText = () => {
         if(isAuthorized("Suppliers", "update")){
            return "Add/Edit"
        } else if(isAuthorized("Suppliers", "create")){
            return "Add"
        } else {
            return ""
        }
    }

    return (
        <ProductEntryModal 
            productOption={productOption}
            vendorOption={vendorOption}
            categoryOption={categoryOption}
            subCategoryOption={subCategoryOption}
            manufacturerOption={manufacturerOption}
            isSaveClicked={props.isSaveClicked}
            handleInputChange={props.handleInputChange}
            values={props.values}
            errors={props.errors}
            showSupplierModal={props.showSupplierModal}
            showCategoryModal={props.showCategoryModal}
            showSubCategoryModal={props.showSubCategoryModal}
            SKUOption={SKUOption}
            handleProduct={props.handleProduct}
            productSaved={productSaved}
            handleMessage={props.handleMessage}
            errorProduct={props.errorProduct}
            getValue={getValue}
            getError={getError}
            isNew={props.isNew}
            AddEditSupplierLinkText={AddEditSupplierLinkText}
        />)
};

export default ProductEntryModalContainer;
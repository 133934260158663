import React from 'react';
import {SofturaTable,Card,Checkbox,OutlineButton,PrimaryButton} from '../../../../CommonComponents';
import Back from '../../../../assets/images/Back/Back.png';
import './ViewBulkUpload.scss';
interface ICategory {
    text: string,
    content: string
}
interface IData {
    Invoice: string,
    ProductOrder: string,
    SKU: string,
    Manufacturer: string,
    MfgSKU:string,
    Category:string | ICategory,
    SubCategory:string
}
interface IColumn {
    key: string,
    title?: string,
    width: string,
    style?: any
}
interface Iprops {
    data: IData[],
    column: IColumn[],
    onHideShowViewBulk: React.MouseEventHandler<HTMLDivElement>;
}
const ViewBulkUpload = (props:Iprops) => {
    return <Card>
        <div className='viewBulkUpload'>
            <div className='clearfix'>
                <div className='float-left leftsideButton'>
                    <div className='backButton' onClick={props.onHideShowViewBulk}>
                        <img src={Back} alt='backImg'/>
                    </div>
                    <div>
                        <label className='labelText'>File Name</label>
                        <p>Rebate report July 2022.xlsx</p>
                    </div>
                    <div>
                        <label className='labelText'>Supplier</label>
                        <p>A & G Tree</p>

                    </div>
                    <div>
                        <label className='labelText'>Upload Date</label>
                        <p>07/26/2022</p>
                    </div>
                </div>
                <div className='float-right'>
                    <div>
                        <Checkbox label='Auto Correct' className='autoCorrect'/>
                    </div>
                    <div className='searchBlock'>

                    </div>
                </div>
            </div>
            <div className='rebate-table'>
                <SofturaTable data={props.data} columns={props.column} sort={true} filter={true} globalSearch={true}/>
            </div>

            <div className='clearfix mt-3'>
                    <div className='float-right'>
                      <OutlineButton text={'Cancel'} className='mr-2'></OutlineButton>
                      <OutlineButton text={'Save'} className='mr-2'></OutlineButton>
                      <PrimaryButton text={'Approve'}></PrimaryButton>
                    </div>
                </div>
        </div>
    </Card>
}

export default ViewBulkUpload;
import {
  getPaymentReconcillationData, getPaymentReverse, getUpdateCheckNumber, saveUpdatePaymentReverse, setUpdateCheckNumber
} from './action'

const dispatchGetPaymentReconcillationData = (dispatch: Function) => (payment: any) => {
  dispatch(getPaymentReconcillationData(payment));
};

const dispatchSaveUpdatePaymentReverse = (dispatch: Function) => (payment: any) => {
  dispatch(saveUpdatePaymentReverse(payment));
};

const dispatchgetPaymentReverse = (dispatch: Function) => (reverseId: any) => {
  dispatch(getPaymentReverse(reverseId));
};

const dispatchGetUpdateCheckNumber = (dispatch: Function) => (payload: any) => {
  dispatch(getUpdateCheckNumber(payload));
};

const dispatchSetUpdateCheckNumber = (dispatch: Function) => (payload: any) => {
  dispatch(setUpdateCheckNumber(payload));
};

const operations = {
  dispatchGetPaymentReconcillationData,
  dispatchSaveUpdatePaymentReverse,
  dispatchgetPaymentReverse,
  dispatchGetUpdateCheckNumber,
  dispatchSetUpdateCheckNumber
}

export default operations
import React, { useEffect, useState } from 'react'
import PrimaryButton from '../Button/PrimaryButton/PrimaryButtonContainer'
import './FileUpload.scss'
import Close_SuccessPopup from "../../assets/images/Close_SuccessPopup.svg"

interface IProps {
  onChange?: any,
  noFiles?: boolean,
  file?: any,
  multiple?: boolean,
  value?: any
  onClick?: Function;
  handleClose?: any
}
const FileUpload = ({
  onChange,
  onClick,
  noFiles = true,
  file = '',
  multiple = false,
  value,
  handleClose
}: IProps) => {

  const [items, setItems] = useState<any>([]);

  useEffect(() => {
    setItems(file)
  }, [file])

  return <><div className='file-upload-wrapper'>
    <button className='choose-file'>Choose File</button>
    {/* {
      noFiles ? <span className='nofile-chosen'>No file chosen</span> : <span className='file-name'>{file}</span>
    } */}
    <input type="file" value={value} name="myfile" className='file-upload' onChange={onChange} multiple={multiple} />
  </div>
    {/* <PrimaryButton onClick={onClick}><span className='fa fa-upload upload-icon'></span> Upload</PrimaryButton> */}

    <ul className="fileNamesBlock clearfix">
      {items.map((item: any) => (
        <>
          <>
            <li key={item.name}>
              {item.name}
              <span className="me-2" onClick={() => handleClose(item.name)}>
                <img src={Close_SuccessPopup} alt="" />
              </span>
            </li>
          </>
        </>
      ))}
    </ul>
  </>
}

export default FileUpload

import React, {useState, useEffect} from 'react';
import IndividualEntry from './IndividualEntry'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import productEntryOperations from "../../../../state/features/RebateManagement/ProductEntry/operations";
import productEntrySelectors from "../../../../state/features/RebateManagement/ProductEntry/selectors";
import supplierEntryOperations from "../../../../state/features/RebateManagement/SupplierEntry/operations";
import supplierEntrySelectors from "../../../../state/features/RebateManagement/SupplierEntry/selectors";
import categoryEntryOperations from "../../../../state/features/RebateManagement/ProductCategoryEntry/operations";
import categoryEntrySelectors from "../../../../state/features/RebateManagement/ProductCategoryEntry/selectors";
import subCategoryEntryOperations from "../../../../state/features/RebateManagement/ProductSubCategoryEntry/operations";
import subCategoryEntrySelectors from "../../../../state/features/RebateManagement/ProductSubCategoryEntry/selectors";
import programEntryOperations from "../../../../state/features/RebateManagement/RebateProgramEntry/operations";
import programEntrySelectors from "../../../../state/features/RebateManagement/RebateProgramEntry/selectors";
import rebateSpendOperations from "../../../../state/features/RebateSpent/IndividualEntry/operations";
import rebateSpendSelectors from "../../../../state/features/RebateSpent/IndividualEntry/selectors";

const IndividualEntryContainer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showIndividualModal, setShowIndividualModal] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [values, setValues] = useState<any>({});
    const [isSaveClicked, setSaveClicked] = useState(false);
    const [rebateProgramOptions, setRebateProgramOptions] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [vendorOptions, setVendorOptions] = useState([]);
    const [manufacturerOptions, setManufacturerOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);

    const dispatchGetRebateProgram = programEntryOperations.dispatchGetRebateProgram(dispatch);
    const dispatchGetRebateProgramById = programEntryOperations.dispatchGetRebateProgramById(dispatch);
    const dispatchSetRebateProgramById = programEntryOperations.dispatchSetRebateProgramById(dispatch);
    const dispatchGetProductData = productEntryOperations.dispatchGetProductData(dispatch);
    const dispatchGetSupplierdata = supplierEntryOperations.dispatchGetSupplierdata(dispatch);
    const dispatchGetManufacturerData = supplierEntryOperations.dispatchGetManufacturerData(dispatch);
    const dispatchGetCategoryData = categoryEntryOperations.dispatchGetCategoryData(dispatch);
    const dispatchGetSubCategoryData = subCategoryEntryOperations.dispatchGetSubCategoryData(dispatch);
    const dispatchGetCreateUpdateRebateSpend = rebateSpendOperations.dispatchGetCreateUpdateRebateSpend(dispatch);
    const dispatchSetRebateSpendSavedFlag = rebateSpendOperations.dispatchSetRebateSpendSavedFlag(dispatch);

    const {
        RebateProgramList,
        rebateProgramById,
        productData,
        suppliersData,
        manufacturerdata,
        categoryData,
        subCategoryData,
        rebateSpendSaved
    }: any = useSelector((state) => ({
        RebateProgramList: programEntrySelectors.getRebateProgram(state),
        rebateProgramById: programEntrySelectors.getRebateProgramById(state),
        productData: productEntrySelectors.getProductData(state),
        suppliersData: supplierEntrySelectors.getSuppliersData(state),
        manufacturerdata: supplierEntrySelectors.getManufacturer(state),
        categoryData: categoryEntrySelectors.getCategoryData(state),
        subCategoryData: subCategoryEntrySelectors.getSubCategoryData(state),
        rebateSpendSaved: rebateSpendSelectors.getRebateSpendSaved(state),
    }));

    const getInitialError = () => {
        setErrors({
            invoice:'Please provide Invoice',
            purchaseOrder:'Please provide Purchase Order',
            vendorId:'Please select Vendor',
            mfgSku:'',
        });
    }
    
    useEffect(() => {
        setShowIndividualModal(true);
        dispatchGetRebateProgram();
        dispatchGetProductData();
        dispatchGetSupplierdata();
        dispatchGetManufacturerData();
        dispatchGetCategoryData();
        dispatchGetSubCategoryData();
        getInitialError();
        dispatchSetRebateProgramById({});
        return (() => {
            getInitialError();
            dispatchSetRebateProgramById({})
        })
    }, []);

    useEffect(() => {
        if (RebateProgramList?.length > 0) {
            const options = RebateProgramList.filter((i: any) => i.isActive).map((item: any) => (
                {
                    key: parseInt(item.id),
                    text:item.name,
                    label:item.name,
                    value:item.name,
                    entry: true
                 }
            ))
            setRebateProgramOptions(options);
        }
    }, [RebateProgramList]);

    useEffect(() => {
        if (rebateProgramById.id || rebateProgramById.programId) {
            const tempValues = values;
            if (rebateProgramById?.supplierId) {
                tempValues.vendorId = rebateProgramById?.supplierId;
            }
            if (rebateProgramById?.rebateTypes?.rebateProductTypes?.[0].productId) {
                tempValues.productId = rebateProgramById?.rebateTypes?.rebateProductTypes?.[0].productId
            }
            if (rebateProgramById?.rebateTypes?.rebateProductCategoryTypes?.[0].productCategoryId) {
                tempValues.categoryId = rebateProgramById?.rebateTypes?.rebateProductCategoryTypes?.[0].productCategoryId
            }
            if (rebateProgramById?.rebateTypes?.rebateProductSubCategoryTypes?.[0].productSubCategoryId) {
                tempValues.subCategoryId = rebateProgramById?.rebateTypes?.rebateProductSubCategoryTypes?.[0].productSubCategoryId
            }
            for (const key in tempValues) {
                getValidateData(key, tempValues[key])
            }
            setValues(tempValues);
        }
    }, [rebateProgramById]);

    useEffect(() => {
        if (productData?.length > 0) {
            const options = productData.filter((i: any) => i.isActive).map((item: any) => ({
                ...item,
                text: item.productName,
                label: item.productName,
                value: item.productName,
                key: parseInt(item.productId)
            }))
            setProductOptions(options);
        }
    }, [productData]);

    const getVendorOptions = (item: any) => ({
        ...item,
        text: item.supplierName,
        label: item.supplierName,
        value: item.supplierName,
        key: parseInt(item.supplierId)
    })

    useEffect(() => {
        if (suppliersData?.length > 0) {
            const options = suppliersData.filter((i: any) => i.isActive).map((item: any) => (getVendorOptions(item)))
            setVendorOptions(options);
        }
    }, [suppliersData]);

    useEffect(() => {
        if (manufacturerdata?.length > 0) {
            const options = manufacturerdata.filter((i: any) => i.isActive).map((item: any) => (getVendorOptions(item)))
            setManufacturerOptions(options);
        }
    }, [manufacturerdata]);

    useEffect(() => {
        if (categoryData?.length > 0) {
            const options = categoryData.filter((i: any) => i.isActive).map((item: any) => ({
                ...item,
                text: item.categoryName,
                label: item.categoryName,
                value: item.categoryName,
                key: parseInt(item.categoryId)
            }))
            setCategoryOptions(options);
        }
    }, [categoryData]);

    useEffect(() => {
        if (subCategoryData?.length > 0) {
            const options = subCategoryData.filter((i: any) => i.isActive).map((item: any) => ({
                ...item,
                text: item.subCategoryName,
                label: item.subCategoryName,
                value: item.subCategoryName,
                key: parseInt(item.subCategoryId)
            }))
            setSubCategoryOptions(options);
        }
    }, [subCategoryData]);

    useEffect(() => { 
        if (rebateSpendSaved) {
            const element = document.getElementsByClassName("modalBodyContainer");
            element[0]?.scroll(0,0);
            setValues({});
            getInitialError();
            setSaveClicked(false);
        }
    }, [rebateSpendSaved])

    const handleHome = () => {
        navigate("/");
    }

    const hidePopup = () => {
        setValues({})
        setShowIndividualModal(false);
        handleHome();
    }

    const handleSave = () => {
        setSaveClicked(true);
        let error = Object.values(errors).filter((val:any) => val !== '');
        if (error?.length === 0) {
            const payload = {
                name: "",
                supplierName: "string",
                invoiceDate: "2022-09-12T12:23:24.519Z",
                itemDescription: "",
                itemQuantity: 0,
                unitPrice: 0,
                totalPrice: 0,
                taxAmount: 0,
                totalPriceLessTax: 0,
                branchNumber: Number(values.branchNumber),
                agency: true,
                supplierId: values.vendorId,
                programId: values.programId,
                sku: values.sku,
                mfgSku: values.mfgsku,
                manufactureId: values.manufacturerId,
                categoryId: values.categoryId,
                subcategoryId: values.subCategoryId,
                productId: values.productId,
                invoiceNumber: values.invoice,
                ponumber: parseInt(values.purchaseOrder)
              }
            dispatchGetCreateUpdateRebateSpend(payload);
            console.log(values, "Values")
        }
    }

    const handleInvoiceCheck = (value: any) => {
        if(!value && !values.purchaseOrder){
            errors.purchaseOrder='Please provide Purchase Order'
            errors.invoice='Please provide Invoice'
        }else{
            errors.invoice=''
            errors.purchaseOrder=''
        }
    }

    const handlePurchaseOrder = (value: any) => {
        if(!value && !values.invoice){
            errors.purchaseOrder='Please provide Purchase Order'
            errors.invoice='Please provide Invoice'
        }else{
            errors.purchaseOrder=''
            errors.invoice=''
        }
    }

    const handleVendorCheck = (value: any) => {
        if(!value){
            errors.vendorId='Please select Vendor'
        }else{
            errors.vendorId=''
        }
    }

    const handleManufacturerCheck = (value: any) => {
        if (value && !values.mfgSku) {
            errors.mfgSku='Please provide Menufacturer SKU'
        }else{
            errors.mfgSku=''
        }
    }

    const handleMfgSkuCheck = (value: any) => {
        if(!value && values.manufacturerId){
            errors.mfgSku='Please provide Menufacturer SKU'
        }else{
            errors.mfgSku=''
        }
    }

    const getValidateData = (name: any, value: any) => {
        switch (name) {
            case 'invoice':
                handleInvoiceCheck(value)
                break;
            case 'purchaseOrder':
                handlePurchaseOrder(value);
                break;
            case 'vendorId':
                handleVendorCheck(value);
                break;
            case 'manufacturerId':
                handleManufacturerCheck(value);
                break;
            case 'mfgSku':
                handleMfgSkuCheck(value);
                break;
            default:
                break;
        }
    }

    const handleInputChange = (event: any, item: any) => {
        let name = event.name || event.target.getAttribute('name');
        let value = item;
        if (
            name === "programId" ||
            name === "productId" ||
            name === "vendorId" ||
            name === "manufacturerId" ||
            name === "categoryId" ||
            name === "subCategoryId"
            ) {
            value = item.key
        }
        if (name === "programId") {
            dispatchGetRebateProgramById(value);
        }
        if (name === "isActive") value = item;
        getValidateData(name, value);
        if (
            (name === "invoice" && value?.length > 50) ||
            (name === "purchaseOrder" && value?.length > 50) ||
            ((name === "sku" || name === "mfgSku") && value?.length > 50)
        ) {
            return;
        }
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleMessage = () => {
        dispatchSetRebateSpendSavedFlag(false);
    }

    const breadcrumlist = [
        { 
        text: 'Rebate Spend',
        key: 'f1',
        // href: '/Home', 
        // onClick: (e: any) => {
        //      e.preventDefault();
        //      handleHome() 
        // }
         },
        { text: 'Individual Entry', key: 'f2' }
    ]

    const getValue = (name: any) => {
        if(values[name]) {
            return values[name];
        } else {
            return "";
        }
    }

    const getError = (name: any) => {
        if (!isSaveClicked) {
            return "";
        }
        if (errors[name]) {
            return errors[name];
        } else {
            return "";
        }
    }

    const getExternalErrorMessage = (name: any) => {
        if (!isSaveClicked) {
            return "";
        } 
        if (errors[name]){
            return <p className='error-message'>{errors[name]}</p>
        }
        return "";
    }

    return (
        <IndividualEntry 
            showIndividualModal={showIndividualModal}
            hidePopup={hidePopup}
            handleSave={handleSave}
            handleInputChange={handleInputChange}
            breadcrumlist={breadcrumlist}
            rebateProgramOptions={rebateProgramOptions}
            productOptions={productOptions}
            vendorOptions={vendorOptions}
            manufacturerOptions={manufacturerOptions}
            categoryOptions={categoryOptions}
            subCategoryOptions={subCategoryOptions}
            rebateSpendSaved={rebateSpendSaved}
            handleMessage={handleMessage}
            getValue={getValue}
            getError={getError}
            getExternalErrorMessage={getExternalErrorMessage}
        />
    )
}

export default IndividualEntryContainer;
import React, { useState, useRef, useEffect, useCallback } from 'react'
import PaymentReconcillations, { IBreadcrumb } from './PaymentReconcillations'
import { ContextMenu } from "../../../CommonComponents";
import './PaymentReconcillations.scss'
import PaymentReconcillationsSelector from '../../../../state/features/RebateManagement/PaymentReconcillations/selectors';
import PaymentReconcillationsOperations from '../../../../state/features/RebateManagement/PaymentReconcillations/operations';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import api from 'client/utils/clientApiHelper'
import { useMsal } from '@azure/msal-react';
import { isAuthorized } from 'client/utils/Permissions';
import ErrorHandling from 'client/CommonComponents/ErrorHandling/ErrorHandling';


const PaymentReconcillationsContainer = () => {
  const dispatch = useDispatch();
  const [showAssignPopup, setShowAssignPopup] = useState(false);
  const [showReversePopup, setShowReversePopup] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [paginationData, setPaginationData] = useState<any>({})
  const [pagesCount, setPagesCount] = useState<number>(0)
  const [serverTotal, setServerTotal] = useState(0)
  const [sortData, setSortData] = useState<any>('')
  const [searchValue, setSearchValue] = useState('')
  const [debouncedValue, setDebouncedValue] = useState('');
  const [isShowAssignPayment, setIsShowAssignPayment] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>({});
  const [files, setFiles] = useState<any>([]);
  const [comment, setComment] = useState<any>({
    comment: '',
    paymentReverseId: 0,
    isError: false
  });
  const [paymentSplitUpData, setPaymentSplitUpData] = useState<any>();
  const [showErrorMessage, setShowErrorMessage] = useState<Boolean>(false);
  const [disableButton, setDisableButton] = useState<Boolean>(false);
  const [loading, setLoading] = useState<Boolean>(false);
  const [showValidationPopup, setShowValidationPopup] = useState<Boolean>(false);
  const [viewOrEdit, setViewOrEdit] = useState<Boolean>(false);


  const { accounts } = useMsal();
  const initialRow = {
    rowId: uuidv4(),
    rowValues: { id: 0, generalLedgerRebatePaymentId: selectedRowData.id, isActive: true, userEmailId: accounts[0].username },
    contractOptions: [],
    programYearOptions: [],
    collectionFrequencyOption: []
  };
  const [assignFormData, setAssignFormData] = useState<any[]>([initialRow]);
  const [checkNumber, setCheckNumber] = useState("");

  const dispatchGetPaymentReconcillationData = PaymentReconcillationsOperations.dispatchGetPaymentReconcillationData(dispatch);
  const dispatchSaveUpdatePaymentReverse = PaymentReconcillationsOperations.dispatchSaveUpdatePaymentReverse(dispatch);
  const dispatchgetPaymentReverse = PaymentReconcillationsOperations.dispatchgetPaymentReverse(dispatch);
  const dispatchSetUpdateCheckNumber = PaymentReconcillationsOperations.dispatchSetUpdateCheckNumber(dispatch);
  const dispatchGetUpdateCheckNumber = PaymentReconcillationsOperations.dispatchGetUpdateCheckNumber(dispatch);

  const {
    paymentReconcillationData,
    paymentReverseData,
    PaymentReconcilationLoader,
    updateCheckNumber
  }: any = useSelector(
    (state: any) => ({
      paymentReconcillationData: PaymentReconcillationsSelector.getPaymentReconcillationData(state),
      paymentReverseData: PaymentReconcillationsSelector.getPaymentReverseData(state),
      PaymentReconcilationLoader: PaymentReconcillationsSelector.getPaymentReconcilationLoader(state),
      updateCheckNumber: PaymentReconcillationsSelector.getUpdateCheckNumber(state)
    })
  )


  useEffect(() => {
    if (paymentReconcillationData?.paymentReconciliationList?.length > 0) {
      const updatedOrderList = paymentReconcillationData?.paymentReconciliationList?.map((item: any) => ({
        ...item,
        generalLedgerDate: moment(item.generalLedgerDate).format("MM/DD/YYYY")
      }));
      setGridData(updatedOrderList)
      setServerTotal(paymentReconcillationData?.totalRecordCount)
    } else {
      setGridData([])
      setServerTotal(0)
    }
  }, [paymentReconcillationData])

  useEffect(() => {
    if (paymentReverseData?.comment && paymentReverseData?.comment != '') {
      setComment({
        comment: paymentReverseData?.comment,
        paymentReverseId: paymentReverseData?.paymentReverseId
      })
    } else {
      setComment({
        comment: '',
        paymentReverseId: 0
      })
    }
  }, [paymentReverseData]);

  useEffect(() => {
    if (serverTotal && paginationData?.pageSize) {
      let pageSize = paginationData?.pageSize
      let tempPageCount = serverTotal > pageSize ? Math.ceil(serverTotal / pageSize - 1) : 0

      setPagesCount(tempPageCount)
    }
  }, [serverTotal, paginationData?.pageSize])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [JSON.stringify(searchValue)]);

  useEffect(() => {
    getData();
  }, [debouncedValue, paginationData, sortData, isShowAssignPayment])

  useEffect(() => {
    if(updateCheckNumber && !showReversePopup && !showAssignPopup){
      getData();
      dispatchSetUpdateCheckNumber(false)
    }
  }, [updateCheckNumber, showReversePopup, showAssignPopup])

  const getData = () => {
    let payload: any = {
      "pageIndex": paginationData?.pageIndex != undefined ? paginationData?.pageIndex + 1 : 1,
      "pageSize": paginationData?.pageSize || 10,
      "sortColumn": null,
      "sortDirection": null,
      "searchTerm": null,
      "filterByPaymentStatus": isShowAssignPayment
    }
    if (debouncedValue != '') {
      payload.searchTerm = debouncedValue
    }
    if (sortData?.key && sortData?.key != '') {
      payload.sortColumn = sortData?.key;
      payload.sortDirection = sortData?.sortDirection;
    }
    if (payload.pageIndex && payload.pageSize) {
      dispatchGetPaymentReconcillationData(payload);
    }
  }
   const onGlobalSearch = (e: any) => {
    setSearchValue(e?.target?.value)
  }

  const fetchAction = useCallback((fetchData: any) => {

    if (fetchData.action && fetchData.action === "paging") {
      setPaginationData(fetchData.data)
    }
    if (fetchData.action && fetchData.action === "sorting") {
      setSortData(fetchData.data[0]);
    }
  }, [])

  const openAssignPopup = () => {
    setShowAssignPopup(true);
  }
  const openReversePopup = () => {
    setShowReversePopup(true);
  }
  const handleClose = () => {
    setShowAssignPopup(false);
    setShowReversePopup(false)
    setSelectedRowData({});
    setPaymentSplitUpData([])
    setAssignFormData([initialRow])
    onDismissAlert("", false)
    setShowErrorMessage(false)
    setDisableButton(false)
    setLoading(false)
    setShowValidationPopup(false)
    setFiles([])
  }

  const getFormattedValue = (value: any) => {
    let newValue: any;
    if (Number.isInteger(value)) {
      newValue = value?.toLocaleString() + ".00";
    } else {
      newValue = value?.toLocaleString();
    }
    const valueArray = newValue.split(".");
    if (valueArray[1]?.length === 1) {
      return newValue + "0";
    } else {
      return newValue;
    }
  };

  const onClickSave = () => {
    if (comment?.comment && comment?.comment != '') {
      const payload = {
        "paymentReverseId": comment?.paymentReverseId,
        "generalLedgerRebatePaymentId": selectedRowData?.id,
        "comment": comment?.comment,
        "userEmailId": accounts[0].username,
        "files": files
      }
      const formData = new FormData();
      formData.append('paymentReverseId', comment?.paymentReverseId)
      formData.append('generalLedgerRebatePaymentId', selectedRowData?.id)
      formData.append('comment', comment?.comment)
      formData.append('userEmailId', accounts[0].username)
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i] as any);
      }
      dispatchSaveUpdatePaymentReverse(formData)
      const gridPayload: any = {
        "pageIndex": paginationData?.pageIndex != undefined ? paginationData?.pageIndex + 1 : 1,
        "pageSize": paginationData?.pageSize || 10,
        "sortColumn": null,
        "sortDirection": null,
        "searchTerm": null,
        "filterByPaymentStatus": isShowAssignPayment
      }
      dispatchGetPaymentReconcillationData(gridPayload);
      handleClose()
    } else {
      setComment({
        ...comment,
        isError: true
      })
    }
  }

  const saveCheckNumber = (value: any, type: any) => {
    dispatchSetUpdateCheckNumber(false)
    const payload = {
      "generalLedgerRebatePaymentId": selectedRowData?.id,
      "checkNo": value.trim(),
      "userName": accounts[0].username,
      "paymentType": type
    }
    dispatchGetUpdateCheckNumber(payload)
  }

  const validateCheckNumber = (value: any) => {
    if(value.trim() == ""){
      return true
    }else{
      var regExp = new RegExp("^\\d+$");
      var isValid = regExp.test(value.trim());
      var isLengthValid = value.trim()?.length < 9 
      return isValid && isLengthValid;
    }
  }


  const CustomCell = (rowData: any) => {
    const [showContextualMenu, setShowContextualMenu] = useState(false);
    const onShowContextualMenu = (ev: React.MouseEvent<HTMLElement>) => {
      ev.preventDefault();
      setShowContextualMenu(true);
    }
    const menuItems = !(rowData.statusName == 'Reversed') ? [
      {
        key: 'Assign',
        text: 'Assign',
        onClick: () => {
          getUserAuthorized()
          setSelectedRowData(rowData);
          setLoading(true)
          getPaymentSplitUpData(rowData?.id);
          setViewOrEdit(false)
        }
      },
      {
        key: 'Reverse',
        text: 'Reverse',
        onClick: () => {
          getUserAuthorized()
          openReversePopup();
          setSelectedRowData(rowData);
          dispatchgetPaymentReverse(rowData?.id);
          setViewOrEdit(false)
        }
      },
    ] : [
      {
        key: 'View/Edit',
        text: 'View/Edit',
        onClick: () => {
          getUserAuthorized()
          openReversePopup();
          setSelectedRowData(rowData);
          dispatchgetPaymentReverse(rowData?.id);
          setViewOrEdit(true)
        }
      },

    ]


    const onHideContextualMenu = () => setShowContextualMenu(false);
    const linkRef = useRef(null);
    return (
      <>
        <ContextMenu
          items={menuItems}
          hidden={showContextualMenu}
          target={linkRef}
          onItemClick={onHideContextualMenu}
          onDismiss={onHideContextualMenu}
          className='grid-context-menu'
          onClick={(e:any)=>{e.preventDefault()}}
        >
          <p className="context-menu" ref={linkRef} onClick={onShowContextualMenu}><span className='fa fa-ellipsis-h'> </span> </p>
        </ContextMenu>
      </>
    )
  }

  const breadcrumlist: IBreadcrumb[] = [
    {
      text: "Rebate Management",
      key: "f1",

    },
    {
      text: "Payment Reconciliations",
      key: "f2;",
    },
  ];
  const options = { 
    minimumFractionDigits: 2,
    maximumFractionDigits: 2 
  };
  const gridColumn = [
    {
      key: 'Action',
      title: 'Action',
      width: "50px",
      Cell: (row: any) => CustomCell(row?.rowData),
      style: { textAlign: 'center' },

    },
    {
      key: "vendorName",
      title: "Vendor Name",
      width: "280px",
    },
    {
      key: "docType",
      title: "Doc Type",
      width: "60px"
    },
    {
      key: "checkNumber",
      title: "Check No",
      width: "60px"
    },
    {
      key: "amount",
      title: "Amount",
      width: "70px",
      style: { textAlign: 'right' },
      Cell: (row: any) => {
        return (
          <span>
            {" "}
            {row?.rowData?.amount != null ? (
              <p>
                $
                {getFormattedValue(
                  Number(row?.rowData?.amount || 0).toLocaleString('en', options)
                )}
              </p>
            ) : (
              ""
            )}
          </span>
        );
      }
    },
    {
      key: "remark",
      title: "Remark",
      width: "200px"
    },
    {
      key: "generalLedgerDate",
      title: "G/L Date",
      width: "70px"
    },
    {
      key: "statusName",
      title: "Status",
      width: "70px"
    }
  ]

  // assign logic 

  const onDismissAlert = (value: any, isError: boolean, type?: string) => {
    setComment({
      ...comment,
      type: type,
      errorMessage: value,
      isError: isError
    })
  }

  const paymentSplitUpValidation = (inputData: any) => {
    const activeInputData = inputData.filter((data: any) => data?.rowValues?.isActive !== false);

    const assignedAmount = activeInputData?.reduce((accumulator: number, currentValue: any) => {
      return accumulator + parseFloat(currentValue?.rowValues?.amount.replaceAll(",","").replace("$","") || 0);
    }, 0);
    if (parseFloat(selectedRowData.amount.toFixed(2)) !== parseFloat(assignedAmount.toFixed(2))) {
      onDismissAlert(`The sum of amounts ($${assignedAmount.toLocaleString('en', options)}) must equal the Check Amount. Please check and re-enter amounts or cancel`, true, "error")
      return false
    }
    onDismissAlert("", false)
    return true
  }

  const assignPaymentValidation = (inputData: any) => {

    const activeInputData = inputData.filter((data: any) => data?.rowValues?.isActive !== false);

    const isValidated = activeInputData?.every((data: any) =>
      data?.rowValues?.vendorSupplierId !== undefined && data?.rowValues?.vendorSupplierId !== "" &&
      data?.rowValues?.contractDetailId !== undefined && data?.rowValues?.contractDetailId !== "" &&
      data?.rowValues?.programYear !== undefined && data?.rowValues?.programYear !== "" &&
      data?.rowValues?.collectionFrequencyValue !== undefined && data?.rowValues?.collectionFrequencyValue !== "" &&
      data?.rowValues?.amount !== undefined && data?.rowValues?.amount !== ""
    );

    setShowErrorMessage(!isValidated);
    return isValidated
  }

  const assignEmptyValidation = (updatedFormData: any) => {

    if (updatedFormData?.length === 0) {
      onDismissAlert(`Please add the assigned data.`, true, "error")
      return false
    }
    return true
  }

  const getAddPaymentValidation = async (inputData: any) => {

    const validateRowData = inputData[0]?.rowValues
    const payload = {
      "id": 0,
      "generalLedgerRebatePaymentId": 0,
      "vendorSupplierId": validateRowData?.vendorSupplierId,
      "contractDetailId": validateRowData?.contractDetailId,
      "programYear": validateRowData?.programYear,
      "collectionFrequencyId": 0,
      "collectionFrequencyValue": validateRowData?.collectionFrequencyValue,
      "amount": 0,
      "isActive": true,
      "userEmailId": ""
    }
    
    const response = await api.callPost(`PaymentSplitUp/AddPayment/Validate`, payload)
    if(!response){
      const tempData = [...inputData]
      tempData.unshift(initialRow);
      setAssignFormData(tempData);
    }
    setShowValidationPopup(response)
    return response

  }

  const onClickAssign = () => {

    let updatedFormData: any = assignFormData
    updatedFormData = assignFormData?.slice(1);

    if (assignPaymentValidation(updatedFormData) &&
      assignEmptyValidation(updatedFormData) && paymentSplitUpValidation(updatedFormData)) {

      let assignPaymentPayload = updatedFormData.map((data: any) => data?.rowValues)

      for (let i = 0; i < assignPaymentPayload.length; i++) {
        assignPaymentPayload[i].amount=parseFloat(assignPaymentPayload[i].amount.replaceAll(",","").replace("$","")).toFixed(2)
      }

      const payload: any = {
        "pageIndex": paginationData?.pageIndex != undefined ? paginationData?.pageIndex + 1 : 1,
        "pageSize": paginationData?.pageSize || 10,
        "sortColumn": null,
        "sortDirection": null,
        "searchTerm": null,
        "filterByPaymentStatus": isShowAssignPayment
      }

      saveUpdatePaymentAssign(assignPaymentPayload)
        .then((result: any) => {
          onDismissAlert(`${result}`, true, "success")
          dispatchGetPaymentReconcillationData(payload);
          setDisableButton(true)
          setTimeout(() => {
            handleClose();
          }, 2000);
        })
        .catch((error: any) => {
          onDismissAlert(`${error.message}. Please try again or cancel`, true, "error")
        });
    }
  }

  const saveUpdatePaymentAssign = async (payload: any) => {
    return await api.callPost(`PaymentSplitUp/PaymentAssign`, payload)
  }

  const getPaymentSplitUpData = async (generalLedgerRebatePaymentId: any) => {
    await api.callGet(`PaymentSplitUp/PaymentSplitUp/${generalLedgerRebatePaymentId}`).then((result: any) => {
      setPaymentSplitUpData(result)
      if (result?.length === 0) {
        setLoading(false)
        openAssignPopup();
      }
    })
  }

  const getContractDetails = async (supplierId: any) => {
    const contractDetailsResponse = await api.callGet(`RebateContract/GetRebateContractNameByVendorId?vendorId=${supplierId}`)
    let contractDetailsOptions: any = []
    if (contractDetailsResponse?.length > 0) {
      contractDetailsOptions = contractDetailsResponse?.map((item: any) => (
        {
          value: item.contractName,
          key: item.contractId,
          label: item.contractName,
        }
      ))
    }
    return contractDetailsOptions
  }

  const getContractProgramYears = async (contractDetailId: any) => {
    const programYearsResponse = await api.callGet(`RebateContract/GetProgramYears?contractDetailId=${contractDetailId}`)
    let programYearOptions: any = []
    if (programYearsResponse?.length > 0) {
      programYearOptions = programYearsResponse?.map((item: any, index: any) => (
        {
          value: item,
          key: index,
          label: item,
        }
      ))
    }
    return programYearOptions
  }

  const getContractCollectionFrequency = async (contractDetailId: any, programYear: any) => {
    const collectionFrequencyResponse = await api.callGet(`RebateContractCollectionFrequency/GetRebateContractCollectionFrequencyByContractDetailId?ContractDetailId=${contractDetailId}&ProgramYear=${programYear}`)

    let collectionFrequency: any;

    if (collectionFrequencyResponse?.collectionFrequencyValue?.length > 0) {
      const options = collectionFrequencyResponse?.collectionFrequencyValue?.map((item: any, index: any) => (
        {
          value: item,
          key: index,
          label: item,
        }
      ))

      collectionFrequency = { collectionFrequencyId: collectionFrequencyResponse?.collectionFrequencyId, collectionFrequencyOptions: options }
    }

    return collectionFrequency
  }

  const getCompletePeriod = async (supplierId: any, contractDetailId: any, programYear: any, collectionFrequency: any) => {

    const payload: any = {
      "vendorSupplierId":supplierId,
      "contractDetailId":contractDetailId,
      "programYear":programYear,
      "collectionFrequencyValue":collectionFrequency
    }
    return await api.callPost(`PaymentSplitUp/AddPayment/Completed`, payload)
  }

  useEffect(() => {
    const fetchData = async () => {
      if (paymentSplitUpData?.length > 0) {

        const tempdataSplitUp = async () => {

            const promises = paymentSplitUpData.map(async (element: any) => {
            const contractOptions = await getContractDetails(element?.vendorSupplierId);
            const programYearOptions = await getContractProgramYears(element?.contractDetailId);
            const collectionFrequency = await getContractCollectionFrequency(element?.contractDetailId, element?.programYear);
            const CompletePeriod = await getCompletePeriod(element?.vendorSupplierId,element?.contractDetailId,element?.programYear,element?.collectionFrequencyValue)


            let tempRowValues = {
              id: element?.id,
              generalLedgerRebatePaymentId: element?.generalLedgerRebatePaymentId,
              vendorSupplierId: element?.vendorSupplierId,
              contractDetailId: element?.contractDetailId,
              programYear: element?.programYear,
              collectionFrequencyId: element.collectionFrequencyId,
              collectionFrequencyValue: element?.collectionFrequencyValue,
              amount: "$"+ parseFloat(element?.amount.toString().replace("$","").replaceAll(",","")).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, '$&,') ,
              isActive: element?.isActive,
              userEmailId: accounts[0].username.replace(/@[^@]*$/,"").replace("."," "),
              completePeriod: CompletePeriod
            };
            return {
              rowId: uuidv4(),
              rowValues: { ...tempRowValues },
              contractOptions: contractOptions,
              programYearOptions: programYearOptions,
              collectionFrequencyOption: collectionFrequency?.collectionFrequencyOptions
            };
          });

          return await Promise.all(promises);
        };

        const formDataResponses = await tempdataSplitUp();
        if (formDataResponses?.length > 0) {
          setLoading(false)
          openAssignPopup();
        }
        addFormSection(formDataResponses, false);
      }
    };

    fetchData();
  }, [paymentSplitUpData]);

  useEffect(() => {
    if (selectedRowData) {
      const tempData = [...assignFormData].map(data => {
        return {
          ...data,
          rowValues: { ...data.rowValues, id: 0, generalLedgerRebatePaymentId: selectedRowData.id, isActive: true, userEmailId: accounts[0].username },
        }
      })
      setAssignFormData(tempData)
    }

  }, [selectedRowData]);

  const validatePeriod = (inputData: any) => {
    const toValidateData = inputData[0]?.rowValues
    const updatedFormData = assignFormData?.slice(1);

    let isValidate = true
    updatedFormData.forEach((data: any) => {
      if (data?.rowValues?.contractDetailId === toValidateData?.contractDetailId &&
        data?.rowValues?.programYear === toValidateData?.programYear &&
        data?.rowValues?.collectionFrequencyValue === toValidateData?.collectionFrequencyValue) {
        onDismissAlert(`There is already data for this period - ${toValidateData?.collectionFrequencyValue}. Please try again or cancel`, true, "error")
        isValidate = false
      }
    })
    return isValidate

  }

  const addFormSection = (inputData: any, isValidateAddPayment: boolean) => {

    const activeInputData = inputData;    

    if (assignPaymentValidation(activeInputData) && validatePeriod(activeInputData)) {
      if (isValidateAddPayment) {
        getAddPaymentValidation(activeInputData)
      }
      if(!isValidateAddPayment){
        const tempData = [...inputData]
        tempData.unshift(initialRow);
        setAssignFormData(tempData);
      }      
    }

  };

  const getUserAuthorized = () =>{
    const isUserAuthorized = isAuthorized("PaymentReconcilation", "update") || isAuthorized("PaymentReconcilation", "create")    
    setDisableButton(!isUserAuthorized)
  }

  const [authorized, setAuthorized] = useState<any>(null);
  useEffect(() => {
    const checkPermission = isAuthorized("PaymentReconcilation", "view");
    setAuthorized(checkPermission);

  }, []);

  if (!(authorized) && authorized != null) {
    return <ErrorHandling errorNumber='403' />
  }

  return (
    <>
      <PaymentReconcillations
        showAssignPopup={showAssignPopup}
        breadcrumlist={breadcrumlist}
        gridColumn={gridColumn}
        handleClose={handleClose}
        showReversePopup={showReversePopup}
        gridData={gridData}
        serverTotal={serverTotal}
        fetchAction={fetchAction}
        pagesCount={pagesCount}
        searchValue={searchValue}
        onGlobalSearch={onGlobalSearch}
        isShowAssignPayment={isShowAssignPayment}
        setIsShowAssignPayment={setIsShowAssignPayment}
        selectedRowData={selectedRowData}
        getFormattedValue={getFormattedValue}
        onClickSave={onClickSave}
        setComment={setComment}
        comment={comment}
        assignFormData={assignFormData}
        setAssignFormData={setAssignFormData}
        onClickAssign={onClickAssign}
        onDismissAlert={onDismissAlert}
        assignPaymentValidation={assignPaymentValidation}
        showErrorMessage={showErrorMessage}
        disableButton={disableButton}
        loading={loading}
        addFormSection={addFormSection}
        getContractDetails={getContractDetails}
        getContractProgramYears={getContractProgramYears}
        getContractCollectionFrequency={getContractCollectionFrequency}
        showValidationPopup={showValidationPopup}
        setShowValidationPopup={setShowValidationPopup}
        initialRow={initialRow}
        viewOrEdit={viewOrEdit}
        PaymentReconcilationLoader={PaymentReconcilationLoader}
        checkNumber = {checkNumber}
        setCheckNumber = {setCheckNumber}
        saveCheckNumber = {saveCheckNumber}
        setFiles={setFiles}
        files = {files}
        validateCheckNumber={validateCheckNumber}
        updateCheckNumber={updateCheckNumber}
        getCompletePeriod={getCompletePeriod}
      />
    </>
  )
}

export default PaymentReconcillationsContainer
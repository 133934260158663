import { useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import Report from './Report';
import selectors from '../../../state/features/Reports/selectors';
import operations from '../../../state/features/Reports/operations';
import userSelector from '../../../state/features/UserProfile/selectors';
import { isAuthorized } from 'client/utils/Permissions';
import ErrorHandling from "../../CommonComponents/ErrorHandling/ErrorHandling"


const ReportContainer = (props: any) => {
    const { type } = props
    const dispatch = useDispatch();

    const [reportsData, setReportsData] = useState<any>({});
    
    const dispatchGetReportData = operations.dispatchGetReportData(dispatch);
    const dispatchGetReportDataByYear = operations.dispatchGetReportDataByYear(dispatch)
    const dispatchGetManufacturerReportData = operations.dispatchGetManufacturerReportData(dispatch)
    const dispatchGetAccountandFinanaceReport = operations.dispatchGetAccountandFinanaceReport(dispatch)

    const  {
        reportData,
        reportDataByYear,
        manufacturerReportData,
        AccountandFinanaceData,
        username
        }:any  = useSelector(
        (state:any) => ({
            reportData: selectors.getReportData(state),
            reportDataByYear: selectors.getReportDataByYear(state),
            manufacturerReportData: selectors.getManufacturerReportData(state),
            AccountandFinanaceData: selectors.getAccountandFinanceReport(state),
            username: userSelector.getUsername(state),
        })
    )

    useEffect(() => {
        if (username) {
            if (type === 'report') {
                dispatchGetReportData(username);
            } else if (type === 'rebatespendbyyear') {
                dispatchGetReportDataByYear(username)
            } else if(type === 'AccountingAndFinance') {
                dispatchGetAccountandFinanaceReport(username)
            } else {
                dispatchGetManufacturerReportData(username)
            }
        }
    }, [type, username])

    useEffect(() => {
        if(type === 'report'){
        setReportsData(reportData);
        } else if(type === 'rebatespendbyyear'){
            setReportsData(reportDataByYear);
        } else if(type === 'AccountingAndFinance') {
            setReportsData(AccountandFinanaceData)
        }else {
           setReportsData(manufacturerReportData);
        }
    }, [reportData, reportDataByYear,manufacturerReportData, AccountandFinanaceData, type])
   
    let getNewAccessToken = async () => {
        if(type === 'report'){
            dispatchGetReportData(username);
        }else if(type === 'rebatespendbyyear'){
            dispatchGetReportDataByYear(username)
        } else if(type === 'AccountingAndFinance') {
            dispatchGetAccountandFinanaceReport(username)
        } else {
            dispatchGetManufacturerReportData(username)
        }
        return reportsData?.token;
    };
    const [authorized, setAuthorized] = useState<any>(null);
    useEffect(() => {
        const checkPermission = isAuthorized("Reports", "view");
        setAuthorized(checkPermission);
    }, []);

    if (!(authorized) && authorized != null) {
        return <ErrorHandling errorNumber='403'/>
    }

    return (
        <Report
            // breadcrumlist={breadcrumlist}
            reportsData={reportsData}
            getNewAccessToken={getNewAccessToken}
        />
    )

}

export default ReportContainer;

import {useState} from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './Carousal.scss'

interface IProps {
children?:any
data?:any,
className?:string
}

const ReadMore = ({ children }:IProps) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text?.slice(0, 450) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore && text.length > 449 ? <p className='Viewmore' title='View More'>View More</p> : ''}
      </span>
    </p>
  );
};
const removeTags =(str:any) => {
  let el = document.createElement("div");
  el.innerHTML = str;
  return el.textContent || el.innerText || "";
}
const CarousalContainer = ( {data, className}:IProps) => {
  let dataMap = data.data
  return (<>  
  {
    dataMap  &&
    <Carousel className={className} infiniteLoop={true} showStatus ={false} stopOnHover={true} showArrows={false} autoPlay={true} autoFocus={true} showThumbs={false} transitionTime={3}>
    {
      dataMap.map((item:any, index:any) => {          
        return (
          <div key={index}>
            <p className='segmentHeading'>{item.segmentName}</p>
            <img src={item.imageBlobUrl} alt='News Carousal' title='Image'/>
            <p className='LegendHeading' title={item.title}>{item.title}</p>
            <p className="legend container"><ReadMore>
              {
                removeTags((item.message))
              }
              </ReadMore></p>              
          </div>
      )
      }
    )}
    </Carousel>
  }
  </>)
}

export default CarousalContainer

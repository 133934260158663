import { SET_REBATESPENDDATA, SET_REBATESPEND, SET_SAVEDREBATESPENDFLAG } from './types'
const initialState = {
  rebateSpend: {},
  rebateSpendData: [],
  rebateSpendSaved: false
}

const reducer = (state = initialState, action:any  = {}) => {
  switch (action.type) {
    case SET_REBATESPENDDATA:
      return {
        ...state,
        rebateSpendData: action.payload,
      }
    case SET_REBATESPEND:
      return {
        ...state,
        rebateSpend: action.payload,
      }
    case SET_SAVEDREBATESPENDFLAG:
      return {
        ...state,
        rebateSpendSaved: action.payload,
      }
    default: return state
  }
}

export default reducer

import * as R from "ramda";


const getBulkUploadFlag = (state: any) => R.pathOr(false, ['BulkUpload', 'bulkUploadSuccess'], state);
const getBulkUploadError = (state: any) => R.pathOr('', ["BulkUpload", "bulkUploadFailed"], state);

const selectors = {
  getBulkUploadFlag,
  getBulkUploadError
};

export default selectors;

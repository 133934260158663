import React from 'react'
import './Footer.scss'

const Footer = () => {
  return (
    <div className='footer-wrapper'>
        <div className='text-center'>&copy; 2021-2023 Brightview. All Rights Reserved.</div>
    </div>
  )
}

export default Footer

import React from 'react'
import { CustomMutiSelect } from '@softura/fluentui-basiccomponents'
import './MultiSelectoption.scss'

interface IProps {
  options?: any,
  onChange?: any,
  value?: any,
  className?: any;
  disabled?: any;
  overrideStrings?:any;
  valueRenderer?:any
  filterOptions?:any
  isLoading?:any
}
const MultiSelectoption = ({
  options,
  onChange,
  value,
  valueRenderer,
  className="",
  disabled = false,
  overrideStrings= {},
  filterOptions,
  isLoading
}:IProps) => {

  return (
    <CustomMutiSelect
      options={options}
      onChange={onChange}
      value={value}
      className={className}
      disabled={disabled}
      overrideStrings={overrideStrings}
      valueRenderer={valueRenderer}
      filterOptions={filterOptions}
      isLoading={isLoading}
    />
  )
}

export default MultiSelectoption;

import * as R from 'ramda';

const GetNavItem = (state:any) => R.pathOr('', ['nav', 'nav'], state);
const GetNavHeight = (state:any) => R.pathOr('', ['nav', 'height'], state);
const GetNavProgramEntry = (state:any) => R.pathOr('', ['nav', 'navProgramEntry'], state);
const GetNavVendorEntry = (state:any) => R.pathOr('', ['nav', 'navVendorEntry'], state);
const GetNavProductEntry = (state:any) => R.pathOr('', ['nav', 'navProductEntry'], state);
const selectors = {
  GetNavItem,
  GetNavHeight,
  GetNavProgramEntry,
  GetNavVendorEntry,
  GetNavProductEntry
}

export default selectors

import React from "react";
import { Breadcrumb, ModalPopup } from "client/CommonComponents";
import CategoryModal from "./CategoryModal";
import "./ProductCategoryEntry.scss";
import ProductSubCategoryEntry from "../ProductSubCategoryEntry";
import { isAuthorized } from "client/utils/Permissions";

interface IProps {
    breadcrumlist?: any;
    hidePopup?: any;
    handleSave?: any;
    handleInputChange?: any;
    isSaveClicked?: any;
    showCategoryModal?: any;
    errors?: any;
    values?: any;
    handleAddEditClick?: any;
    categoryOptions?: any;
    subCategoryOptions?: any;
    showProductCategory?:boolean
    showProductSubCategory:boolean
    hideSubCategoryModal:Function
    handleCategory?: any;
    categorySaved?: any;
    handleMessage?: Function;
    errorCategory?: any;
    FooterPrimaryBtnTxt?: string;
}

const ProductCategoryEntry = (props: IProps) => {
    const {
        breadcrumlist,
        hidePopup,
        handleSave,
        handleInputChange,
        isSaveClicked,
        showCategoryModal,
        errors,
        values,
        handleAddEditClick,
        categoryOptions,
        subCategoryOptions,
        handleCategory,
        showProductCategory,
        showProductSubCategory,
        hideSubCategoryModal,
        categorySaved,
        handleMessage,
        errorCategory,
        FooterPrimaryBtnTxt
    } = props;

    return (
        <div className="product-category-entry-container">
            {
                !showProductCategory?
                <Breadcrumb items={breadcrumlist} maxDisplayedItems='5' />
                : null
            }
            {
                showCategoryModal
                ? <ModalPopup
                    ShowModalPopupFooter={true}
                    ModalPopupTitle="Product Category Entry"
                    ModalPopupType="medium"
                    closeModalPopup={hidePopup}
                    FooterSecondaryBtnTxt={"Cancel"}
                    SecondryBtnOnclick={hidePopup}
                    FooterPrimaryBtnTxt={FooterPrimaryBtnTxt}
                    PrimaryBtnOnclick={handleSave}
                    FooterPrimaryDisabled={!isAuthorized("Categories", "create")}
                >
                    <CategoryModal
                        handleInputChange={handleInputChange}
                        errors={errors}
                        isSaveClicked={isSaveClicked}
                        values={values}
                        handleAddEditClick={handleAddEditClick}
                        categoryOptions={categoryOptions}
                        subCategoryOptions={subCategoryOptions}
                        handleCategory={handleCategory}
                        categorySaved={categorySaved}
                        handleMessage={handleMessage}
                        errorCategory={errorCategory}
                    />
                </ModalPopup>
                : null
            }
            {
                showProductSubCategory ? 
                <ProductSubCategoryEntry showProductSubCategory={showProductSubCategory} hideProductSubCategoryModal={hideSubCategoryModal} /> : null
            }
        </div>
    )
}

export default ProductCategoryEntry;


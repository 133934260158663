import {useState, useEffect, useRef} from 'react'
import Layout from './Layout'
import {useSelector} from 'react-redux'

interface IProps {
  children?: any,
}
const LayoutContainer = ({ children }:IProps) => {

const wrapperRef = useRef<any>(null);
const [showMenu, setShowMenu] = useState(false)
const toggleDropdown = () => {
    setShowMenu(!showMenu)
}

useEffect(() => {
  const documentClick = (e:any) => {
    if (!e.target.closest('.user-menu')) {
      setShowMenu(false)
    } else {
      setShowMenu(true)
    }
  }
  document.addEventListener('click', documentClick)

  return () => {
    document.removeEventListener('click', documentClick)
  }
}, [])

const activeNavigation = useSelector((state:any) => state.nav.nav)
useEffect(() => {
  if(activeNavigation === 'Home'){
    wrapperRef.current.scrollTop = 0;
  }
}, [activeNavigation]);

return <Layout showMenu={showMenu} toggleDropdown={toggleDropdown} wrapperRef={wrapperRef}>{children}</Layout>
}

export default LayoutContainer

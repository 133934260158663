import { useEffect, useState } from 'react'
import UploadFileStatus from './UploadFileStatus'
import { useDispatch, useSelector } from 'react-redux';
import selfReportingSelector from '../../../../state/features/RebateManagement/SelfReporting/selectors';
import selfReportingOperations from '../../../../state/features/RebateManagement/SelfReporting/operations';
import userSelector from '../../../../state/features/UserProfile/selectors';

const UploadFileStatusContainer = (props: any) => {

  const {
    selectedYear,
    onCloseUpdatePopup,
    updateStatusData
  } = props

  const dispatch = useDispatch();

  const [statusList, setStatusList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState<any>([]);
  const [selectedMonth, setSelectedMonth] = useState<any>([]);
  const [selectedVendorList, setSelectedVendorList] = useState<any>([]);
  const [StatusResponse, SetStatusResponse] = useState<any>([false]);
  const [Updatebutton, setUpdatebutton] = useState<any>([true]);
  const [TooltipEnable, setTooltipEnable] = useState<any>(false);

  const {
    fileStatusDropdownList,
    calendarMonthDropdownList,
    vendorDropdownList,
    updateFileStatus,
    username
  }: any = useSelector(
    (state: any) => ({
      fileStatusDropdownList: selfReportingSelector.getFileStatusDropdownList(state),
      calendarMonthDropdownList: selfReportingSelector.getCalendarMonthDropdownList(state),
      vendorDropdownList: selfReportingSelector.getVendorDropdownList(state),
      updateFileStatus: selfReportingSelector.getUpdateFileStatus(state),
      username: userSelector.getUsername(state),
    })
  )

  const dispatchGetFileStatusDropdownList = selfReportingOperations.dispatchGetFileStatusDropdownList(dispatch);
  const dispatchGetCalendarMonthDropdownList = selfReportingOperations.dispatchGetCalendarMonthDropdownList(dispatch);
  const dispatchGetVendorDropdownList = selfReportingOperations.dispatchGetVendorDropdownList(dispatch);
  const dispatchGetUpdateFileStatus = selfReportingOperations.dispatchGetUpdateFileStatus(dispatch);

  useEffect(() => {
    if (!updateStatusData?.stage || updateStatusData?.stage == '') {
      dispatchGetCalendarMonthDropdownList({
        year: selectedYear,
        from: "Popup"
      });
    } else {
      dispatchGetCalendarMonthDropdownList({
        year: selectedYear,
        from: "Grid"
      });
    }
  }, [selectedYear, updateStatusData])

  useEffect(() => {
    dispatchGetFileStatusDropdownList({
      fileStage: updateStatusData?.stage || null,
      fileStatus: updateStatusData?.status || null
    });
  }, [updateStatusData])

  useEffect(() => {
    if (updateStatusData?.month != '' && updateStatusData?.month) {
      let findMonth = monthList?.find((item: any) => item.monthNo == updateStatusData.month)
      setSelectedMonth(findMonth)
    }
  }, [updateStatusData?.month, monthList])

  useEffect(() => {
    if (updateStatusData?.supplierId != '' && updateStatusData?.supplierId) {
      let findVendor = vendorList?.filter((item: any) => item.value == updateStatusData.supplierId)
      setSelectedVendorList(findVendor)
    }
  }, [updateStatusData?.supplierId, vendorList])

  useEffect(() => {
    if (updateStatusData?.status != '' && updateStatusData?.status) {
      let findStatus = statusList?.find((item: any) => item.label == updateStatusData.status)
      setSelectedStatus(findStatus)
    }
  }, [updateStatusData?.status, statusList])

  useEffect(() => {
    if (selectedMonth?.value) {
      dispatchGetVendorDropdownList({
        year: selectedYear,
        month: selectedMonth?.value,
        from: updateStatusData?.month ? 'Grid' : 'Popup'
      });
    }
  }, [selectedMonth])

  useEffect(() => {
    if (fileStatusDropdownList?.length > 0) {
      let list = fileStatusDropdownList.map((item: any) => {
        return { ...item, value: item.statusId, label: item.statusName }
      })
      setStatusList(list)
    } else {
      setStatusList([])
    }
  }, [fileStatusDropdownList])

  useEffect(() => {
    if (calendarMonthDropdownList?.length > 0) {
      let list = calendarMonthDropdownList.map((item: any) => {
        return { ...item, value: item.monthNo, label: item.monthName }
      })
      setMonthList(list)
    } else {
      setMonthList([])
    }
  }, [calendarMonthDropdownList])

  useEffect(() => {
    if (vendorDropdownList?.length > 0) {
      let list = vendorDropdownList.map((item: any) => {
        return { ...item, value: item?.supplierId, label: item?.vendorName }
      })
      setVendorList(list)
    } else {
      setVendorList([])
    }
  }, [vendorDropdownList])

  useEffect(() => {
    if(updateFileStatus != '' )
    {
      SetStatusResponse(true);
    }
    else {
      SetStatusResponse(false);
    }
  },[updateFileStatus])

  const onChangeStatus = (selected: any) => {
    setSelectedStatus(selected);
  }

  const onChangeMonth = (selected: any) => {
    setSelectedMonth(selected);
    setVendorList([])
    setSelectedVendorList([])
  }

  const onChangeVendor = (selected: any) => {
    setSelectedVendorList(selected);
    setTooltipEnable(true)
  }

  const onClickUpdate = () => {
    if (selectedMonth?.label && selectedStatus?.label && selectedVendorList?.length > 0) {
      let list = selectedVendorList.map((item: any) => item.value)
      const payload = {
        "month": selectedMonth?.value,
        "year": selectedYear,
        "supplierIdList": list.join(','),
        "stageName": updateStatusData.stage ? updateStatusData.stage : 'Received',
        "statusName": selectedStatus?.label,
        "userEmailId": username
      }
      setUpdatebutton(false);
      dispatchGetUpdateFileStatus(payload)   
    }
  }

  return (
    <div>
      <UploadFileStatus
        statusList={statusList}
        monthList={monthList}
        vendorList={vendorList}
        selectedVendorList={selectedVendorList}
        onChangeStatus={onChangeStatus}
        onChangeMonth={onChangeMonth}
        onChangeVendor={onChangeVendor}
        onClickUpdate={onClickUpdate}
        selectedStatus={selectedStatus}
        selectedMonth={selectedMonth}
        updateStatusData={updateStatusData}
        updateFileStatus={updateFileStatus}
        StatusResponse={StatusResponse}
        Updatebutton={Updatebutton}
      />
    </div>
  )
}

export default UploadFileStatusContainer
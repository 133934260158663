import {
   getRebateSpendData,
   getRebateSpendById,
   getCreateUpdateRebateSpend,
   getRebateSpendSavedFlag,
   setRebateSpendSavedFlag,
   setRebateSpend
} from './action'

  const dispatchGetRebateSpendData = (dispatch:Function) => () => {
    dispatch(getRebateSpendData());
  };
  
  const dispatchGetRebateSpendById = (dispatch:Function) => (id: any) => {
    dispatch(getRebateSpendById(id));
  };

  const dispatchGetCreateUpdateRebateSpend = (dispatch: Function) => (data: any) => {
    dispatch(getCreateUpdateRebateSpend(data));
  }

  const dispatchGetRebateSpendSavedFlag = (dispatch: Function) => () => {
    dispatch(getRebateSpendSavedFlag());
  }

  const dispatchSetRebateSpendSavedFlag = (dispatch: Function) => (flag: boolean) => {
    dispatch(setRebateSpendSavedFlag(flag));
  }
  
  const dispatchSetRebateSpend = (dispatch: Function) => (data: any) => {
    dispatch(setRebateSpend(data));
  }

  const operations = {
    dispatchGetRebateSpendData,
    dispatchGetRebateSpendById,
    dispatchGetCreateUpdateRebateSpend,
    dispatchGetRebateSpendSavedFlag,
    dispatchSetRebateSpendSavedFlag,
    dispatchSetRebateSpend
}

export default operations
import {
  SET_COUNTRY_DROPDOWN,
  SET_BRANCH_VALUES,
  INSERT_USER_DETAIL,
  SET_RESULT_MESSAGE,
  SET_USER_DETAIL,
  SET_TITLE_DETAILS,
    SET_DEPARTMENT_DETAILS,
    SET_USER_BRANCH_DETAIL,
    UPDATE_USER_DETAILS,
    SET_USER_NAME,
    SET_USER_ROLE,
    SET_USER_ROLES_CLAIMS
} from './types';
 
const initialState = {
  loading:false,
  Branchvalues: {},
  countryDropdown: [],
  userDetails:{},
  resultMessage:'',
  titleDetails:[],
  departmentDetails:[],
  getUserDetails:{},
  showUserProfile: false,
  userProfileDetails: {},
  userUpdateDetails:{},
  username: "",
  userRole: {},
  rolesandClaims: [],
}

const reducer = (state= initialState, action:any = {}) => {
  switch (action.type) {
    case SET_COUNTRY_DROPDOWN:
      const countryDropdown = action.countryDropdown;
      return {
        ...state, countryDropdown
      };
      case SET_TITLE_DETAILS:
      const titleDetails = action.titleDetails;
      return {
        ...state, titleDetails
      }
      case SET_USER_DETAIL:
        const getUserDetails = action.value;
        return {
          ...state, getUserDetails
        };
      case SET_DEPARTMENT_DETAILS:
        const departmentDetails = action.departmentDetails;
        return {
          ...state, departmentDetails
        };
      case SET_BRANCH_VALUES:
        return {
          ...state,
          Branchvalues: action.value
      }
      case INSERT_USER_DETAIL:
        return {
          ...state,
          userDetails: action.value
          }
      case SET_USER_BRANCH_DETAIL:
          return {
              ...state,
              userProfileDetails: action.value
          }
      case SET_RESULT_MESSAGE:
        return {
          ...state,
         resultMessage: action.value
      }
      case UPDATE_USER_DETAILS:
        return {
          ...state,
          userUpdateDetails: action.value
      }
      case SET_USER_NAME:
        return {
          ...state,
          username: action.name
      }
      case SET_USER_ROLE:
        return {
          ...state,
          userRole: action.role
      }
      case SET_USER_ROLES_CLAIMS:
        return {
          ...state,
          rolesandClaims: action.value
        }
    default:
      return state
  }
}

export default reducer

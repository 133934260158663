import React from 'react'
import { ReactSelectBox } from '@softura/fluentui-basiccomponents'
import './SearchSelectbox.scss'

interface IProps {
  placeholder?: string,
  options?: any,
  onChange?: any,
  name?:any,
  value?: any,
  filterOptionValue?: any;
  className?: any;
  target?: string;
  isDisabled?: boolean
  inputValue?: any,
  onInputChange?:any,
  isLoading?: any
}
const SearchSelectBox = ({
  placeholder,
  options,
  onChange,
  name,
  value,
  filterOptionValue,
  className="",
  target,
  isDisabled,
  inputValue,
  onInputChange,
  isLoading
}:IProps) => {


  return (
    <ReactSelectBox
      placeholder={placeholder}
      options={options}
      filterOptionValue={filterOptionValue}
      onChange={onChange}
      value={value}
      name={name}
      className={className}
      // menuIsOpen={true}
      inputValue={inputValue}
      onInputChange={onInputChange}
      isDisabled={isDisabled}
      isLoading = {isLoading}
      menuPortalTarget={target ? document.getElementsByClassName(target)[0] : ""}
      styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
    />
  )
}

export default SearchSelectBox;

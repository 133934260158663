import { put, call, takeEvery, all } from 'redux-saga/effects';
import api from '../../../../client/utils/clientApiHelper';
import {
    GET_ADD_SELF_REPORTING_NOTES,
    GET_CALENDAR_MONTH_DROPDOWN_LIST,
    GET_EMAIL_REMINDER,
    GET_EMAIL_TEMPLATE,
    GET_FILE_COMPLETION_DATA,
    GET_FILE_STATUS_DROPDOWN_LIST,
    GET_SELF_REPORTING_EXCEL_DATA,
    GET_SELF_REPORTING_GRID_DATA, GET_SELF_REPORTING_GRID_HEADER_DATA, GET_SELF_REPORTING_NOTES, GET_STATUS_DROPDOWN_LIST, GET_UPDATE_FILE_STATUS, GET_VALIDATE_EMAIL_REMINDER, GET_VENDOR_DROPDOWN_LIST, GET_VENDOR_FILE_COUNT, GET_VENDOR_HISTORY_DATA, GET_VENDOR_LIST_BY_STATUS, GET_VENDOR_TYPE_DROPDOWN_LIST
} from './types'

import * as selfReportingActions from './action';

function* getSelfReportingGridData(payload: any): any {
    try {
        yield put(selfReportingActions.setSelfReportingLoader({gridData: true}))
        const response = yield call(api.callPost, `VendorSelfReporting/Grid`, payload?.payload);
        if (response) {
            yield put(selfReportingActions.setSelfReportingGridData(response));
        } else {
            yield put(selfReportingActions.setSelfReportingGridData([]));
        }
        yield put(selfReportingActions.setSelfReportingLoader({gridData: false}))
    } catch (error) {
        yield put(selfReportingActions.setSelfReportingLoader({gridData: false}))
        yield put(selfReportingActions.setSelfReportingGridData([]));
    }
}

function* getSelfReportingExcelData(payload: any): any {
    try {
        const response = yield call(api.callPost, `VendorSelfReporting/Grid/DowloadExcel`, payload?.payload);
        if (response) {
            yield put(selfReportingActions.setSelfReportingExcelData(response));
        } else {
            yield put(selfReportingActions.setSelfReportingExcelData([]));
        }
    } catch (error) {
        yield put(selfReportingActions.setSelfReportingExcelData([]));
    }
}

function* getSelfReportingGridHeaderData(payload: any): any {
    try {
        const response = yield call(api.callGet, `VendorSelfReporting/GridHeader/${payload?.payload}`);
        if (response) {
            yield put(selfReportingActions.setSelfReportingGridHeaderData(response));
        } else {
            yield put(selfReportingActions.setSelfReportingGridHeaderData([]));
        }
    } catch (error) {
        yield put(selfReportingActions.setSelfReportingGridHeaderData([]));
    }
}

function* getVendorFileCount(payload: any): any {
    try {
        const response = yield call(api.callGet, `VendorSelfReporting/Status/FileCount/${payload?.payload?.month}/${payload?.payload?.year}`);
        if (response) {
            yield put(selfReportingActions.setVendorFileCount(response));
        } else {
            yield put(selfReportingActions.setVendorFileCount([]));
        }
    } catch (error) {
        yield put(selfReportingActions.setVendorFileCount([]));
    }
}

function* getVendorHistoryData(payload: any): any {
    try {
        const response = yield call(api.callGet, `VendorSelfReporting/VendorHistory/${payload?.payload?.supplierId}/${payload?.payload?.year}`);
        if (response) {
            yield put(selfReportingActions.setVendorHistoryData(response));
        } else {
            yield put(selfReportingActions.setVendorHistoryData([]));
        }
    } catch (error) {
        yield put(selfReportingActions.setVendorHistoryData([]));
    }
}

function* getFileStatusDropdownList(payload: any): any {
    try {
        const response = yield call(api.callPost, `VendorSelfReporting/Dropdown/FileStatus`, payload?.payload);
        if (response) {
            yield put(selfReportingActions.setFileStatusDropdownList(response));
        } else {
            yield put(selfReportingActions.setFileStatusDropdownList([]));
        }
    } catch (error) {
        yield put(selfReportingActions.setFileStatusDropdownList([]));
    }
}

function* getCalendarMonthDropdownList(payload: any): any {
    try {
        const response = yield call(api.callGet, `VendorSelfReporting/Dropdown/CalendarMonth/${payload?.payload.year}/${payload?.payload?.from}`);
        if (response) {
            yield put(selfReportingActions.setCalendarMonthDropdownList(response));
        } else {
            yield put(selfReportingActions.setCalendarMonthDropdownList([]));
        }
    } catch (error) {
        yield put(selfReportingActions.setCalendarMonthDropdownList([]));
    }
}

function* getUpdateFileStatus(payload: any): any {
    try {
        const response = yield call(api.callPost, `VendorSelfReporting/Update/FileStatus`, payload.payload);
        if (response) {
            yield put(selfReportingActions.setUpdateFileStatus(response));
        } else {
            yield put(selfReportingActions.setUpdateFileStatus([]));
        }
    } catch (error) {
        yield put(selfReportingActions.setUpdateFileStatus([]));
    }
}

function* getVendorTypeDropdownList(): any {
    try {
        const response = yield call(api.callGet, `VendorSelfReporting/Dropdown/VendorType`);
        if (response) {
            yield put(selfReportingActions.setVendorTypeDropdownList(response));
        } else {
            yield put(selfReportingActions.setVendorTypeDropdownList([]));
        }
    } catch (error) {
        yield put(selfReportingActions.setVendorTypeDropdownList([]));
    }
}

function* getFileCompletionData(payload: any): any {
    try {
        const response = yield call(api.callGet, `VendorSelfReporting/FileCompletion/${payload?.payload}`);
        if (response) {
            yield put(selfReportingActions.setFileCompletionData(response));
        } else {
            yield put(selfReportingActions.setFileCompletionData([]));
        }
    } catch (error) {
        yield put(selfReportingActions.setFileCompletionData([]));
    }
}

function* getVendorListByStatus(payload: any): any {
    try {
        const response = yield call(api.callGet, `VendorSelfReporting/GetVendorListByStatus/${payload?.payload?.month}/${payload?.payload?.year}/${payload?.payload?.statusId}`);
        if (response) {
            yield put(selfReportingActions.setVendorListByStatus(response));
        } else {
            yield put(selfReportingActions.setVendorListByStatus([]));
        }
    } catch (error) {
        yield put(selfReportingActions.setVendorListByStatus([]));
    }
}

function* getValidateEmailReminder(payload: any): any {
    try {
        const response = yield call(api.callPost, `VendorSelfReporting/EmailRemainder/Validate`, payload?.payload);
        if (response) {
            yield put(selfReportingActions.setValidateEmailReminder(response));
        } else {
            yield put(selfReportingActions.setValidateEmailReminder([]));
        }
    } catch (error) {
        yield put(selfReportingActions.setValidateEmailReminder([]));
    }
}

function* getEmailReminder(payload: any): any {
    try {        
        const response = yield call(api.callPost, `VendorSelfReporting/SendEmailRemainder`, payload?.payload);
        if (response) {
            yield put(selfReportingActions.setEmailReminder(response));
        } else {
            yield put(selfReportingActions.setEmailReminder([]));
        }
    } catch (error) {
        yield put(selfReportingActions.setEmailReminder([]));
    }
}

function* getVendorDropdownList(payload: any): any {
    try {
        const response = yield call(api.callGet, `VendorSelfReporting/FilteredVendorList/${payload?.payload?.month}/${payload?.payload?.year}/${payload?.payload?.from}`,);
        if (response) {
            yield put(selfReportingActions.setVendorDropdownList(response));
        } else {
            yield put(selfReportingActions.setVendorDropdownList([]));
        }
    } catch (error) {
        yield put(selfReportingActions.setVendorDropdownList([]));
    }
}

function* getStatusDropdownList(): any {
    try {
        const response = yield call(api.callPost, `VendorSelfReporting/Dropdown/AllFileStatus`);
        if (response) {
            yield put(selfReportingActions.setStatusDropdownList(response));
        } else {
            yield put(selfReportingActions.setStatusDropdownList([]));
        }
    } catch (error) {
        yield put(selfReportingActions.setStatusDropdownList([]));
    }
}

function* getAddSelfReportingNotes(payload: any): any {
    try {
        const response = yield call(api.callPost, `VendorSelfReporting/Notes/Add`,payload?.payload);
        if (response) {
            yield put(selfReportingActions.setAddSelfReportingNotes(response));
        } else {
            yield put(selfReportingActions.setAddSelfReportingNotes(false));
        }
    } catch (error) {
        yield put(selfReportingActions.setAddSelfReportingNotes(false));
    }
}

function* getSelfReportingNotes(payload: any): any {
    try {
        const response = yield call(api.callGet, `VendorSelfReporting/Notes/Get/${payload?.payload}`,);
        if (response) {
            yield put(selfReportingActions.setSelfReportingNotes(response));
            yield put(selfReportingActions.setAddSelfReportingNotes(false));
        } else {
            yield put(selfReportingActions.setSelfReportingNotes([]));
            yield put(selfReportingActions.setAddSelfReportingNotes(false));
        }
    } catch (error) {
        yield put(selfReportingActions.setSelfReportingNotes([]));
        yield put(selfReportingActions.setAddSelfReportingNotes(false));
    }
}

function* getEmailTemplate(payload: any): any {
    try {        
        const response = yield call(api.callPost, `VendorSelfReporting/GetEmailtemplate`, payload?.payload);
        if (response) {
            yield put(selfReportingActions.setEmailTemplate(response));
        } else {
            yield put(selfReportingActions.setEmailTemplate([]));
        }
    } catch (error) {
        yield put(selfReportingActions.setEmailTemplate([]));
    }
}

function* watchGetCommonDashboard() {
    yield takeEvery(GET_SELF_REPORTING_GRID_DATA, getSelfReportingGridData);
    yield takeEvery(GET_SELF_REPORTING_EXCEL_DATA, getSelfReportingExcelData);
    yield takeEvery(GET_SELF_REPORTING_GRID_HEADER_DATA, getSelfReportingGridHeaderData);
    yield takeEvery(GET_VENDOR_FILE_COUNT, getVendorFileCount);
    yield takeEvery(GET_VENDOR_HISTORY_DATA, getVendorHistoryData);
    yield takeEvery(GET_FILE_STATUS_DROPDOWN_LIST, getFileStatusDropdownList);
    yield takeEvery(GET_CALENDAR_MONTH_DROPDOWN_LIST, getCalendarMonthDropdownList);
    yield takeEvery(GET_UPDATE_FILE_STATUS, getUpdateFileStatus);
    yield takeEvery(GET_VENDOR_TYPE_DROPDOWN_LIST, getVendorTypeDropdownList);
    yield takeEvery(GET_FILE_COMPLETION_DATA, getFileCompletionData);
    yield takeEvery(GET_VENDOR_LIST_BY_STATUS, getVendorListByStatus);
    yield takeEvery(GET_VALIDATE_EMAIL_REMINDER, getValidateEmailReminder);
    yield takeEvery(GET_EMAIL_REMINDER, getEmailReminder);
    yield takeEvery(GET_VENDOR_DROPDOWN_LIST, getVendorDropdownList);
    yield takeEvery(GET_STATUS_DROPDOWN_LIST, getStatusDropdownList);
    yield takeEvery(GET_ADD_SELF_REPORTING_NOTES, getAddSelfReportingNotes);
    yield takeEvery(GET_SELF_REPORTING_NOTES, getSelfReportingNotes);
    yield takeEvery(GET_EMAIL_TEMPLATE, getEmailTemplate);
}

export default function* sagas() {
    yield all([
        watchGetCommonDashboard()
    ])
}
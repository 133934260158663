import React, { useState } from 'react'
import {Row,Col,InputField, Alert, Label, Tooltip, FileUpload} from '../../../../CommonComponents';
import './ReversePopup.scss';
import InfoIcon from "../../../../assets/images/InfoIcon.svg";

const ReversePopup = (props:any) => {
  const {
    selectedRowData,
    getFormattedValue,
    onChangeComment,
    comment,
    onDismissAlert,
    checkNumber,
    setCheckNumber,
    saveCheckNumber,
    onFileChange,
    files,
    handleClose,
    fileError,
    validateCheckNumber,
    isCheckNumberUpdated,
    setIsCheckNumberUpdated
  } = props;

  return (
    <div className="reversePopup">
        <div className='paymentDetailsContent mb-3'>
            <ul className="clearfix">
              <li className="paymentDetails_VendorName">
                <p className='heading'>Vendor Name</p>
                <p className='detail'>{selectedRowData?.vendorName}</p>
              </li>
              <li>
                <p className='heading'>Doc Type</p>
                <p className='detail'>{selectedRowData?.docType}</p>
              </li>
              <li>
                <p className='heading'>Check No</p>
                <InputField
                  value={checkNumber}
                  onChange={(item: any) => {
                    validateCheckNumber(item.target.value) && setCheckNumber(item.target.value)
                  }}
                  onBlur={(item:any)=>saveCheckNumber(item.target.value, 'reverse')}
                />
              </li>
              <li>
                <p className='heading'>Check Amount</p>
                <p className='detail'>{`$` + getFormattedValue(Number(selectedRowData?.amount || 0).toLocaleString('en', { minimumFractionDigits: 2,maximumFractionDigits: 2 }))}</p>
              </li>
            </ul>
          </div>
          <Alert showAlert={isCheckNumberUpdated} dismissAlert={()=>setIsCheckNumberUpdated(false)} type='success' text='Check Number Updated Successfully'/>
          <div className='mb-3'>
          <Label className="heading" required>Reason for Reversal</Label>
            <InputField 
              row={3}  
              multiline={true} 
              resizable={false}
              value={comment?.comment}
              onChange={(item:any)=>{
                onChangeComment(item.target.value)
              }}
            />
                {comment?.isError && <><br></br><Alert
                showAlert={true}
                type={"error"}
                text={"The reason field cannot be blank. To proceed, please enter a reason for reversal"}
                dismissAlert={()=>onDismissAlert()}
            /></>}
          </div>
          <div>
            <Label className="heading">Attachments  
                  <Tooltip content={"Allowed file formats are pdf, xls, xlsx, csv, jpg, tiff, bmp, gif, jpeg, png, webp, svg, txt, rtf, doc, docx. Upload files not more than 5MB"}>
                    <span className="fa fa-info-circle ms-1"></span>
                  </Tooltip>
            </Label>
            <FileUpload 
              onChange={onFileChange}
              handleClose={handleClose} 
              file={files} 
              multiple={true}
            />
            {fileError?.length > 0 && <p className="error-message">{fileError}</p>}
          </div>
    </div>
  )
}

export default ReversePopup
import {
   getProductData,
   getProductById,
   getCreateUpdateProduct,
   getProductSavedFlag,
   setProductSavedFlag,
   setProduct,
   setErrorProduct,
   setProductName
} from './action'

  const dispatchGetProductData = (dispatch:Function) => () => {
    dispatch(getProductData());
  };
  
  const dispatchGetProductById = (dispatch:Function) => (id: any) => {
    dispatch(getProductById(id));
  };

  const dispatchGetCreateUpdateProduct = (dispatch: Function) => (data: any) => {
    dispatch(getCreateUpdateProduct(data));
  }

  const dispatchGetProductSavedFlag = (dispatch: Function) => () => {
    dispatch(getProductSavedFlag());
  }

  const dispatchSetProductSavedFlag = (dispatch: Function) => (flag: boolean) => {
    dispatch(setProductSavedFlag(flag));
  }

  const dispatchsetProduct = (dispatch: Function) => (data: any) => {
    dispatch(setProduct(data));
  }

  const dispatchSetErrorProduct = (dispatch: Function) => (data: any) => {
    dispatch(setErrorProduct(data));
  }

  const dispatchSetProductName = (dispatch: Function) => (data: any) => {
    dispatch(setProductName(data));
  }

  const operations = {
    dispatchGetProductData,
    dispatchGetProductById,
    dispatchGetCreateUpdateProduct,
    dispatchGetProductSavedFlag,
    dispatchSetProductSavedFlag,
    dispatchsetProduct,
    dispatchSetErrorProduct,
    dispatchSetProductName
}

export default operations
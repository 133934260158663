import React from 'react'
import './FileNotReceived.scss'
import { Row, Col, InputField, SofturaTable } from 'client/CommonComponents'
import data from './data.json'

interface Iprops {
  gridColumn?: any;
  vendorListByStatus?: any
  fetchAction?: any
  pageNo?: any
}
const FileNotReceived = (props: Iprops) => {
  const {
    gridColumn,
    vendorListByStatus,
    fetchAction,
    pageNo
  } = props
  
  return (
    <div className='FileNotReceivedContainer'>
      <Row>
        <Col lg={12}>
          <div className='rebate-table'>
            <SofturaTable
              data={vendorListByStatus}
              columns={gridColumn}
              sort={true}
              pageSizes={[10, 25, 50, 100]}
              globalSearch={true}
              fetchAction={fetchAction}
              defaultPageSize={pageNo}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default FileNotReceived
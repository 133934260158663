import React from 'react'
import { Dropdowns } from '@softura/fluentui-basiccomponents'
import './Dropdown.scss'

interface IProps {
  placeholder?: string,
  options?: any,
  labelText?: string,
  className?: string,
  defaultSelectedKey?:any,
  onChange?: any,
  onRenderOption?: any,
  onRenderTitle?: any,
  disabled?: boolean,
  key?:string,
  name?:any,
  errorMessage?: string,
  value?: any,
  required?:any,
  id?:any,
  responsiveMode?:any,
  multiSelect?: boolean,
  selectedKeys?:any,
  selectedKey?:any
}
const DropdownContainer = ({
  placeholder,
  options,
  labelText,
  className,
  defaultSelectedKey,
  onChange,
  onRenderOption,
  onRenderTitle,
  disabled,
  key,
  name,
  errorMessage,
  value,
  required,
  id,
  multiSelect,
  selectedKeys,
  selectedKey
}:IProps) => {


  return (
    <Dropdowns
          className={`${className}`}
          placeholder={placeholder}
          options={options}
          labelText={labelText}
          onChange={onChange}
          onRenderOption={onRenderOption}
          onRenderTitle={onRenderTitle}
          disabled={disabled}
          defaultSelectedKey={defaultSelectedKey}
          key={key}
          name={name}
          errorMessage={errorMessage}
          selectedItem={value}
          required={required}
          id={id}
          responsiveMode='large'
          multiSelect={multiSelect}
          selectedKeys={selectedKeys}
          selectedKey={selectedKey}
    />
  )
}

export default DropdownContainer

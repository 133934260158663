import {
    getCountryDropdown,
    insertUserDetail,
    getBranchValues,
    getTitleDetails,
    getDepartmentDetails,
    getUserDetails,
    setResultMessage,
    updateUserDetails,
    setUsername,
    setUserRole,
    getUserRolesAndClaims
} from './actions'

const dispatchGetCountryDropdown = (dispatch:Function) => () => {
    dispatch(getCountryDropdown());
  };
  const dispatchBrahchValues = (dispatch:Function) => () => {
    dispatch(getBranchValues());
  };
  const dispatchGetTitleDetails = (dispatch:Function) => () => {
    dispatch(getTitleDetails());
  };
  const dispatchGetDepartmentDetails = (dispatch:Function) => () => {
    dispatch(getDepartmentDetails());
  };
  const dispatchUserDetail = (dispatch:Function) => (user:any) => {
    dispatch(insertUserDetail(user));
  };
  const dispatchGetUserDetail = (dispatch:Function) => (user:any) => {
    dispatch(getUserDetails(user));
  };
  const getResultMessage = (dispatch:Function) => (value:any) => {
    dispatch(setResultMessage(value));
  };

  const dispatchUpdateUserDetails=(dispatch:Function)=>(value:any,id:any)=>{
    dispatch(updateUserDetails(value,id));
  }

  const dispatchSetUsername=(dispatch:Function)=>(name:any)=>{
    dispatch(setUsername(name));
  }

  const dispatchSetUserRole =(dispatch:Function)=>(role:any)=>{
    dispatch(setUserRole(role));
  }

  const dispatchGetUserRolesAndClaims = (dispatch: Function) => (userId: any) => {
    dispatch(getUserRolesAndClaims(userId))
  }

const operations = {
    dispatchGetCountryDropdown,
    dispatchBrahchValues,
    dispatchUserDetail,
    dispatchGetTitleDetails,
    dispatchGetDepartmentDetails,
    dispatchGetUserDetail,
    getResultMessage,
    dispatchUpdateUserDetails,
    dispatchSetUsername,
    dispatchSetUserRole,
    dispatchGetUserRolesAndClaims
}

export default operations
import {
  GET_PAYMENT_RECONCILLATION_DATA, GET_PAYMENT_REVERSE, SAVE_UPDATE_PAYMENT_REVERSE, SET_PAYMENT_RECONCILLATION_DATA, SET_PAYMENT_REVERSE,SET_PAYMENT_RECONCILATION_LOADER, GET_UPDATE_CHECK_NUMBER, SET_UPDATE_CHECK_NUMBER
} from './types'

export const getPaymentReconcillationData = (payload: any) => {
  return {
    type: GET_PAYMENT_RECONCILLATION_DATA,
    payload
  }
}

export const setPaymentReconcillationData = (data: any) => {
  return {
    type: SET_PAYMENT_RECONCILLATION_DATA,
    payload: data
  }
}

export const saveUpdatePaymentReverse = (payload: any) => {
  return {
    type: SAVE_UPDATE_PAYMENT_REVERSE,
    payload
  }
}

export const getPaymentReverse = (reverseId: any) => {
  return {
    type: GET_PAYMENT_REVERSE,
    reverseId
  }
}

export const setPaymentReverse = (data: any) => {
  return {
    type: SET_PAYMENT_REVERSE,
    data
  }
}

  export const setPaymentReconcilationLoader = (data: any) => {
    return {
      type: SET_PAYMENT_RECONCILATION_LOADER,
      data
    }
}

export const getUpdateCheckNumber = (payload: any) => {
  return {
    type: GET_UPDATE_CHECK_NUMBER,
    payload
  }
}

export const setUpdateCheckNumber = (data: any) => {
  return {
    type: SET_UPDATE_CHECK_NUMBER,
    data
  }
}
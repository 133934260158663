import React, { useEffect } from 'react'
import FileNotReceived from './FileNotReceived'

const statusList: any = ['NOT RECEIVED', 'FILE ERROR']

const FileNotReceivedContainer = (props: any) => {
  const {
    vendorListByStatus,
    selectedStatus,
    onClickSendReminder,
    fetchAction,
    Pageno
  } = props;

  useEffect(() => {
    if (statusList.includes(selectedStatus?.title)) {
      gridColumn.splice(3, 0, actionColumn)
      setGridColumns(gridColumn)
    } else {
      setGridColumns(gridColumn);
    }
  }, [selectedStatus])

  const gridColumn = [
    {
      key: "month",
      title: "Month",
      width: "80px",
    },
    {
      key: "year",
      title: "Year",
      width: "60px",
    },
    {
      key: "vendor",
      title: "Vendor",
      width: "200px",
    }
  ]

  const actionColumn = {
    key: 'Action',
    title: 'Action',
    width: "90px",
    Cell: (row: any) => {
      return (<a
        href='/'
        onClick={(e: any) => {
          e.preventDefault();
          onClickSendReminder([row.rowData.month], row.rowData.supplierId)
        }}>Send Reminder
      </a>)
    },
  }

  const [gridColumns, setGridColumns] = React.useState(gridColumn);

  return (
    <div>
      <FileNotReceived
        gridColumn={gridColumns}
        vendorListByStatus={vendorListByStatus}
        fetchAction={fetchAction}
        pageNo={Pageno}
      />
    </div>
  )
}

export default FileNotReceivedContainer
import React from "react";
import { Row, Col, Label, InputField, Checkbox, Alert, SearchSelectBox } from "client/CommonComponents";
import "./ProductCategoryEntry.scss";
import { isAuthorized } from "client/utils/Permissions";

interface IProps {
    handleInputChange?: any;
    errors?: any;
    isSaveClicked?: any;
    values?: any;
    handleAddEditClick?: any;
    categoryOptions?: any;
    subCategoryOptions?: any;
    handleCategory?: any;
    categorySaved?: any;
    handleMessage?: Function;
    errorCategory?: any;
}

const CategoryModal = (props: IProps) => {
    const {
        handleInputChange,
        errors,
        isSaveClicked,
        values,
        handleAddEditClick,
        categoryOptions,
        subCategoryOptions,
        categorySaved,
        handleMessage,
        errorCategory
     } = props;
     return (
        <div className="category-modal-container">
            <Row>
                <Col lg={12} className='mb-3'>
                    <Alert showAlert={errorCategory} dismissAlert={handleMessage} type="error" text={errorCategory || "Something went wrong please try after sometime"} className="mb-2" />
                    <Alert showAlert={categorySaved} dismissAlert={handleMessage} type="success" text='Saved Successfully' className="mb-2" />
                </Col>
                <Col lg={6} className='mb-3'>
                    <Label>Categories</Label>
                    <SearchSelectBox
                        onChange={(item: any, e: any) => props.handleCategory(e, item)}
                        value={categoryOptions?.find((i: any) => i.key === values.categoryId)}
                        filterOptionValue={3}
                        placeholder='Add New'
                        options={categoryOptions}
                        name='addNew'
                        className="search-dropdown"
                        target="modalPopupMasterContainer"
                    />
                </Col>
                <Col lg={6} className='mb-3'>
                    <Label required>Name</Label>
                    <InputField name='categoryName' onChange={handleInputChange} value={values.categoryName} errorMessage={isSaveClicked ? errors.categoryName : ''}/>
                </Col>
                <Col lg={12} className='mb-3'>
                    <Label>Description</Label>
                    <InputField row={3}  multiline={true} resizable={false} name='description' onChange={handleInputChange} value={values.description} errorMessage={isSaveClicked ? errors.description : ''}/>
                </Col>
                <Col lg={6} className='mb-3'>
                    <Label>
                        <div className="sub-category">
                            Subcategories
                            {isAuthorized("Subcategories", "create") && <span onClick={handleAddEditClick} className={!isAuthorized("Subcategories", "create") ? "disableLink" : ""}>
                                Add/Edit
                            </span>}
                        </div>
                    </Label>
                    <SearchSelectBox
                        onChange={(item: any, e: any) => props.handleInputChange(e, item)}
                        value={subCategoryOptions?.find((i: any) => i.key === values.subCategoryId)}
                        filterOptionValue={3}
                        placeholder='Select'
                        options={subCategoryOptions}
                        name='subCategoryId'
                        className="search-dropdown"
                        target="modalPopupMasterContainer"
                    />
                </Col>
                <Col lg={12} className='mb-4'>
                    <Checkbox checked={values.isActive? true: false} label='Active' onChange={handleInputChange} className='autoCorrect' name="isActive" disabled={!isAuthorized("Categories", "delete")}/>
                </Col>
            </Row>
        </div>
     )

}

export default CategoryModal;
import React from "react";
import { Row, Col, Label, InputField, Checkbox, Alert, SearchSelectBox } from "client/CommonComponents";
import "./ProductSubCategoryEntry.scss";
import { isAuthorized } from "client/utils/Permissions";

interface IProps {
    handleInputChange?: any;
    errors?: any;
    isSaveClicked?: any;
    values?: any;
    subCategoryOptions?: any;
    handleSubCategory?: any;
    subCategorySaved?: boolean;
    handleMessage?: Function;
    errorSubCategory?: any;
}

const SubCategoryModal = (props: IProps) => {
    const {
        handleInputChange,
        errors,
        isSaveClicked,
        values,
        subCategoryOptions,
        handleSubCategory,
        subCategorySaved,
        handleMessage,
        errorSubCategory
     } = props;

     return (
        <div className="category-modal-container">
            <Row>
                <Col lg={12} className='mb-3'>
                    <Alert showAlert={errorSubCategory} text={errorSubCategory || "Something went wrong please try after sometime"} type="error" dismissAlert={handleMessage} className="mb-2" />
                    <Alert showAlert={subCategorySaved} text='Saved Successfully' type="success" dismissAlert={handleMessage} className="mb-2" />
                </Col>
                <Col lg={6} className='mb-3'>
                    <Label>Sub Category</Label>
                    <SearchSelectBox
                        placeholder='Add New'
                        value={subCategoryOptions?.find((i: any) => i.key === values.subCategoryId)}
                        filterOptionValue={3}
                        onChange={(item: any, e: any) => handleSubCategory(e, item)}
                        options={subCategoryOptions}
                        name='subCategory'
                        className="search-dropdown"
                        target="modalPopupMasterContainer"
                    />
                </Col>
                <Col lg={6} className='mb-3'>
                    <Label required>Name</Label>
                    <InputField name='subCategoryName' onChange={handleInputChange} value={values.subCategoryName} errorMessage={isSaveClicked ? errors.subCategoryName : ''}/>
                </Col>
                <Col lg={12} className='mb-3'>
                    <Label>Description</Label>
                    <InputField row={3}  multiline={true} resizable={false} name='description' onChange={handleInputChange} value={values.description} errorMessage={isSaveClicked ? errors.description : ''}/>
                </Col>
                <Col lg={12} className='mb-4'>
                    <Checkbox checked={values.IsActive} label='Active' onChange={handleInputChange} className='autoCorrect' disabled={!isAuthorized("Subcategories", "delete")} name="IsActive"/>
                </Col>
            </Row>
        </div>
     )

}

export default SubCategoryModal;
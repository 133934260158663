import React, { useEffect, useState } from "react";
import NewBulkUpload from "./NewBulkUpload";
import { useNavigate } from "react-router-dom";
import ViewBulkUpload from "../ViewBulkUpload";
import { useDispatch, useSelector } from "react-redux";
import supplierEntrySelectors from "../../../../../state/features/RebateManagement/SupplierEntry/selectors";
import bulkUploadSelectors from "../../../../../state/features/RebateSpent/BulkUpload/selectors";
import supplierEntryOperations from "../../../../../state/features/RebateManagement/SupplierEntry/operations";
import bulkuploadOperations from "../../../../../state/features/RebateSpent/BulkUpload/operations";
import userSelector from '../../../../../state/features/UserProfile/selectors';

const NewBulkUploadContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [files, setFiles] = useState<Array<any>>([]);
  const [noFiles, setNoFiles] = useState(false);
  const [fileError, setFileError] = useState<string>("");
  const [showViewBulkUpload, setShowViewBulkUpload] = useState(false);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [vendorValue, setVendorValue] = useState<string>("");
  const [vendorError, setVendorError] = useState<string>("");
  const dispatchGetSupplierdata =
  supplierEntryOperations.dispatchGetSupplierdata(dispatch);
  const dispatchSetBulkUploadFiles =
    bulkuploadOperations.dispatchSetBulkUploadFiles(dispatch);
  const dispatchSetBulkUploadFlag = bulkuploadOperations.dispatchSetBulkUploadFlag(dispatch)
  const dispatchSetBulkUploadError = bulkuploadOperations.dispatchsetBulkUploadError(dispatch)


  const { bulkUploadSuccess, bulkUploadFailed, suppliersData, username }: any = useSelector((state) => ({
    bulkUploadSuccess: bulkUploadSelectors.getBulkUploadFlag(state),
    bulkUploadFailed: bulkUploadSelectors.getBulkUploadError(state),
    suppliersData: supplierEntrySelectors.getSuppliersData(state),
    username: userSelector.getUsername(state),
  }));

  useEffect(() => {
    dispatchGetSupplierdata();
  }, []);

  const getVendorOptions = (item: any) => ({
    ...item,
    text: item.supplierName,
    label: item.supplierName,
    value: item.supplierName,
    key: parseInt(item.supplierId),
  });

  useEffect(() => {
    if (suppliersData?.length > 0) {
      const options = suppliersData
        .filter((i: any) => i.isActive)
        .map((item: any) => getVendorOptions(item));
      setVendorOptions(options);
    }
  }, [suppliersData]);

  const breadcrumlist = [
    {
      text: "Rebate Spend",
      key: "f1",
      // href: "/Home",
      // onClick: (e: any) => {
      //   e.preventDefault();
      //   handleHome();
      // },
    },
    { text: "Bulk Upload", key: "f2" },
  ];
  const handleHome = () => {
    navigate("/");
  };

  const setFileNError = (e: any) => {
    setFiles(files.concat(e.target.files[0]));
    setFileError("");
  };

  const handleSignleFileChange = (e: any) => {
    switch (e.target.files[0].type) {
      // case "text/csv":
      //   setFileNError(e);
      //   break;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        setFileNError(e);
        break;
      case "application/vnd.ms-excel":
        setFileNError(e);
        break;
      default:
        setFileError("Upload only xlxs file");
        break;
    }
  };

  const updateFile = (element: any) => {
    setFiles((prev) => prev.concat(element));
  };

  const getCheckValid = (element: any) => {
    switch (element.type) {
      // case "text/csv":
      //   updateFile(element);
      //   break;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        updateFile(element);
        break;
      case "application/vnd.ms-excel":
        updateFile(element);
        break;
      default:
        setFileError("Upload only xlxs files");
        break;
    }
  };

  const handleMultipleFileChange = (e: any) => {
    for (let element of e.target.files) {
      getCheckValid(element);
    }
  };

  const onFileChange = (e: any) => {
    if (e.target.files.length === 1 && !files.length) {
      handleSignleFileChange(e);
    } else {
      handleMultipleFileChange(e);
    }
    setNoFiles(false);
    handleMessage();
  };

  const handleUploadFiles = () => {
     if(!files.length && !vendorValue){
      setFileError("Please add files to upload");
      setVendorError("Please select a vendor");
    } else if(files.length && !vendorValue){
      setVendorError("Please select a vendor");
    } else if (!files.length && vendorValue){
      setFileError("Please add files to upload");
    } else {
      const data = new FormData();
      files.forEach((file) => {
        data.append(`Files`, file);
      });
      dispatchSetBulkUploadFiles(data, username, vendorValue);
      setFiles([]);
      setVendorValue("");
    }
  };

  const onHideShowViewBulk = () => {
    setShowViewBulkUpload(false);
  };

  const handleVendorChange = (item: any) => {
    setVendorValue(item.key);
    setVendorError("")
    handleMessage();
  };

  const handleMessage = () => {
    dispatchSetBulkUploadFlag(false)
    dispatchSetBulkUploadError('')
  }

  if (showViewBulkUpload) {
    return <ViewBulkUpload onHideShowViewBulk={onHideShowViewBulk} />;
  } else {
    return (
      <NewBulkUpload
        breadcrumlist={breadcrumlist}
        handleHome={handleHome}
        onFileChange={onFileChange}
        noFiles={noFiles}
        files={files}
        fileError={fileError}
        handleUploadFiles={handleUploadFiles}
        bulkUploadSuccess={bulkUploadSuccess}
        bulkUploadFailed={bulkUploadFailed}
        vendorOptions={vendorOptions}
        vendorValue={vendorValue}
        handleVendorChange={handleVendorChange}
        vendorError={vendorError}
        handleMessage={handleMessage}
      />
    );
  }
};

export default NewBulkUploadContainer;

export const GET_PAYMENT_RECONCILLATION_DATA = 'GET_PAYMENT_RECONCILLATION_DATA';
export const SET_PAYMENT_RECONCILLATION_DATA = 'SET_PAYMENT_RECONCILLATION_DATA';

export const SAVE_UPDATE_PAYMENT_REVERSE = 'SAVE_UPDATE_PAYMENT_REVERSE';
export const SET_UPDATE_PAYMENT_REVERSE = 'SET_UPDATE_PAYMENT_REVERSE';

export const GET_PAYMENT_REVERSE = 'GET_PAYMENT_REVERSE';
export const SET_PAYMENT_REVERSE = 'SET_PAYMENT_REVERSE';

export const SET_PAYMENT_RECONCILATION_LOADER = 'SET_PAYMENT_RECONCILATION_LOADER'

export const GET_UPDATE_CHECK_NUMBER = 'GET_UPDATE_CHECK_NUMBER';
export const SET_UPDATE_CHECK_NUMBER = 'SET_UPDATE_CHECK_NUMBER';


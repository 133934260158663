import * as R from 'ramda';

const getProductData = (state:any) => R.pathOr('', ['ProductEntry', 'productData'], state);
const getProduct = (state:any) => R.pathOr('', ['ProductEntry', 'product'], state);
const getProductSaved = (state:any) => R.pathOr('', ['ProductEntry', 'productSaved'], state);
const getErrorProduct = (state:any) => R.pathOr('', ['ProductEntry', 'errorProduct'], state);
const getProductName = (state:any) => R.pathOr('', ['ProductEntry', 'productName'], state);
const selectors = {
  getProductData,
  getProduct,
  getProductSaved,
  getErrorProduct,
  getProductName
}

export default selectors

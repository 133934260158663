import * as R from 'ramda';

const getPaymentReconcillationData = (state:any) => R.pathOr('', ['PaymentReconcillations', 'paymentReconcillationData'], state);
const getPaymentReverseData = (state:any) => R.pathOr('', ['PaymentReconcillations', 'paymentReverse'], state);
const getPaymentReconcilationLoader = (state:any) => R.pathOr('',['PaymentReconcillations','paymentReconcillationLoader'],state)
const getUpdateCheckNumber = (state:any) => R.pathOr('',['PaymentReconcillations','updateCheckNumber'],state)

const selectors = {
  getPaymentReconcillationData,
  getPaymentReverseData,
  getPaymentReconcilationLoader,
  getUpdateCheckNumber
}

export default selectors

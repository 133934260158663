export const GET_STATES = 'GET_STATES';
export const SET_STATES = 'SET_STATES';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const GET_SUPPLIERDATA = 'GET_SUPPLIERDATA';
export const SET_SUPPLIERDATA = 'SET_SUPPLIERDATA';
export const GET_SUPPLIER = 'GET_SUPPLIER';
export const SET_SUPPLIER = 'SET_SUPPLIER';
export const GET_CREATEUPDATESUPPLIER = 'GET_CREATEUPDATESUPPLIER';
export const GET_MANUFACTURER = 'GET_MANUFACTURER';
export const SET_MANUFACTURER = 'SET_MANUFACTURER';
export const GET_SAVEDSUPPLIERFLAG = 'GET_SAVEDSUPPLIERFLAG';
export const SET_SAVEDSUPPLIERFLAG = 'SET_SAVEDSUPPLIERFLAG';
export const SET_ERRORSUPPLIER = 'SET_ERRORSUPPLIER';
export const SET_SUPPLIERNAME = 'SET_SUPPLIERNAME';
export const GET_CATEGORY_MANAGER = 'GET_CATEGORY_MANAGER';
export const SET_CATEGORY_MANAGER = 'SET_CATEGORY_MANAGER';
export const GET_PRIMARY_ABNUMBER = 'GET_PRIMARY_ABNUMBER'
export const SET_PRIMARY_ABNUMBER = 'SET_PRIMARY_ABNUMBER'
export const GET_SECONDARY_ABNUMBER = 'GET_SECONDARY_ABNUMBER'
export const SET_SECONDARY_ABNUMBER = 'SET_SECONDARY_ABNUMBER'
export const GET_SUPPLIER_DETAILS_BY_ABNUMBER = 'GET_SUPPLIER_DETAILS_BY_ABNUMBER'
export const SET_SUPPLIER_DETAILS_BY_ABNUMBER = 'SET_SUPPLIER_DETAILS_BY_ABNUMBER'
export const GET_SECONDARY_ABNUMBER_BY_SUPPLIER = 'GET_SECONDARY_ABNUMBER_BY_SUPPLIER'
export const SET_SECONDARY_ABNUMBER_BY_SUPPLIER = 'SET_SECONDARY_ABNUMBER_BY_SUPPLIER'
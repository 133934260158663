import * as R from 'ramda';

const getSubCategoryData = (state:any) => R.pathOr('', ['SubCategoryEntry', 'subCategoryData'], state);
const getSubCategory = (state:any) => R.pathOr('', ['SubCategoryEntry', 'subCategory'], state);
const getSubCategorySaved = (state:any) => R.pathOr('', ['SubCategoryEntry', 'subCategorySaved'], state);
const getErrorSubCategory = (state:any) => R.pathOr('', ['SubCategoryEntry', 'errorSubCategory'], state);
const getSubCategoryName = (state:any) => R.pathOr('', ['SubCategoryEntry', 'subCategoryName'], state);
const selectors = {
  getSubCategoryData,
  getSubCategory,
  getSubCategorySaved,
  getErrorSubCategory,
  getSubCategoryName
}

export default selectors
import React, { useEffect, useState } from 'react';
import AddContact from './AddContact';
import collectionTrackingOperations from '../../../../../state/features/RebateManagement/CollectionTracking/operations';
import userSelector from '../../../../../state/features/UserProfile/selectors';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

let currentTime = new Date();
const AddContactContainer = (props: any) => {

    const {
        contactModalVisible,
        hideAddContactModal,
        selectedSupplier,
        insertContactHistoryData,
        Status
    } = props;

    
    const [response, setResponse] = useState<Boolean>(false)
    const [result, setResult] = useState<Boolean>(false)

    const initialAlertState = {
        isShowAlert: false,
        alertMessage: "",
        isPastDate: false,
        onClickYes: () => {},
        onClickNo: () => {}
    }
    const dispatch = useDispatch();
    const [contactData, setContactData] = useState<any>([{
        SupplierId: selectedSupplier,
        date: null,
        comment: "",
        id: 0
    }]);

    const [alertData, setAlertData] = useState<any>(initialAlertState);

    const dispatchGetInsertContactHistoryData = collectionTrackingOperations.dispatchGetInsertContactHistoryData(dispatch);

    const {
        username
    }: any = useSelector(
        (state: any) => ({
            username: userSelector.getUsername(state)
        })
    )

    useEffect(() => {
    if(Status==='success') {
        setResponse(true)
    }
    else {
        setResponse(false)
    }
    },[Status])

    const onClickAdd = () => {
        let contactDataTemp = JSON.parse(JSON.stringify(contactData));
        let lastElement = contactDataTemp[contactDataTemp.length - 1]
        const initialState = {
            SupplierId: selectedSupplier,
            date: null,
            comment: "",
            id: lastElement.id + 1
        }
        contactDataTemp.push(initialState)
        setContactData(contactDataTemp)
    }

    const onClickDelete = (deleteId: any) => {
        let contactDataTemp = JSON.parse(JSON.stringify(contactData));
        let updatedData = contactDataTemp.filter((item: any) => item.id != deleteId.id)
        setContactData(updatedData)
    }

    const onChangeDate = (date: any, id: any) => {
        let contactDataTemp = JSON.parse(JSON.stringify(contactData));
        let updatedData = contactDataTemp.map((item: any) => {
            if (item.id === id) {
                item.date = moment(date).format();
            }
            return item
        })
        setContactData(updatedData)
    }

    const onChangeComment = (comment: any, id: any) => {
        let contactDataTemp = JSON.parse(JSON.stringify(contactData));
        let updatedData = contactDataTemp.map((item: any) => {
            if (item.id === id) {
                item.comment = comment
            }
            return item
        })
        setContactData(updatedData)
    }
    
    const validation = () => {        
        let contactDataTemp = JSON.parse(JSON.stringify(contactData));
        const tempData = contactDataTemp[contactDataTemp.length-1]
        if(!tempData.date && tempData.comment.trim() == ""){
            contactDataTemp.pop();
        }
        const isDate = contactDataTemp.filter((item: any) => !item.date).length
        const isComment = contactDataTemp.filter((item: any) => item.comment.trim() == "").length
        const commentCount = contactDataTemp.filter((item: any) => item.comment.trim()).length
        let alertMessage = ""
        if(isComment > 0 || commentCount === 0){
            alertMessage = "Please provide a comment"
        }
        const isPastDate = contactDataTemp.filter((item: any) => currentTime.getTime() - new Date(item.date != null ? item?.date : currentTime).getTime() > 0).length             
        if (alertMessage.length > 0) {
            setAlertData({
                isShowAlert: true,
                alertMessage: alertMessage,
                isPastDate: false
            })
            return false;
        } else if (isPastDate > 0) {
            setAlertData({
                isShowAlert: true,
                alertMessage: "Are you sure you want to set the expected payment date to a date in the past?",
                isPastDate: true,
                onClickYes: () => {insertData()},
                onClickNo: () => {onCloseAlert()}
            })
            return false;
        } else {
            return true
        }
    }

    const onClickAddList = () => {
        if (validation()) {
            insertData()
        }
    }
    const insertData = () => {
        let contactDataTemp = JSON.parse(JSON.stringify(contactData));
        const tempData = contactDataTemp[contactDataTemp.length-1]
        if(!tempData.date && tempData.comment.trim() == ""){
            contactDataTemp.pop();
        }
        let updatedData = contactDataTemp.map((item: any) => {
            item.createdBy = username.replace(/@[^@]*$/,"").replace("."," ")
            item.SupplierId = selectedSupplier.id
            item.expectedPaymentDate = item.date
            return item
        })
        dispatchGetInsertContactHistoryData(updatedData)
        setResult(true)
        setTimeout(() => {
        hideAddContactModal()
        }, 2000);
    }

    const onCloseAlert = () => {
        setAlertData(initialAlertState)
    }

    return (
        <AddContact
            onClickAdd={onClickAdd}
            contactData={contactData}
            onClickDelete={onClickDelete}
            onChangeDate={onChangeDate}
            onChangeComment={onChangeComment}
            onClickAddList={onClickAddList}
            contactModalVisible={contactModalVisible}
            hideAddContactModal={hideAddContactModal}
            alertData={alertData}
            onCloseAlert={onCloseAlert}
            insertContactHistoryData={insertContactHistoryData}
            Status={Status}
            response={response}
            result={result}
        />
    )
}

export default AddContactContainer;

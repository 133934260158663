import { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { operations, selectors } from "state/features/RebateManagement/RebateProgramEntry";
import userSelector from 'state/features/UserProfile/selectors';
import moment from "moment";
import RenewContract from './RenewContract'


const initialState = {
isNewContractModal: true,
contractDetailsModal: false,
selectDataModal: false,
newContractName: '',
contractNameError: '',
programYear: 0,
programYearError: '',
startDate: new Date(),
startDateError: '',
endDate: new Date(),
endDateError: '',
evergreen: false,
expectedPaymentDate: new Date(),
endofyearDaystoPay: true,
withinTerms: true,
netDays: true,
eop: true,
rebatePeriodId: true,
rebateTypeSettings: true,
calculationTypeId: true,
calculationMethodId: true,
tiers: true,
rebateFrequencyId: true,
rebatePayMethodId: true,
isExcludeCreditCardSpend: true
};

const reducer = (state: any, action: any) => {
  let newState;
  switch (action.type) {
    case "NEW_CONTRACT_NEXT":
      newState = { ...state, isNewContractModal: false, contractDetailsModal: true }
      break;
    case "NEW_CONTRACT_NAME_CHANGE":
      newState = { ...state, newContractName: action.payload }
      break;
    case "NEW_CONTRACT_ERROR":
      newState = { ...state, contractNameError: action.payload }
      break;
    case "PROGRAM_YEAR_CHANGE":
      newState = { ...state, programYear: action.payload }
      break;
    case "PROGRAM_YEAR_ERROR":
      newState = { ...state, programYearError: action.payload }
      break;
    case "START_DATE_CHANGE":
      newState = { ...state, startDate: new Date(action.payload) }
      break;
    case "START_DATE_ERROR":
      newState = { ...state, startDateError: action.payload }
      break;
    case "END_DATE_CHANGE":
      newState = { ...state, endDate: new Date(action.payload) }
      break;
    case "END_DATE_ERROR":
      newState = { ...state, endDateError: action.payload }
      break;
    case "EVERGREEN_CHANGE":
      newState = { ...state, evergreen: action.payload }
      break;
    case "EXPECTED_PAYMENT_DATE_CHANGE":
      newState = { ...state, expectedPaymentDate: new Date(action.payload) }
      break;
    case "CONTRACT_DETAILS_NEXT":
      newState = { ...state, contractDetailsModal: false, selectDataModal: true }
      break;
    case "END_OF_YEAR_TO_PAY_CHANGE":
      newState = { ...state, endofyearDaystoPay: action.payload }
      break;
    case "WITHIN_TERMS_CHANGE":
      newState = { ...state, withinTerms: action.payload }
      break;
    case "NET_DAYS_CHANGE":
      newState = { ...state, netDays: action.payload }
      break;
    case "EOP_CHANGE":
      newState = { ...state, eop: action.payload }
      break;
    case "REBBATE_PERIOD_ID_CHANGE":
      newState = { ...state, rebatePeriodId: action.payload }
      break;
    case "REBBATE_TYPE_SETTINGS_CHANGE":
      newState = { ...state, rebateTypeSettings: action.payload }
      break;
    case "CALCULATION_TYPE_ID_CHANGE":
      newState = { ...state, calculationTypeId: action.payload }
      break;
    case "CALCULATION_METHOD_ID_CHANGE":
      newState = { ...state, calculationMethodId: action.payload }
      break;
    case "TIERS_CHANGE":
      newState = { ...state, tiers: action.payload }
      break;
    case "REBATE_FREQUENCY_ID_CHANGE":
      newState = { ...state, rebateFrequencyId: action.payload }
      break;
    case "REBATE_PAYMENT_METHOD_ID_CHANGE":
      newState = { ...state, rebatePayMethodId: action.payload }
      break;
    case "SELECT_DATA_FINISH":
      newState = { ...state, selectDataModal: false }
      break;
    case "CLOSE_RENEW_CONTRACT":
      newState = { ...initialState, isNewContractModal: false }
      break;
    case "EXCLUDE_CREDIT_CARD_CHANGE":
      newState = { ...state, isExcludeCreditCardSpend: action.payload }
      break;  
    default:
      newState = state;
  }
  return newState
};

const RenewContractContainer = (props: any) => {
const { values, closeRenewContract } = props

const dispatchGlobal = useDispatch();
const { allContract, username }: any = useSelector((state: any) => ({
  allContract: selectors.getAllContract(state),
  username: userSelector.getUsername(state),
}))

const [state, dispatch] = useReducer(reducer, initialState);
const dispatchGetAddContractName = operations.dispatchGetAddContractName(dispatchGlobal);
const dispatchSetContractDetailsById = operations.dispatchSetContractDetailsById(dispatchGlobal);

  useEffect(() => {
    let tempValues = JSON.parse(JSON.stringify(values))
    if (tempValues.programYear) {
      dispatch({ type: 'PROGRAM_YEAR_CHANGE', payload: Number(tempValues.programYear) + 1 })
    }

    if (tempValues.startDate) {
      dispatch({ type: 'START_DATE_CHANGE', payload: moment(tempValues.startDate).add(1, 'y').format() })
    }

    if (tempValues.endDate && !tempValues.everGreen) {
      dispatch({ type: 'END_DATE_CHANGE', payload: moment(tempValues.endDate).add(1, 'y').format() })
    }

    if (tempValues.everGreen) {
      dispatch({ type: 'EVERGREEN_CHANGE', payload: tempValues.everGreen })
    }

    if (tempValues.expectedPaymentDate) {
      dispatch({ type: 'EXPECTED_PAYMENT_DATE_CHANGE', payload: moment(tempValues.expectedPaymentDate).add(1, 'y').format() })
    }

  }, [])

const onContractNameChange = (e: any) => {
  dispatch({type: 'NEW_CONTRACT_NAME_CHANGE', payload: e.target.value })
  dispatch({ type: 'NEW_CONTRACT_ERROR', payload: '' })
}

const validateContractName = (newContractName: string) => {
  let isValid = true
  if(!newContractName){
    dispatch({ type: 'NEW_CONTRACT_ERROR', payload: 'Please provide New Contract Name' })
    isValid = false
  } else {
    const checkDuplicate = allContract?.find((contract: any)=> contract.text?.toLowerCase() === newContractName?.toLowerCase())
    if(checkDuplicate){
      dispatch({ type: 'NEW_CONTRACT_ERROR', payload: 'Contract with same name already exists' })
      isValid = false
    }
  }
  return isValid
}

  const onNextNewContractName = () => {
    let isValid = validateContractName(state.newContractName)
    if (isValid) {
      const payload = {
        contractName: state.newContractName,
        programId: values.program,
        modifiedBy: username
    }
    dispatchGetAddContractName(payload, values.program)
    dispatch({ type: 'NEW_CONTRACT_NEXT' })
    }
  }

  const onContractDetailsNext = () => {
    const startYear = new Date(state?.startDate).getFullYear();
    const endYear = new Date(state?.endDate).getFullYear();
    if (!state.programYear || !state.startDate || (!state.endDate && !state.evergreen)) {
      if (!state.programYear) {
        dispatch({ type: 'PROGRAM_YEAR_ERROR', payload: 'Please provide valid Program Year in YYYY format' })
      }
      if (!state.startDate) {
        dispatch({ type: 'START_DATE_ERROR', payload: 'Please provide Start Date' })
      }
      if (!state.endDate && !state.evergreen) {
        dispatch({ type: 'END_DATE_ERROR', payload: 'Please provide End Date' })
      }
    } else if (state.programYear?.toString()?.length < 4) {
      dispatch({ type: 'PROGRAM_YEAR_ERROR', payload: 'Please provide valid Program Year in YYYY format' })
    } else if (state.programYear < startYear || (!state.evergreen) && state.programYear > endYear) {
      dispatch({ type: 'PROGRAM_YEAR_ERROR', payload: 'Please provide valid Program Year between Start Date and End Date' })
    } else {
      dispatch({ type: 'PROGRAM_YEAR_ERROR', payload: '' })
      dispatch({ type: 'CONTRACT_DETAILS_NEXT' })
    }
  }

  const onFinishClick = () => {
    const tempValues = JSON.parse(JSON.stringify(values))
    const contractDetails = allContract.find((contract: any) => state.newContractName === contract.text)
    const newContractData = {
      contractDetailId: Number(contractDetails?.value),
      contractName: contractDetails?.text,
      rebateProgramId: tempValues?.rebateProgramId,
      contractStartDate: state.startDate,
      contractEndDate: state?.evergreen ? null : state.endDate,
      programYear: state.programYear,
      billingTermsId: tempValues?.billingTermsId,
      rebateCalculationId: tempValues?.rebateCalculationId,
      rebateFrequencyId: state.rebateFrequencyId ? tempValues?.rebateFrequencyId : null,
      rebatePaymentMethodId: state?.rebatePayMethodId ? tempValues?.rebatePayMethodId : null,
      paymentDueDate: state?.expectedPaymentDate,
      agency: tempValues?.agency,
      eop: state?.eop ? tempValues?.eop : false,
      withinTerms: state?.withinTerms ? tempValues?.withinTerms : false,
      everGreen: state?.evergreen,
      isActive: tempValues?.isActive,
      closeDate: tempValues?.closeDate,
      extended: tempValues?.extended,
      dateExtended: tempValues?.dateExtended,
      oldEndDate: tempValues?.oldEndDate,
      reNegotiated: tempValues?.reNegotiated,
      dateReNegotiated: tempValues?.dateReNegotiated,
      contractLocation: tempValues?.contractLocation,
      endofYearDaystoPay: state?.endofyearDaystoPay ? tempValues?.endofYearDaystoPay : 0,
      calculateOnInvoiceDate: tempValues?.calculateOnInvoiceDate,
      calculateOnPaymentDate: tempValues?.calculateOnPaymentDate,
      createdBy: tempValues?.createdBy,
      createdOn: new Date(tempValues?.createdOn),
      modifiedBy: tempValues?.modifiedBy,
      modifiedOn: new Date(tempValues?.modifiedOn),
      rebateProgram: tempValues?.rebateProgram,
      rebateCalculations: state.calculationTypeId ? { ...tempValues?.rebateCalculations, calculationMethodId: state.calculationMethodId ? tempValues?.rebateCalculations?.calculationMethodId : 0 } : {
        calculationId: 0,
        calculationTypeId: 0,
        calculationMethodId: 0,
        contractDetailId: null,
        createdBy: null,
        createdOn: null,
        modifiedBy: null,
        modifiedOn: null
      },
      rebatePeriods: state.rebatePeriodId ? { ...tempValues?.rebatePeriods } : {
        startMonth: 1,
        startDay: null,
        endMonth: 1,
        endDay: null,
        calenderMonthOffset: 0,
        fiscalMonthOffset: 0,
        rebatePeriodId: null,
        periodMasterId: null
      },
      billingTerms: { ...tempValues?.billingTerms, paymentTerms: { ...tempValues?.billingTerms?.paymentTerms, netDays: state?.netDays ? tempValues?.billingTerms?.paymentTerms?.netDays : 0 } },
      calculationTypeFlatPercentagesVM: { ...tempValues?.calculationTypeFlatPercentagesVM, contractDetailId: contractDetails?.value },
      calculationTypeGrowthTargetsVM: { ...tempValues?.calculationTypeGrowthTargetsVM, contractDetailId: contractDetails?.value },
      calculationTypeGrowthTargetTieredVM: { ...tempValues?.calculationTypeGrowthTargetTieredVM, contractDetailId: contractDetails?.value },
      calculationTypeLumSumsVM: { ...tempValues?.calculationTypeLumSumsVM, contractDetailId: contractDetails?.value },
      calculationTypePrePaidLumSumsVM: { ...tempValues?.calculationTypePrePaidLumSumsVM, contractDetailId: contractDetails?.value },
      rebateTiers: state.tiers ? [...tempValues?.rebateTiers] : [],
      contractDetailRebateTypeVM: state.rebateTypeSettings ? { ...tempValues?.contractDetailRebateTypeVM } : {
        contractDetailRebateTypeId: 0,
        contractDetailId: null,
        rebateTypeId: null,
        rebateProductCategoryTypes: [],
        rebateProductSubCategoryTypes: [],
        rebateProductTypes: []
      },
      rebateCheckdate: [],
      vendorCreditCardSpend: state.isExcludeCreditCardSpend ? [...tempValues?.vendorCreditCardSpend] : []
    }
    dispatchSetContractDetailsById(newContractData)
    dispatch({ type: 'SELECT_DATA_FINISH' })
  }

  const handleProgramYearChange = (value: number) => {
      dispatch({ type: 'PROGRAM_YEAR_ERROR', payload: '' })
      dispatch({type: 'PROGRAM_YEAR_CHANGE', payload: value })
  }

  const onIncrement = () => {
    dispatch({type: 'PROGRAM_YEAR_CHANGE', payload: (state.programYear + 1) })
  }

  const onDecrement = () => {
    if(state.programYear > 0){
      dispatch({type: 'PROGRAM_YEAR_CHANGE', payload: (state.programYear - 1) })
    }
  }

  const handleDateChange = (date: Date, dateType: string) => {
    if (dateType === 'startDate') {
      dispatch({ type: 'START_DATE_CHANGE', payload: date })
      dispatch({type: 'START_DATE_ERROR', payload: ''})
    } else if (dateType === 'endDate') {
      dispatch({ type: 'END_DATE_CHANGE', payload: date })
      dispatch({type: 'END_DATE_ERROR', payload: ''})
    } else {
      dispatch({ type: 'EXPECTED_PAYMENT_DATE_CHANGE', payload: date })
    }
  }

  const onChangeEvergreen = (e: any, value: any) => {
    dispatch({ type: 'EVERGREEN_CHANGE', payload: value })
    if (value) {
      dispatch({ type: 'END_DATE_CHANGE', payload: null })
      dispatch({type: 'END_DATE_ERROR', payload: ''})
    }
  }

  const onChangeSelection = (e: any, value: any) => {
    let name = e.target.name;
    if (name === 'endofyearDaystoPay') {
      dispatch({ type: 'END_OF_YEAR_TO_PAY_CHANGE', payload: value })
    } else if (name === 'withinTerms') {
      dispatch({ type: 'WITHIN_TERMS_CHANGE', payload: value })
    } else if (name === 'netDays') {
      dispatch({ type: 'NET_DAYS_CHANGE', payload: value })
    } else if (name === 'eop') {
      dispatch({ type: 'EOP_CHANGE', payload: value })
    } else if (name === 'rebatePeriodId') {
      dispatch({ type: 'REBBATE_PERIOD_ID_CHANGE', payload: value })
    } else if (name === 'rebateTypeSettings') {
      dispatch({ type: 'REBBATE_TYPE_SETTINGS_CHANGE', payload: value })
    } else if (name === 'calculationTypeId') {
      dispatch({ type: 'CALCULATION_TYPE_ID_CHANGE', payload: value })
    } else if (name === 'calculationMethodId') {
      dispatch({ type: 'CALCULATION_METHOD_ID_CHANGE', payload: value })
    } else if (name === 'tiers') {
      dispatch({ type: 'TIERS_CHANGE', payload: value })
    } else if (name === 'rebateFrequencyId') {
      dispatch({ type: 'REBATE_FREQUENCY_ID_CHANGE', payload: value })
    } else if (name === 'rebatePayMethodId') {
      dispatch({ type: 'REBATE_PAYMENT_METHOD_ID_CHANGE', payload: value })
    } else if(name === 'isExcludeCreditCardSpend'){
      dispatch({ type: 'EXCLUDE_CREDIT_CARD_CHANGE', payload: value })
    }
  }

  const onCloseRenewContract = () => {
    dispatch({ type: 'CLOSE_RENEW_CONTRACT'})
    closeRenewContract()
  }

  return <RenewContract 
          state={state} 
          dispatch={dispatch} 
          onContractNameChange={onContractNameChange}
          onNextNewContractName={onNextNewContractName}
          onContractDetailsNext={onContractDetailsNext}
          onFinishClick={onFinishClick}
          handleProgramYearChange={handleProgramYearChange}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          handleDateChange={handleDateChange}
          onChangeEvergreen={onChangeEvergreen}
          onChangeSelection={onChangeSelection}
          onCloseRenewContract={onCloseRenewContract}
          />
}

export default RenewContractContainer

export const SET_USER_BRANCH_DETAIL = 'SET_USER_BRANCH_DETAIL';
export const GET_COUNTRY_DROPDOWN = 'GET_COUNTRY_DROPDOWN';
export const SET_COUNTRY_DROPDOWN = 'SET_COUNTRY_DROPDOWN';
export const SET_BRANCH_VALUES = 'SET_BRANCH_VALUES';
export const GET_BRANCH_VALUES = 'GET_BRANCH_VALUES';
export const INSERT_USER_DETAIL = 'INSERT_USER_DETAIL';
export const SET_RESULT_MESSAGE = 'SET_RESULT_MESSAGE';
export const GET_TITLE_DETAILS = 'GET_TITLE_DETAILS';
export const SET_TITLE_DETAILS = 'SET_TITLE_DETAILS';
export const GET_DEPARTMENT_DETAILS = 'GET_DEPARTMENT_DETAILS';
export const SET_DEPARTMENT_DETAILS = 'SET_DEPARTMENT_DETAILS';
export const GET_USER_DETAIL = 'GET_USER_DETAIL';
export const SET_USER_DETAIL = 'SET_USER_DETAIL';
export const SHOW_USER_PROFILE = 'SHOW_USER_PROFILE';
export const SET_COUNTRY_DETAILS =  'SET_COUNTRY_DETAILS';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const GET_USER_NAME = 'GET_USER_NAME';
export const SET_USER_NAME = 'SET_USER_NAME';
export const SET_USER_ROLE = 'SET_USER_ROLE';

export const GET_USER_ROLES_CLAIMS = 'GET_USER_ROLES_CLAIMS';
export const SET_USER_ROLES_CLAIMS = 'SET_USER_ROLES_CLAIMS';
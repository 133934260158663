import { put, call, takeEvery, all } from 'redux-saga/effects';  
import api from '../../../../client/utils/clientApiHelper';
import {
    GET_REBATESPENDDATA,
    GET_REBATESPENDBYID,
    GET_CREATEUPDATEREBATESPEND
} from './types'

import * as rebateSpendActions from './action';

function* getRebateSpendData():any {
    try {
        const response = yield call(api.callGet, `RebateSpend/GetAllRebateSpend`);
        yield put(rebateSpendActions.setRebateSpendData(response));
    } catch (error) {
        yield put(rebateSpendActions.setRebateSpendData([]));
    }
    
}

function* getRebateSpendById(data: any): any {
    try {
        const response = yield call(api.callGet, `RebateSpend/GetRebateSpendById?rebateSpendId=${data.id}`);
        yield put(rebateSpendActions.setRebateSpend(response));
    } catch (error) {
        yield put(rebateSpendActions.setRebateSpend({}));
    }
}

function* getCreateUpdateRebateSpend(data: any): any {
    try {
        const response = yield call(api.callPost, `RebateSpend/CreateUpdateRebateSpend`, data.payload);
        if (response) {
            yield put(rebateSpendActions.getRebateSpendData());
            yield put(rebateSpendActions.setRebateSpendSavedFlag(true))
        }
    } catch (error) {
        yield put(rebateSpendActions.setRebateSpend({}));
    }
}

function* watchGetCommonDashboard() {
    yield takeEvery(GET_REBATESPENDDATA, getRebateSpendData);
    yield takeEvery(GET_REBATESPENDBYID, getRebateSpendById);
    yield takeEvery(GET_CREATEUPDATEREBATESPEND, getCreateUpdateRebateSpend);
}

export default function* sagas() {
    yield all([
        watchGetCommonDashboard()
    ])
}
import React, { useCallback, useEffect, useState } from 'react'
import VendorFiles from './VendorFiles'
import { useDispatch, useSelector } from 'react-redux';
import selfReportingSelector from '../../../../state/features/RebateManagement/SelfReporting/selectors';
import selfReportingOperations from '../../../../state/features/RebateManagement/SelfReporting/operations';
interface InnerDiv {
  id: number;
  text: string;
}
const innerDivs: InnerDiv[] = [
  { id: 1, text: '2023' },
  { id: 2, text: '2024' },
  { id: 3, text: '2025' },
  { id: 4, text: '2026' },
  { id: 5, text: '2027' },
  { id: 6, text: '2028' },
  { id: 7, text: '2029' },
  { id: 8, text: '2030' },
  { id: 9, text: '2031' },
];
const innerDivsTwo: InnerDiv[] = [
  { id: 1, text: '2033' },
  { id: 2, text: '2034' },
  { id: 3, text: '2035' },
  { id: 5, text: '2037' },
  { id: 6, text: '2038' },
  { id: 7, text: '2039' },
  { id: 8, text: '2040' },
  { id: 4, text: '2036' },
  { id: 9, text: '2041' },
];

const months = ['January', 'February', 'March', "April", "May", "June", "July", "August", "September", "October", "November", "December"];
let currentTime = new Date();
const VendorFilesContainer = (props: any) => {
  const {
    setSelectedYear,
    selectedYear,
    setCurrentMonth,
    currentMonth,
    fileCompletionData,
    onClickSendReminder
  } = props;

  const dispatch = useDispatch();

  const dispatchGetVendorFileCount = selfReportingOperations.dispatchGetVendorFileCount(dispatch);
  const dispatchGetVendorListByStatus = selfReportingOperations.dispatchGetVendorListByStatus(dispatch);

  const [currentYearDiv, setCurrentYearDiv] = useState(0);
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const [isCalloutMonthVisible, setisCalloutMonthVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<any>({
    title: '',
    statusId: 0
  });
  const [selectedInnerDiv, setSelectedInnerDiv] = useState<number | null>(null);
  const [showFileStatusPopup, setShowFileStatusPopup] = useState(false);
  const [Pageno, setPageno] = useState<any>();

  const [SelectedMonthDiv, setSelectedMonthDiv] = useState<number | null>(null);

  const {
    vendorFileCount,
    vendorListByStatus
  }: any = useSelector(
    (state: any) => ({
      vendorFileCount: selfReportingSelector.getVendorFileCount(state),
      vendorListByStatus: selfReportingSelector.getVendorListByStatus(state)
    })
  )

  useEffect(() => {
    dispatchGetVendorFileCount({
      month: currentMonth + 1,
      year: selectedYear
    })
  }, [currentMonth, selectedYear])

  useEffect(() => {
    const year:any = innerDivs.find((item:any)=> parseInt(item.text) === currentTime.getFullYear())
    year?.id && setSelectedInnerDiv(year.id)
  }, [])

  const handleInnerDivClick = (id: number) => {
    setSelectedInnerDiv(id);
  };

  const handleButtonClick = () => {
    if (selectedInnerDiv !== null) {
      const selectedInnerDivText = innerDivs.find((div) => div.id === selectedInnerDiv)?.text || '';
      setSelectedYear(selectedInnerDivText);
      setCurrentMonth(Math.max(0,currentTime.getMonth() - 2));
      setIsCalloutVisible(!isCalloutVisible);
    }
    setIsCalloutVisible(false);
  };

  const fetchAction = useCallback((fetchData: any) => {

    if (fetchData.action && fetchData.action === "paging") {
      setPageno(fetchData.data.pageSize)
    }
  }, [])

  const toggleIsCalloutVisible = () => {
    setIsCalloutVisible(!isCalloutVisible);
  }

  const toggleMonthSelectVisible = () => {
    setisCalloutMonthVisible(!isCalloutMonthVisible);
    setSelectedMonthDiv(currentMonth);
  }


  const handlePrevClick = () => {
    setCurrentMonth((prevIndex: any) => (prevIndex === 0 ? months.length - 1 : prevIndex - 1));
    setSelectedMonthDiv(currentMonth - 1);
  };

  const handleNextClick = () => {
    setCurrentMonth((prevIndex: any) => (prevIndex === months.length - 1 ? 0 : prevIndex + 1));
    setSelectedMonthDiv(currentMonth + 1);
  };

  const handlePrevYearClick = () => {
    setCurrentYearDiv((prevIndex) => (prevIndex === 0 ? yearDiv.length - 1 : prevIndex - 1));
  };

  const handleNextYearClick = () => {
    setCurrentYearDiv((prevIndex) => (prevIndex === yearDiv.length - 1 ? 0 : prevIndex + 1));
  };

  const handleCalloutClose = () => {
    setIsCalloutVisible(false);
    setisCalloutMonthVisible(false);
  }

  const openFileStatusPopup = (statusName: string) => {
    dispatchGetVendorListByStatus({
      month: currentMonth + 1,
      year: selectedYear,
      statusId: statusName
    })
    setSelectedStatus({
      title: statusName.toUpperCase(),
      statusName: statusName
    })
    setShowFileStatusPopup(true);
  }
  const handleClose = () => {
    setShowFileStatusPopup(false);
  }

  const handleInnerMonthvClick = (index: any) => {
    setSelectedMonthDiv(index);
  };

  const handleMonthClick = () => {
    setCurrentMonth(SelectedMonthDiv);
    setisCalloutMonthVisible(false);
  }

  const yearDiv = [
    <div key="1">
      <div className="yearContainer">
        {innerDivs.map((innerDiv: any) => {
          return parseInt(innerDiv.text) <= currentTime.getFullYear() ?
            (
              <div
                key={innerDiv.id}
                className={`yearBox ${selectedInnerDiv === innerDiv.id ? 'yearSelected' : ''} ${parseInt(innerDiv.text) > currentTime.getFullYear() ? 'disabledYear' : ''}`}
                onClick={() => handleInnerDivClick(innerDiv.id)}>
                {innerDiv.text}
              </div>
            ) : <></>
        })}

      </div>
    </div>,
    // <div key="2">
    //   <div className='yearContainer'>
    //     {innerDivsTwo.map((innerDiv: any) => (
    //       <div
    //         key={innerDiv.id}
    //         className={`yearBox ${selectedInnerDiv === innerDiv.id ? 'yearSelected' : ''} ${parseInt(innerDiv.text) > currentTime.getFullYear() ? 'disabledYear' : ''}`}
    //         onClick={() => handleInnerDivClick(innerDiv.id)}
    //       >
    //         {innerDiv.text}
    //       </div>
    //     ))}
    //   </div>
    // </div>,
  ];

  return (
    <div>
      <VendorFiles
        months={months}
        currentMonth={currentMonth}
        handlePrevClick={handlePrevClick}
        handleNextClick={handleNextClick}
        currentYearDiv={currentYearDiv}
        handlePrevYearClick={handlePrevYearClick}
        handleNextYearClick={handleNextYearClick}
        yearDiv={yearDiv}
        toggleIsCalloutVisible={toggleIsCalloutVisible}
        isCalloutVisible={isCalloutVisible}
        selectedYear={selectedYear}
        handleButtonClick={handleButtonClick}
        handleCalloutClose={handleCalloutClose}
        showFileStatusPopup={showFileStatusPopup}
        openFileStatusPopup={openFileStatusPopup}
        handleClose={handleClose}
        vendorFileCount={vendorFileCount}
        fileCompletionData={fileCompletionData}
        vendorListByStatus={vendorListByStatus}
        selectedStatus={selectedStatus}
        onClickSendReminder={onClickSendReminder}
        fetchAction={fetchAction}
        Pageno={Pageno}
        toggleMonthSelectVisible = {toggleMonthSelectVisible}
        isCalloutMonthVisible = {isCalloutMonthVisible}
        handleMonthClick = {handleMonthClick}
        SelectedMonthDiv = {SelectedMonthDiv}
        setSelectedMonthDiv = {setSelectedMonthDiv}
        handleInnerMonthvClick = {handleInnerMonthvClick}
      />
    </div>
  )
}

export default VendorFilesContainer
import React from "react";
import './InputSpinner.scss';
import IncrementArrow from '../../assets/images/Arrows/UpArrow.png';
import DescrmentArrow from '../../assets/images/Arrows/DownArrow.png';

interface IProps {
    value?: string,
    onIncrement?:React.MouseEventHandler<HTMLDivElement>,
    onDecrement?:React.MouseEventHandler<HTMLDivElement>,
    disabled?:boolean,
    onChange?:React.ChangeEventHandler<HTMLInputElement>,
    onBlur?: React.FocusEventHandler<HTMLInputElement>,
    readOnly?:boolean
    type?: string;
    placeholder?:string;
}
const InputSpinner = ({value='',onDecrement,onIncrement,disabled,onChange,onBlur,readOnly, type, placeholder}:IProps) => {
    return <div className="monthSpinner">
        <input type={type} value={value} onChange={onChange} readOnly={readOnly} onBlur={onBlur} className='form-control' disabled={disabled} placeholder={placeholder}/>
        <div className={`spinner ${disabled? 'spinnerDisabled':''}`}>
            <div className="forward arrowBtn" onClick={onIncrement}>
                <img src={IncrementArrow} alt='upArrow'/>
            </div>
            <div className="backword arrowBtn" onClick={onDecrement}>
                <img src={DescrmentArrow} alt='downArrow'/>
            </div>
        </div>
    </div>
}

export default InputSpinner;

import { 
  GET_SUBCATEGORYDATA, 
  SET_SUBCATEGORYDATA,
  GET_SUBCATEGORYBYID,
  SET_SUBCATEGORY,
  GET_CREATEUPDATESUBCATEGORY,
  GET_SAVEDSUBCATEGORYFLAG,
  SET_SAVEDSUBCATEGORYFLAG,
  SET_ERRORSUBCATEGORYFLAG,
  SET_SUBCATEGORYNAME
} from './types'

export const getSubCategoryData = () => {
  return {
    type: GET_SUBCATEGORYDATA
  }
}

export const setSubCategoryData = (data: any) => {
  return {
    type: SET_SUBCATEGORYDATA,
    payload: data
  }
}

export const getSubCategoryById = (id: any) => {
  return {
    type: GET_SUBCATEGORYBYID,
    id: id
  }
}

export const setSubCategory = (data:any) => {
  return {
    type: SET_SUBCATEGORY,
    payload: data
  }
}

export const getCreateUpdateSubCategory = (data:any) => {
  return {
    type: GET_CREATEUPDATESUBCATEGORY,
    payload: data
  }
}

export const getSubCategorySavedFlag = () => {
  return {
    type: GET_SAVEDSUBCATEGORYFLAG
  }
}

export const setSubCategorySavedFlag = (flag: any) => {
  return {
    type: SET_SAVEDSUBCATEGORYFLAG,
    payload: flag
  }
}

export const setErrorSubCategory = (flag: any) => {
  return {
    type: SET_ERRORSUBCATEGORYFLAG,
    payload: flag
  }
}

export const setSubCategoryName = (name: any) => {
  return {
    type: SET_SUBCATEGORYNAME,
    payload: name
  }
}
import { GET_HEIGHT, SHOW_MENU, SHOW_ORDER_MANAGEMENT, NAVIGATION, NAV_PROGRAM_ENTRY, NAV_VENDOR_ENTRY, NAV_PRODUCT_ENTRY } from './types'
export const nav = (tempNav:any) => {
  return {
    type: NAVIGATION,
    payload: tempNav
  }
}
export const getHeight = (height:any) => {
  return {
    type: GET_HEIGHT,
    payload: height
  }
}

export const showOrderManagement = (show:any) => {
  return {
    type: SHOW_ORDER_MANAGEMENT,
    payload: show
  }
}

export const showMenu = () => {
  return {
    type: SHOW_MENU
  }
}

export const navProgramEntry = (flag: any) => {
  return {
    type: NAV_PROGRAM_ENTRY,
    payload: flag
  }
}

export const navVendorEntry = (flag:any) => {
  return {
    type: NAV_VENDOR_ENTRY,
    payload: flag
  }
}

export const navProductEntry = (flag:any) => {
  return {
    type: NAV_PRODUCT_ENTRY,
    payload: flag
  }
}
import * as R from 'ramda';

const getStateData = (state:any) => R.pathOr('', ['SupplierEntry', 'states'], state);
const getCountryData = (state:any) => R.pathOr('', ['SupplierEntry', 'countries'], state);
const getSuppliersData = (state:any) => R.pathOr('', ['SupplierEntry', 'suppliersData'], state);
const getSupplier = (state:any) => R.pathOr('', ['SupplierEntry', 'supplier'], state);
const getManufacturer = (state:any) => R.pathOr('', ['SupplierEntry', 'manufacturerdata'], state);
const getSupplierSaved = (state:any) => R.pathOr('', ['SupplierEntry', 'supplierSaved'], state);
const getErrorSupplier = (state:any) => R.pathOr('', ['SupplierEntry', 'errorSupplier'], state);
const getSupplierName = (state:any) => R.pathOr('', ['SupplierEntry', 'supplierName'], state);
const getCategoryManagerData = (state:any) => R.pathOr('',["SupplierEntry", 'categoryManager'], state);
const getPrimaryAbnumber = (state:any) => R.pathOr('',["SupplierEntry", 'primaryAbnumber'], state);
const getSecondaryAbnumber = (state:any) => R.pathOr('',["SupplierEntry", 'secondaryAbnumber'], state);
const getSupplierDetailsByAbnumber = (state:any) => R.pathOr('',["SupplierEntry", 'supplierDetailsByAbnumber'], state);
const getSecondaryAbnumberBySupplier = (state:any) => R.pathOr('',["SupplierEntry", 'secondaryAbnumberBySupplier'], state);

const selectors = {
  getStateData,
  getCountryData,
  getSuppliersData,
  getSupplier,
  getManufacturer,
  getSupplierSaved,
  getErrorSupplier,
  getSupplierName,
  getCategoryManagerData,
  getPrimaryAbnumber,
  getSecondaryAbnumber,
  getSupplierDetailsByAbnumber,
  getSecondaryAbnumberBySupplier
}

export default selectors

import React from "react";
import { Breadcrumb, ModalPopup } from "client/CommonComponents";
import SubCategoryModal from "./SubCategoryModal";
import "./ProductSubCategoryEntry.scss";
import { isAuthorized } from "client/utils/Permissions";

interface IProps {
    breadcrumlist?: any;
    hidePopup?: any;
    handleSave?: any;
    handleInputChange?: any;
    isSaveClicked?: any;
    showCategoryModal?: any;
    errors?: any;
    values?: any;
    subCategoryOptions?: any;
    handleSubCategory?: any;
    showProductSubCategory?:boolean;
    subCategorySaved?: boolean;
    handleMessage?: Function;
    errorSubCategory?: any;
    FooterPrimaryBtnTxt?: any;
}

const ProductSubCategoryEntry = (props: IProps) => {
    const {
        breadcrumlist,
        hidePopup,
        handleSave,
        handleInputChange,
        isSaveClicked,
        showCategoryModal,
        errors,
        values,
        subCategoryOptions,
        handleSubCategory,
        showProductSubCategory,
        subCategorySaved,
        handleMessage,
        errorSubCategory,
        FooterPrimaryBtnTxt
    } = props;

    return (
        <div className="product-sub-category-entry-container">
            { !showProductSubCategory? <Breadcrumb items={breadcrumlist} maxDisplayedItems='5' /> : null}
            {
                showCategoryModal
                ? <ModalPopup
                    ShowModalPopupFooter={true}
                    ModalPopupTitle="Sub Category"
                    ModalPopupType="medium"
                    closeModalPopup={hidePopup}
                    FooterSecondaryBtnTxt={"Cancel"}
                    SecondryBtnOnclick={hidePopup}
                    FooterPrimaryBtnTxt={FooterPrimaryBtnTxt()}
                    PrimaryBtnOnclick={handleSave}
                    FooterPrimaryDisabled={!isAuthorized("Subcategories", "create")}
                >
                    <SubCategoryModal
                        handleInputChange={handleInputChange}
                        errors={errors}
                        isSaveClicked={isSaveClicked}
                        values={values}
                        subCategoryOptions={subCategoryOptions}
                        handleSubCategory={handleSubCategory}
                        subCategorySaved={subCategorySaved}
                        handleMessage={handleMessage}
                        errorSubCategory={errorSubCategory}
                    />
                </ModalPopup>
                : null
            }
        </div>
    )
}

export default ProductSubCategoryEntry;
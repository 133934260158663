import { all } from 'redux-saga/effects';

import {combinedSaga as commonDashboardSaga} from './CommonDashboard';
import {combinedSaga as userProfileSaga} from './UserProfile';
import {combinedSaga as supplierEntrySaga} from './RebateManagement/SupplierEntry';
import { combinedSaga as categoryEntrySaga } from './RebateManagement/ProductCategoryEntry';
import { combinedSaga as subCategoryEntrySaga } from './RebateManagement/ProductSubCategoryEntry';
import { combinedSaga as RebateProgramEntry } from './RebateManagement/RebateProgramEntry';
import { combinedSaga as productEntrySaga } from './RebateManagement/ProductEntry';
import { combinedSaga as RebateSpendIndividualSage } from './RebateSpent/IndividualEntry';
import { combinedSaga as ReportsSaga } from './Reports';
import { combinedSaga as BulkUploadSaga } from "./RebateSpent/BulkUpload";
import { combinedSaga as SelfReportingSaga } from "./RebateManagement/SelfReporting";
import { combinedSaga as CollectionTrackingSaga } from "./RebateManagement/CollectionTracking";
import { combinedSaga as PaymentReconcillations } from "./RebateManagement/PaymentReconcillations";


export default function* sagas() {
  yield all([
    commonDashboardSaga(),
    userProfileSaga(),
    supplierEntrySaga(),
    categoryEntrySaga(),
    subCategoryEntrySaga(),
    productEntrySaga(),
    RebateProgramEntry(),
    RebateSpendIndividualSage(),
    ReportsSaga(),
    BulkUploadSaga(),
    SelfReportingSaga(),
    CollectionTrackingSaga(),
    PaymentReconcillations()
  ])
}
import React, {useRef} from "react";
import ViewBulkUpload from "./ViewBulkUpload";
import {ContextMenu,Tooltip} from '../../../../CommonComponents';
import RedWarning from '../../../../assets/images/red-warning.png'
import YellowWarning from '../../../../assets/images/yellow-warning.png'

interface Iprops {
    onHideShowViewBulk: React.MouseEventHandler<HTMLDivElement>
}

const ViewBulkUploadContainer = (props:Iprops) => {
    const data = [
        {
            Invoice: 'BV-22-01-001',
            ProductOrder: '25916930',
            Product:{
                text: 'Bike Rack',
                content: 'Product is not matched with purchase order'
            },
            Vendor: '',
            SKU:'2451-152-157',
            Manufacturer: '',
            MfgSKU:'2561-466-368',
            Category:'Bike Rack',
            SubCategory:'CORE SKUS'
        },
        {
            Invoice: 'BV-22-01-001',
            ProductOrder: '25916930',
            Product: 'RTV ULT BLK HI TEMP 1 EA PRMTX',
            Vendor: '',
            SKU:'2451-152-157',
            Manufacturer: '',
            MfgSKU:'2561-466-368',
            Category:'Bike Rack',
            SubCategory:'CORE SKUS'
        },
        {
            Invoice: 'BV-22-01-001',
            ProductOrder: '25916930',
            Product: 'RTV ULT BLK HI TEMP 1 EA PRMTX',
            Vendor: '',
            SKU:'2451-152-157',
            Manufacturer: '',
            MfgSKU:'2561-466-368',
            Category:'Bike Rack',
            SubCategory:'CORE SKUS'
        },
        {
            Invoice: 'BV-22-01-001',
            ProductOrder: '25916930',
            Product: 'RTV ULT BLK HI TEMP 1 EA PRMTX',
            Vendor: '',
            SKU:'2451-152-157',
            Manufacturer: '',
            MfgSKU:'2561-466-368',
            Category:{
                text: 'Bike Rack',
                content: 'Category is not matched with purchase order'
            },
            SubCategory:'CORE SKUS'
        },
    ]
    const menuItems = [
        {
          key: 'View',
          text: 'View',
          onClick: () => console.log('View clicked'),
        },
        {
          key: 'Edit',
          text: 'Edit',
          onClick: () => console.log('Edit clicked'),
        },
        {
          key: 'Download',
          text: 'Download',
          onClick: () => console.log('Download clicked'),
        },
    ]

const CustomCell = () => {
    const [showContextualMenu, setShowContextualMenu] = React.useState(false);
    const onShowContextualMenu = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault(); // don't navigate
        setShowContextualMenu(true);
      }
  
    const onHideContextualMenu = () => setShowContextualMenu(false);
    const linkRef = useRef(null);
    return (
        <>
        <ContextMenu 
            items={menuItems}
            hidden={showContextualMenu}
            target={linkRef}
            onItemClick = {onHideContextualMenu}
            onDismiss = {onHideContextualMenu}
        >          
        <a className="context-menu" ref={linkRef}  href="/" onClick={onShowContextualMenu}><span className='fa fa-ellipsis-h'> </span> </a>       
        </ContextMenu>  
        </>
    )
    }
    const column = [
        {
            key: "Action",
            title: "Action",
            width: "80px",
            FilterCell: null,
            Cell: CustomCell,
            style: {textAlign: 'center'},

        },
        {
            key: "Invoice",
            title: "Invoice #",
            width: "140px"
        },
        {
            key: "ProductOrder",
            title: "Product Order",
            width: "140px"
        },
        {
            key: "Product",
            title: "Product",
            width: "140px",
            Cell: (row:any) => {
                return <>{typeof row.rowData.Product === 'object'?<><span className="">{row.rowData.Product.text}</span> <Tooltip content="Product is not matched with purchase order"><img src={RedWarning} alt='redWarning' className="ml-1"/></Tooltip></>: <span className="">{row.rowData.Product}</span>} </>
            }
        },
        {
            key: "Vendor",
            title: "Vendor",
            width: "140px"
        },
        {
            key: "SKU",
            title: "SKU",
            width: "120px",
        },
        {
            key: "Manufacturer",
            title: "Manufacturer",
            width: "120px",
        },
        {
            key: "MfgSKU",
            title: "Mfg SKU",
            width: "120px",
        },
        {
            key: "Category",
            title: "Category",
            width: "120px",
            Cell: (row:any) => {
                return <>{typeof row.rowData.Category === 'object'?<><span className="">{row.rowData.Category.text}</span> <Tooltip content="Category is not matched with purchase order"><img src={YellowWarning} alt='yellowWarning' className="ml-1"/></Tooltip></>: <span className="">{row.rowData.Category}</span>} </>
            }
        },
        {
            key: "SubCategory",
            title: "Sub Category",
            width: "120px",
        }
    ]

    return (
        <ViewBulkUpload 
            data={data}
            column={column}
            onHideShowViewBulk={props.onHideShowViewBulk}
        />
    )

}

export default ViewBulkUploadContainer
import { Checkbox, Col, Datepicker, InputField, InputSpinner, Label, ModalPopup } from 'client/CommonComponents'

const RenewContract = (props: any) => {
  const {
    state,
    onContractNameChange,
    onNextNewContractName,
    onContractDetailsNext,
    onFinishClick,
    handleProgramYearChange,
    onIncrement,
    onDecrement,
    handleDateChange,
    onChangeEvergreen,
    onChangeSelection,
    onCloseRenewContract
  } = props

  return (
    <div>
      {state.isNewContractModal &&
        <ModalPopup
          ShowModalPopupFooter={state.isNewContractModal}
          ModalPopupTitle="New Contract Name"
          ModalPopupType="small"
          closeModalPopup={onCloseRenewContract}
          FooterPrimaryBtnTxt="Next"
          PrimaryBtnOnclick={onNextNewContractName}
        >
          <InputField
            label='Contract Name'
            placeholder='Enter New Contract Name'
            errorMessage={state.contractNameError}
            value={state.newContractName}
            onChange={(e: any) => onContractNameChange(e)} />
        </ModalPopup>
      }
      {state.contractDetailsModal &&
        <ModalPopup
          ShowModalPopupFooter={state.contractDetailsModal}
          ModalPopupTitle="Contract Details"
          ModalPopupType="small"
          closeModalPopup={onCloseRenewContract}
          FooterPrimaryBtnTxt="Next"
          PrimaryBtnOnclick={onContractDetailsNext}
        >
          <>
            <Col xs={12} md={8} lg={8} xl={8} className='mb-3 px-xl-0'>
              <div className="clearfix">
                <Label className="float-left" required>Program Year</Label>
                {/* <Tooltip content={`${fieldInfoDescription.programYear ? fieldInfoDescription.programYear : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip> */}
              </div>
              <InputSpinner type='number' value={state.programYear} onChange={(e) => handleProgramYearChange(e.target.value)} onIncrement={onIncrement} onDecrement={onDecrement} placeholder='YYYY' />
              {state.programYearError && <p className='mt-1 requiredText'>{state.programYearError}</p>}
            </Col>

            <Col xs={12} md={8} lg={8} xl={8} className='mb-3'>
              <div className="clearfix">
                <Label className="float-left" required>Start Date</Label>
                {/* <Tooltip content={`${fieldInfoDescription.startDate ? fieldInfoDescription.startDate : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip> */}
              </div>
              <Datepicker value={state.startDate} onChangeDate={(date: Date) => handleDateChange(date, 'startDate')} allowTextInput placeholder="Select Start Date" />
              {state.startDateError && <p className='mt-1 requiredText'>{state.startDateError}</p>}
            </Col>
            <Col xs={12} md={8} lg={8} xl={8} className='mb-3'>
              <div className="clearfix">
                <Label className="float-left" required={!state.evergreen}>End Date</Label>
                {/* <Tooltip content={`${fieldInfoDescription.endDate ? fieldInfoDescription.endDate : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip> */}
              </div>
              <Datepicker value={state.endDate} minDate={state.startDate} onChangeDate={(date: Date) => handleDateChange(date, 'endDate')} allowTextInput placeholder="Select End Date" disabled={state.evergreen} />
              {state.endDateError && <p className='mt-1 requiredText'>{state.endDateError}</p>}
            </Col>
            <Col xs={12} md={8} lg={8} xl={8} className='mb-3 mt-4 pt-3 px-xl-0'>
              <div className="clearfix">
                <Checkbox name='evergreen' checked={state.evergreen} onChange={onChangeEvergreen} label='Evergreen' type='small' className="float-left" />
                {/* <Tooltip content={`${fieldInfoDescription.Evergreen ? fieldInfoDescription.Evergreen : ''}`}><span className="fa fa-info-circle ms-1" /></Tooltip> */}
              </div>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <div className="clearfix">
                <Label className="float-left">Expected Payment Date</Label>
                {/* <Tooltip content={`${fieldInfoDescription.expectedPaymentDate ? fieldInfoDescription.expectedPaymentDate : ''}`}><span className="fa fa-info-circle ms-1"></span></Tooltip> */}
              </div>

              <Datepicker value={state.expectedPaymentDate} onChangeDate={(date: Date) => handleDateChange(date, 'expectedPaymentDate')} allowTextInput placeholder="Select Expected Payment Date" />
            </Col>
          </>
        </ModalPopup>
      }
      {state.selectDataModal &&
        <ModalPopup
          ShowModalPopupFooter={state.selectDataModal}
          ModalPopupTitle="New Contract Settings"
          ModalPopupType="small"
          closeModalPopup={onCloseRenewContract}
          FooterPrimaryBtnTxt="Finish"
          PrimaryBtnOnclick={onFinishClick}
        >
          <h5>Selected Settings Will Be Copied to New Contract</h5>
          <Col xs={12} md={8} lg={8} xl={8} className='mb-3 mt-4 pt-3'>
            <div className="clearfix">
              <Checkbox name='endofyearDaystoPay' checked={state.endofyearDaystoPay} onChange={onChangeSelection} label='End of year Days to Pay' />
              <Checkbox name='withinTerms' checked={state.withinTerms} onChange={onChangeSelection} label='Within Terms' />
              <Checkbox name='netDays' checked={state.netDays} onChange={onChangeSelection} label='NET Days' />
              <Checkbox name='eop' checked={state.eop} onChange={onChangeSelection} label='Early Order Program (EOP)' />
              <Checkbox name='rebatePeriodId' checked={state.rebatePeriodId} onChange={onChangeSelection} label='Rebate Period' />
              <Checkbox name='rebateTypeSettings' checked={state.rebateTypeSettings} onChange={onChangeSelection} label='Rebate Type Settings' />
              <Checkbox name='calculationTypeId' checked={state.calculationTypeId} onChange={onChangeSelection} label='Calculation Type' />
              <Checkbox name='calculationMethodId' checked={state.calculationMethodId} onChange={onChangeSelection} label='Calculation Method' />
              <Checkbox name='tiers' checked={state.tiers} onChange={onChangeSelection} label='Tiers (if any)' />
              <Checkbox name='rebateFrequencyId' checked={state.rebateFrequencyId} onChange={onChangeSelection} label='Collection Frequency' />
              <Checkbox name='rebatePayMethodId' checked={state.rebatePayMethodId} onChange={onChangeSelection} label='Payment Method' />
              <Checkbox name='isExcludeCreditCardSpend' checked={state.isExcludeCreditCardSpend} onChange={onChangeSelection} label='Exclude Credit Card Spend' />
            </div>
          </Col>
        </ModalPopup>

      }
    </div>
  )
}

export default RenewContract
import React from 'react'
import GaugeChart from 'react-gauge-chart'
import './Guage.scss'

interface IProps {
    className?:any,
    id?:any,
    meterLevel?:any
    colors?:any,
    arcWidth?:any
    percent?:any
    arcPadding?:any
    cornerRadius?:any,
    needleColor?:any,
    hideText?:any
}
const chartStyle = {
   
}
const GuageContainer = ({className, id, meterLevel, colors, arcWidth, percent, arcPadding, cornerRadius, needleColor, hideText}:IProps) => {
  return (
    <>
    <GaugeChart id={id} 
     nrOfLevels={meterLevel} 
     colors={colors} 
     arcWidth={arcWidth} 
     percent={percent} 
     arcPadding={arcPadding} 
     cornerRadius={cornerRadius} 
     needleColor={needleColor} 
     hideText={hideText}
     className={`guage-chart ${className}`}
     style={chartStyle}
    />
    </>
  )
}

export default GuageContainer

import React from 'react'
import { Row, Col, InputField, Label, SearchSelectBox, Alert, ModalPopup, Tooltip, PrimaryButton, OutlineButton, Checkbox } from '../../../../CommonComponents';
import './AssignPopup.scss'
import AddIcon from '../../../../assets/images/AddIcon.svg'

interface Iprops {
  selectedRowData?: any;
  getFormattedValue?: any;
  formData?: any;
  addFormSection?: any;
  removeFormSection?: any;
  handleInputChange?: any;
  comment?: any;
  onDismissAlert?: any;
  extractNumbers?: any;
  getExternalError?: any;
  showErrorMessage?: any;
  supplierOptions?: any;
  showValidationPopup?: any
  handleClose?: any
  hideValidatePopup?: any
  onClickPrimaryButton?: any
  updateInput?:any
  updateInputOnFocus?:any
  checkNumber?: any
  setCheckNumber?: any
  saveCheckNumber?: any
  validateCheckNumber?: any
  isCheckNumberUpdated?: any
  setIsCheckNumberUpdated?: any
}

const AssignPopup = (props: Iprops) => {
  const {
    selectedRowData,
    getFormattedValue,
    formData,
    addFormSection,
    removeFormSection,
    handleInputChange,
    comment,
    onDismissAlert,
    extractNumbers,
    getExternalError,
    showErrorMessage,
    supplierOptions,
    showValidationPopup,
    handleClose,
    hideValidatePopup,
    onClickPrimaryButton,
    updateInput,
    updateInputOnFocus,
    checkNumber,
    setCheckNumber,
    saveCheckNumber,
    validateCheckNumber,
    isCheckNumberUpdated,
    setIsCheckNumberUpdated
  } = props;

  const options = { 
    minimumFractionDigits: 2,
    maximumFractionDigits: 2 
  };
  return (
    <div className='PaymentReconcillationAssignPopup'>
      <div className='paymentDetailsContent mb-3'>
        <ul className="clearfix">
          <li className="paymentDetails_VendorName">
              <p className='heading'>Vendor Name</p>
              <p className='detail'>{selectedRowData?.vendorName}</p>
          </li>
          <li>
              <p className='heading'>Doc Type</p>
              <p className='detail'>{selectedRowData?.docType}</p>
          </li>
          <li>
            <p className='heading'>Check No</p>
              <InputField
                value={checkNumber}
                onChange={(item: any) => {
                  validateCheckNumber(item.target.value) && setCheckNumber(item.target.value)
                }}
                onBlur={(item:any)=>saveCheckNumber(item.target.value, 'assign')}
              />
          </li>
          <li>
            <p className='heading'>Check Amount</p>
              <p className='detail'>{`$` + getFormattedValue(Number(selectedRowData?.amount || 0).toLocaleString('en', { minimumFractionDigits: 2,maximumFractionDigits: 2 }))}</p>
          </li>
        </ul>
      </div>
      <Alert showAlert={isCheckNumberUpdated} dismissAlert={()=>setIsCheckNumberUpdated(false)} type='success' text='Check Number Updated Successfully'/>
      {comment?.isError && <Alert
        showAlert={true}
        type={comment?.type}
        text={comment?.errorMessage}
        dismissAlert={() => onDismissAlert()}
      />}

      {/* {showValidationPopup && (
        <ModalPopup
          ShowModalPopupFooter={true}
          ModalPopupTitle={"Validation"}
          ModalPopupType="small"
          FooterSecondaryBtnTxt="Cancel"
          FooterPrimaryBtnTxt="Ok"
          closeModalPopup={handleClose}
          SecondryBtnOnclick={handleClose}
          PrimaryBtnOnclick={hideValidatePopup}
        >
          <div className="mb-3">
            <p>Payment Period is marked complete. Are you sure you wish to assign payment?</p>
          </div>

        </ModalPopup>
      )} */}
      <br />

      <div>
        <Row>
          <Col lg={3} md={6} sm={12}>
            <Label required>Vendor</Label>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <Label required>Rebate Contract</Label>
          </Col>
          <Col lg={1} md={6} sm={12} className="yearDropdownHeading">
            <Label required>Year</Label>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <Label required>Quarter/ Month</Label>
          </Col>
          <Col lg={1} md={6} sm={12} className="amountFieldHeading">
            <Label required>Amount</Label>
          </Col>
          <Col lg={1} md={6} sm={12} className="px-0">
            <Label>Complete Period</Label>
          </Col>
        </Row>
      </div>

      {formData.map((data: any, index: any) => (
        <div key={data?.rowId}>
          {data?.rowValues?.isActive &&
            <Row>
              <Col lg={3} md={6} sm={12} className='mb-3 pe-0'>
                <SearchSelectBox
                  placeholder="Select"
                  name='vendorSupplierId'
                  className="search-dropdown"
                  onChange={(item: any, e: any) => handleInputChange(data?.rowId, e, item?.key)}
                  options={supplierOptions}
                  value={supplierOptions?.find((i: any) => i.key === data?.rowValues?.vendorSupplierId)}
                  filterOptionValue={3}
                />
                {showErrorMessage ? getExternalError("vendorSupplierId", data?.rowId) : undefined}
              </Col>
              <Col lg={2} md={6} sm={12} className='mb-3 pe-0'>
                <SearchSelectBox
                  placeholder="Select"
                  name='contractDetailId'
                  className="search-dropdown"
                  onChange={(item: any, e: any) => handleInputChange(data?.rowId, e, item?.key)}
                  options={data?.contractOptions}
                  value={data?.rowValues?.contractDetailId != "" ? data?.contractOptions?.find((i: any) => i.key === data?.rowValues?.contractDetailId) : ""}
                  filterOptionValue={3}
                />
                {showErrorMessage ? getExternalError("contractDetailId", data?.rowId) : undefined}
              </Col>
              <Col lg={1} md={6} sm={12} className='yearDropdown mb-3 pe-0'>
                <SearchSelectBox
                  placeholder="Select"
                  name='programYear'
                  className="search-dropdown"
                  onChange={(item: any, e: any) => handleInputChange(data?.rowId, e, item?.value)}
                  options={data?.programYearOptions}
                  value={data?.rowValues?.programYear != "" ? data?.programYearOptions?.find((i: any) => i.value === data?.rowValues?.programYear) : ""}
                  filterOptionValue={3}
                />
                {showErrorMessage ? getExternalError("programYear", data?.rowId) : undefined}
              </Col>
              <Col lg={2} md={6} sm={12} className='mb-3 pe-0'>
                <SearchSelectBox
                  placeholder="Select"
                  name='collectionFrequencyValue'
                  className="search-dropdown"
                  onChange={(item: any, e: any) => handleInputChange(data?.rowId, e, item?.value)}
                  options={data?.collectionFrequencyOption}
                  value={data?.rowValues?.collectionFrequencyValue != "" ? data?.collectionFrequencyOption?.find((i: any) => i.value === data?.rowValues?.collectionFrequencyValue) : ""}
                  filterOptionValue={3}
                />
                {showErrorMessage ? getExternalError("collectionFrequencyValue", data?.rowId) : undefined}
              </Col>
              <Col lg={1} md={6} sm={12} className='amountField mb-3 pe-0'>
                <Tooltip content={data?.rowValues?.amount}>
                  <InputField placeholder='Enter'
                  name="amount"
                  onChange={(e: any) => handleInputChange(data?.rowId, e)}
                  value={data?.rowValues?.amount}
                  onFocus={() => updateInputOnFocus(data?.rowValues?.amount, data?.rowId)}
                  onBlur={() => updateInput(data?.rowValues?.amount, data?.rowId)}
                  
                />
                </Tooltip>        
                {showErrorMessage ? getExternalError("amount", data?.rowId) : undefined}
              </Col>

              <Col lg={1} md={6} sm={12} className='mb-3 pe-0 mt-2'>
                <Checkbox className="CompletePeriod"
                name="completePeriod"
                checked={data?.rowValues?.completePeriod ?? false}
                onChange={(e:any) => handleInputChange(data?.rowId, e, !data?.rowValues?.completePeriod )}/>
              </Col>

              <Col lg={1}>
                {index === 0 ? (
                  <Tooltip content={"Add New Payment"}>
                    <button className="plusbtn" onClick={() => addFormSection(formData, true)}>
                      <span><img src={AddIcon} alt='add' /></span>
                    </button>
                  </Tooltip>
                ) : (
                  <Tooltip content={"Delete Payment"}>
                    <button className="deletebtn" onClick={() => removeFormSection(data?.rowId, data?.rowValues?.id)}><span className="fa fa-trash"></span></button>
                  </Tooltip>
                )}
              </Col>
            </Row>
          }
          {(showValidationPopup && index == 0) && (
            <Alert
              actions={
                <div>
                  <OutlineButton text={'No'} onClick={() => {
                    hideValidatePopup();
                  }} />
                  <PrimaryButton text={'Yes'} className="ml-0" onClick={() => {
                    hideValidatePopup();
                    onClickPrimaryButton();
                  }} />
                </div>}
              type="warning"
              className="mb-2"
              showAlert={showValidationPopup}
              text={"Payment period is marked complete. Are you sure you wish to assign payment?"} />
          )}

        </div>
      ))}
    </div>
  )
}

export default AssignPopup
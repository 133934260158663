import { SET_SUBCATEGORYDATA, SET_SUBCATEGORY, SET_SAVEDSUBCATEGORYFLAG, SET_ERRORSUBCATEGORYFLAG, SET_SUBCATEGORYNAME } from './types'
const initialState = {
  subCategory: {},
  subCategoryData: [],
  subCategorySaved: false,
  errorSubCategory: "",
  subCategoryName: "",
}

const reducer = (state = initialState, action:any = {}) => {
  switch (action.type) {
    case SET_SUBCATEGORYDATA:
      return {
        ...state,
        subCategoryData: action.payload,
      }
    case SET_SUBCATEGORY:
      return {
        ...state,
        subCategory: action.payload,
      }
    case SET_SAVEDSUBCATEGORYFLAG:
      return {
        ...state,
        subCategorySaved: action.payload,
      }
    case SET_ERRORSUBCATEGORYFLAG:
      return {
        ...state,
        errorSubCategory: action.payload
      }
    case SET_SUBCATEGORYNAME:
      return {
        ...state,
        subCategoryName: action.payload
      }
    default: return state
  }
}

export default reducer
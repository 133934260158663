import * as R from 'ramda';

const getCategoryData = (state:any) => R.pathOr('', ['CategoryEntry', 'categoryData'], state);
const getCategory = (state:any) => R.pathOr('', ['CategoryEntry', 'category'], state);
const getCategorySaved = (state:any) => R.pathOr('', ['CategoryEntry', 'categorySaved'], state);
const getErrorCategory = (state:any) => R.pathOr('', ['CategoryEntry', 'errorCategory'], state);
const getCategoryName = (state:any) => R.pathOr('', ['CategoryEntry', 'categoryName'], state);
const selectors = {
  getCategoryData,
  getCategory,
  getCategorySaved,
  getErrorCategory,
  getCategoryName
}

export default selectors

export const GET_CALCULATION_FREQUENCY = 'GET_CALCULATION_FREQUENCY';
export const SET_CALCULATION_FREQUENCY = 'SET_CALCULATION_FREQUENCY';
export const GET_CALCULATION_METHOD = 'GET_CALCULATION_METHOD';
export const SET_CALCULATION_METHOD = 'SET_CALCULATION_METHOD';
export const GET_CALCULATION_TYPE = 'GET_CALCULATION_TYPE';
export const SET_CALCULATION_TYPE = 'SET_CALCULATION_TYPE';
export const GET_REBATE_PROGRAM = 'GET_REBATE_PROGRAM';
export const SET_REBATE_PROGRAM = 'SET_REBATE_PROGRAM';
export const GET_REBATE_PROGRAM_BY_ID = 'GET_REBATE_PROGRAM_BY_ID';
export const SET_REBATE_PROGRAM_BY_ID = 'SET_REBATE_PROGRAM_BY_ID';
export const GET_CREATEUPDATE_REBATE_PROGRAM = 'GET_CREATEUPDATE_REBATE_PROGRAM';
export const GET_REBATE_DELETE = 'GET_REBATE_DELETE';
export const SET_REBATE_DELETE = 'SET_REBATE_DELETE';
export const POST_REBATE_ADD = 'POST_REBATE_ADD';
export const SET_REBATE_ADD = 'SET_REBATE_ADD';
export const POST_REBATE_EDIT = 'POST_REBATE_EDIT';
export const SET_REBATE_EDIT = 'SET_REBATE_EDIT';
export const SET_REBATE_ADDEDIT_RESPONSE = 'SET_REBATE_ADDEDIT_RESPONSE';
export const GET_REBATEPERIOD = 'GET_REBATEPERIOD';
export const SET_REBATEPERIOD = 'SET_REBATEPERIOD';
export const GET_REBATEPAYMENTMETHOD = 'GET_REBATEPAYMENTMETHOD';
export const SET_REBATEPAYMENTMETHOD = 'SET_REBATEPAYMENTMETHOD';

export const GET_ALL_CONTRACT = 'GET_ALL_CONTRACT';
export const SET_ALL_CONTRACT = 'SET_ALL_CONTRACT';
export const GET_ADD_CONTRACT_NAME = 'GET_ADD_CONTRACT_NAME';
export const GET_UPDATE_CONTRACT_NAME = 'GET_UPDATE_CONTRACT_NAME';
export const GET_DELETE_CONTRACT_NAME = 'GET_DELETE_CONTRACT_NAME';
export const GET_CONTRACT_DETAILS_BY_ID = 'GET_CONTRACT_DETAILS_BY_ID';
export const SET_CONTRACT_DETAILS_BY_ID = 'SET_CONTRACT_DETAILS_BY_ID';

export const GET_ALL_CONTRACT_LIST = 'GET_ALL_CONTRACT_LIST'
export const SET_ALL_CONTRACT_LIST = 'SET_ALL_CONTRACT_LIST'
export const GET_PROGRAM_BY_VENDOR_ID = 'GET_PROGRAM_BY_VENDOR_ID';
export const GET_CONTRCT_BY_PROGRAM_ID = 'GET_CONTRCT_BY_PROGRAM_ID';

export const GET_ALL_REBATE_TYPES = 'GET_ALL_REBATE_TYPES';
export const SET_ALL_REBATE_TYPES = 'SET_ALL_REBATE_TYPES';

export const SET_PROGRAM_ERROR = 'SET_PROGRAM_ERROR';

export const SET_CONTRACT_BY_ID_LOADING = 'SET_CONTRACT_BY_ID_LOADING';
export const GET_CONTRACT_BY_ID_LOADING = 'GET_CONTRACT_BY_ID_LOADING';

export const SET_REBATE_PROGRAM_FIELD_INFO = 'SET_REBATE_PROGRAM_FIELD_INFO';
export const GET_REBATE_PROGRAM_FIELD_INFO = 'GET_REBATE_PROGRAM_FIELD_INFO';

export const SET_CONTRACT_VALIDATION_FLAG = 'SET_CONTRACT_VALIDATION_FLAG';
export const GET_CONTRACT_VALIDATION_FLAG = 'GET_CONTRACT_VALIDATION_FLAG';
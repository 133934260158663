import React from 'react'
import { DialogBox } from '@softura/fluentui-basiccomponents'
import './Dialog.scss'

interface IProps {
  hideDialog?: any,
  onDismiss?: any,
  dialogContentProps?: any
}
const DialogContainer = ({ hideDialog, onDismiss, dialogContentProps }:IProps) => {
  return (<>
    <DialogBox hideDialog={hideDialog} onDismiss={onDismiss} dialogContentProps={dialogContentProps}>
    </DialogBox>
    </>
  )
}

export default DialogContainer

import * as R from 'ramda';

const getReportData = (state:any) => R.pathOr('', ['Reports', 'reportData'], state);
const getReportDataByYear = (state:any) => R.pathOr('', ['Reports', 'reportDataByYear'], state);
const getSpendRebateDashboard = (state:any) => R.pathOr('', ['Reports', 'spendRebateDashboard'], state)
const getManufacturerReportData = (state:any) => R.pathOr('', ['Reports', 'manufacturerReportData'], state);
const getAccountandFinanceReport = (state:any) => R.pathOr('', ['Reports', 'accountAndFinanceReport'], state);
const selectors = {
  getReportData,
  getReportDataByYear,
  getSpendRebateDashboard,
  getManufacturerReportData,
  getAccountandFinanceReport
}

export default selectors
import {
  getContactHistoryGridData, getContractDropdownData, getDeleteContactHistoryById, getInsertContactHistoryData, getPaidExpectedGridData, getPaymentHistoryGridData, getSavePaymentCompletion, getSupplierDropdownData, getYearDropdownData, setDeleteContactHistoryById, setInsertContactHistoryData, setPaidExpectedGridData, setPaymentHistoryGridData, setSavePaymentCompletion
} from './action'

const dispatchGetContactHistoryGridData = (dispatch: Function) => (payload: any) => {
  dispatch(getContactHistoryGridData(payload));
};

const dispatchGetInsertContactHistoryData = (dispatch: Function) => (payload: any) => {
  dispatch(getInsertContactHistoryData(payload));
};

const dispatchSetInsertContactHistoryData = (dispatch: Function) => (payload: any) => {
  dispatch(setInsertContactHistoryData(payload));
};

const dispatchGetDeleteContactHistoryById = (dispatch: Function) => (payload: any) => {
  dispatch(getDeleteContactHistoryById(payload));
};

const dispatchSetDeleteContactHistoryById = (dispatch: Function) => (payload: any) => {
  dispatch(setDeleteContactHistoryById(payload));
};

const dispatchGetPaymentHistoryGridData = (dispatch: Function) => (payload: any) => {
  dispatch(getPaymentHistoryGridData(payload));
};

const dispatchGetPaidExpectedGridData = (dispatch: Function) => (payload: any) => {
  dispatch(getPaidExpectedGridData(payload));
};

const dispatchSetPaymentHistoryGridData = (dispatch: Function) => (payload: any) => {
  dispatch(setPaymentHistoryGridData(payload));
};

const dispatchSetPaidExpectedGridData = (dispatch: Function) => (payload: any) => {
  dispatch(setPaidExpectedGridData(payload));
};

const dispatchGetSupplierDropdownData = (dispatch: Function) => () => {
  dispatch(getSupplierDropdownData());
};

const dispatchGetContractDropdownData = (dispatch: Function) => (payload: any) => {
  dispatch(getContractDropdownData(payload));
};

const dispatchGetYearDropdownData = (dispatch: Function) => (payload: any) => {
  dispatch(getYearDropdownData(payload));
};

const dispatchGetSavePaymentCompletion = (dispatch: Function) => (payload: any) => {
  dispatch(getSavePaymentCompletion(payload));
};

const dispatchSetSavePaymentCompletion = (dispatch: Function) => (payload: any) => {
  dispatch(setSavePaymentCompletion(payload));
};

const operations = {
  dispatchGetContactHistoryGridData,
  dispatchGetInsertContactHistoryData,
  dispatchSetInsertContactHistoryData,
  dispatchGetDeleteContactHistoryById,
  dispatchSetDeleteContactHistoryById,
  dispatchGetPaymentHistoryGridData,
  dispatchGetPaidExpectedGridData,
  dispatchGetSupplierDropdownData,
  dispatchGetContractDropdownData,
  dispatchGetYearDropdownData,
  dispatchSetPaymentHistoryGridData,
  dispatchSetPaidExpectedGridData,
  dispatchGetSavePaymentCompletion,
  dispatchSetSavePaymentCompletion,
}

export default operations
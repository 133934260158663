import { useState, useEffect, useRef, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Navigation from './NavigationContainer';
import { useDispatch, useSelector,  } from 'react-redux'
import navSelectors from '../../../state/features/Navigation/selectors';
import navOperations from '../../../state/features/Navigation/operations';
import './Navigation.scss'
import Home from "../../assets/images/menuItems/Home.png";
import Admin from "../../assets/images/menuItems/Admin.png";
import Management from "../../assets/images/menuItems/Management.png";
import Reports from "../../assets/images/menuItems/Reports.png";
import Spend from "../../assets/images/menuItems/Spend.png";
import SelfReportDashboard from "../../assets/images/menuItems/SelfReportDashboardIcon.png";
import userOperations from '../../../state/features/UserProfile/operations';
import userSelector from '../../../state/features/UserProfile/selectors';
import { isAuthorized } from 'client/utils/Permissions';


const NavigationContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [headerNav, showHeaderNav] = useState(true)

  const dispatchNav =  navOperations.dispatchNav(dispatch)
  // const dispatchGetUserRolesAndClaims = userOperations.dispatchGetUserRolesAndClaims(dispatch);
  const dispatchSetUserRole = userOperations.dispatchSetUserRole(dispatch)
  const dispatchNavProgramEntry = navOperations.dispatchNavProgramEntry(dispatch);
  const dispatchNavVendorEntry = navOperations.dispatchNavVendorEntry(dispatch);
  const dispatchNavProductEntry = navOperations.dispatchNavProductEntry(dispatch);

  useEffect(() => {
    const windowResize = () => {
      const width = window.innerWidth
      if (width < 600) {
        showHeaderNav(false)
      } else {
        showHeaderNav(true)
      }
    }
    windowResize()
    window.addEventListener('resize', windowResize)
  }, []);

  const {
    rolesandClaims,
    username,
    userRole
  } :any  = useSelector(
    (state:any) => ({
      rolesandClaims: userSelector.getRolesandClaims(state),
      username: userSelector.getUsername(state),
      userRole: userSelector.getUserRole(state)
    }
    ))

  // useEffect(() => {
  //   if (username) {
  //     dispatchGetUserRolesAndClaims(username);
  //   }
  // }, [username])

  useEffect(() => {
    if (rolesandClaims?.length) {
      const tempRole = {
        id: rolesandClaims[0].roleID,
        name: rolesandClaims[0].roleName
      };

      let rebatePermission: any = {}
      rolesandClaims.forEach((i: any) => {
        const areaName = i.applicationAreaName?.split(" ").join("");
        const areaPermission: any = rebatePermission[areaName] || {};
        let permission = i.permissionName.split("_")[0]?.toLowerCase();
        if(i.permissionName.includes('Prepped')){
          permission = "update_prepped";
        }
        switch (permission) {
          case "view":
            areaPermission.view = true;
            break;
          case "create":
            areaPermission.create = true;
            break;
          case "update":
            areaPermission.update = true;
            break;
          case "update_prepped":
            areaPermission.update_prepped = true;
            break;
          case "delete":
            areaPermission.delete = true;
            break;
          case "run":
            areaPermission.run = true;
            break;
          case "design":
            areaPermission.design = true;
            break;
          case "modify":
            areaPermission.modify = true;
            break;
          default:
            break;
        }
        rebatePermission = {
          ...rebatePermission,
          [areaName]: areaPermission
        }
      })

      const roleAndPermission = {
        role: tempRole,
        permissions: rebatePermission
      }
      dispatchSetUserRole(roleAndPermission);
    }
  }, [rolesandClaims])

  const managementRef = useRef(null);
  const spentRef = useRef(null);
  const reportRef = useRef(null);
  const [showContextualMenu, setShowContextualMenu] = useState<any>({
    management: false,
    spent: false,
    report: false,
  });

  const rebateManagementItems = [];
  const show = false;

  if (isAuthorized("RebateAgreements", "view")) {
    rebateManagementItems.push({
      key: "programEntry",
      text: "Rebate Program Entry",
      path: "/program-entry",
      parent: "Rebate Management",
    })
  }

  if (isAuthorized("Suppliers", "view")) {
    rebateManagementItems.push({
      key: "supplierEntry",
      text: "Vendor Entry",
      path: "/vendor-entry",
      parent: "Rebate Management",
    })
  }

  if (isAuthorized("Products", "view")) {
    rebateManagementItems.push({
      key: "productEntry",
      text: "Product Entry",
      path: "/product-entry",
      parent: "Rebate Management",
    })    
  }

  if (isAuthorized("PaymentReconcilation", "view")) {
    rebateManagementItems.push({
      key: "paymentReconcillation",
      text: "Payment Reconciliations",
      path: "/payment-reconcillations",
      parent: "Rebate Management",
    })
  }

  if (isAuthorized("CollectionTracking", "view")) {
    rebateManagementItems.push({
      key: "collectiontracking",
      text: "Collections Tracking",
      path: "/collection-tracking",
      parent: "Rebate Management",
    })
  }


  if (isAuthorized("Categories", "view") && show) {
    rebateManagementItems.push({
      key: "categoryEntry",
      text: "Product Category Entry",
      path: "/category-entry",
      parent: "Rebate Management",
    })
  }

  if (isAuthorized("Subcategories", "view") && show) {
    rebateManagementItems.push({
      key: "subCategoryEntry",
      text: "Product Sub Category Entry",
      path: "/sub-category-entry",
      parent: "Rebate Management",
    })
  }

  let menuList: any
  
  if(Object.keys(userRole).length > 0){
      menuList = [
      {
        text: 'Home',
        Item: 'Home',
        icon: 'home-icon',
        active: 'active',
        Title : 'Home',
        isContext: false,
        path: "/"
      }
    ]
  }  

  if (isAuthorized("SelfReportingDashboard", "view")) {
    menuList.push({
        text: 'Self Reporting Dashboard',
        Item: 'Self Reporting Dashboard',
        icon:'selfreportdashboard-icon',
        Title : 'Self Reporting Dashboard',
        active: 'active',
        isContext: false,
        path: "/self-reporting-dashboard"
      })
  }

  if (
    isAuthorized("RebateAgreements", "view") ||
    isAuthorized("Suppliers", "view") ||
    isAuthorized("Products", "view") ||
    isAuthorized("Categories", "view") ||
    isAuthorized("Subcategories", "view")
  ) {
    menuList.push({
      text: 'Rebate Management',
      Item: 'Rebate Management',
      icon: 'management-icon',
      Title : 'Rebate Management',
      isContext: true,
      items: rebateManagementItems
    })
  }
  if (show) {
    menuList.push({
      text: 'Rebate Spend',
      Item: 'Rebate Spend',
      icon: 'spent-icon',
      Title :'Rebate Spend',
      isContext: true,
      items: [
        {
          key: "bulkUpload",
          text: "Bulk Upload",
          path: "/bulk-upload",
          parent: "Rebate Spend"
        },
        {
          key: "individualEntry",
          text: "Individual Entry",
          path: "/individual-entry",
          parent: "Rebate Spend"
        }
      ]
    })
  }

  if (isAuthorized("Reports", "view")) {
    menuList.push({
      text: 'Reports',
      Item: 'Reports',
      icon: 'reports-icon',
      Title: 'Reports',
      isContext: true,
      items: [
        {
          key: "report",
          text: "Rebate Dashboard",
          path: "/report",
          parent: "Reports",
        },      
        {
          key: "manufacturerReport",
          text: "Manufacturer Report Out",
          path: "/manufacturerReport",
          parent: "Reports",
        },
        {
          key: "AccountingAndFinance",
          text: "Accounting & Finance",
          path: "/AccountingAndFinance",
          parent: "Reports",
        },   
        {
          key: "22RebateByDivision",
          text: "2022 Rebate by Division",
          path: "/rebatespendbyyear",
          parent: "Reports",
        }                
      ]
    })
  }

  if ((userRole?.role?.name === "Admin User" || userRole?.role?.name === "Super User") && show) {
    menuList.push({
      text: 'Admin',
      Item: 'Admin',
      icon: 'admin-icon',
      Title:'Admin',
      isContext: false,
      path: "/admin"
    })
  }

  const handleClick = (item:any) => {
    dispatchNav(item.text);
    navigate(item.path);
  }

  const nav = useSelector(
    state=> ({
      nav: navSelectors.GetNavItem(state)
    })
  )

  const getImageUrl = (icon: any) => {
    switch (icon) {
      case "home-icon":
        return Home;
        case "selfreportdashboard-icon":
        return SelfReportDashboard;
      case "management-icon":
        return Management;
      case "spent-icon":
        return Spend;
      case "reports-icon":
        return Reports;
      case "admin-icon":
        return Admin;
      default:
        return Home;
    }
  }

  const onShowContextualMenu = (e: any, item: string) => {
    e.preventDefault();
    setShowContextualMenu({
      management: item === "Rebate Management" ? !showContextualMenu.management : false,
      spent: item === "Rebate Spend" ? !showContextualMenu.spent : false,
      report: item === "Reports" ? !showContextualMenu.report : false
    });
  };

  const onHideContextualMenu = useCallback(() => setShowContextualMenu({
    management: false,
    spent: false,
    report: false
  }), []);

  const getDireaction = (text: any) => {
    if (text === "Rebate Spend") {
      return showContextualMenu.spent ? "up" : "down"
    } else if (text === "Rebate Management") {
      return showContextualMenu.management ? "up" : "down"
    } else {
      return showContextualMenu.report ? "up" : "down"
    }
  }

  const handleItemClick = (item: any) => {
    if (item.key === "programEntry") {
      dispatchNavProgramEntry(true);
    } else if(item.key === "supplierEntry") {
      dispatchNavVendorEntry(true);
    } else if(item.key === "productEntry"){
      dispatchNavProductEntry(true);
    } else if(item.key === "paymentReconcillation"){
      dispatchNavProductEntry(true);
    }
      navigate(item.path);
      dispatchNav(item.parent);
  }

  const getHiddenState = (menu: any) => {
    if (menu.text === "Rebate Spend") {
      return showContextualMenu.spent
    } else if (menu.text === "Rebate Management") {
      return showContextualMenu.management
    } else {
      return showContextualMenu.report;
    }
  }

  const getSelectedRef = (menu: any) => {
    if (menu.text === "Rebate Spend") {
      return spentRef;
    } else if (menu.text === "Rebate Management") {
      return managementRef
    } else {
      return reportRef
    }
  }

  const getActiveClass = (menu: any) => {
    if (menu.text === nav.nav) {
      return "active";
    } else {
      return "";
    }
  }

  return (
    <Navigation
      headerNav={headerNav}
      menuList={menuList}
      showContextualMenu={showContextualMenu}
      spentRef={spentRef}
      managementRef={managementRef}
      handleItemClick={handleItemClick}
      onShowContextualMenu={onShowContextualMenu}
      onHideContextualMenu={onHideContextualMenu}
      nav={nav}
      getImageUrl={getImageUrl}
      getDireaction={getDireaction}
      handleClick={handleClick}
      getHiddenState={getHiddenState}
      getSelectedRef={getSelectedRef}
      getActiveClass={getActiveClass}
    />
  )
}

export default NavigationContainer
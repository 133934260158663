import * as R from 'ramda';
const getCalculationFrequency = (state:any) => R.pathOr([], ['RebateProgramEntry', 'calculationFrequency'], state);
const getCalculationMethod = (state:any) => R.pathOr([], ['RebateProgramEntry', 'calculationMethod'], state);
const getCalculationType = (state:any) => R.pathOr([], ['RebateProgramEntry', 'calculationType'], state);
const getRebateProgram = (state:any) => R.pathOr([], ['RebateProgramEntry', 'rebateProgram'], state);
const getRebateProgramById = (state:any) => R.pathOr([], ['RebateProgramEntry', 'rebateProgramById'], state);
const getDeleteProgram = (state:any) => R.pathOr([], ['RebateProgramEntry', 'rebateDelete'], state);
const getRebateSaved = (state:any) => R.pathOr([], ['RebateProgramEntry', 'rebateSaved'], state);
const getRebateSavedEdit = (state:any) => R.pathOr([], ['RebateProgramEntry', 'rebateSavedEdit'], state);
const getRebatePeriods = (state: any) => R.pathOr([], ['RebateProgramEntry', 'rebatePeriods'], state);
const getRebatePaymentMethod = (state: any) => R.pathOr([], ['RebateProgramEntry', 'paymentMethod'], state);
const getAllContract = (state: any) => R.pathOr([], ['RebateProgramEntry', 'allContract'], state);
const getContractDetails = (state: any) => R.pathOr([], ['RebateProgramEntry', 'contractDetails'], state);
const getContractList = (state: any) => R.pathOr([], ['RebateProgramEntry', 'contractList'], state);
const getAllRebateTypes = (state: any) => R.pathOr([], ['RebateProgramEntry', 'rebateTypes'], state);
const getProgramError = (state: any) => R.pathOr([], ['RebateProgramEntry', 'programError'], state);
const getContractByIdLoading = (state: any) => R.pathOr('', ['RebateProgramEntry', 'contractByIDloading'], state)
const getRebateProgramFieldsInfo = (state: any) => R.pathOr([], ['RebateProgramEntry', 'rebateProgramFieldInfo'], state)
const getContractValidationFlag = (state: any) => R.pathOr([],['RebateProgramEntry', 'contractValidation'], state )

const selectors = {
    getCalculationFrequency,
    getCalculationMethod,
    getCalculationType,
    getRebateProgram,
    getRebateProgramById,
    getDeleteProgram,
    getRebateSaved,
    getRebateSavedEdit,
    getRebatePeriods,
    getRebatePaymentMethod,
    getAllContract,
    getContractDetails,
    getContractList,
    getAllRebateTypes,
    getProgramError,
    getContractByIdLoading,
    getRebateProgramFieldsInfo,
    getContractValidationFlag
}

export default selectors
import React, { useEffect } from 'react'
import CloseIcon from '../../assets/images/close.png'
import './ModalPopup.scss'

interface IProps {
  closeModalPopup?: any,
  ModalPopupTitle?: string,
  ModalPopupType?: string,
  ShowModalPopupFooter?: any,
  ModalPopupName?: string,
  children?: any,
  getFooterActionButton?:any
  getFooterSecondaryBtnTxt?:any
  getFooterPrimaryBtnTxt?:any
}
const ModalPopup = ({
  ModalPopupType,
  ModalPopupTitle,
  closeModalPopup,
  ShowModalPopupFooter,
  ModalPopupName,
  children,
  getFooterActionButton,
  getFooterSecondaryBtnTxt,
  getFooterPrimaryBtnTxt
}:IProps) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])
  return (
        <div className={`modalPopupMasterContainer ${ModalPopupName}`}>
          
            <div className='dropShadowcontainer'></div>
            <div className={`modalPopupContainer ${ModalPopupType}`}>
            <div className='modalPopupHeader'>
               <div className='modalTitle'>{ModalPopupTitle}</div>
               {closeModalPopup && <div className='modalCloseIcon'><img src={CloseIcon} alt="close" onClick={closeModalPopup}/></div>}
            </div>
            <div className='modalPopupBody'>
            <div className="modalBodyContainer">
                    {children}
            </div>
            </div>
            {
            ShowModalPopupFooter
              ? <div className='modalPopupFooter'>
                <div className='footerButton'>
                  {getFooterActionButton()}
                  {getFooterSecondaryBtnTxt()}
                  {getFooterPrimaryBtnTxt()}
                </div>
            </div>
              : null}
        </div>
        </div>
  )
}

export default ModalPopup

import React from 'react'
import { Breadcrumbcomponent } from '@softura/fluentui-basiccomponents'
import './Breadcrumb.scss'

interface IProps {
  items?: any,
  maxDisplayedItems?: any,
  className?: any
}

const BreadcrumContainer = ({ items, maxDisplayedItems = '', className = '' }:IProps) => {
  return (
    <Breadcrumbcomponent
     className = {`breadcrumb ${className}`}
     items = {items}
     maxDisplayedItems = {maxDisplayedItems}
    />
  )
}

export default BreadcrumContainer

import { put, call, takeEvery, all } from 'redux-saga/effects';
import api from '../../../../client/utils/clientApiHelper';
import {
    GET_PAYMENT_RECONCILLATION_DATA, GET_PAYMENT_REVERSE, GET_UPDATE_CHECK_NUMBER, SAVE_UPDATE_PAYMENT_REVERSE
} from './types'

import * as paymentReconciliationActions from './action';

function* getPaymentReconcillationData(payload:any): any {
    try {
        yield put(paymentReconciliationActions.setPaymentReconcilationLoader({gridData: true}))
        const response = yield call(api.callPost, `PaymentReconciliation/GeneralLedgerRebatePayment/Grid`, payload.payload);
        if (response) {
            yield put(paymentReconciliationActions.setPaymentReconcillationData(response));
        } else {
            yield put(paymentReconciliationActions.setPaymentReconcillationData([]));
        }
        yield put(paymentReconciliationActions.setPaymentReconcilationLoader({gridData: false}))
    } catch (error) {
        yield put(paymentReconciliationActions.setPaymentReconcilationLoader({gridData: false}))
        yield put(paymentReconciliationActions.setPaymentReconcillationData([]));
    }

}

function* saveUpdatePaymentReverse(payload:any): any {
    try {
        const response = yield call(api.callAttachmentPost, `PaymentReconciliation/PaymentReverse`, payload.payload);
    } catch (error) {
        // yield put(paymentReconciliationActions.setPaymentReconcillationData([]));
    }
}

function* getPaymentReverse(reverseId:any): any {
    try {
        const response = yield call(api.callGet, `PaymentReconciliation/PaymentReverse/${reverseId.reverseId}`);
        if (response) {
            yield put(paymentReconciliationActions.setPaymentReverse(response));
        } else {
            yield put(paymentReconciliationActions.setPaymentReverse([]));
        }
    } catch (error) {
        yield put(paymentReconciliationActions.setPaymentReverse([]));
    }
}

function* getUpdateCheckNumber(payload:any): any {
    try {
        const response = yield call(api.callPost, `PaymentReconciliation/UpdateCheckNo`, payload?.payload);
        if (response) {
            yield put(paymentReconciliationActions.setUpdateCheckNumber(response));
        } else {
            yield put(paymentReconciliationActions.setUpdateCheckNumber(false));
        }
    } catch (error) {
        yield put(paymentReconciliationActions.setUpdateCheckNumber(false));
    }
}

function* watchGetCommonDashboard() {
    yield takeEvery(GET_PAYMENT_RECONCILLATION_DATA, getPaymentReconcillationData);
    yield takeEvery(SAVE_UPDATE_PAYMENT_REVERSE, saveUpdatePaymentReverse);
    yield takeEvery(GET_PAYMENT_REVERSE, getPaymentReverse);
    yield takeEvery(GET_UPDATE_CHECK_NUMBER, getUpdateCheckNumber);
}

export default function* sagas() {
    yield all([
        watchGetCommonDashboard()
    ])
}
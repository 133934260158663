import React from 'react'
import { Breadcrumb, Card, Row, Col, SofturaTable, InputField, SwitchToggle, ContextMenu, ModalPopup, Spinner } from '../../../CommonComponents';
import data from './data.json'
import './PaymentReconcillations.scss'
import AssignPopup from './AssignPopup';
import ReversePopup from './ReversePopup';

export interface IBreadcrumb {
  text: string,
  key: string,
  href?: string,
  onClick?: React.MouseEventHandler<HTMLDivElement>,
}
interface Iprops {
  breadcrumlist: IBreadcrumb[],
  gridData?: any;
  gridColumn?: any;
  isOpen?: any;
  hidePopup?: any;
  showAssignPopup?: any;
  handleClose?: any;
  showReversePopup?: any;
  serverTotal?: any
  pagesCount?: any
  searchValue?: any
  fetchAction?: any
  onGlobalSearch?: any
  isShowAssignPayment?: any
  setIsShowAssignPayment?: any
  selectedRowData?: any
  getFormattedValue?: any
  onClickSave?: any
  setComment?: any
  comment?: any
  assignFormData?: any
  setAssignFormData?: any;
  onClickAssign?: any;
  onDismissAlert?: any;
  assignPaymentValidation?: any;
  showErrorMessage?: any;
  setShowErrorMessage?: any;
  disableButton?: any;
  loading?: any;
  getContractCollectionFrequency?: any;
  addFormSection?: any;
  getContractDetails?: any;
  getContractProgramYears?: any;
  showValidationPopup?: any
  setShowValidationPopup?: any
  initialRow?: any
  viewOrEdit?: any
  PaymentReconcilationLoader?: any
  checkNumber?: any
  setCheckNumber?: any
  saveCheckNumber?: any
  setFiles?: any
  files?: any
  validateCheckNumber? : any
  updateCheckNumber? : any
  getCompletePeriod?: any
}
const PaymentReconcillations = (props: Iprops) => {
  const {
    showReversePopup,
    showAssignPopup,
    handleClose,
    gridData,
    serverTotal,
    pagesCount,
    searchValue,
    fetchAction,
    onGlobalSearch,
    isShowAssignPayment,
    setIsShowAssignPayment,
    selectedRowData,
    getFormattedValue,
    onClickSave,
    setComment,
    comment,
    assignFormData,
    setAssignFormData,
    onClickAssign,
    onDismissAlert,
    assignPaymentValidation,
    showErrorMessage,
    disableButton,
    loading,
    getContractCollectionFrequency,
    addFormSection,
    getContractDetails,
    getContractProgramYears,
    showValidationPopup,
    setShowValidationPopup,
    initialRow,
    viewOrEdit,
    PaymentReconcilationLoader,
    checkNumber,
    setCheckNumber,
    saveCheckNumber,
    setFiles,
    files,
    validateCheckNumber,
    updateCheckNumber,
    getCompletePeriod
  } = props;
  return (
    <>
    { 
    PaymentReconcilationLoader.gridData &&
      <Spinner text={"Fetching Data ..."} /> }
    <div className='paymentRenconcillation-container'>
      <Breadcrumb items={props.breadcrumlist} maxDisplayedItems='5' />
      <Card>
        <Row>
          <Col lg={7} md={5} sm={12}>
            <p className='heading pt-2'>
              PAYMENT RECONCILIATIONS
            </p>
            <ContextMenu />
          </Col>
          <Col lg={3} md={4} sm={12} className='pe-0'>
            <div className='switchToggleContainer'>
              <p className='switchToggleLabel'>Show Assigned/Reversed Payments</p>
              <SwitchToggle
                className='switchToggle'
                checked={isShowAssignPayment}
                onChange={(e: any, item: any) => setIsShowAssignPayment(item)}
              />
            </div>
          </Col>
          <Col lg={2} md={3} sm={12}>
            <div className="searchBox mb-3">
              <InputField
                placeholder='Search'
                onBlur={(e?: any) => onGlobalSearch(e)}
                onChange={(e?: any) => onGlobalSearch(e)}
                value={searchValue}
              />
              <span className="fa fa-search"></span>
            </div>
          </Col>
        </Row>
        <div className='rebate-table rebateTableFixed'>
          <SofturaTable
            data={gridData ?? []}
            columns={props.gridColumn}
            pageSizes={[10, 25, 50, 100]}
            defaultPageSize={10}
            fetchAction={fetchAction}
            pagesCount={pagesCount}
            sort={true}
            serverTotal={serverTotal}
            isServerSide={true}
            fixedHeader={true}
          />
        </div>
      </Card>

      {loading ? <Spinner text={"Fetching Data ..."} />
        :
        <>
          {showAssignPopup && (
            <ModalPopup
              ShowModalPopupFooter={true}
              ModalPopupTitle={"Assign"}
              ModalPopupType="large"
              FooterSecondaryBtnTxt="Cancel"
              FooterPrimaryBtnTxt="Assign"
              closeModalPopup={handleClose}
              ModalPopupName = "AssignPopup"
              SecondryBtnOnclick={handleClose}
              PrimaryBtnOnclick={onClickAssign}
              FooterPrimaryDisabled={disableButton}
            >
              <AssignPopup
                selectedRowData={selectedRowData}
                getFormattedValue={getFormattedValue}
                assignFormData={assignFormData}
                setAssignFormData={setAssignFormData}
                comment={comment}
                showErrorMessage={showErrorMessage}
                onDismissAlert={onDismissAlert}
                assignPaymentValidation={assignPaymentValidation}
                addFormSection={addFormSection}
                getContractDetails={getContractDetails}
                getContractProgramYears={getContractProgramYears}
                getContractCollectionFrequency={getContractCollectionFrequency}
                handleClose={handleClose}
                showValidationPopup={showValidationPopup}
                setShowValidationPopup={setShowValidationPopup}
                initialRow={initialRow}
                checkNumber = {checkNumber}
                setCheckNumber = {setCheckNumber}
                saveCheckNumber = {saveCheckNumber}
                validateCheckNumber={validateCheckNumber}
                updateCheckNumber = {updateCheckNumber}
                getCompletePeriod={getCompletePeriod}
              />
            </ModalPopup>
          )}
        </>
      }


      {showReversePopup && (
        <ModalPopup
          ShowModalPopupFooter={true}
          ModalPopupTitle={viewOrEdit ? 'View / Edit': 'Reverse'}
          ModalPopupType="extramedium"
          FooterSecondaryBtnTxt="Cancel"
          FooterPrimaryBtnTxt={viewOrEdit ? 'Save': 'Reverse'}
          closeModalPopup={handleClose}
          SecondryBtnOnclick={handleClose}
          PrimaryBtnOnclick={onClickSave}
          FooterPrimaryDisabled={disableButton}
        >
          <ReversePopup
            selectedRowData={selectedRowData}
            getFormattedValue={getFormattedValue}
            setComment={setComment}
            comment={comment}
            checkNumber = {checkNumber}
            setCheckNumber = {setCheckNumber}
            saveCheckNumber = {saveCheckNumber}
            setFiles = {setFiles}
            files = {files}
            validateCheckNumber={validateCheckNumber}
            updateCheckNumber = {updateCheckNumber}
          />
        </ModalPopup>
      )}
    </div>
</>
)
}

export default PaymentReconcillations;
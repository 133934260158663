export const GET_CONTACT_HISTORY_GRID_DATA = 'GET_CONTACT_HISTORY_GRID_DATA';
export const SET_CONTACT_HISTORY_GRID_DATA = 'SET_CONTACT_HISTORY_GRID_DATA';

export const GET_INSERT_CONTACT_HISTORY_DATA = 'GET_INSERT_CONTACT_HISTORY_DATA';
export const SET_INSERT_CONTACT_HISTORY_DATA = 'SET_INSERT_CONTACT_HISTORY_DATA';

export const GET_DELETE_CONTACT_HISTORY_BY_ID = 'GET_DELETE_CONTACT_HISTORY_BY_ID';
export const SET_DELETE_CONTACT_HISTORY_BY_ID = 'SET_DELETE_CONTACT_HISTORY_BY_ID';

export const GET_PAYMENT_HISTORY_GRID_DATA = 'GET_PAYMENT_HISTORY_GRID_DATA';
export const SET_PAYMENT_HISTORY_GRID_DATA = 'SET_PAYMENT_HISTORY_GRID_DATA';

export const GET_PAID_EXPECTED_GRID_DATA = 'GET_PAID_EXPECTED_GRID_DATA';
export const SET_PAID_EXPECTED_GRID_DATA = 'SET_PAID_EXPECTED_GRID_DATA';

export const GET_SUPPLIER_DROPDOWN_DATA = 'GET_SUPPLIER_DROPDOWN_DATA';
export const SET_SUPPLIER_DROPDOWN_DATA = 'SET_SUPPLIER_DROPDOWN_DATA';

export const GET_CONTRACT_DROPDOWN_DATA = 'GET_CONTRACT_DROPDOWN_DATA';
export const SET_CONTRACT_DROPDOWN_DATA = 'SET_CONTRACT_DROPDOWN_DATA';

export const GET_YEAR_DROPDOWN_DATA = 'GET_YEAR_DROPDOWN_DATA';
export const SET_YEAR_DROPDOWN_DATA = 'SET_YEAR_DROPDOWN_DATA';

export const GET_SAVE_PAYMENT_COMPLETION = 'GET_SAVE_PAYMENT_COMPLETION';
export const SET_SAVE_PAYMENT_COMPLETION = 'SET_SAVE_PAYMENT_COMPLETION';

export const SET_COLLECTIONS_HISTORY_LOADER = 'SET_COLLECTIONS_HISTORY_LOADER';

import { Datepicker, OutlineButton, PrimaryButton, ModalPopup, Alert, InputField, Tooltip, Row, Col, Label } from '../../../../CommonComponents';
import "./AddContract.scss";

const AddContact = (props: any) => {
  const {
    onClickAdd,
    contactData,
    onClickDelete,
    onChangeDate,
    onChangeComment,
    onClickAddList,
    contactModalVisible,
    hideAddContactModal,
    alertData,
    onCloseAlert,
    insertContactHistoryData,
    Status,
    response,
    result
  } = props;

  const reverseContactData = [...contactData].reverse()

  return (
    <ModalPopup
      ShowModalPopupFooter={contactModalVisible}
      ModalPopupTitle="Add"
      closeModalPopup={hideAddContactModal}
      FooterPrimaryBtnTxt="Add"
      FooterSecondaryBtnTxt="Cancel"
      PrimaryBtnOnclick={() => onClickAddList()}
      SecondryBtnOnclick={() => hideAddContactModal()}
    >
      <>
      <div>
          {response && <>
          {Status == 'success' ?
           <Alert showAlert={true} type='success' text='File Updated Successfully'/>
          : <Alert showAlert={true} type='error' text='Something went wrong please try after sometime'/>}<br></br></>}
        </div>
        <Alert
          showAlert={alertData.isShowAlert}
          className="alertMessage mb-3"
          dismissAlert={onCloseAlert}
          type="error" text={alertData.alertMessage}
          actions={alertData.isPastDate ? <><PrimaryButton onClick={alertData.onClickYes} text="Yes" /><OutlineButton onClick={alertData.onClickNo} text="No" /></> : null}
        />
        <div className="clearfix">
            <div className="AddContactLeft"> 
              <Label>Follow Up Date</Label>
            </div>
            <div className="expectedComment"> 
              <Label required>Comment</Label>
            </div>
        </div>
        <div>
          {reverseContactData.map((data: any, index: any) => {
            return (
              <div key={data.id}>
                <div className='addFormList'>
                  <div className='formListGroup'>
                    <div className='expectedPaymentDate'>
                      <Datepicker
                        value={data.date ? new Date(data.date) : null}
                        minDate={undefined}
                        onChangeDate={(item: any) => onChangeDate(item, data.id)}
                        allowTextInput={false}
                        disabled={false}
                        placeholder="Select Date" />
                    </div>
                    <div className='expectedComment'>
                      <InputField
                        placeholder="Enter Comment"
                        onChange={(item: any) => onChangeComment(item.target.value, data.id)}
                        value={data.comment}
                        className="dropdown"
                      />
                    </div>
                  </div>
                  <div className='formGrpButton'>
                    {
                      index === 0
                        ?
                        <Tooltip content={"Add New Comment"}>
                          <PrimaryButton disabled={result} className='pull-right addButton' onClick={() => onClickAdd()}>
                            <div className='addIcon'>+</div>
                          </PrimaryButton>
                        </Tooltip>
                        : <Tooltip content={"Delete Comment"}>
                          <OutlineButton disabled={result} className='pull-right next-btn' onClick={() => onClickDelete(data)}>
                            <span className="fa fa-trash"></span>
                          </OutlineButton>
                        </Tooltip>
                    }
                  </div>
                </div>
              </div>
            )
          })}
        </div>


      </>
    </ModalPopup>

  );
}

export default AddContact;
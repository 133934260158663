import {SET_COMMON_DASHBOARD_APPS, SET_ADD_APP_FAV, SET_LOADING, SET_NEWS_AND_ANNOUNCEMENT} from './types';

const initialState = {
  commonPortalApps: {favoriteApplication:[], applications:[]},
  addFavApps:false,
  loading:false,
  NewsAndAnnouncementDetails:[{}]
}
  
const reducer = (state = initialState, action:any = {}) => {
  switch (action.type) {
    case SET_COMMON_DASHBOARD_APPS:
      return {
        ...state,
        commonPortalApps: action.value
      }
    case SET_ADD_APP_FAV:
        return {
          ...state,
          addFavApps: !state.addFavApps
        }
    case SET_LOADING:
        return {
          ...state,
          loading: action.value
        }
    case SET_NEWS_AND_ANNOUNCEMENT:
      const NewsAndAnnouncementDetails = action.value;
      return {
        ...state, NewsAndAnnouncementDetails
      };
    default:
      return state
  }
}

export default reducer

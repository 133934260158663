import { 
  GET_REPORT_DATA,
  SET_REPORT_DATA,
  GET_REPORT_DATA_BY_YEAR,
  SET_REPORT_DATA_BY_YEAR,
  GET_SPEND_REBATE_DASHBOARD,
  SET_SPEND_REBATE_DASHBOARD,
  GET_MANUFACTURER_REPORT_DATA,
  SET_MANUFACTURER_REPORT_DATA,
  GET_ACCOUNTANDFINANCE_REPORT,
  SET_ACCOUNTANDFINANCE_REPORT
} from './types'

export const getReportData = (userEmail: any) => {
  return {
    type: GET_REPORT_DATA,
    userEmail
  }
}

export const setReportData = (data: any) => {
  return {
    type: SET_REPORT_DATA,
    payload: data
  }
}

export const getReportDataByYear = (userEmail: any) => {
  return {
    type: GET_REPORT_DATA_BY_YEAR,
    userEmail
  }
}

export const setReportDataByYear = (data: any) => {
  return {
    type: SET_REPORT_DATA_BY_YEAR,
    payload: data
  }
}

export const getSpendRebateDashboard = (userEmail: any) => {
  return {
    type: GET_SPEND_REBATE_DASHBOARD,
    userEmail
  }
}

export const setSpendRebateDashboard = (data: any) => {
  return {
    type: SET_SPEND_REBATE_DASHBOARD,
    payload: data
  }
  
}
export const getManufacturerReportData = (userEmail: any) => {
  return {
    type: GET_MANUFACTURER_REPORT_DATA,
    userEmail
  }
}

export const setManufacturerReportData = (data: any) => {
  return {
    type: SET_MANUFACTURER_REPORT_DATA,
    payload: data
  }
}

export const getAccountandFinanceReport = (userEmail: any) => {
  return {
    type: GET_ACCOUNTANDFINANCE_REPORT,
    userEmail
  }
}

export const setAccountandFinanceReport = (data: any) => {
  return {
    type: SET_ACCOUNTANDFINANCE_REPORT,
    payload: data
  }
}
import React from "react";
import { Row, Col, Label, InputField, Alert, SearchSelectBox } from "client/CommonComponents";

interface IProps {
    handleInputChange?: any;
    rebateProgramOptions?: any;
    productOptions?: any;
    vendorOptions?: any;
    manufacturerOptions?: any;
    categoryOptions?: any;
    subCategoryOptions?: any;
    rebateSpendSaved?: any;
    handleMessage?: any;
    getValue?: any;
    getError?: any;
    getExternalErrorMessage?: any;
}

const IndividualModal = (props: IProps) => {
    const {
        handleInputChange,
        rebateProgramOptions,
        productOptions,
        vendorOptions,
        manufacturerOptions,
        categoryOptions,
        subCategoryOptions,
        rebateSpendSaved,
        handleMessage,
        getValue,
        getError,
        getExternalErrorMessage
     } = props;
     
    return (
        <div>
            <Row>
                <Col lg={12} className='mb-3'>
                    <Alert showAlert={rebateSpendSaved} text='Saved Successfully' dismissAlert={handleMessage} type="success" className="mb-2" />
                </Col>
                <Col lg={6} className='mb-3'>
                    <Label>Rebate Program</Label>
                    <SearchSelectBox
                        placeholder="Select"
                        options={rebateProgramOptions}
                        filterOptionValue={3}
                        onChange={(item: any, e: any) => handleInputChange(e, item)}
                        value={rebateProgramOptions?.find((i: any) => i.key === getValue("programId"))}
                        name='programId'
                        className="search-dropdown"
                        target="modalPopupMasterContainer"
                    />
                </Col>
                <Col lg={6} className='mb-3'>
                    <Label required={!getValue("purchaseOrder")}>Invoice #</Label>
                    <InputField name='invoice' value={getValue("invoice")} onChange={handleInputChange} errorMessage={getError("invoice")} />
                </Col>
                <Col lg={6} className='mb-3'>
                    <Label required={!getValue("invoice")}>Purchase Order</Label>
                    <InputField type="number" name='purchaseOrder' value={getValue("purchaseOrder")} onChange={handleInputChange} errorMessage={getError("purchaseOrder")} />
                </Col>
                <Col lg={6} className='mb-3'>
                    <Label>Product</Label>
                    <SearchSelectBox
                        placeholder="Select"
                        options={productOptions}
                        filterOptionValue={3}
                        onChange={(item: any, e: any) => handleInputChange(e, item)}
                        value={productOptions?.find((i: any) => i.key === getValue("productId"))}
                        name='productId'
                        className="search-dropdown"
                        target="modalPopupMasterContainer"
                    />
                </Col>
                <Col lg={6} className='mb-3'>
                    <Label required>Vendor</Label>
                    <SearchSelectBox
                        placeholder="Select"
                        options={vendorOptions}
                        filterOptionValue={3}
                        onChange={(item: any, e: any) => handleInputChange(e, item)}
                        value={vendorOptions?.find((i: any) => i.key === getValue("vendorId"))}
                        name='vendorId'
                        className="search-dropdown"
                        target="modalPopupMasterContainer"
                    />
                    {getExternalErrorMessage("vendorId")}
                </Col>
                <Col lg={6} className='mb-3'>
                    <Label>SKU</Label>
                    <InputField name='sku' value={getValue("sku")} onChange={handleInputChange} />
                </Col>
                <Col lg={6} className='mb-3'>
                    <Label>Manufacturer</Label>
                    <SearchSelectBox
                        placeholder="Select"
                        options={manufacturerOptions}
                        filterOptionValue={3}
                        onChange={(item: any, e: any) => handleInputChange(e, item)}
                        value={manufacturerOptions?.find((i: any) => i.key === getValue("manufacturerId"))}
                        name='manufacturerId'
                        className="search-dropdown"
                        target="modalPopupMasterContainer"
                    />
                </Col>
                <Col lg={6} className='mb-3'>
                    <Label required={getValue("manufacturerId")}>Manufacturer SKU</Label>
                    <InputField name="mfgSku" onChange={handleInputChange} value={getValue("mfgSku")} errorMessage={getError("mfgSku")}/>
                </Col>
                <Col lg={6} className='mb-3'>
                    <Label>Categories</Label>
                    <SearchSelectBox
                        placeholder="Select"
                        options={categoryOptions}
                        filterOptionValue={3}
                        onChange={(item: any, e: any) => handleInputChange(e, item)}
                        value={categoryOptions?.find((i: any) => i.key === getValue("categoryId"))}
                        name='categoryId'
                        className="search-dropdown"
                        target="modalPopupMasterContainer"
                    />
                </Col>
                <Col lg={6} className='mb-3'>
                    <Label>Subcategories</Label>
                    <SearchSelectBox
                        placeholder="Select"
                        options={subCategoryOptions}
                        filterOptionValue={3}
                        onChange={(item: any, e: any) => handleInputChange(e, item)}
                        value={subCategoryOptions?.find((i: any) => i.key === getValue("subCategoryId"))}
                        name='subCategoryId'
                        className="search-dropdown"
                        target="modalPopupMasterContainer"
                    />
                </Col>
                <Col lg={6} className='mb-3'>
                    <Label>Branch Number</Label>
                    <InputField name="branchNumber" type="number" onChange={handleInputChange} value={getValue("branchNumber")} errorMessage={getError("branchNumber")}/>
                </Col>
            </Row>
        </div>
    )
}

export default IndividualModal;
import React, { useCallback, useEffect, useRef, useState } from 'react';
import CollectionTracking, { IBreadcrumb } from './CollectionTracking';
import { useDispatch, useSelector } from 'react-redux';
import collectionTrackingOperations from '../../../../state/features/RebateManagement/CollectionTracking/operations';
import collectionTrackingSelectors from '../../../../state/features/RebateManagement/CollectionTracking/selectors';
import userSelector from '../../../../state/features/UserProfile/selectors';
import moment from 'moment';
import { useDebounce } from 'client/CommonComponents/CustomDebounce/DebounceHook';
import { Alert, InputField, Progressbar, SwitchToggle, Tooltip } from 'client/CommonComponents';
import { isAuthorized } from 'client/utils/Permissions';
import ErrorHandling from 'client/CommonComponents/ErrorHandling/ErrorHandling';

const CollectionTrackingContainer = () => {

    const initialState = {
        isModalPopupVisible: false,
        ModalPopupTitle: "",
        closeModalPopup: () => { },
        FooterPrimaryBtnTxt: "",
        FooterSecondaryBtnTxt: "",
        PrimaryBtnOnclick: () => { },
        SecondryBtnOnclick: () => { },
        children: <></>
    }

    const dispatch = useDispatch();
    const [contactGridData, setContactGridData] = useState<any>([]);
    const [contactModalVisible, setContactModalVisible] = useState(false);
    const [popupValues, setPopupValues] = useState<any>(initialState)
    const [paidGridData, setPaidGridData] = useState<any>([]);
    const [paymentGridData, setPaymentGridData] = useState<any>([]);
    const [paymentPaginationData, setPaymentPaginationData] = useState<any>({})
    const [paymentPagesCount, setPaymentPagesCount] = useState<number>(0)
    const [paymentServerTotal, setPaymentServerTotal] = useState(0)
    const [paymentSortData, setPaymentSortData] = useState<any>('')
    const [paymentSearchValue, setPaymentSearchValue] = useState('')
    const [supplierDropdownList, setSupplierDropdownList] = useState([])
    const [contractDropdownList, setContractDropdownList] = useState([])
    const [yearDropdownList, setYearDropdownList] = useState([])
    const [selectedSupplier, setSelectedSupplier] = useState<any>([])
    const [selectedContract, setSelectedContract] = useState<any>([])
    const [selectedYear, setSelectedYear] = useState<any>([])
    const [contactPaginationData, setContactPaginationData] = useState<any>({})
    const [contactPagesCount, setContactPagesCount] = useState<number>(0)
    const [contactServerTotal, setContactServerTotal] = useState(0)
    const [contactSortData, setContactSortData] = useState<any>('')
    const [contactSearchValue, setContactSearchValue] = useState('')
    const [totalPaid, setTotalPaid] = useState<any>(0)
    const [totalExpected, setTotalExpected] = useState<any>(0)
    const [totalDelta, setTotalDelta] = useState<any>(0)
    const [completeData, setCompleteData] = useState<any>("")
    const [status, setStatus] = useState<any>("")
    const selectedSupplierRef = useRef("");
    const selectedContractRef = useRef("");
    const selectedYearRef = useRef("");
    const usernameRef = useRef("");
    const isCompletion = useRef(false);

    const dispatchGetContactHistoryGridData = collectionTrackingOperations.dispatchGetContactHistoryGridData(dispatch);
    const dispatchSetInsertContactHistoryData = collectionTrackingOperations.dispatchSetInsertContactHistoryData(dispatch);
    const dispatchGetDeleteContactHistoryById = collectionTrackingOperations.dispatchGetDeleteContactHistoryById(dispatch);
    const dispatchSetDeleteContactHistoryById = collectionTrackingOperations.dispatchSetDeleteContactHistoryById(dispatch);
    const dispatchGetPaymentHistoryGridData = collectionTrackingOperations.dispatchGetPaymentHistoryGridData(dispatch);
    const dispatchGetPaidExpectedGridData = collectionTrackingOperations.dispatchGetPaidExpectedGridData(dispatch);
    const dispatchGetSupplierDropdownData = collectionTrackingOperations.dispatchGetSupplierDropdownData(dispatch);
    const dispatchGetContractDropdownData = collectionTrackingOperations.dispatchGetContractDropdownData(dispatch);
    const dispatchGetYearDropdownData = collectionTrackingOperations.dispatchGetYearDropdownData(dispatch);
    const dispatchSetPaymentHistoryGridData = collectionTrackingOperations.dispatchSetPaymentHistoryGridData(dispatch)
    const dispatchSetPaidExpectedGridData = collectionTrackingOperations.dispatchSetPaidExpectedGridData(dispatch)
    const dispatchGetSavePaymentCompletion = collectionTrackingOperations.dispatchGetSavePaymentCompletion(dispatch)
    const dispatchSetSavePaymentCompletion = collectionTrackingOperations.dispatchSetSavePaymentCompletion(dispatch)

    const {
        contactHistoryGridData,
        insertContactHistoryData,
        deleteContactHistoryById,
        paymentHistoryGridData,
        paidExpectedGridData,
        supplierDropdownData,
        contractDropdownData,
        yearDropdownData,
        savePaymentCompletion,
        username,
        CollectionsHistoryLoader
    }: any = useSelector(
        (state: any) => ({
            contactHistoryGridData: collectionTrackingSelectors.getContactHistoryGridData(state),
            insertContactHistoryData: collectionTrackingSelectors.getInsertContactHistoryData(state),
            deleteContactHistoryById: collectionTrackingSelectors.getDeleteContactHistoryById(state),
            paymentHistoryGridData: collectionTrackingSelectors.getPaymentHistoryGridData(state),
            paidExpectedGridData: collectionTrackingSelectors.getPaidExpectedGridData(state),
            supplierDropdownData: collectionTrackingSelectors.getSupplierDropdownData(state),
            contractDropdownData: collectionTrackingSelectors.getContractDropdownData(state),
            yearDropdownData: collectionTrackingSelectors.getYearDropdownData(state),
            savePaymentCompletion: collectionTrackingSelectors.getSavePaymentCompletion(state),
            username: userSelector.getUsername(state),  
            CollectionsHistoryLoader: collectionTrackingSelectors.getCollectionsHistory(state)
        })
    )
    useEffect(() => {
        usernameRef.current = username
    }, [username])
    useEffect(() => {
        if (supplierDropdownData?.length > 0) {
            let list = supplierDropdownData.map((item: any) => {
                return { ...item, key: item.id, text: item.supplierName, value: item.id, label: item.supplierName }
            })
            setSupplierDropdownList(list)
        }
    }, [supplierDropdownData])

    useEffect(() => {
        if (contractDropdownData?.length > 0) {
            let list = contractDropdownData.map((item: any) => {
                return { ...item, key: item.contractId, text: item.contractName, value: item.contractId, label: item.contractName }
            })
            if(list.length == 1)
            {
                setSelectedContract(list[0])
            }
            setContractDropdownList(list)
        } else {
            setContractDropdownList([])
        }
    }, [contractDropdownData])

    const breadcrumlist: IBreadcrumb[] = [
      {
        text: "Rebate Management",
        key: "f1",
  
      },
      {
        text: "Collections Tracking",
        key: "f2;",
      },
    ];

    useEffect(() => {
        if (yearDropdownData?.length > 0) {
            let list = yearDropdownData.map((item: any) => {
                return { key: item, text: item, value: item, label: item }
            })
            if(list.length == 1)
            {
                setSelectedYear(list[0])
            }
            setYearDropdownList(list)
        }
    }, [yearDropdownData])

    useEffect(() => {
        if (contactHistoryGridData?.contactHistoryList?.length > 0) {
            const updatedlist = contactHistoryGridData?.contactHistoryList?.map((item: any) => ({
                ...item,
                date: moment(item.date).format("MM/DD/YYYY"),
                expectedPaymentDate: moment(item.expectedPaymentDate).format("MM/DD/YYYY"),
            }));
            setContactGridData(updatedlist)
        } else {
            setContactGridData([])
        }
        setContactServerTotal(contactHistoryGridData?.totalRecordCount)

    }, [contactHistoryGridData])

    useEffect(() => {
        if (paymentHistoryGridData?.paymentHistory?.length > 0) {
            const updatedlist = paymentHistoryGridData?.paymentHistory?.map((item: any) => ({
                ...item,
                glDate: moment(item.glDate).format("MM/DD/YYYY"),
            }));
            setPaymentGridData(updatedlist)
        } else {
            setPaymentGridData([])
        }
        setPaymentServerTotal(paymentHistoryGridData?.totalRecords)
    }, [paymentHistoryGridData])

    useEffect(() => {
        if (paidExpectedGridData?.length > 0) {
            let paid = 0;
            let expected = 0;
            let delta = 0;
            paidExpectedGridData.map((item: any) => {
                paid = paid + item.paid;
                expected += item.expected
                delta += item.delta
            })
            setTotalPaid(paid)
            setTotalExpected(expected)
            setTotalDelta(delta)
            setPaidGridData(paidExpectedGridData)
        } else {
            setTotalPaid(0)
            setTotalExpected(0)
            setTotalDelta(0)
            setPaidGridData([])
        }

    }, [paidExpectedGridData])

    useEffect(() => {
        dispatchGetSupplierDropdownData();
        dispatchSetPaymentHistoryGridData([])
        dispatchSetPaidExpectedGridData([])
        setContactGridData([])
    }, [])

    const paymentDebounce = useDebounce(paymentSearchValue, 500)
    const contactDebounce = useDebounce(contactSearchValue, 500)

    useEffect(() => {
        if (savePaymentCompletion) {
            dispatchSetSavePaymentCompletion(false)
            getPaidExpected();
            getContactHistoryGridData()
        }
    }, [savePaymentCompletion])
    useEffect(() => {
        getPaidExpected()
    }, [selectedSupplier, selectedContract, selectedYear])

    const getPaidExpected = () => {
        const payload = {
            "supplierId": selectedSupplier.key,
            "contractId": selectedContract.key,
            "year": selectedYear.key
        }
        if (payload.supplierId && payload.contractId && payload.year) {
            dispatchGetPaidExpectedGridData(payload)
        }
    }
    useEffect(() => {
        const payload = {
            "pageIndex": paymentPaginationData?.pageIndex != undefined ? paymentPaginationData?.pageIndex + 1 : undefined,
            "pageSize": paymentPaginationData?.pageSize,
            "sortColumn": paymentSortData?.key,
            "sortDirection": paymentSortData?.sortDirection,
            "searchTerm": paymentSearchValue,
            "supplierId": selectedSupplier.key,
            "contractId": selectedContract.key,
            "programYear": selectedYear.key
        }
        if (payload.pageIndex && payload.pageSize && payload.supplierId && payload.contractId && payload.programYear) {
            dispatchGetPaymentHistoryGridData(payload)
        }
    }, [paymentSortData, paymentPaginationData, paymentDebounce, selectedSupplier, selectedContract, selectedYear])

    useEffect(() => {
        getContactHistoryGridData();
    }, [contactSortData, contactPaginationData, contactDebounce, selectedSupplier])

    useEffect(() => {
        if (deleteContactHistoryById) {
            getContactHistoryGridData();
            dispatchSetDeleteContactHistoryById(false)
        }
    }, [deleteContactHistoryById])

    useEffect(() => {
        if (insertContactHistoryData) {
            setStatus('success')
            getContactHistoryGridData();
            dispatchSetInsertContactHistoryData(false)
        }
    }, [insertContactHistoryData])

    const getContactHistoryGridData = () => {
        let payload = {
            "supplierId": selectedSupplier.id,
            "pageIndex": contactPaginationData?.pageIndex != undefined ? contactPaginationData?.pageIndex + 1 : undefined,
            "pageSize": contactPaginationData?.pageSize,
            "sortColumn": contactSortData?.key,
            "sortDirection": contactSortData?.sortDirection,
            "searchTerm": contactSearchValue
        }
        if (payload.supplierId && payload.pageIndex && payload.pageSize) {
            dispatchGetContactHistoryGridData(payload)
        }
    }

    useEffect(() => {
        if (paymentServerTotal && paymentPaginationData?.pageSize) {
            let pageSize = paymentPaginationData?.pageSize
            let tempPageCount = paymentServerTotal > pageSize ? Math.ceil(paymentServerTotal / pageSize - 1) : 0
            setPaymentPagesCount(tempPageCount)
        }
    }, [paymentServerTotal, paymentPaginationData?.pageSize])

    const paymentFetchAction = useCallback((fetchData: any) => {
        if (fetchData.action && fetchData.action === "paging") {
            setPaymentPaginationData(fetchData.data)
        }
        if (fetchData.action && fetchData.action === "sorting") {
            setPaymentSortData(fetchData.data[0]);
        }
    }, [])

    const getFormattedValue = (value: any) => {
        let newValue: any;
        if (Number.isInteger(value)) {
          newValue = value?.toLocaleString() + ".00";
        } else {
          newValue = value?.toLocaleString();
        }
        return newValue;
    }

    useEffect(() => {
        if (contactServerTotal && contactPaginationData?.pageSize) {
            let pageSize = contactPaginationData?.pageSize
            let tempPageCount = contactServerTotal > pageSize ? Math.ceil(contactServerTotal / pageSize - 1) : 0
            setContactPagesCount(tempPageCount)
            if (tempPageCount < contactPaginationData.pageIndex) {
                setContactPaginationData({
                    ...contactPaginationData,
                    pageIndex: tempPageCount
                })
            }
        }
    }, [contactServerTotal, contactPaginationData?.pageSize])

    const contactFetchAction = useCallback((fetchData: any) => {
        if (fetchData.action && fetchData.action === "paging") {
            setContactPaginationData(fetchData.data)
        }
        if (fetchData.action && fetchData.action === "sorting") {
            setContactSortData(fetchData.data[0]);
        }
    }, [])

    const hideAddContactModal = () => {
        setContactModalVisible(false);
    }

    const showAddContactModal = () => {        
        setStatus('')
        setContactModalVisible(true);
    }

    const contactColumn = [
        {
            key: "addedBy",
            title: "Added By",
            width: "100px",
        },
        {
            key: "date",
            title: "Date",
            width: "100px"
        },
        {
            key: "comment",
            title: "Comment",
            width: "350px"
        },
        {
            key: "expectedPaymentDate",
            title: "Follow Up Date",
            width: "120px",
            Cell: (row: any) => {
                return (
                    <div className='expectedPaymentDate'>
                        <div className='expectedDate'>{row.rowData.expectedPaymentDate !== "01/01/0001" ? row.rowData.expectedPaymentDate : ""}</div>
                        {
                            isAuthorized("CollectionTracking", "update") && <div onClick={() => {
                                setPopupValues({
                                    isModalPopupVisible: true,
                                    ModalPopupTitle: "Confirmation",
                                    ModalPopupName: "deleteConfirmPopup",
                                    closeModalPopup: hidePopup,
                                    FooterPrimaryBtnTxt: "Ok",
                                    FooterSecondaryBtnTxt: "Cancel",
                                    PrimaryBtnOnclick: () => {
                                        dispatchGetDeleteContactHistoryById(row.rowData.id)
                                        hidePopup();
                                    },
                                    SecondryBtnOnclick: hidePopup,
                                    children: <p>Are you sure you want to delete this comment?</p>
                                })
                            }} className='expectedTrashBtn'>
                                <Tooltip content={"Delete Contact History"}>
                                    <span className="fa fa-trash"></span>
                                </Tooltip>
                            </div>

                        }

                    </div>
                );
            }
        }
    ];

    const paymentColumn = [
        {
            key: "period",
            title: "Payment Period",
            width: "120px",
        },
        {
            key: "documentType",
            title: "Doc Type",
            width: "100px"
        },
        {
            key: "checkNo",
            title: "Check No",
            width: "150px"
        },
        {
            key: "amount",
            title: "Amount",
            width: "150px",
            Cell: (row: any) => {
                return (
                    <div>{'$' + getFormattedValue(Number(row.rowData.amount.toFixed(2)).toLocaleString('en', options))}</div>
                );
            },
        },
        {
            key: "glDate",
            title: "G/L Date",
            width: "100px"
        }
    ];
    const disableSwitch = useRef(false);
    const options = { 
        minimumFractionDigits: 2,
        maximumFractionDigits: 2 
      };
    useEffect(() => {
        const checkPermission = !isAuthorized("CollectionTracking", "update");
        disableSwitch.current = checkPermission
    }, [])
    //const USformatter = new Intl.NumberFormat("en-US");
    const paidColumn = [
        {
            key: 'collectionFrequency',
            title: 'Quarter',
            width: "90px"
        },
        {
            key: "paidExpected",
            title: "Paid/Expected",
            width: "300px",
            Cell: (row: any) => {
                const per = (row.rowData.paid * 100) / row.rowData.expected
                return (<div className='progressField'><Progressbar progress={per > 100 ? 100 : per} /> <div className='progressCost'>{"$" + getFormattedValue(Number(row.rowData.paid.toFixed(2)).toLocaleString('en', options)) + " / " + "$" + getFormattedValue(Number(row.rowData.expected).toLocaleString('en', options))}</div></div>)
            }
        },
        {
            key: "delta",
            title: "Delta",
            width: "120px",
            Cell: (row: any) => {
                return (
                    row.rowData.delta < 0
                        ? <div className='deltaData'>{'($' + getFormattedValue(Number(row.rowData.delta* -1).toLocaleString('en', options)) + ')'}</div>
                        : <div className='deltaData'>{'$' + getFormattedValue(Number(row.rowData.delta).toLocaleString('en', options))}</div>

                );
            },
        },
        {
            key: "completed",
            title: "Completed",
            width: '120px',
            Cell: (row: any) => (<CompletionComponent {...row} disableSwitch={disableSwitch} />),
        },
    ];

    useEffect(() => {
        selectedSupplierRef.current = selectedSupplier.key
    }, [selectedSupplier])
    useEffect(() => {
        selectedContractRef.current = selectedContract.key
    }, [selectedContract])
    useEffect(() => {
        selectedYearRef.current = selectedYear.key
    }, [selectedYear])


    const CompletionComponent = (props: any) => {
        const {
            rowData,
            disableSwitch
        } = props;
        const [isToggle, setIsToggle] = useState(rowData.completed)
        const [isOk, setIsOk] = useState(false)
        const [text, setText] = useState<any>("")
        useEffect(()=>{
            setIsToggle(rowData.completed)
        },[rowData.completed])
        useEffect(() => {
            if (isOk) {
                if (text?.length > 0) {
                    saveCompletionStatus(rowData, isToggle, text);
                    setText("")
                    hidePopup();
                }
                setIsOk(false);
            }
        }, [isOk])
        const hide = (item: any) => {
            hidePopup();
            setIsOk(false);
            setText("")
            setIsToggle(!item)
        }
        return (
            <div><SwitchToggle disabled={disableSwitch.current} checked={isToggle} onChange={(e: any, item: any) => {
                setIsToggle(item)
                if (item && rowData.paid < rowData.expected) {
                    setPopupValues({
                        isModalPopupVisible: true,
                        ModalPopupTitle: "Please Explain Reason for Completion",
                        closeModalPopup: () => hide(item),
                        FooterPrimaryBtnTxt: "Ok",
                        FooterSecondaryBtnTxt: "Cancel",
                        PrimaryBtnOnclick: () => {
                            setIsOk(true)
                        },
                        SecondryBtnOnclick: () => hide(item),
                        text: text,
                        setText: setText
                    })
                } else {
                    saveCompletionStatus(rowData, item, "");
                }

            }} /></div>
        );
    }

    const Explaination = (props: any) => {
        const {
            text,
            setText
        } = props;
        const [showAlert, setShowAlert] = useState<any>(false)

        return <>
            <Alert showAlert={showAlert} type='error' text='Please add explaination' dismissAlert={() => { setShowAlert(false) }} />
            <InputField
                placeholder="Enter Explaination"
                onChange={(item: any) => {
                    setText(item.target.value)
                    if (showAlert && item.target.value != "") setShowAlert(false)
                }}
                onBlur={(item: any) => {
                    item.target.value == "" && setShowAlert(true)
                }}
                value={text}
            />
        </>
    }
    const saveCompletionStatus = (data: any, status: any, text: any) => {
        const payload = {
            "supplierId": selectedSupplierRef.current,
            "contractId": selectedContractRef.current,
            "programYear": selectedYearRef.current,
            "periodName": data?.collectionFrequency,
            "completionStatus": status,
            "comments": text,
            "loggedInUser": usernameRef.current.replace(/@[^@]*$/,"").replace("."," "),
            "completedBy": usernameRef.current
        }
        dispatchGetSavePaymentCompletion(payload)
    }

    const hidePopup = () => {
        setPopupValues({
            ...initialState,
            isModalPopupVisible: false
        })
    }

    useEffect(() => {
        setSelectedSupplier('')
        setSelectedContract('')
        setSelectedYear('')
    },[])

    useEffect(() => {
        if(selectedContract?.key)
        {
            dispatchGetYearDropdownData(selectedContract.key)
        }
    },[selectedContract])

    const onChangeVendor = (item: any) => {
        setSelectedSupplier(item);
        setSelectedContract('')
        setSelectedYear('')
        setContractDropdownList([])
        setYearDropdownList([])
        dispatchSetPaymentHistoryGridData([])
        dispatchSetPaidExpectedGridData([])
        dispatchGetContractDropdownData(item.key);
    }

    const onChangeContract = (item: any) => {
        setSelectedContract(item);
        setSelectedYear('')
        setYearDropdownList([])
        dispatchSetPaymentHistoryGridData([])
        dispatchSetPaidExpectedGridData([])
        dispatchGetYearDropdownData(item.key)
    }

    const onChangeYear = (item: any) => {
        setSelectedYear(item)
    }

    const contentTableFooter = [
        {
            footerCell: <>Total</>
        },
        {
            footerCell: <div className='progressField'><Progressbar progress={totalPaid > totalExpected ? 100 : ((totalPaid * 100) / totalExpected)} /> <div className='progressCost'>{'$' + getFormattedValue(Number(totalPaid).toLocaleString('en', options)) + ' / ' + '$' + getFormattedValue(Number(totalExpected).toLocaleString('en', options))}</div></div>
        },
        {
            footerCell: <div className='deltaData'> 
            {totalDelta < 0 ?
            '($'+ getFormattedValue(Number(totalDelta * -1).toLocaleString('en', options)) + ')'
            : '$'+ getFormattedValue(Number(totalDelta).toLocaleString('en', options))}
            </div>,
        },
        {
            footerCell: <div></div>,
        },
    ];

    const [authorized, setAuthorized] = useState<any>(null);
    useEffect(() => {
        const checkPermission = isAuthorized("CollectionTracking", "view");
        setAuthorized(checkPermission);
    }, []);

    if (!(authorized) && authorized != null) {
        return <ErrorHandling errorNumber='403' />
    }

    return (
        <CollectionTracking
            breadcrumlist={breadcrumlist}
            hideAddContactModal={hideAddContactModal}
            showAddContactModal={showAddContactModal}
            contactModalVisible={contactModalVisible}
            contactColumn={contactColumn}
            paymentColumn={paymentColumn}
            paidColumn={paidColumn}
            contactGridData={contactGridData}
            paymentGridData={paymentGridData}
            paidGridData={paidGridData}
            popupValues={popupValues}
            paymentFetchAction={paymentFetchAction}
            paymentPagesCount={paymentPagesCount}
            paymentServerTotal={paymentServerTotal}
            paymentSearchValue={paymentSearchValue}
            setPaymentSearchValue={setPaymentSearchValue}
            supplierDropdownList={supplierDropdownList}
            contractDropdownList={contractDropdownList}
            yearDropdownList={yearDropdownList}
            onChangeVendor={onChangeVendor}
            onChangeContract={onChangeContract}
            onChangeYear={onChangeYear}
            selectedSupplier={selectedSupplier}
            selectedContract={selectedContract}
            selectedYear={selectedYear}
            contactFetchAction={contactFetchAction}
            contactPagesCount={contactPagesCount}
            contactServerTotal={contactServerTotal}
            contactSearchValue={contactSearchValue}
            setContactSearchValue={setContactSearchValue}
            contentTableFooter={contentTableFooter}
            Explaination={Explaination}
            collectionsHistoryLoader={CollectionsHistoryLoader}
            insertContactHistoryData={insertContactHistoryData}
            Status={status}
        />
    )
}

export default CollectionTrackingContainer;

import { SET_CATEGORYDATA, SET_CATEGORY, SET_SAVEDCATEGORYFLAG, SET_ERRORCATEGORYFLAG, SET_CATEGORYNAME } from './types'
const initialState = {
  category: {},
  categoryData: [],
  categorySaved: false,
  errorCategory: "",
  categoryName: ""
}

const reducer = (state = initialState, action:any = {}) => {
  switch (action.type) {
    case SET_CATEGORYDATA:
      return {
        ...state,
        categoryData: action.payload,
      }
    case SET_CATEGORY:
      return {
        ...state,
        category: action.payload,
      }
    case SET_SAVEDCATEGORYFLAG:
      return {
        ...state,
        categorySaved: action.payload,
      }
    case SET_ERRORCATEGORYFLAG:
      return {
        ...state,
        errorCategory: action.payload,
      }
    case SET_CATEGORYNAME:
        return {
          ...state,
          categoryName: action.payload,
        }
    default: return state
  }
}

export default reducer

import React, {useEffect, useState} from "react";
import ProductSubCategoryEntry from "./ProductSubCategoryEntry";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import subCategoryEntrySelector from '../../../../state/features/RebateManagement/ProductSubCategoryEntry/selectors';
import subCategoryEntryOperations from '../../../../state/features/RebateManagement/ProductSubCategoryEntry/operations';
import userSelector from '../../../../state/features/UserProfile/selectors';
import { isAuthorized } from "client/utils/Permissions";
import ErrorHandling from "../../../CommonComponents/ErrorHandling/ErrorHandling"
interface Iprops {
    showProductSubCategory?: boolean,
    hideProductSubCategoryModal?:Function
}
const ProductSubCategoryEntryContainer = (props:Iprops) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showSubCategoryModal, setShowSubCategoryModal] = useState(false);
    const [errors, setErrors] = useState<any>({
        subCategoryName: "Please provide Name"
    });
    const [values, setValues] = useState<any>({
        subCategoryName: "",
        description: "",
        IsActive: true
    });
    const [isSaveClicked, setSaveClicked] = useState(false);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const dispatchGetSubCategoryData = subCategoryEntryOperations.dispatchGetSubCategoryData(dispatch);
    const dispatchGetSubCategoryById = subCategoryEntryOperations.dispatchGetSubCategoryById(dispatch);
    const dispatchGetCreateUpdateSubCategory = subCategoryEntryOperations.dispatchGetCreateUpdateSubCategory(dispatch)
    const dispatchSetSubCategorySavedFlag = subCategoryEntryOperations.dispatchSetSubCategorySavedFlag(dispatch);
    const dispatchSetSubCategory = subCategoryEntryOperations.dispatchSetSubCategory(dispatch);
    const dispatchSetErrorSubCategory = subCategoryEntryOperations.dispatchSetErrorSubCategory(dispatch);
    const dispatchSetSubCategoryName = subCategoryEntryOperations.dispatchSetSubCategoryName(dispatch);
    const  {
        subCategoryData,
        subCategory,
        subCategorySaved,
        errorSubCategory,
        username,
    }:any  = useSelector(
        (state:any) => ({
            subCategoryData: subCategoryEntrySelector.getSubCategoryData(state),
            subCategory: subCategoryEntrySelector.getSubCategory(state),
            subCategorySaved: subCategoryEntrySelector.getSubCategorySaved(state),
            errorSubCategory: subCategoryEntrySelector.getErrorSubCategory(state),
            username: userSelector.getUsername(state),
        })
    )

    const getInitialError = () => {
        setErrors({
            subCategoryName:'Please provide Name',
        });
    }

    useEffect(() => {
        setShowSubCategoryModal(true);
        dispatchGetSubCategoryData();
        if (props.showProductSubCategory && typeof props.showProductSubCategory !== "boolean") {
            dispatchGetSubCategoryById(props.showProductSubCategory);
        }
        getInitialError();
    }, []);

    useEffect(() => {
        if (subCategoryData?.length > 0) {
            const options = subCategoryData.map((item: any) => (
                {
                    ...item,
                    label: item.subCategoryName,
                    value: item.subCategoryName,
                    key: parseInt(item.subCategoryId)
                }
            ));
            const addNew = {
                value: "addNew",
                key: "addNew",
                label: "Add New",
            }
            options.unshift(addNew);
            setSubCategoryOptions(options);
        }
    }, [subCategoryData]);

    useEffect(() => {
        if (subCategory?.subCategoryId) {
            setValues({
                ...subCategory,
                IsActive: subCategory.isActive
            });
            for (const key in subCategory) {
                getValidateData(key, subCategory[key])
            }
        }
    }, [subCategory])

    useEffect(() => {
        if (subCategorySaved) {
            setValues({
                subCategoryName: "",
                description: "",
                IsActive: true
            })
            getInitialError();
            dispatchSetSubCategory({});
            setSaveClicked(false);
            setTimeout(() => {
                closeProductSubCategory()
            }, 2000)
        }
    }, [subCategorySaved])

    const handleHome = () => {
        navigate("/");
    }

    const hidePopup = () => {
        setValues({
            subCategoryName: "",
            description: "",
            IsActive: true
        })
        dispatchSetSubCategory({});
        setShowSubCategoryModal(false);
        dispatchSetSubCategorySavedFlag(false);
        if(props.hideProductSubCategoryModal) {
            props.hideProductSubCategoryModal()
        } else {
            handleHome();
        }
    }

    const checkforName = (value: any) => {
        const istrue = subCategoryData?.length && subCategoryData?.find((i: any) => i.subCategoryName.toLowerCase() === value.toLowerCase())
        if (istrue && !values.subCategoryId) {
            errors.subCategoryName='Name already exists'
        } else {
            errors.subCategoryName=''
        }
    }

    const handleNameCheck = (value: any) => {
        if(!value){
            errors.subCategoryName='Please provide Name'
        } else if (value?.length > 2 && value?.length < 250) {
            checkforName(value);
        } else if (value?.length >= 250) {
            errors.subCategoryName='Maximum 250 characters allowed'
        } else {
            errors.subCategoryName='Please provide Valid Name'
        }
    }

    const handleDescriptionCheck = (value: any) => {
        if (value && value?.length >= 500) {
            errors.description = "Maximum 500 characters allowed"
        } else {
            errors.description = ""
        }
    }

    const getValidateData = (name: any, value: any) => {
        if (name === "subCategoryName") {
            handleNameCheck(value);
        }
        if (name === "description") {
            handleDescriptionCheck(value);
        }
    }

    const handleInputChange = (event: any, item: any) => {
        if (subCategorySaved || errorSubCategory) {
            handleMessage();
        }
        let name = event.target.getAttribute('name');
        let value = item;
        if (name === "IsActive") value = item;
        getValidateData(name, value);

        if (
            (name === "subCategoryName" && value?.length > 250) || 
            (name === "description" && value?.length > 500)) 
        {
            return;
        }

        setValues({
            ...values,
            [name]: value
        })
    }

    const closeProductSubCategory = () => {
        if(props.hideProductSubCategoryModal) {
            props.hideProductSubCategoryModal()
            dispatchSetSubCategorySavedFlag(false);
        }
    }

    const handleSave = () => {
        setSaveClicked(true);
        for (const key in values) {
            getValidateData(key, values[key])
        }
        let error = Object.values(errors).filter((val:any) => val !== '');
        if (error?.length === 0) {
            if (props.showProductSubCategory && !values.subCategoryId) {
                dispatchSetSubCategoryName(values.subCategoryName);
            }
            const payload = {...values}
            if (!payload.description) {
                payload.description= ""
            }
            if (payload.subCategoryId) {
                payload.modifiedBy = username;
            } else {
                payload.createdBy = username;
            }
            dispatchGetCreateUpdateSubCategory(payload);
        }
    }

    const handleSubCategory = (_event: any, item: any) => {
        if (subCategorySaved || errorSubCategory) {
            handleMessage();
        }
        if (item.key === "addNew") {
            setValues({
                subCategoryName: "",
                description: "",
                IsActive: true
            })
            dispatchSetSubCategory({});
            getInitialError();
            setSaveClicked(false);
        } else {
            dispatchGetSubCategoryById(item.key)
        }
    }

    const handleMessage = () => {
        dispatchSetSubCategorySavedFlag(false);
        dispatchSetErrorSubCategory("");
    }

    const breadcrumlist = [
        { 
        text: 'Rebate Management', 
        key: 'f1', 
        // href: '/Home', 
        // onClick: (e: any) => {
        //         e.preventDefault(); handleHome() 
        //     } 
        },
        { text: 'Sub Category', key: 'f2' }
    ]

    const getFooterPrimaryBtnTxt = () => {
        if (values.subCategoryId) {
            return "Update";
        } else {
            return "Save";
        }
    }
    const [authorized, setAuthorized] = useState<any>(null);
    useEffect(() => {
        const checkPermission = isAuthorized("Subcategories", "view");
        setAuthorized(checkPermission);
        
    }, []);

    if (!(authorized) && authorized != null) {
        return <ErrorHandling errorNumber='403'/>
    } 

    return (
        <ProductSubCategoryEntry
            breadcrumlist={breadcrumlist}
            showCategoryModal={showSubCategoryModal}
            hidePopup={hidePopup}
            handleSave={handleSave}
            handleInputChange={handleInputChange}
            errors={errors}
            isSaveClicked={isSaveClicked}
            values={values}
            subCategoryOptions={subCategoryOptions}
            handleSubCategory={handleSubCategory}
            showProductSubCategory={props.showProductSubCategory}
            subCategorySaved={subCategorySaved}
            handleMessage={handleMessage}
            errorSubCategory={errorSubCategory}
            FooterPrimaryBtnTxt={getFooterPrimaryBtnTxt}
        />
    )

}

export default ProductSubCategoryEntryContainer;
import * as R from 'ramda';
import axios from 'axios';
import {v1 as uuid} from "uuid";

function CustomException(error) {
  return {
    endpoint: R.pathOr('', ['response', 'config', 'url'], error),
    statusText: R.pathOr('', ['response', 'statusText'], error),
    message: R.pathOr('', ['message'], error),
    status: R.pathOr(500, ['response', 'status'], error),
    data: R.pathOr(null, ['response', 'data'], error),
  };
}

const baseApiUrl = process.env.REACT_APP_API_BASE_URL
const apiVersion = process.env.REACT_APP_API_VERSION
const contentType ='application/json'

const get = (endpoint, headers, options = {}) => {
  const commonHeaders = {
    'Content-Type': contentType,
    'Access-Control-Allow-Origin': baseApiUrl,
    'x-correlation-id': uuid(),
  };
  const commonGetOptions = {
    method: 'GET',
    responseType: 'json',
    ...options,
  };
  return axios.get(`${baseApiUrl}${apiVersion}${endpoint}`,
    {
      ...commonGetOptions,
      headers: { ...commonHeaders, ...headers },
    })
    .then(response => response.data)
    .catch((error) => {
      throw new CustomException(error);
    });
};

const post = (endpoint, payload, headers, options = {}) => {
  const commonHeaders = {
    'Content-Type': contentType,
    'Access-Control-Allow-Origin': baseApiUrl,
    'x-correlation-id': uuid(),
  };
  const commonGetOptions = {
    method: 'POST',
    responseType: 'json',
    ...options,
  };
  return axios.post(`${baseApiUrl}${apiVersion}${endpoint}`,
  payload,
    {
      ...commonGetOptions,
      headers: { ...commonHeaders, ...headers },
    })
    .then(response => response.data)
    .catch((error) => {
      throw new CustomException(error);
    });
};

const put = (endpoint, payload, headers, options = {}) => {
  const commonHeaders = {
    'Content-Type': contentType,
    'Access-Control-Allow-Origin': baseApiUrl,
    'x-correlation-id': uuid(),
  };
  const commonGetOptions = {
    method: 'PUT',
    responseType: 'json',
    ...options,
  };
  return axios.put(`${baseApiUrl}${apiVersion}${endpoint}`,
  payload,
    {
      ...commonGetOptions,
      headers: { ...commonHeaders, ...headers },
    })
    .then(response => response.data)
    .catch((error) => {
      throw new CustomException(error);
    });
};

const attachmentPost = (endpoint, payload, headers, options = {}) => {
  const commonHeaders = {
    'Content-Type': "multipart/form-data",
    'Access-Control-Allow-Origin': baseApiUrl,
    'x-correlation-id': uuid(),
  };
  const commonGetOptions = {
    method: 'POST',
    responseType: 'json',
    ...options,
  };
  return axios.post(`${baseApiUrl}${apiVersion}${endpoint}`,
  payload,
    {
      ...commonGetOptions,
      headers: { ...commonHeaders, ...headers },
    })
    .then(response => response.data)
    .catch((error) => {
      throw new CustomException(error);
    });
};

function exportFunctions() {
    const callGet = get;
    const callPost = post;
    const callPut = put;   
    const callAttachmentPost = attachmentPost; 
    return {
      callGet,
      callPost,
      callPut,
      callAttachmentPost     
    };
  }
  export default exportFunctions();

import React, { useState } from 'react';
import { Progressbar, SwitchToggle, Card, Row, Col, DropdownBox, Label, Datepicker, Checkbox, Quantity, OutlineButton, SofturaTable, PrimaryButton, InputSpinner, ModalPopup, Alert, SearchSelectBox, MultiSelectoption, Tooltip, RadioContainer, Search, Breadcrumb, Spinner } from '../../../CommonComponents';
import './CollectionTracking.scss';
import AddContact from './AddContact';
import { isAuthorized } from 'client/utils/Permissions';

export interface IBreadcrumb {
  text: string;
  key: string;
  href?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

interface Iprops {
    breadcrumlist: IBreadcrumb[],
    showAddContactModal?: Function,
    hideAddContactModal?: Function,
    contactModalVisible?: Boolean,
    contactColumn?: any
    paymentColumn?: any
    paidColumn?: any
    contactGridData?: any
    setAddContactList?: any
    popupValues?: any
    paymentGridData?: any
    paidGridData?: any
    paymentFetchAction?: any
    paymentPagesCount?: any
    paymentServerTotal?: any
    paymentSearchValue?: any
    setPaymentSearchValue?: any
    supplierDropdownList?: any
    contractDropdownList?: any
    yearDropdownList?: any
    onChangeVendor?: any
    onChangeContract?: any
    onChangeYear?: any
    selectedSupplier?: any
    selectedContract?: any
    selectedYear?: any
    contactFetchAction?: any
    contactPagesCount?: any
    contactServerTotal?: any
    contactSearchValue?: any
    setContactSearchValue?: any
    contentTableFooter?: any
    footerPrimaryDisabled?: any
    Explaination?: any
    collectionsHistoryLoader?: any
    insertContactHistoryData?: any
    Status?: any
}
const CollectionTracking = (props: Iprops) => {
    const {
        contactColumn,
        paymentColumn,
        paidColumn,
        contactGridData,
        paymentGridData,
        paidGridData,
        setAddContactList,
        popupValues,
        paymentFetchAction,
        paymentPagesCount,
        paymentServerTotal,
        paymentSearchValue,
        setPaymentSearchValue,
        supplierDropdownList,
        contractDropdownList,
        yearDropdownList,
        onChangeVendor,
        onChangeContract,
        onChangeYear,
        selectedSupplier,
        selectedContract,
        selectedYear,
        contactFetchAction,
        contactPagesCount,
        contactServerTotal,
        contactSearchValue,
        setContactSearchValue,
        contentTableFooter,
        footerPrimaryDisabled,
        Explaination,
        collectionsHistoryLoader,
        insertContactHistoryData,
        Status
    } = props;

    return (
        <>
        { 
        collectionsHistoryLoader.Loading &&             
         <Spinner text={"Fetching Data ..."} /> }
            <div className='collectionTracking'>
             <Breadcrumb items={props.breadcrumlist} maxDisplayedItems='5' />
                <div className='cardContainer'>
                    <div className='headerContent'>
                        <div className='headertitle'>Collections Tracking</div>
                        <div className='headerTools'>
                            <div className='headerTooldropdown vendorDropdown'>
                                {/* <DropdownBox
                                    placeholder="Select Vendor"
                                    options={supplierDropdownList}
                                    onChange={(e: any, item: any) => onChangeVendor(item)}
                                    value={selectedSupplier}
                                    className="dropdown"
                                    name="categoryManagerId"
                                /> */}
                                <SearchSelectBox
                                    options={supplierDropdownList}
                                    placeholder="Select Vendor"
                                    onChange={onChangeVendor}
                                    value={selectedSupplier}
                                    filterOptionValue={1}
                                />
                            </div>
                            <div className='headerTooldropdown contractDropdown'>
                                {/* <DropdownBox
                                    placeholder="Select Contract"
                                    options={contractDropdownList}
                                    onChange={(e: any, item: any) => onChangeContract(item)}
                                    value={selectedContract}
                                    className="dropdown"
                                    name="categoryManagerId"
                                /> */}
                                <SearchSelectBox
                                    options={contractDropdownList}
                                    placeholder="Select Contract"
                                    onChange={onChangeContract}
                                    value={selectedContract}
                                    filterOptionValue={1}
                                />
                            </div>
                            <div className='headerTooldropdown yearDropdown'>
                                {/* <DropdownBox
                                    placeholder="Select Year"
                                    options={yearDropdownList}
                                    onChange={(e: any, item: any) => onChangeYear(item)}
                                    value={selectedYear}
                                    className="dropdown"
                                    name="categoryManagerId"
                                /> */}
                                <SearchSelectBox
                                    options={yearDropdownList}
                                    placeholder="Select Year"
                                    onChange={onChangeYear}
                                    value={selectedYear}
                                    filterOptionValue={1}
                                />
                            </div>


                        </div>
                    </div>
                    <div className='cardContainer'>


                        <Row>

                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className='headerContent'>
                                    <div className='headertitle'>Payment History</div>
                                    <div className='pull-right mb-2'>

                                        <Search
                                            placeholder={`Search`}
                                            className='searchTextbox'
                                            onSearch={(e?: any) => setPaymentSearchValue(e?.target?.value)}
                                            onBlur={(e?: any) => setPaymentSearchValue(e?.target?.value)}
                                            onChange={(e?: any) => setPaymentSearchValue(e?.target?.value)}
                                            value={paymentSearchValue}
                                        />
                                    </div>

                                </div>
                                <div className='rebate-table'>
                                    <SofturaTable
                                        data={paymentGridData}
                                        pageSizes={[10, 25, 50, 100]}
                                        columns={paymentColumn}
                                        sort={true}
                                        editable={true}
                                        pagination={true}
                                        fetchAction={paymentFetchAction}
                                        serverTotal={paymentServerTotal}
                                        isServerSide={true}
                                        pagesCount={paymentPagesCount}
                                    />
                                </div>

                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className='rebate-table paymentDetailsHistory rebateTableFixed'>
                                    <SofturaTable
                                        data={paidGridData}
                                        columns={paidColumn}
                                        sort={true}
                                        editable={true}
                                        pagination={false}
                                        fixedHeader={true}
                                        tableFooterData={paidGridData?.length > 0 ? contentTableFooter : []}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='cardContainer'>

                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className='headerContent'>
                                    <div className='headertitle'>Contact History</div>
                                    <div className='contactHistoryAdd mb-2'>
                                        <Search
                                            placeholder={`Search`}
                                            className='searchTextbox'
                                            onSearch={(e?: any) => setContactSearchValue(e?.target?.value)}
                                            onBlur={(e?: any) => setContactSearchValue(e?.target?.value)}
                                            onChange={(e?: any) => setContactSearchValue(e?.target?.value)}
                                            value={contactSearchValue}
                                        />
                                        {isAuthorized("CollectionTracking", "create") && <PrimaryButton  disabled={!(selectedSupplier.id > 0)} className='pull-right next-btn contactHistoryAdd' onClick={props.showAddContactModal}>
                                            <div>
                                                <span className='contactHistoryAddIcon'>Add</span>
                                            </div>
                                        </PrimaryButton>}
                                    </div>
                                </div>

                                <div className='rebate-table addContactTable rebateTableFixed'>
                                    <SofturaTable
                                        data={contactGridData}
                                        columns={contactColumn}
                                        pageSizes={[10, 25, 50, 100]}
                                        sort={true}
                                        editable={true}
                                        pagination={true}
                                        fetchAction={contactFetchAction}
                                        serverTotal={contactServerTotal}
                                        isServerSide={true}
                                        pagesCount={contactPagesCount}
                                        fixedHeader={true}
                                    />
                                </div>

                            </Col>
                        </Row>
                    </div>
                </div>
                {props.contactModalVisible &&
                    <AddContact
                        contactModalVisible={props.contactModalVisible}
                        hideAddContactModal={props.hideAddContactModal}
                        setAddContactList={setAddContactList}
                        selectedSupplier={selectedSupplier}
                        insertContactHistoryData={insertContactHistoryData}
                        Status={Status}
                    />
                }

                {popupValues.isModalPopupVisible &&
                    <ModalPopup
                        ShowModalPopupFooter={popupValues.isModalPopupVisible}
                        {...popupValues}
                    >
                        <>
                            {popupValues.children ? popupValues.children : <Explaination test={popupValues.text} setText={popupValues.setText} />}
                        </>
                    </ModalPopup>
                }
            </div>
</>
    )
}

export default CollectionTracking;

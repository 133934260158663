import React from 'react'
import { TextFieldComponent } from '@softura/fluentui-basiccomponents'
import './Input.scss'

interface IProps {
  label?: string,
  placeholder?: string,
  required?: boolean,
  onChange?: any,
  disabled?: boolean,
  readOnly?: boolean,
  icon?: any
  type?: string,
  canRevealPassword?: boolean
  errorMessage?: string,
  multiline?: boolean,
  row?: string | number,
  className?: string | number,
  resizable?: boolean,
  value?: any,
  name?:any,
  onBlur?:any
  onFocus?:any
}
const InputContainer = ({
  label,
  placeholder,
  required,
  onChange,
  disabled,
  readOnly,
  icon,
  type,
  canRevealPassword,
  errorMessage,
  multiline,
  row,
  className,
  resizable,
  value,
  name,
  onBlur,
  onFocus
}:IProps) => {
  return (
    <TextFieldComponent
      className = {`form-input ${className}`}
      label = {label}
      onChange = {onChange}
      required = {required}
      placeholder = {placeholder}
      canRevealPassword = {canRevealPassword}
      errorMessage = {errorMessage}
      disabled = {disabled}
      readOnly = {readOnly}
      icon = {icon}
      type = {type}
      multiline = {multiline}
      row = {row}
      resizable = {resizable}
      value = {value}
      name={name}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  )
}

export default InputContainer

import { put, call, takeEvery, all } from 'redux-saga/effects';  
import api from '../../../../client/utils/clientApiHelper';
import {
    GET_PRODUCTDATA,
    GET_PRODUCTBYID,
    GET_CREATEUPDATEPRODUCT
} from './types'

import * as productEntryActions from './action';

function* getProductData():any {
    try {
        const response = yield call(api.callGet, `Product/GetAllProductsData`);
        if (response) {
            yield put(productEntryActions.setProductData(response));
        } else {
            yield put(productEntryActions.setProductData([]));
        }
    } catch (error) {
        yield put(productEntryActions.setProductData([]));
    }
    
}

function* getProductById(data: any): any {
    try {
        const response = yield call(api.callGet, `Product/GetProductById?productId=${data.id}`);
        yield put(productEntryActions.setProduct(response));
    } catch (error) {
        yield put(productEntryActions.setProduct({}));
    }
}

function* getCreateUpdateProduct(data: any): any {
    try {
        const response = yield call(api.callPost, `Product/CreateUpdateProducts`, data.payload);
        if (response) {
            yield put(productEntryActions.getProductData());
            yield put(productEntryActions.setProductSavedFlag(true))
        }
    } catch (error) {
        yield put(productEntryActions.setErrorProduct("Something went wrong please try after sometime"));
        yield put(productEntryActions.setProduct({}));
    }
}

function* watchGetCommonDashboard() {
    yield takeEvery(GET_PRODUCTDATA, getProductData);
    yield takeEvery(GET_PRODUCTBYID, getProductById);
    yield takeEvery(GET_CREATEUPDATEPRODUCT, getCreateUpdateProduct);
}

export default function* sagas() {
    yield all([
        watchGetCommonDashboard()
    ])
}
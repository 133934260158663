import { isAuthorized } from 'client/utils/Permissions';
import React from 'react';
import {Row,Col,Label,InputField,Checkbox, Alert, SearchSelectBox} from '../../../../CommonComponents';
interface IDropdown {
    key: string,
    text: string
}
interface Iprops {
    productOption: IDropdown[],
    vendorOption: IDropdown[],
    categoryOption: IDropdown[],
    subCategoryOption: IDropdown[],
    manufacturerOption: IDropdown[],
    isSaveClicked?: boolean,
    handleInputChange?: any,
    values?: any,
    errors?: any,
    showSupplierModal:Function,
    showCategoryModal:Function,
    showSubCategoryModal:Function,
    SKUOption?: any;
    handleProduct?: any;
    productSaved?: boolean;
    handleMessage?: Function;
    errorProduct?: any;
    getValue?: any;
    getError?: any;
    isNew?: boolean;
    AddEditSupplierLinkText?: Function
}
const ProductEntryModal = (props:Iprops) => {
    const {
        handleInputChange,
        handleProduct,
        handleMessage,
        errorProduct,
        getValue,
        getError,
        isNew,
        AddEditSupplierLinkText
    } = props;

    const addNew = {
        label: "Add New",
        value: "Add New",
        key: "addNew"
    }
    
    return <><Row>
    <Col xs={12} md={12} className='mb-3'>
        <Alert showAlert={errorProduct} text={'Something went wrong please try after sometime'} type='error' dismissAlert={handleMessage} className="mb-2" />
        <Alert showAlert={props.productSaved} text='Saved Successfully' type='success' dismissAlert={handleMessage} className="mb-2" />
    </Col>            
    <Col xs={12} md={6} className='mb-3'>
        <Label>Product</Label>
        <SearchSelectBox
            placeholder="Select Existing or Add New"
            options={props.productOption}
            filterOptionValue={3}
            onChange={(item: any, e: any) => handleProduct(e, item)}
            value={props.productOption?.find((i: any) => i.key === getValue("productId")) || (isNew ? addNew : "") }
            name='product'
            className="search-dropdown"
            target="modalPopupMasterContainer"
        />
    </Col>
    <Col xs={12} md={6} className='mb-3'>
        <Label required>Name</Label>
        <InputField name='productName' value={getValue("productName")} onChange={handleInputChange} errorMessage={getError("productName")}/>
    </Col>
    <Col xs={12} md={12} className='mb-3'>
        <Label>Description</Label>
        <InputField multiline={true} row="4" value={getValue("description")} onChange={handleInputChange} resizable={false} name='description' errorMessage={getError("description")}/>
    </Col>
    <Col xs={12} md={6} className='mb-3'>
        <div className='clearfix'>
            <Label className="float-left">Vendor</Label>
            { (!props.getValue("productId") ||isAuthorized("Suppliers", "update")) && AddEditSupplierLinkText && <a href="/" onClick={e => {e.preventDefault();props.showSupplierModal("vendor")}} className={"float-right addEdit " + (!isAuthorized("Suppliers", "create") && "disableLink")}>{AddEditSupplierLinkText()}</a>}
        </div>
        <SearchSelectBox
            placeholder="Select"
            options={props.vendorOption}
            filterOptionValue={3}
            onChange={(item: any, e: any) => props.handleInputChange(e, item)}
            value={props.vendorOption?.find((i: any) => i.key === props.getValue("supplierId")) || ""}
            name='supplierId'
            className="search-dropdown"
            target="modalPopupMasterContainer"
        />
    </Col>
    <Col xs={12} md={6} className='mb-3'>
        <div className='clearfix'>
            <Label className="float-left">Categories</Label>
        </div>
        <SearchSelectBox
            placeholder="Select"
            options={props.categoryOption}
            filterOptionValue={3}
            onChange={(item: any, e: any) => handleInputChange(e, item)}
            value={props.categoryOption?.find((i: any) => i.key === getValue("categoryId")) || ""}
            name='categoryId'
            className="search-dropdown"
            target="modalPopupMasterContainer"
        />
    </Col>
    <Col xs={12} md={6} className='mb-3'>
        <div className='clearfix'>
            <Label className="float-left">Subcategories</Label>
        </div>
        <SearchSelectBox
            placeholder="Select"
            options={props.subCategoryOption}
            filterOptionValue={3}
            onChange={(item: any, e: any) => handleInputChange(e, item)}
            value={props.subCategoryOption?.find((i: any) => i.key === getValue("subCategoryId")) || ""}
            name='subCategoryId'
            className="search-dropdown"
            target="modalPopupMasterContainer"
        />
    </Col>
    <Col xs={12} md={6} className='mb-3'>
        <Label required>SKU</Label>
        <InputField placeholder='' value ={getValue("sku")} onChange={handleInputChange} name='sku' errorMessage={getError("sku")}/>
    </Col>
    <Col xs={12} md={6} className='mb-3'>
        <div className='clearfix'>
            <Label className="float-left">Manufacturer</Label>
            {(!getValue("productId") || isAuthorized("Suppliers", "update")) && AddEditSupplierLinkText &&<a href="/" onClick={e => {e.preventDefault();props.showSupplierModal("manufacturer")}} className={"float-right addEdit " + (!isAuthorized("Suppliers", "create") && "disableLink")}>{AddEditSupplierLinkText()}</a>}
        </div>
        <SearchSelectBox
            placeholder="Select"
            options={props.manufacturerOption}
            filterOptionValue={3}
            onChange={(item: any, e: any) => handleInputChange(e, item)}
            value={props.manufacturerOption?.find((i: any) => i.key === getValue("manufacturerId")) || ""}
            name='manufacturerId'
            className="search-dropdown"
            target="modalPopupMasterContainer"
        />
    </Col>
    <Col xs={12} md={6} className='mb-3'>
        <Label required={getValue("manufacturerId")}>Manufacturer SKU</Label>
        <InputField name='mfgSku' value={getValue("mfgSku")} onChange={handleInputChange} errorMessage={getError("mfgSku")}/>
    </Col>
    <Col xs={12} md={6}>
       <Checkbox checked={getValue("agency")} name='agency' label='Agency Product' onChange={handleInputChange}/>
    </Col>
    <Col xs={12} md={12}>
       <Checkbox checked={getValue("isActive")} label='Active' name='isActive' onChange={handleInputChange} disabled={!isAuthorized("Products", "delete")}/>
    </Col>
</Row>
</>
}

export default ProductEntryModal;
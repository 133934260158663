import React from 'react'
import { Callout } from '@softura/fluentui-basiccomponents'
interface IProps {
    children?:any,
    toggleIsCalloutVisible:Function,
    targetId?: any,
    className?:string,
    DirectionalHint?: any
    onDismiss?: any
}
const CalloutContainer = ({
    children = null,
    toggleIsCalloutVisible,
    targetId,
    className,
    DirectionalHint,
    onDismiss
  }:IProps) => {
    return <Callout targetID={targetId} toggleIsCalloutVisible={toggleIsCalloutVisible} className={className} directionalHint={DirectionalHint} onDismiss={onDismiss}>
    {children}
    </Callout>
  }

export default CalloutContainer
import {
    put,
    call,
    takeEvery,
    all
  } from 'redux-saga/effects';  
import api from '../../../client/utils/clientApiHelper' 
import {
    GET_COMMON_DASHBOARD,
    ADD_APP_FAV,
    REMOVE_APP_FAV,
    GET_NEWS_AND_ANNOUNCEMENT
} from './types'

import * as commonDashboardActions from './actions';

function* getCommonDashboard(req:any):any {
    try {
        const {user} = req.user;
        const response = yield call(api.callGet, `applications/${user}`);
            yield put(commonDashboardActions.setCommonDashboardApps(response));
    } catch (error) {
        yield put(commonDashboardActions.setCommonDashboardApps([]));
    }
    
}

function* addFavoriteApp(req:any):any {
    try{
        const response = yield call(api.callPost, 'applications/favourites', req.favApp);
        if(response === true){
            yield put(commonDashboardActions.setAddFavApp(response));
        }
    }
    catch(error){

    }
}

function* removeFavoriteApp(req:any):any {
    try{
        yield put(commonDashboardActions.setLoading(true));
        const response = yield call(api.callPut, 'applications/favourites', req.favApp);
        if(response){
            yield put(commonDashboardActions.setAddFavApp(response));
        }
        yield put(commonDashboardActions.setLoading(false));
    }
    catch(error){
        yield put(commonDashboardActions.setLoading(false));
    }
}
function* getNewsAndAnnouncementDetails(req:any):any {
    try {
        const response = yield call(api.callPost, `news/search`, req.reqBody); 
        yield put(commonDashboardActions.setNewsAndAnnouncementDetails(response));
    } catch (error) {
        yield put(commonDashboardActions.setNewsAndAnnouncementDetails([]));
} 
}

function* watchGetCommonDashboard() {
    yield takeEvery(GET_COMMON_DASHBOARD, getCommonDashboard);
    yield takeEvery(ADD_APP_FAV, addFavoriteApp);
    yield takeEvery(REMOVE_APP_FAV, removeFavoriteApp);
    yield takeEvery(GET_NEWS_AND_ANNOUNCEMENT,getNewsAndAnnouncementDetails)
}

export default function* saga() {
    yield all([
        watchGetCommonDashboard()
    ])
}
import { put, call, takeEvery, all } from 'redux-saga/effects';  
import api from '../../../../client/utils/clientApiHelper';
import {
    GET_SUBCATEGORYDATA,
    GET_SUBCATEGORYBYID,
    GET_CREATEUPDATESUBCATEGORY
} from './types'

import * as subCategoryEntryActions from './action';

function* getSubCategoryData():any {
    try {
        const response = yield call(api.callGet, `ProductSubCatogories/GetAllSubCategoriesData`);
        if (response) {
            yield put(subCategoryEntryActions.setSubCategoryData(response));
        } else {
            yield put(subCategoryEntryActions.setSubCategoryData([]));
        }
    } catch (error) {
        yield put(subCategoryEntryActions.setSubCategoryData([]));
    }
    
}

function* getSubCategoryById(data: any): any {
    try {
        const response = yield call(api.callGet, `ProductSubCatogories/GetSubCategoriesById?SubCategoryId=${data.id}`);
        yield put(subCategoryEntryActions.setSubCategory(response));
    } catch (error) {
        yield put(subCategoryEntryActions.setSubCategory({}));
    }
}

function* getCreateUpdateSubCategory(data: any): any {
    try {
        const response = yield call(api.callPost, `ProductSubCatogories/CreateUpdateSubCategories`, data.payload);
        if (response) {
            yield put(subCategoryEntryActions.setSubCategorySavedFlag(true))
            yield put(subCategoryEntryActions.getSubCategoryData());
        }
    } catch (error) {
        yield put(subCategoryEntryActions.setSubCategory({}));
        yield put(subCategoryEntryActions.setErrorSubCategory("Something went wrong please try after sometime"));
    }
}

function* watchGetCommonDashboard() {
    yield takeEvery(GET_SUBCATEGORYDATA, getSubCategoryData);
    yield takeEvery(GET_SUBCATEGORYBYID, getSubCategoryById);
    yield takeEvery(GET_CREATEUPDATESUBCATEGORY, getCreateUpdateSubCategory);
}

export default function* sagas() {
    yield all([
        watchGetCommonDashboard()
    ])
}
import React from 'react'
import './Badge.scss'

interface IProps {
  children?: any,
  className?: any
}

const Badge = ({
  children = null,
  className = ''
}) => {
  return <div className = {`badgebox ${className}`}>{children}</div>
}

export default Badge

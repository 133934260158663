import { LogLevel } from "@azure/msal-browser";

export function getclientId()
{
    let clientId : any;
    clientId = process.env.REACT_APP_AZURE_CLIENT_ID
    return clientId;
}

export function getAuthority()
{
    let authority : any;
    authority = process.env.REACT_APP_AZURE_AUTHORITY
    return authority;
}

export function getLoginScopes()
{
    let scopes : any;
    scopes = process.env.REACT_APP_AZURE_LOGIN_SCOPE
    return scopes;
}


export function getRedirectUri()
{
    let redirectUri : any;
    redirectUri = process.env.REACT_APP_AZURE_REDIRECT_URL
    return redirectUri;
}

export function getTenantId()
{
    let tenantId : any;
    tenantId = process.env.REACT_APP_AZURE_TENANT_ID
    return tenantId;
}

export function getGraphUri()
{
    let tenantId : any;
    tenantId = process.env.REACT_APP_AZURE_GRAPH_URL
    return tenantId;
}

export const msalConfig = {
    auth: {
       clientId: getclientId(),
       authority: `${getAuthority()}/${getTenantId()}`,
       redirectUri: getRedirectUri()
    },
    cache: {
        storeAuthStateInCookie: false, 
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level: any, message: any, containsPii: any) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                        console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;		
                    default :
                        console.warn("");		
                        return;	
                }	
            }	
        }	
    }
};


export const loginRequest = {
    scopes: [getLoginScopes()]
};


export const graphConfig = {
    graphMeEndpoint: getGraphUri()
};
import { SET_BULKUPLOADERROR, SET_BULKUPLOADFLAG } from "./types";

const initialState = {
  bulkUploadSuccess: false,
  bulkUploadFailed: "",
};

const reducers = (state = initialState, action: any = {}) => {
  switch (action.type) {
    case SET_BULKUPLOADERROR:
      return {
        ...state,
        bulkUploadFailed: action.payload
      };

    case SET_BULKUPLOADFLAG:
      return {
        ...state,
        bulkUploadSuccess: action.payload
      };
    default:
      return state;
  }
};

export default reducers;

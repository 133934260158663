import React, {useState} from 'react';
import AddEditContract from "./AddEditContract";

interface Iprops {
    handleAddEditContractInputChange:Function,
    onAddContract:React.MouseEventHandler<HTMLDivElement>,
    addEditContractList:any,
    addContractText:string,
    onDeleteContract:Function,
    onSearchContract:any,
    submitAddEditContactClick:any,
    programSaved:boolean,
    programDeleted?: boolean,
    handleMessage:Function;
    programError?: any;
}

const AddEditContractContainer = (props:Iprops) => {
    const [selectedContract, setSelectedContract] = useState({});
    const handleEditContract = (e: any) => {
        setSelectedContract({
            ...selectedContract,
            label: e.target.value
        })
    }
    return <AddEditContract
        handleAddEditContractInputChange={props.handleAddEditContractInputChange}
        onAddContract={props.onAddContract}
        addEditContractList={props.addEditContractList}
        addContractText={props.addContractText}
        onDeleteContract={props.onDeleteContract}
        onSearchContract={props.onSearchContract}
        onEditClick={(i: any) => {setSelectedContract(i); props.handleMessage()}}
        onEditSave={() => {props.submitAddEditContactClick(selectedContract); setSelectedContract({})}}
        programSaved={props.programSaved}
        programDeleted={props.programDeleted}
        handleMessage={props.handleMessage}
        selectedContract={selectedContract}
        handleEditContract={handleEditContract}
        programError={props.programError}
    />
}

export default AddEditContractContainer;
import * as R from 'ramda';

const getContactHistoryGridData = (state:any) => R.pathOr('', ['CollectionTracking', 'contactHistoryGridData'], state);
const getInsertContactHistoryData = (state:any) => R.pathOr('', ['CollectionTracking', 'insertContactHistoryData'], state);
const getDeleteContactHistoryById = (state:any) => R.pathOr('', ['CollectionTracking', 'deleteContactHistoryById'], state);
const getPaymentHistoryGridData = (state:any) => R.pathOr('', ['CollectionTracking', 'paymentHistoryGridData'], state);
const getPaidExpectedGridData = (state:any) => R.pathOr('', ['CollectionTracking', 'paidExpectedGridData'], state);
const getSupplierDropdownData = (state:any) => R.pathOr('', ['CollectionTracking', 'supplierDropdownData'], state);
const getContractDropdownData = (state:any) => R.pathOr('', ['CollectionTracking', 'contractDropdownData'], state);
const getYearDropdownData = (state:any) => R.pathOr('', ['CollectionTracking', 'yearDropdownData'], state);
const getSavePaymentCompletion = (state:any) => R.pathOr('', ['CollectionTracking', 'savePaymentCompletion'], state);
const getCollectionsHistory = (state:any) => R.pathOr('', ['CollectionTracking', 'collectionHistoryLoader'], state);

const selectors = {
  getContactHistoryGridData,
  getInsertContactHistoryData,
  getDeleteContactHistoryById,
  getPaymentHistoryGridData,
  getPaidExpectedGridData,
  getSupplierDropdownData,
  getContractDropdownData,
  getYearDropdownData,
  getSavePaymentCompletion,
  getCollectionsHistory
}

export default selectors

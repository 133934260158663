import {
  put,
  call,
  takeEvery,
  all
} from 'redux-saga/effects';
import api from '../../../client/utils/clientApiHelper' 

import * as userProfileActions from './actions';

import {
  GET_COUNTRY_DROPDOWN,
  GET_BRANCH_VALUES,
  INSERT_USER_DETAIL,
  GET_TITLE_DETAILS,
  GET_DEPARTMENT_DETAILS,
  GET_USER_DETAIL,
  UPDATE_USER_DETAILS,
  GET_USER_ROLES_CLAIMS,
} from './types'


function* getCountryDropdownSaga(_req:any):any {
  try {
      const response = yield call(api.callGet, `masterdata/dropdown/countries`);
      const defaultCountryDropdownOption = {
          "text": "Select",
          "value": null
        }
        response.unshift(defaultCountryDropdownOption); 
      yield put(userProfileActions.setCountryDropdown(response));
  } catch (error) {
      yield put(userProfileActions.setCountryDropdown([]));
} 
}

function* getTitleDetails(_req:any):any {
  try {
      const response = yield call(api.callGet, `masterdata/dropdown/titles`); 
      yield put(userProfileActions.setTitleDetails(response));
  } catch (error) {
      yield put(userProfileActions.setTitleDetails([]));
} 
}

function* getDepartmentDetails(_req:any):any {
  try {
      const response = yield call(api.callGet, `masterdata/dropdown/departments`); 
      yield put(userProfileActions.setDepartmentDetails(response));
  } catch (error) {
      yield put(userProfileActions.setDepartmentDetails([]));
} 
}

function* getBranchValues():any {
  try {
      const response = yield call(api.callGet, `masterdata/dropdown/branches`);
      yield put(userProfileActions.setBranchValues(response));
  } catch (error) {
      yield put(userProfileActions.setBranchValues([]));
  }

}

function* saveUserDetail(req:any):any {
  try{
     const response = yield call(api.callPost,'users', req.value);    
      if(response === true){
         yield put(userProfileActions.setResultMessage('User is successfully added'));         
      }
  }
  catch(error){
      yield put(userProfileActions.setResultMessage('Email Id already existing'));
  }
} 

function* getUserDetails(_req:any):any {
  try {
      // const response = yield call(api.callGet, `users/${user}`); 
      // yield put(userProfileActions.setUserDetails(response));
  } catch (error) {
      yield put(userProfileActions.setUserDetails([]));
} 
}

function* updateUserDetailsSaga(req:any):any {
  try {
      const response = yield call(api.callPut, 'users', req.value,req.id); 
      if(response === true){
          yield put(userProfileActions.setResultMessage('User is successfully updated'));         
       }
  } catch (error) {
      yield put(userProfileActions.setResultMessage('Error occured while updating user'));         

} 
}

function* getUserRolesAndClaims(req:any):any {
  try {      
      const response = yield call(api.callGet, `UserProfile/userrolepermissions?userEmailId=${req.userId}`);      
      if (response) {          
          yield put(userProfileActions.setUserRolesAndClaims(response));
      } else {
          yield put(userProfileActions.setUserRolesAndClaims([]));
      }
  } catch (error) {
      yield put(userProfileActions.setUserRolesAndClaims(error));
} 
}

function* watchGetUserProfileSaga() {
  yield takeEvery(INSERT_USER_DETAIL, saveUserDetail);
  yield takeEvery(GET_COUNTRY_DROPDOWN, getCountryDropdownSaga);
  yield takeEvery(GET_BRANCH_VALUES,getBranchValues);
  yield takeEvery(GET_TITLE_DETAILS, getTitleDetails);
  yield takeEvery(GET_DEPARTMENT_DETAILS, getDepartmentDetails);
  yield takeEvery(GET_USER_DETAIL, getUserDetails);
  yield takeEvery(GET_BRANCH_VALUES, getBranchValues);
  yield takeEvery(UPDATE_USER_DETAILS, updateUserDetailsSaga)
  yield takeEvery(GET_USER_ROLES_CLAIMS, getUserRolesAndClaims)

}

export default function* sagas() {
  yield all([
      watchGetUserProfileSaga()
  ])
}
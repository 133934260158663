import { put, call, takeEvery, all } from 'redux-saga/effects';  
import api from '../../../../client/utils/clientApiHelper';
import {
    GET_CALCULATION_FREQUENCY,
    GET_CALCULATION_METHOD,
    GET_CALCULATION_TYPE,
    POST_REBATE_ADD,
    GET_REBATE_DELETE,
    GET_REBATE_PROGRAM,
    GET_REBATE_PROGRAM_BY_ID,
    GET_CREATEUPDATE_REBATE_PROGRAM,
    POST_REBATE_EDIT,
    GET_REBATEPERIOD,
    GET_REBATEPAYMENTMETHOD,
    GET_ALL_CONTRACT,
    GET_ADD_CONTRACT_NAME,
    GET_UPDATE_CONTRACT_NAME,
    GET_DELETE_CONTRACT_NAME,
    GET_CONTRACT_DETAILS_BY_ID,
    GET_ALL_CONTRACT_LIST,
    GET_PROGRAM_BY_VENDOR_ID,
    GET_CONTRCT_BY_PROGRAM_ID,
    GET_ALL_REBATE_TYPES,
    GET_REBATE_PROGRAM_FIELD_INFO,
    GET_CONTRACT_VALIDATION_FLAG
} from './types'

import * as RebateProgramEntryAction from './action';

function* getCalculationFrequency():any {
    try {
        const response = yield call(api.callGet, `RebateProgramData/GetCalculationFrequency`);
        yield put(RebateProgramEntryAction.setCalculationFrequency(response));
    } catch (error) {
        yield put(RebateProgramEntryAction.setCalculationFrequency([]));
    }
    
}

function* getCalculationMethod():any {
    try {
        const response = yield call(api.callGet, `RebateProgramData/GetRebateCalculationMethod`);
        yield put(RebateProgramEntryAction.setCalculationMethod(response));
    } catch (error) {
        yield put(RebateProgramEntryAction.setCalculationMethod([]));
    }
    
}

function* getCalculationType():any {
    try {
        const response = yield call(api.callGet, `RebateProgramData/GetRebateCalculationType`);
        yield put(RebateProgramEntryAction.setCalculationType(response));
    } catch (error) {
        yield put(RebateProgramEntryAction.setCalculationType([]));
    }
    
}

function* getRebateProgram():any {
    try {
        const response = yield call(api.callGet, `RebateProgramData/GetAllRebateProgramsList`);
        if (response) {
            yield put(RebateProgramEntryAction.setRebateProgram(response));
        } else {
            yield put(RebateProgramEntryAction.setRebateProgram([]));
        }
    } catch (error) {
        yield put(RebateProgramEntryAction.setRebateProgram([]));
    }
    
}

function* getRebateProgramById(req:any):any {
    try {
        const response = yield call(api.callGet, `RebateProgramData/GetRebateProgramsById?rebateProgramsId=${req.payload}`);
        yield put(RebateProgramEntryAction.setRebateProgramById(response));
    } catch (error) {
        yield put(RebateProgramEntryAction.setRebateProgramById([]));
    }
}

function* getRebateProgramDelete(req:any):any {
    try {
        const response = yield call(api.callPost, `RebateProgramData/DeleteRebatePrograms?programId=${req.payload}&inActive=false`);
        if (response) {
            yield put(RebateProgramEntryAction.setRebateDeleteProgram(response));
            yield put(RebateProgramEntryAction.getProgramByVendorId(req.vendorID));
        } else {
            yield put(RebateProgramEntryAction.setProgramError("Something went wrong please try after sometime"));
        }
    } catch (error) {
        yield put(RebateProgramEntryAction.setRebateDeleteProgram(false));
        yield put(RebateProgramEntryAction.setProgramError("Something went wrong please try after sometime"));
    }
}

function* postRebateProgramAdd(req:any):any {
    try {
        const response = yield call(api.callPost, `RebateProgramData/AddRebateProgramName`, req.payload);
        if (response) {
            yield put(RebateProgramEntryAction.setRebateAdd(response));
            yield put(RebateProgramEntryAction.getProgramByVendorId(req.vendorID))
        } else {
            yield put(RebateProgramEntryAction.setProgramError("Program with same name already exists"));
        }
    } catch (error) {
        yield put(RebateProgramEntryAction.setRebateAdd(false));
        yield put(RebateProgramEntryAction.setProgramError("Program with same name already exists"));
    }
}

function* postRebateProgramEdit(req:any):any {
    try {
        const response = yield call(api.callPost, `RebateProgramData/UpdateRebateProgramName`, req.payload);
        if (response) {
            yield put(RebateProgramEntryAction.setRebateAdd(response));
            yield put(RebateProgramEntryAction.getProgramByVendorId(req.payload.supplierId))
        }else {
            yield put(RebateProgramEntryAction.setProgramError("Program with same name already exists"));
        }
    } catch (error) {
        yield put(RebateProgramEntryAction.setRebateEdit(false));
        yield put(RebateProgramEntryAction.setProgramError("Program with same name already exists"));
    }
}
function* getCreateUpdateRebateProgram(req: any): any {
    try {
        yield put(RebateProgramEntryAction.setContractByIdLoading(true))
        const res = yield call(api.callPost, 'ContractDetails/UpdateContractDetails', req.payload);
        if (res) {
            yield put(RebateProgramEntryAction.setRebateEdit(res));
            yield put(RebateProgramEntryAction.getRebateProgram());
            yield put(RebateProgramEntryAction.getAllContract());
            yield put(RebateProgramEntryAction.getAllContractList());
            yield put(RebateProgramEntryAction.setContractByIdLoading(false))
        } else {
            yield put(RebateProgramEntryAction.setProgramError("Something went wrong please try after sometime"));
            yield put(RebateProgramEntryAction.setContractByIdLoading(false))
        }
    } catch(error) {
        yield put(RebateProgramEntryAction.setRebateEdit(false));
        yield put(RebateProgramEntryAction.setProgramError("Something went wrong please try after sometime"));
        yield put(RebateProgramEntryAction.setContractByIdLoading(false))
        console.log(error);
    }
}

function* getRebatePeriod():any {
    try {
        const response = yield call(api.callGet, `RebateProgramData/dropdown/RebatepPeriod`);
        yield put(RebateProgramEntryAction.setRebatePeriod(response));
    } catch (error) {
        yield put(RebateProgramEntryAction.setRebatePeriod([]));
    } 
}

function* getRebatePayment():any {
    try {
        const response = yield call(api.callGet, `RebateProgramData/dropdown/RebatepPaymentMethod`);
        yield put(RebateProgramEntryAction.setRebatePaymentMethod(response));
    } catch (error) {
        yield put(RebateProgramEntryAction.setRebatePaymentMethod([]));
    } 
}

function* getAllContract(): any {
    try {
        const response = yield call(api.callGet, `ContractDetails/GetAllContractDetails`);
        if (response) {
            yield put(RebateProgramEntryAction.setAllContract(response));
        } else {
            yield put(RebateProgramEntryAction.setAllContract([]));
        }
    } catch (error) {
        yield put(RebateProgramEntryAction.setAllContract([]));
    }
}

function* getAddContractName(req:any):any {
    try {
        const response = yield call(api.callPost, `ContractDetails/AddContractDetailName`, req.payload);
        if (response) {
            yield put(RebateProgramEntryAction.setRebateAdd(response));
            yield put(RebateProgramEntryAction.getContractByProgramId(req.programID))
        } else {
            yield put(RebateProgramEntryAction.setProgramError("Contract with same name already exists"));
        }
    } catch (error) {
        yield put(RebateProgramEntryAction.setRebateAdd(false));
        yield put(RebateProgramEntryAction.setProgramError("Contract with same name already exists"));
    }
}

function* getUpdateContractName(req:any):any {
    try {
        const response = yield call(api.callPost, `ContractDetails/UpdateContractDetailName`,req.payload);
        if (response) {
            yield put(RebateProgramEntryAction.setRebateAdd(response));
            yield put(RebateProgramEntryAction.getContractByProgramId(req.payload.programId))
        } else {
            yield put(RebateProgramEntryAction.setProgramError("Contract with same name already exists"));
        }
    } catch (error) {
        yield put(RebateProgramEntryAction.setRebateEdit(false));
        yield put(RebateProgramEntryAction.setProgramError("Contract with same name already exists"));
    }
}

function* getDeleteContractName(req:any):any {
    try {
        const response = yield call(api.callPost, `ContractDetails/DeleteContractName?programId=${req.payload}&inActive=false`);
        if (response) {
            yield put(RebateProgramEntryAction.setRebateDeleteProgram(response));
            yield put(RebateProgramEntryAction.getContractByProgramId(req.programID));
        } else {
            yield put(RebateProgramEntryAction.setProgramError("Something went wrong please try after sometime"));
        }
    } catch (error) {
        yield put(RebateProgramEntryAction.setRebateDeleteProgram(false));
        yield put(RebateProgramEntryAction.setProgramError("Something went wrong please try after sometime"));
    }
}

function* getContractById(req:any):any {
    try {
        yield put(RebateProgramEntryAction.setContractByIdLoading(true))
        const response = yield call(api.callGet, `ContractDetails/GetContractDetailsId?contractDetailId=${req.payload}`);
        if(response){
            yield put(RebateProgramEntryAction.setContractDetailsById(response));
            yield put(RebateProgramEntryAction.setContractByIdLoading(false));
        }
    } catch (error) {
        yield put(RebateProgramEntryAction.setContractDetailsById({}));
        yield put(RebateProgramEntryAction.setContractByIdLoading(false))
    }
}

function* getAllContractList(): any {
    try {
        const response = yield call(api.callGet, `ContractDetails/GetAllContractsList`);
        if (response) {
            yield put(RebateProgramEntryAction.setAllContractList(response));
        } else {
            yield put(RebateProgramEntryAction.setAllContractList([]));
        }
    } catch (error) {
        yield put(RebateProgramEntryAction.setAllContractList([]));
    }
}

function* getProgramByVendorId(req: any):any {
    try {
        const response = yield call(api.callGet, `ContractDetails/GetAllRebateProgramsListByVendorId?supplierId=${req.payload}`);
        if (response) {
            yield put(RebateProgramEntryAction.setRebateProgram(response));
        } else {
            yield put(RebateProgramEntryAction.setRebateProgram([]));
        }
    } catch (error) {
        yield put(RebateProgramEntryAction.setRebateProgram([]));
    }
    
}

function* getContractByProgramId(req: any): any {
    try {
        const response = yield call(api.callGet, `ContractDetails/GetAllContractsByProgramId?programId=${req.payload}`);
        if (response) {
            yield put(RebateProgramEntryAction.setAllContract(response));
        } else {
            yield put(RebateProgramEntryAction.setAllContract([]));
        }
    } catch (error) {
        yield put(RebateProgramEntryAction.setAllContract([]));
    }
}

function* getAllRebateTypes():any {
    try {
        const response = yield call(api.callGet, `RebateProgramData/GetAllRebateTypesList`);
        if (response) {
            yield put(RebateProgramEntryAction.setAllRebateTypes(response));
        } else {
            yield put(RebateProgramEntryAction.setAllRebateTypes([]));
        }
    } catch (error) {
        yield put(RebateProgramEntryAction.setAllRebateTypes([]));
    }
    
}

function* getRebateProgramFieldsInfo():any {
    try{
        const response = yield call(api.callGet, `LookupData/GetFields`);
        if(response){
            yield put(RebateProgramEntryAction.setRebateProgramFieldInfo(response));
        } else {
            yield put(RebateProgramEntryAction.setRebateProgramFieldInfo([]));
        }
    } catch (error) {
        yield put(RebateProgramEntryAction.setRebateProgramFieldInfo([]));
    }
}

function* getContractValidationFlag(req:any):any {
    try {
        const response = yield call(api.callPost, `ContractDetails/ValidateContractDetails`,req.payload);
        if (response) {
            yield put(RebateProgramEntryAction.setContractValidationFlag(response));
        }else {
            yield put(RebateProgramEntryAction.setContractValidationFlag(false));
        }
    } catch (error) {
        yield put(RebateProgramEntryAction.setContractValidationFlag(false));
    }
}

function* watchAllSaga() {
    yield takeEvery(GET_CALCULATION_FREQUENCY, getCalculationFrequency);
    yield takeEvery(GET_CALCULATION_METHOD, getCalculationMethod);
    yield takeEvery(GET_CALCULATION_TYPE, getCalculationType);
    yield takeEvery(GET_REBATE_PROGRAM, getRebateProgram);
    yield takeEvery(GET_REBATE_PROGRAM_BY_ID, getRebateProgramById);
    yield takeEvery(GET_REBATE_DELETE, getRebateProgramDelete);
    yield takeEvery(POST_REBATE_ADD, postRebateProgramAdd);
    yield takeEvery(POST_REBATE_EDIT, postRebateProgramEdit);
    yield takeEvery(GET_REBATEPERIOD, getRebatePeriod);
    yield takeEvery(GET_REBATEPAYMENTMETHOD, getRebatePayment);
    yield takeEvery(GET_ALL_CONTRACT, getAllContract);
    yield takeEvery(GET_ADD_CONTRACT_NAME, getAddContractName);
    yield takeEvery(GET_UPDATE_CONTRACT_NAME, getUpdateContractName);
    yield takeEvery(GET_DELETE_CONTRACT_NAME, getDeleteContractName);
    yield takeEvery(GET_CONTRACT_DETAILS_BY_ID, getContractById);

    yield takeEvery(GET_CREATEUPDATE_REBATE_PROGRAM, getCreateUpdateRebateProgram)

    yield takeEvery(GET_ALL_CONTRACT_LIST, getAllContractList)
    yield takeEvery(GET_PROGRAM_BY_VENDOR_ID, getProgramByVendorId)
    yield takeEvery(GET_CONTRCT_BY_PROGRAM_ID, getContractByProgramId)
    
    yield takeEvery(GET_ALL_REBATE_TYPES, getAllRebateTypes)

    yield takeEvery(GET_REBATE_PROGRAM_FIELD_INFO, getRebateProgramFieldsInfo)
    yield takeEvery(GET_CONTRACT_VALIDATION_FLAG, getContractValidationFlag)
}

export default function* sagas() {
    yield all([
        watchAllSaga()
    ])
}
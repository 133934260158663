import { GET_ADD_SELF_REPORTING_NOTES, GET_CALENDAR_MONTH_DROPDOWN_LIST, GET_EMAIL_REMINDER, GET_EMAIL_TEMPLATE, GET_FILE_COMPLETION_DATA, GET_FILE_STATUS_DROPDOWN_LIST, GET_SELF_REPORTING_EXCEL_DATA, GET_SELF_REPORTING_GRID_DATA, GET_SELF_REPORTING_GRID_HEADER_DATA, GET_SELF_REPORTING_NOTES, GET_STATUS_DROPDOWN_LIST, GET_UPDATE_FILE_STATUS, GET_VALIDATE_EMAIL_REMINDER, GET_VENDOR_DROPDOWN_LIST, GET_VENDOR_FILE_COUNT, GET_VENDOR_HISTORY_DATA, GET_VENDOR_LIST_BY_STATUS, GET_VENDOR_TYPE_DROPDOWN_LIST, SET_ADD_SELF_REPORTING_NOTES, SET_CALENDAR_MONTH_DROPDOWN_LIST, SET_EMAIL_REMINDER, SET_EMAIL_TEMPLATE, SET_FILE_COMPLETION_DATA, SET_FILE_STATUS_DROPDOWN_LIST, SET_SELF_REPORTING_EXCEL_DATA, SET_SELF_REPORTING_GRID_DATA, SET_SELF_REPORTING_GRID_HEADER_DATA, SET_SELF_REPORTING_LOADER, SET_SELF_REPORTING_NOTES, SET_STATUS_DROPDOWN_LIST, SET_UPDATE_FILE_STATUS, SET_VALIDATE_EMAIL_REMINDER, SET_VENDOR_DROPDOWN_LIST, SET_VENDOR_FILE_COUNT, SET_VENDOR_HISTORY_DATA, SET_VENDOR_LIST_BY_STATUS, SET_VENDOR_TYPE_DROPDOWN_LIST } from './types'

export const getSelfReportingGridData = (payload: any) => {
  return {
    type: GET_SELF_REPORTING_GRID_DATA,
    payload
  }
}

export const setSelfReportingGridData = (data: any) => {
  return {
    type: SET_SELF_REPORTING_GRID_DATA,
    data
  }
}

export const getSelfReportingExcelData = (payload: any) => {
  return {
    type: GET_SELF_REPORTING_EXCEL_DATA,
    payload
  }
}

export const setSelfReportingExcelData = (data: any) => {
  return {
    type: SET_SELF_REPORTING_EXCEL_DATA,
    data
  }
}

export const getSelfReportingGridHeaderData = (payload: any) => {
  return {
    type: GET_SELF_REPORTING_GRID_HEADER_DATA,
    payload
  }
}

export const setSelfReportingGridHeaderData = (data: any) => {
  return {
    type: SET_SELF_REPORTING_GRID_HEADER_DATA,
    data
  }
}

export const getVendorFileCount = (payload: any) => {
  return {
    type: GET_VENDOR_FILE_COUNT,
    payload
  }
}

export const setVendorFileCount = (data: any) => {
  return {
    type: SET_VENDOR_FILE_COUNT,
    data
  }
}

export const getVendorHistoryData = (payload: any) => {
  return {
    type: GET_VENDOR_HISTORY_DATA,
    payload
  }
}

export const setVendorHistoryData = (data: any) => {
  return {
    type: SET_VENDOR_HISTORY_DATA,
    data
  }
}

export const getFileStatusDropdownList = (payload: any) => {
  return {
    type: GET_FILE_STATUS_DROPDOWN_LIST,
    payload
  }
}

export const setFileStatusDropdownList = (data: any) => {
  return {
    type: SET_FILE_STATUS_DROPDOWN_LIST,
    data
  }
}

export const getCalendarMonthDropdownList = (payload: any) => {
  return {
    type: GET_CALENDAR_MONTH_DROPDOWN_LIST,
    payload
  }
}

export const setCalendarMonthDropdownList = (data: any) => {
  return {
    type: SET_CALENDAR_MONTH_DROPDOWN_LIST,
    data
  }
}

export const getUpdateFileStatus = (payload: any) => {
  return {
    type: GET_UPDATE_FILE_STATUS,
    payload
  }
}

export const setUpdateFileStatus = (data: any) => {
  return {
    type: SET_UPDATE_FILE_STATUS,
    data
  }
}

export const getVendorTypeDropdownList = () => {
  return {
    type: GET_VENDOR_TYPE_DROPDOWN_LIST
  }
}

export const setVendorTypeDropdownList = (data: any) => {
  return {
    type: SET_VENDOR_TYPE_DROPDOWN_LIST,
    data
  }
}

export const getFileCompletionData = (payload: any) => {
  return {
    type: GET_FILE_COMPLETION_DATA,
    payload
  }
}

export const setFileCompletionData = (data: any) => {
  return {
    type: SET_FILE_COMPLETION_DATA,
    data
  }
}

export const getVendorListByStatus = (payload: any) => {
  return {
    type: GET_VENDOR_LIST_BY_STATUS,
    payload
  }
}

export const setVendorListByStatus = (data: any) => {
  return {
    type: SET_VENDOR_LIST_BY_STATUS,
    data
  }
}

export const getValidateEmailReminder = (payload: any) => {
  return {
    type: GET_VALIDATE_EMAIL_REMINDER,
    payload
  }
}

export const setValidateEmailReminder = (data: any) => {
  return {
    type: SET_VALIDATE_EMAIL_REMINDER,
    data
  }
}

export const getEmailReminder = (payload: any) => {
  return {
    type: GET_EMAIL_REMINDER,
    payload
  }
}

export const setEmailReminder = (data: any) => {
  return {
    type: SET_EMAIL_REMINDER,
    data
  }
}

export const getVendorDropdownList = (payload: any) => {
  return {
    type: GET_VENDOR_DROPDOWN_LIST,
    payload
  }
}

export const setVendorDropdownList = (data: any) => {
  return {
    type: SET_VENDOR_DROPDOWN_LIST,
    data
  }
}

export const getStatusDropdownList = () => {
  return {
    type: GET_STATUS_DROPDOWN_LIST
  }
}

export const setStatusDropdownList = (data: any) => {
  return {
    type: SET_STATUS_DROPDOWN_LIST,
    data
  }
}

export const setSelfReportingLoader = (data: any) => {
  return {
    type: SET_SELF_REPORTING_LOADER,
    data
  }
}

export const getAddSelfReportingNotes = (payload: any) => {
  return {
    type: GET_ADD_SELF_REPORTING_NOTES,
    payload
  }
}

export const setAddSelfReportingNotes = (data: any) => {
  return {
    type: SET_ADD_SELF_REPORTING_NOTES,
    data
  }
}

export const getSelfReportingNotes = (payload: any) => {
  return {
    type: GET_SELF_REPORTING_NOTES,
    payload
  }
}

export const setSelfReportingNotes = (data: any) => {
  return {
    type: SET_SELF_REPORTING_NOTES,
    data
  }
}

export const getEmailTemplate = (payload: any) => {
  return {
    type: GET_EMAIL_TEMPLATE,
    payload
  }
}

export const setEmailTemplate = (data: any) => {
  return {
    type: SET_EMAIL_TEMPLATE,
    data
  }
}

import {
   getCategoryData,
   getCategoryById,
   getCreateUpdateCategory,
   getCategorySavedFlag,
   setCategorySavedFlag,
   setCategory,
   setErrorCategory,
   setCategoryName
} from './action'

  const dispatchGetCategoryData = (dispatch:Function) => () => {
    dispatch(getCategoryData());
  };
  
  const dispatchGetCategoryById = (dispatch:Function) => (id: any) => {
    dispatch(getCategoryById(id));
  };

  const dispatchGetCreateUpdateCategory = (dispatch: Function) => (data: any) => {
    dispatch(getCreateUpdateCategory(data));
  }

  const dispatchGetCategorySavedFlag = (dispatch: Function) => () => {
    dispatch(getCategorySavedFlag());
  }

  const dispatchSetCategorySavedFlag = (dispatch: Function) => (flag: boolean) => {
    dispatch(setCategorySavedFlag(flag));
  }
  
  const dispatchSetCategory = (dispatch: Function) => (data: any) => {
    dispatch(setCategory(data));
  }

  const dispatchSetErrorCategory = (dispatch: Function) => (data: any) => {
    dispatch(setErrorCategory(data));
  }

  const dispatchSetCategoryName = (dispatch: Function) => (data: any) => {
    dispatch(setCategoryName(data));
  }

  const operations = {
    dispatchGetCategoryData,
    dispatchGetCategoryById,
    dispatchGetCreateUpdateCategory,
    dispatchGetCategorySavedFlag,
    dispatchSetCategorySavedFlag,
    dispatchSetCategory,
    dispatchSetErrorCategory,
    dispatchSetCategoryName
}

export default operations
import React, {useState} from 'react'
import './CustomTreeview.scss'
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import CheckboxTree from 'react-checkbox-tree';

interface IProps {
  nodes?: any,
  showNodeIcon?:any,
  checked?:any,
  onCheck?:any,
  showExpandAll?:any
}

const CustomTreeview = ({nodes, showNodeIcon,checked, onCheck,showExpandAll}:IProps) => {
const[expanded, setExpanded] = useState<any>([])
  return (
    <CheckboxTree
      nodes={nodes}
      checked={checked}
      onCheck={onCheck}
      expanded={expanded}
      onExpand={expand =>setExpanded(expand)}
      showNodeIcon={showNodeIcon}
      showExpandAll={showExpandAll}
    />
  )
}

export default CustomTreeview

import React, { useState } from 'react'
import { Breadcrumb, Card, Col, InputField, MultiSelectoption, Multiselect, Callout, PrimaryButton, Row, SearchSelectBox, SwitchToggle, OutlineButton, ModalPopup } from '../../../CommonComponents';
import './VendorFiles.scss';
import RebateIconBlue from '../../../../client/assets/images/selfReporting/RebateIconBlue.svg';
import SpendIconBlue from '../../../../client/assets/images/selfReporting/SpendIconBlue.svg';
import SpendIconGreen from '../../../../client/assets/images/selfReporting/SpendIconGreen.svg';
import LinkIcon from '../../../../client/assets/images/selfReporting/LinkIcon.svg';

import ActionMenu from '../../../../client/assets/images/selfReporting/ActionMenu.svg';
import ArrowActiveLeft from '../../../../client/assets/images/selfReporting/ArrowActiveLeft.svg';
import ArrowActiveright from '../../../../client/assets/images/selfReporting/ArrowActiveright.svg';
import CalenderIcon from '../../../../client/assets/images/selfReporting/CalenderIcon.svg';
import FileNotReceived from '../FileNotReceived';

let currentTime = new Date();
interface Iprops {
  currentMonth?: any,
  handlePrevClick?: any,
  handleNextClick?: any,
  months?: any,
  toggleIsCalloutVisible?: any,
  yearDiv?: any,
  currentYearDiv?: any,
  handlePrevYearClick?: any,
  isCalloutVisible?: any,
  handleNextYearClick?: any,
  selectedYear?: any,
  handleButtonClick?: any,
  handleCalloutClose?: any,
  showFileStatusPopup?: any,
  openFileStatusPopup?: any,
  handleClose?: any
  vendorFileCount?: any
  fileCompletionData?: any
  vendorListByStatus?: any
  selectedStatus?: any
  onClickSendReminder?: any,
  toggleMonthSelectVisible?: any,
  isCalloutMonthVisible?: any,
  handleMonthClick?: any,
  SelectedMonthDiv?: any,
  setSelectedMonthDiv?: any,
  handleInnerMonthvClick?: any,
  fetchAction?: any
  Pageno?: any
}

const VendorFiles = (props: Iprops) => {
  const {
    handlePrevClick,
    handleNextClick,
    currentMonth,
    months,
    toggleIsCalloutVisible,
    isCalloutVisible,
    yearDiv,
    currentYearDiv,
    handlePrevYearClick,
    handleNextYearClick,
    selectedYear,
    handleButtonClick,
    handleCalloutClose,
    showFileStatusPopup,
    openFileStatusPopup,
    handleClose,
    vendorFileCount,
    fileCompletionData,
    vendorListByStatus,
    selectedStatus,
    onClickSendReminder,
    toggleMonthSelectVisible,
    isCalloutMonthVisible,
    handleMonthClick,
    SelectedMonthDiv,
    setSelectedMonthDiv,
    handleInnerMonthvClick,
    fetchAction,
    Pageno
  } = props;


  const getFileCount = (statusName: any) => {
    const file = vendorFileCount?.find((item: any) => item.statusName == statusName)
    return file?.vendorTotal
  }

  const disabledLeftArrow = () => {
    return parseInt(currentMonth) == 0 ? true : false 
  }

  const disabledRightArrow = () => {
    if (parseInt(selectedYear) == currentTime.getFullYear()) {
      return currentTime.getMonth() < currentMonth + 1;
    } else if (parseInt(selectedYear) > currentTime.getFullYear()) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className='SelfReportingContainer mb-3'>
      <div className='breadCrumContainer'>
        <Row>
          <Col lg={6}>

          </Col>
          <Col lg={6}>
            <div className='calenderContainer'>
              <div className="calenderBox">
                <InputField value={`Calendar Year : ${selectedYear}`} />
                <span className="calenderIcon" onClick={toggleIsCalloutVisible} id='targetID'><img src={CalenderIcon} alt='calender' /></span>
              </div>
              {isCalloutVisible && <Callout targetId='targetID' toggleIsCalloutVisible={toggleIsCalloutVisible} onDismiss={toggleIsCalloutVisible}>
                <div className="YearSelect">
                  {yearDiv[currentYearDiv]}

                  <div className='yearBtnContainer'>
                    <OutlineButton text='Close' onClick={handleCalloutClose} />
                    <PrimaryButton text='Apply' onClick={handleButtonClick} />
                  </div>
                </div>

              </Callout>}
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col lg={12} xl={6} className="mt-3 mt-sm-0">
          <Card>
            <div className='VendorFilesHeader'>
              <Row>
                <Col sm={6}>
                  <h1 className='CardHeading'>Self Reported Vendor Files</h1>
                </Col>
                <Col sm={6}> 
                  <div className='SelectMonth'> 
                    <span onClick={handlePrevClick} className={`arrow ${disabledLeftArrow() ? 'disabledArrow' : ''}`}><img src={ArrowActiveLeft} alt='Arrow' /></span>
                    <p className='heading mb-0' onClick={toggleMonthSelectVisible} id='targetID2'>{months[currentMonth] + " " + selectedYear}</p>
                    {isCalloutMonthVisible && <Callout targetId='targetID2' toggleIsCalloutVisible={toggleMonthSelectVisible} onDismiss={toggleMonthSelectVisible}>
                      <div className="MonthSelect">
                        {months.map((monthsdata: any, index: any)=> {
                            return (
                              <div 
                                  className={`MonthBox ${SelectedMonthDiv === index ? 'monthSelected' : ''} ${parseInt(selectedYear) === currentTime.getFullYear() && index > currentTime.getMonth() ? 'disabledMonth':''}`}
                                  key={index} 
                                  onClick={() => handleInnerMonthvClick(index)}>
                                     {monthsdata.slice(0, 3)}
                              </div>
                            )
                        })}
                        <div className="monthBtnContainer">
                          <OutlineButton text='Close' onClick={handleCalloutClose} />
                          <PrimaryButton text='Apply' onClick={handleMonthClick} />
                        </div>
                      </div>
                    </Callout>}
                    
                    <span onClick={handleNextClick} className={`arrow ${disabledRightArrow() ? 'disabledArrow' : ''} ${currentMonth >= months.length - 1 ? 'disabledArrow': ''}`}><img src={ArrowActiveright} alt='Arrow' /></span>
                  </div>
                </Col>
              </Row>
            </div>
            <div className='VendorFilesSection'>
              <div className='VendorFilesBlock NotReceivedContainer' onClick={() => openFileStatusPopup('Not Received')}>
                <div className='IndicationContainer'>
                  <div className='box notReceivedIndicator'></div><p className='notReceivedtxt ms-2'>Not Received</p>
                </div>
                <div className='TextContainer'>
                  <p>{getFileCount('Not Received')}</p>
                  <img src={LinkIcon} alt='link' />
                </div>
              </div>

              <div className='VendorFilesBlock' onClick={() => openFileStatusPopup('Received')}>
                <div className='IndicationContainer'>
                  <div className='box ReceivedIndicator'></div><p className='Receivedtxt ms-2'>Received</p>
                </div>
                <div className='TextContainer'>
                  <p>{getFileCount('Received')}</p>
                  <img src={LinkIcon} alt='link' />
                </div>
              </div>

              <div className='VendorFilesBlock' onClick={() => openFileStatusPopup('Prepped')}>
                <div className='IndicationContainer'>
                  <div className='box PreppedIndicator'></div><p className='Preppedtxt ms-2'>Prepped</p>
                </div>
                <div className='TextContainer'>
                  <p>{getFileCount('Prepped')}</p>
                  <img src={LinkIcon} alt='link' />
                </div>
              </div>

              <div className='VendorFilesBlock' onClick={() => openFileStatusPopup('Processed')}>
                <div className='IndicationContainer'>
                  <div className='box ProcessedIndicator'></div><p className='Processedtxt ms-2'>Processed</p>
                </div>
                <div className='TextContainer'>
                  <p>{getFileCount('Processed')}</p>
                  <img src={LinkIcon} alt='link' />
                </div>
              </div>

              <div className='VendorFilesBlock' onClick={() => openFileStatusPopup('No Spend')}>
                <div className='IndicationContainer'>
                  <div className='box NoSpendIndicator'></div><p className='NoSpendtxt ms-2'>No Spend</p>
                </div>
                <div className='TextContainer'>
                  <p>{getFileCount('No Spend')}</p>
                  <img src={LinkIcon} alt='link' />
                </div>
              </div>

              <div className='VendorFilesBlock' onClick={() => openFileStatusPopup('File Error')}>
                <div className='IndicationContainer'>
                  <div className='box FileErrorIndicator'></div><p className='FileErrortxt ms-2'>File Error</p>
                </div>
                <div className='TextContainer'>
                  <p>{getFileCount('File Error')}</p>
                  <img src={LinkIcon} alt='link' />
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={12} xl={6} className="mt-lg-3 mt-xl-0">
          <Card>
            <h2 className='CardHeading'>File Completion &#37; </h2>
            <ul className="fileCompletionTable mt-2">
              {fileCompletionData?.map((item: any) => {
                return (
                  <li>
                    <p>{item.month}</p>
                    <p>{item.completionPercentage}%</p>
                  </li>
                )
              })}
            </ul>
          </Card>
        </Col>
      </Row>

      {showFileStatusPopup && (
        <ModalPopup
          ShowModalPopupFooter={true}
          ModalPopupTitle={selectedStatus?.title}
          ModalPopupType="medium"
          closeModalPopup={handleClose}
          SecondryBtnOnclick={handleClose}
        >
          <FileNotReceived vendorListByStatus={vendorListByStatus} selectedStatus={selectedStatus} onClickSendReminder={onClickSendReminder} fetchAction={fetchAction} Pageno={Pageno} />
        </ModalPopup>
      )}

    </div>
  )
}

export default VendorFiles
import React, {useEffect, useState} from "react";
import ProductCategoryEntry from "./ProductCategoryEntry";
import { useNavigate } from "react-router-dom";
import categoryEntrySelector from '../../../../state/features/RebateManagement/ProductCategoryEntry/selectors';
import categoryEntryOperations from '../../../../state/features/RebateManagement/ProductCategoryEntry/operations';
import subCategoryEntrySelector from '../../../../state/features/RebateManagement/ProductSubCategoryEntry/selectors';
import subCategoryEntryOperations from '../../../../state/features/RebateManagement/ProductSubCategoryEntry/operations';
import userSelector from '../../../../state/features/UserProfile/selectors';
import { useSelector, useDispatch } from "react-redux";
import { isAuthorized } from "client/utils/Permissions";
import ErrorHandling from "../../../CommonComponents/ErrorHandling/ErrorHandling"

interface Iprops {
    showProductCategory?: boolean,
    hideProductCategoryModal?:Function
}
const ProductCategoryEntryContainer = (props:Iprops) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [values, setValues] = useState<any>({
        categoryName: "",
        description: "",
        isActive: true
    });
    const [isSaveClicked, setSaveClicked] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([
        {key: "first", value: "first", text: "First"},
        {key: "second", value: "second", text: "Second"},
        {key: "third", value: "third", text: "Third"},
        {key: "fourth", value: "fourth", text: "Fourth"},
    ]);
    const dispatchGetCategoryData = categoryEntryOperations.dispatchGetCategoryData(dispatch);
    const dispatchGetCategoryById = categoryEntryOperations.dispatchGetCategoryById(dispatch);
    const dispatchGetCreateUpdateCategory = categoryEntryOperations.dispatchGetCreateUpdateCategory(dispatch);
    const dispatchGetSubCategoryData = subCategoryEntryOperations.dispatchGetSubCategoryData(dispatch);
    const dispatchSetSubCategoryName = subCategoryEntryOperations.dispatchSetSubCategoryName(dispatch);
    const dispatchSetCategorySavedFlag = categoryEntryOperations.dispatchSetCategorySavedFlag(dispatch);
    const dispatchSetCategory = categoryEntryOperations.dispatchSetCategory(dispatch);
    const dispatchSetErrorCategory = categoryEntryOperations.dispatchSetErrorCategory(dispatch);
    const dispatchSetCategoryName = categoryEntryOperations.dispatchSetCategoryName(dispatch);
    const  {
        categoryData,
        category,
        subCategoryData,
        subCategoryName,
        categorySaved,
        errorCategory,
        username
    }:any  = useSelector(
        (state:any) => ({
            categoryData: categoryEntrySelector.getCategoryData(state),
            category: categoryEntrySelector.getCategory(state),
            subCategoryData: subCategoryEntrySelector.getSubCategoryData(state),
            subCategoryName: subCategoryEntrySelector.getSubCategoryName(state),
            categorySaved: categoryEntrySelector.getCategorySaved(state),
            errorCategory: categoryEntrySelector.getErrorCategory(state),
            username: userSelector.getUsername(state)
        })
    )

    const getInitialError = () => {
        setErrors({
            categoryName:'Please provide Name',
        });
    }

    useEffect(() => {
        setShowCategoryModal(true);
        dispatchGetCategoryData();
        dispatchGetSubCategoryData();
        if (props.showProductCategory && typeof props.showProductCategory !== "boolean") {
            dispatchGetCategoryById(props.showProductCategory);
        }
        getInitialError();
    }, []);

    useEffect(() => {
        if (categoryData?.length > 0) {
            const options = categoryData.map((item: any) => (
                {
                    ...item,
                    value: item.categoryName,
                    key: parseInt(item.categoryId),
                    label: item.categoryName,
                }
            ))
            const addNew = {
                value: "Add New",
                key: "addNew",
                label: "Add New",
            }
            options.unshift(addNew);
            setCategoryOptions(options);
        }
    }, [categoryData]);

    useEffect(() => {
        if (category?.categoryId) {
            const editObject: any = {
                categoryId: category.categoryId,
                categoryName: category.categoryName,
                description: category.description,
                subCategoryId: category.subCategoryId,
                isActive: category.isActive
            }
            for (const key in editObject) {
                getValidateData(key, editObject[key])
            }
            setValues(editObject);
        }
    }, [category])

    useEffect(() => {
        if (categorySaved) {
            const element = document.getElementsByClassName("modalBodyContainer");
            element[0]?.scroll(0,0);
            setValues({
                categoryName: "",
                description: "",
            })
            dispatchSetCategory({})
            getInitialError();
            setSaveClicked(false);
            setTimeout(() => {
                closeProductCategory();
            }, 2000)
        }
    }, [categorySaved])

    useEffect(() => {
        if (subCategoryData?.length > 0) {
            const options = subCategoryData.filter((i: any) => i.isActive).map((item: any) => (
                {
                    ...item,
                    label: item.subCategoryName,
                    value: item.subCategoryName,
                    key: parseInt(item.subCategoryId)
                }
            ))
            setSubCategoryOptions(options);
        }
    }, [subCategoryData]);

    useEffect(() => {
        if(subCategoryName) {
            const subCategoryItem = subCategoryData.find((i: any) => i.subCategoryName === subCategoryName);
            if (subCategoryItem) {
                setValues({
                    ...values,
                    subCategoryId: Number(subCategoryItem.subCategoryId)
                })
            }
        }
    }, [subCategoryName, subCategoryData])

    const handleHome = () => {
        navigate("/");
    }
    const [showProductSubCategory, setShowSubProductCategory] = useState(false);
    const hideSubCategoryModal = () => {
        setShowSubProductCategory(false)
        setShowCategoryModal(true)
    }
    const showSubCategoryModal = () => {
        if (values.subCategoryId) {
            setShowSubProductCategory(values.subCategoryId);
        } else {
            setShowSubProductCategory(true)
        }
        setShowCategoryModal(false)
    }
  

    const hidePopup = () => {
        setValues({
            categoryName: "",
            description: "",
        })
        dispatchSetCategory({});
        setShowCategoryModal(false);
        dispatchSetCategorySavedFlag(false);
        dispatchSetSubCategoryName("");
        if(props.hideProductCategoryModal) {
            props.hideProductCategoryModal()
        } else{
            handleHome();
        }
            
    }

    const checkforName = (value: any) => {
        const istrue = categoryData?.length && categoryData.find((i: any) => i.categoryName.toLowerCase() === value.toLowerCase())
        if (istrue && !values.categoryId) {
            errors.categoryName='Name already exists'
        } else {
            errors.categoryName=''
        }
    }

    const handleNameCheck = (value: any) => {
        if(!value){
            errors.categoryName='Please provide Name'
        } else if (value?.length > 2 && value?.length < 250) {
            checkforName(value);
        } else if (value?.length >= 250) {
            errors.categoryName='Maximum 250 characters allowed'
        } else {
            errors.categoryName='Please provide Valid Name'
        }
    }

    const handleDescriptionCheck = (value: any) => {
        if (value && value?.length >= 500) {
            errors.description = "Maximum 500 characters allowed"
        } else {
            errors.description = ""
        }
    }

    const getValidateData = (name: any, value: any) => {
        if (name === "categoryName") {
            handleNameCheck(value);
        }
        if (name === "description") {
            handleDescriptionCheck(value);
        }
    }

    const handleInputChange = (event: any, item: any) => {
        if (categorySaved || errorCategory) {
            handleMessage();
        }
        let name = event?.name || event?.target?.getAttribute('name');
        console.log(name)
        dispatchSetSubCategoryName("");
        let value = item;
        if (name === "subCategoryId") value = item.key;
        if (name === "isActive") value = item;
        getValidateData(name, value);
        
        if (
            (name === "categoryName" && value?.length > 250) || 
            (name === "description" && value?.length > 500)) 
        {
            return;
        }

        setValues({
            ...values,
            [name]: value
        })
    }

    const closeProductCategory = () => {
        if(props.hideProductCategoryModal) {
            props.hideProductCategoryModal()
            dispatchSetCategorySavedFlag(false);
        }
    }

    const handleSave = () => {
        setSaveClicked(true);
        dispatchSetSubCategoryName("");
        for (const key in values) {
            getValidateData(key, values[key])
        }
        let error = Object.values(errors).filter((val:any) => val !== '');
        if (error?.length === 0) {
            if (props.showProductCategory && !values.categoryId) {
                dispatchSetCategoryName(values.categoryName);
            }
            const payload = {
                ...values,
                IsActive: values.isActive
            }
            if (!payload.description) {
                payload.description= ""
            }
            if (payload.categoryId) {
                payload.modifiedBy = username;
            } else {
                payload.createdBy = username;
            }
            dispatchGetCreateUpdateCategory(payload);
        }
    }

    const handleAddEditClick = () => {
        showSubCategoryModal()
    }

    const handleCategory = (_event: any, item: any) => {
        if (categorySaved || errorCategory) {
           handleMessage();
        }
        if (item.key === "addNew") {
            setValues({
                categoryName: "",
                description: "",
                isActive: true
            })
            dispatchSetCategory({})
            getInitialError();
            setSaveClicked(false);
        } else {
            dispatchGetCategoryById(item.key)
        }
    }

    const handleMessage = () => {
        dispatchSetCategorySavedFlag(false);
        dispatchSetErrorCategory("");
    }

    const breadcrumlist = [
        { text: 'Home', key: 'f1', href: '/Home', onClick: (e: any) => { e.preventDefault(); handleHome() } },
        { text: 'Product Category Entry', key: 'f2' }
    ]

    const getFooterPrimaryBtnTxt = () => {
        if (values.categoryId) {
            return "Update";
        } else {
            return "Save";
        }
    }
    const [authorized, setAuthorized] = useState<any>(null);
    useEffect(() => {
        const checkPermission = isAuthorized("Categories", "view");
        setAuthorized(checkPermission);
        
    }, []);

    if (!(authorized) && authorized != null) {
        return <ErrorHandling errorNumber='403'/>
    } 

    return (
        <ProductCategoryEntry
            breadcrumlist={breadcrumlist}
            showCategoryModal={showCategoryModal}
            hidePopup={hidePopup}
            handleSave={handleSave}
            handleInputChange={handleInputChange}
            errors={errors}
            isSaveClicked={isSaveClicked}
            values={values}
            handleAddEditClick={handleAddEditClick}
            categoryOptions={categoryOptions}
            subCategoryOptions={subCategoryOptions}
            handleCategory={handleCategory}
            showProductCategory={props.showProductCategory}
            showProductSubCategory={showProductSubCategory}
            hideSubCategoryModal={hideSubCategoryModal}
            categorySaved={categorySaved}
            handleMessage={handleMessage}
            errorCategory={errorCategory}
            FooterPrimaryBtnTxt={getFooterPrimaryBtnTxt()}
        />
    );    
    
}

export default ProductCategoryEntryContainer;


import React from 'react'
import ModalPopup from './ModalPopup'
import { PrimaryButton, OutlineButton } from '../../CommonComponents'

interface IProps {
  closeModalPopup?: any,
  ModalPopupTitle?: string,
  ModalPopupType?: string,
  ShowModalPopupFooter?: any,
  FooterSecondaryBtnTxt?: any,
  FooterPrimaryBtnTxt?: any,
  FooterActionBtnTxt?:any,
  children?: any,
  ModalPopupName?:any,
  PrimaryBtnOnclick?:any,
  SecondryBtnOnclick?:any,
  ActionBtnOnclick?:any,
  FooterPrimaryDisabled?: any;
}
const ModalPopupContainer = ({
  closeModalPopup,
  ModalPopupTitle,
  ModalPopupType,
  ShowModalPopupFooter,
  FooterSecondaryBtnTxt,
  FooterPrimaryBtnTxt,
  FooterActionBtnTxt,
  children,
  ModalPopupName,
  PrimaryBtnOnclick,
  SecondryBtnOnclick,
  ActionBtnOnclick,
  FooterPrimaryDisabled
}:IProps) => {
  
  const getFooterActionButton = () => {
    if (FooterActionBtnTxt) {
      return <OutlineButton text={FooterActionBtnTxt} onClick={ActionBtnOnclick}></OutlineButton>
    } else {
      return null
    }
  }

  const getFooterSecondaryBtnTxt = () => {
    if (FooterSecondaryBtnTxt) {
      return <OutlineButton text={FooterSecondaryBtnTxt} onClick={SecondryBtnOnclick}></OutlineButton>
    } else {
      return null
    }
  }

  const getFooterPrimaryBtnTxt = () => {
    if (FooterPrimaryBtnTxt) {
      return <PrimaryButton text={FooterPrimaryBtnTxt} onClick={PrimaryBtnOnclick} disabled={!!FooterPrimaryDisabled}></PrimaryButton>
    } else {
      return null
    }
  }

  return (
      <ModalPopup ModalPopupName ={ModalPopupName} ShowModalPopupFooter = {ShowModalPopupFooter} ModalPopupType = {ModalPopupType} closeModalPopup={closeModalPopup} ModalPopupTitle={ModalPopupTitle} getFooterActionButton={getFooterActionButton} getFooterSecondaryBtnTxt={getFooterSecondaryBtnTxt} getFooterPrimaryBtnTxt={getFooterPrimaryBtnTxt}>{children}</ModalPopup>
  )
}

export default ModalPopupContainer

import {
  GET_COMMON_DASHBOARD,
  SET_COMMON_DASHBOARD_APPS,
  ADD_APP_FAV,
  REMOVE_APP_FAV,
  SET_ADD_APP_FAV,
  SET_LOADING,
  GET_NEWS_AND_ANNOUNCEMENT,
  SET_NEWS_AND_ANNOUNCEMENT
} from './types';

const getCommonDashboard = (user:any) => {
    return {
      type: GET_COMMON_DASHBOARD,
      user
    }
}

const setCommonDashboardApps = (value:any) => {
  return {
    type: SET_COMMON_DASHBOARD_APPS,
    value
  }
}

const addFavoriteApp = (favApp:any) => {
  return {
    type: ADD_APP_FAV,
    favApp
  }
}

const removeFavoriteApp = (favApp:any) => {
  return {
    type: REMOVE_APP_FAV,
    favApp
  }
}

const setAddFavApp = (value:boolean) => {
  return {
    type: SET_ADD_APP_FAV,
    value
  }
}

const setLoading = (value:boolean) => {
  return {
    type: SET_LOADING,
    value
  }
}
const getNewsAndAnnouncementDetails = (reqBody:any) => {
  return {
    type: GET_NEWS_AND_ANNOUNCEMENT,
    reqBody
  }
}

const setNewsAndAnnouncementDetails = (value:any) => {
  return {
    type: SET_NEWS_AND_ANNOUNCEMENT,
    value
  }
}
export {
  getCommonDashboard,
  setCommonDashboardApps,
  addFavoriteApp,
  removeFavoriteApp,
  setAddFavApp,
  setLoading,
  getNewsAndAnnouncementDetails,
  setNewsAndAnnouncementDetails
}
import AccessDeniedAuthenetication from '../../assets/icons/401&403.svg'
import PageNotFound from '../../assets/icons/404.svg'
import InternalErrorServiceUnavailable from '../../assets/icons/500 & 503.svg'
import './ErrorHandling.scss';
import { Alert } from "../../CommonComponents";

const iconReturn = (status: any) => {
    let src = "";
    switch (status) {
        case "401":
        case "403":
            src = AccessDeniedAuthenetication;
            break;
        case "500":
        case "503":
            src = InternalErrorServiceUnavailable;
            break;
        case "404":
            src = PageNotFound;
            break;
        default:
            break;
    }
    return <img src={src} alt="Error Image" className="error-image" />;
}

const errorNameReturn = (status: any) => {
    let errorName = "";
    switch (status) {
        case "401":
            errorName = 'Authentication Required';
            break;
        case "403":
            errorName = 'Access Denied';
            break;
        case "404":
            errorName = 'Page Not Found';   
            break;
        case "500":
            errorName = 'Internal Server Error';          
            break;
        case "503":
            errorName = 'Service Unavailable';
            break;
        default:
            break;
    }
    // return <p className="error-name">{errorName}</p>;
    return errorName
}

const errorDescriptionReturn = (status: any) => {
    let errorDescription = "";
    switch (status) {
        case "401":
            errorDescription = 'Access is allowed only for registrated users.';
            break;
        case "403":
            // errorDescription = 'The page you are trying to reach is absolutely forbidden for some reason.';
            errorDescription = 'You do not have sufficient permissions to access this resource.'
            break;
        case "404":
            errorDescription = 'The page you are looking for may have been removed, deleted or possibly never existed.';
            break;
        case "500":
            errorDescription = 'The server has been deserted for a while. Please be patient or try again later';
            break;
        case "503":
            errorDescription = 'The server is temporarily busy, Try again later!';
            break;
        default:
            break;
    }
    // return <p className="error-description">{errorDescription}</p>;
    return errorDescription
}

const ErrorHandling = (props:any) =>{
    return(
        <div>
            {/* <div className='unauthorized-container'>
                <>{errorNameReturn(props?.errorNumber)}</>
                <div className="center-block">           
                    <div className="content-section">
                        <p className="error-text">ERROR</p>
                        <p className="error-number">{props?.errorNumber}</p>
                    </div>
                    {iconReturn(props?.errorNumber)}          
                </div>
                <>{errorDescriptionReturn(props?.errorNumber)}</>
            </div> */}
            <div className="ErrorScreens">
                <Alert 
                    type="error" 
                    className="mb-2" 
                    showAlert={true} 
                    text= {
                        <div className="AlertMessageStyle">
                            <h2 className="AlertMessageHeading"><span className="fa fa-exclamation-circle"></span>{errorNameReturn(props?.errorNumber)}</h2>
                            <p>{errorDescriptionReturn(props?.errorNumber)}</p>
                        </div>
                    } /> 
            </div>
        </div>
        
    )
}

export default ErrorHandling;
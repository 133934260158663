import React from "react";
import "./Spinner.scss";
import { SpinnerLoader } from "@softura/fluentui-basiccomponents";

const styles = {
  root: {
    position: "fixed",
    top: 0,
    right: 0,
    zIndex: 99999999999999,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
};

interface IProps {
  text?: string;
}

const Spinner = ({ text, ...props }: IProps) => {
  return (
    <div className="approval-loader">
      <SpinnerLoader styles={styles} label={text} {...props} />
    </div>
  );
};

export default Spinner;

import { 
  SET_COLLECTIONS_HISTORY_LOADER,
  GET_CONTACT_HISTORY_GRID_DATA,
  GET_CONTRACT_DROPDOWN_DATA,
  GET_DELETE_CONTACT_HISTORY_BY_ID,
  GET_INSERT_CONTACT_HISTORY_DATA,
  GET_PAID_EXPECTED_GRID_DATA,
  GET_PAYMENT_HISTORY_GRID_DATA,
  GET_SAVE_PAYMENT_COMPLETION,
  GET_SUPPLIER_DROPDOWN_DATA,
  GET_YEAR_DROPDOWN_DATA,
  SET_CONTACT_HISTORY_GRID_DATA,
  SET_CONTRACT_DROPDOWN_DATA,
  SET_DELETE_CONTACT_HISTORY_BY_ID,
  SET_INSERT_CONTACT_HISTORY_DATA,
  SET_PAID_EXPECTED_GRID_DATA,
  SET_PAYMENT_HISTORY_GRID_DATA,
  SET_SAVE_PAYMENT_COMPLETION,
  SET_SUPPLIER_DROPDOWN_DATA,
  SET_YEAR_DROPDOWN_DATA
} from './types'

export const getContactHistoryGridData = (payload: any) => {
  return {
    type: GET_CONTACT_HISTORY_GRID_DATA,
    payload
  }
}

export const setContactHistoryGridData = (data: any) => {
  return {
    type: SET_CONTACT_HISTORY_GRID_DATA,
    data
  }
}

export const getInsertContactHistoryData = (payload: any) => {
  return {
    type: GET_INSERT_CONTACT_HISTORY_DATA,
    payload
  }
}

export const setInsertContactHistoryData = (data: any) => {
  return {
    type: SET_INSERT_CONTACT_HISTORY_DATA,
    data
  }
}

export const getDeleteContactHistoryById = (payload: any) => {
  return {
    type: GET_DELETE_CONTACT_HISTORY_BY_ID,
    payload
  }
}

export const setDeleteContactHistoryById = (data: any) => {
  return {
    type: SET_DELETE_CONTACT_HISTORY_BY_ID,
    data
  }
}

export const getPaymentHistoryGridData = (payload: any) => {
  return {
    type: GET_PAYMENT_HISTORY_GRID_DATA,
    payload
  }
}

export const setPaymentHistoryGridData = (data: any) => {
  return {
    type: SET_PAYMENT_HISTORY_GRID_DATA,
    data
  }
}

export const getPaidExpectedGridData = (payload: any) => {
  return {
    type: GET_PAID_EXPECTED_GRID_DATA,
    payload
  }
}

export const setPaidExpectedGridData = (data: any) => {
  return {
    type: SET_PAID_EXPECTED_GRID_DATA,
    data
  }
}

export const getSupplierDropdownData = () => {
  return {
    type: GET_SUPPLIER_DROPDOWN_DATA
  }
}

export const setSupplierDropdownData = (data: any) => {
  return {
    type: SET_SUPPLIER_DROPDOWN_DATA,
    data
  }
}

export const getContractDropdownData = (payload: any) => {
  return {
    type: GET_CONTRACT_DROPDOWN_DATA,
    payload
  }
}

export const setContractDropdownData = (data: any) => {
  return {
    type: SET_CONTRACT_DROPDOWN_DATA,
    data
  }
}

export const getYearDropdownData = (payload: any) => {
  return {
    type: GET_YEAR_DROPDOWN_DATA,
    payload
  }
}

export const setYearDropdownData = (data: any) => {
  return {
    type: SET_YEAR_DROPDOWN_DATA,
    data
  }
}

export const getSavePaymentCompletion = (payload: any) => {
  return {
    type: GET_SAVE_PAYMENT_COMPLETION,
    payload
  }
}

export const setSavePaymentCompletion = (data: any) => {
  return {
    type: SET_SAVE_PAYMENT_COMPLETION,
    data
  }
}

  export const setCollectionsHistoryLoader = (data: any) => {
    return {
      type: SET_COLLECTIONS_HISTORY_LOADER,
      data
    }
}

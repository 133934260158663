import React from "react";
import { Breadcrumb, ModalPopup } from "client/CommonComponents";
import IndividualModal from "./IndividualModal";
import "./IndividualEntry.scss";

interface IProps {
    showIndividualModal?: any;
    hidePopup?: any;
    handleSave?: any;
    handleInputChange?: any;
    breadcrumlist?: any;
    rebateProgramOptions?: any;
    productOptions?: any;
    vendorOptions?: any;
    manufacturerOptions?: any;
    categoryOptions?: any;
    subCategoryOptions?: any;
    rebateSpendSaved?: any;
    handleMessage?: any;
    getValue?: any;
    getError?: any
    getExternalErrorMessage?: any;
}

const IndividualEntry = (props: IProps) => {

    const {
        showIndividualModal,
        hidePopup,
        handleSave,
        handleInputChange,
        breadcrumlist,
        rebateProgramOptions,
        productOptions,
        vendorOptions,
        manufacturerOptions,
        categoryOptions,
        subCategoryOptions,
        rebateSpendSaved,
        handleMessage,
        getValue,
        getError,
        getExternalErrorMessage
    } = props;

    return (
        <div className="individual-entry-container">
            <Breadcrumb items={breadcrumlist} maxDisplayedItems='5' />
            {
                showIndividualModal
                ? <ModalPopup
                    ShowModalPopupFooter={true}
                    ModalPopupTitle="Individual Entry"
                    ModalPopupType="medium"
                    closeModalPopup={hidePopup}
                    FooterSecondaryBtnTxt={"Cancel"}
                    SecondryBtnOnclick={hidePopup}
                    FooterPrimaryBtnTxt={"Save"}
                    PrimaryBtnOnclick={handleSave}
                >
                    <IndividualModal
                        handleInputChange={handleInputChange}
                        rebateProgramOptions={rebateProgramOptions}
                        productOptions={productOptions}
                        vendorOptions={vendorOptions}
                        manufacturerOptions={manufacturerOptions}
                        categoryOptions={categoryOptions}
                        subCategoryOptions={subCategoryOptions}
                        rebateSpendSaved={rebateSpendSaved}
                        handleMessage={handleMessage}
                        getValue={getValue}
                        getError={getError}
                        getExternalErrorMessage={getExternalErrorMessage}
                    />
                </ModalPopup>
                : null
            }
        </div>
    )
}

export default IndividualEntry;
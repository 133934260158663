import { 
  GET_CATEGORYDATA, 
  SET_CATEGORYDATA,
  GET_CATEGORYBYID,
  SET_CATEGORY,
  GET_CREATEUPDATECATEGORY,
  GET_SAVEDCATEGORYFLAG,
  SET_SAVEDCATEGORYFLAG,
  SET_ERRORCATEGORYFLAG,
  SET_CATEGORYNAME
} from './types'

export const getCategoryData = () => {
  return {
    type: GET_CATEGORYDATA
  }
}

export const setCategoryData = (data: any) => {
  return {
    type: SET_CATEGORYDATA,
    payload: data
  }
}

export const getCategoryById = (id: any) => {
  return {
    type: GET_CATEGORYBYID,
    id: id
  }
}

export const setCategory = (data:any) => {
  return {
    type: SET_CATEGORY,
    payload: data
  }
}

export const getCreateUpdateCategory = (data:any) => {
  return {
    type: GET_CREATEUPDATECATEGORY,
    payload: data
  }
}

export const getCategorySavedFlag = () => {
  return {
    type: GET_SAVEDCATEGORYFLAG
  }
}

export const setCategorySavedFlag = (flag: any) => {
  return {
    type: SET_SAVEDCATEGORYFLAG,
    payload: flag
  }
}

export const setErrorCategory = (data: any) => {
  return {
    type: SET_ERRORCATEGORYFLAG,
    payload: data
  }
}

export const setCategoryName = (data: any) => {
  return {
    type:   SET_CATEGORYNAME,
    payload: data
  }
}
import React, { useState } from "react";
import { Row, Col, Label, InputField, RadioContainer, Checkbox, Alert, SearchSelectBox, DropdownBox, MultiSelectoption, Tooltip, Spinner } from "client/CommonComponents";
import { MaskedTextField } from '@fluentui/react';
import { isAuthorized } from "client/utils/Permissions";

interface IProps {
    handleInputChange?: any;
    getCheckboxLabel?: any;
    supplierOptions?: any;
    stateOptions?: any;
    countryOptions?: any;
    manufacturerOptions?: any;
    handleRadio?: any;
    handleSupplier?: any;
    supplierSaved?: any;
    handleMessage?: Function;
    errorSupplier?: any;
    getRadioSelectedKey?: any;
    getValue?: any;
    getError?: any;
    getExternalError?: any;
    isNew?: boolean;
    categoryManagerOptions?: any;
    handleCategoryManager?: any;
    primaryAbnumberOptions?: any;
    secondaryAbnumberOptions?: any
    AdditionalAbnoSelected?: any
    selfReportedOptions?: any
    onClickAddNew?: any
    onClickDeleteAddNew?: any
    isAddNewAbnumber?: any
    handleAdditionalAbnumber?: any
    primarySearchValue?: any
    setPrimarySearchValue?: any
    setSecondarySearchValue?: any
    isLoadingPrimary?: any
    isLoadingSecondary?: any
    isNoAbNumber?: any
    setIsNoAbNumber?: any
}

const SupplierModal = (props: IProps) => {
    const {
        handleInputChange,
        stateOptions,
        countryOptions,
        manufacturerOptions,
        handleRadio,
        supplierSaved,
        handleMessage,
        errorSupplier,
        getRadioSelectedKey,
        getValue,
        getError,
        getExternalError,
        isNew,
        categoryManagerOptions,
        handleCategoryManager,
        primaryAbnumberOptions,
        secondaryAbnumberOptions,
        AdditionalAbnoSelected,
        selfReportedOptions,
        onClickAddNew,
        onClickDeleteAddNew,
        isAddNewAbnumber,
        handleAdditionalAbnumber,
        primarySearchValue,
        setPrimarySearchValue,
        setSecondarySearchValue,
        isLoadingPrimary,
        isLoadingSecondary,
        isNoAbNumber,
        setIsNoAbNumber
    } = props;

    const addNew = {
        label: "Add New",
        value: "addNew",
        key: "addNew"
    }

    const getPrimaryAbnumber = () => {
        const value = primaryAbnumberOptions?.find((i: any) => i.key === props.getValue("primaryAbNumber"))
        if(value?.abNumber)
            return value 
        else if(!value?.abNumber && props.getValue("primaryAbNumber"))
            return {label: props.getValue("primaryAbNumber") + '-' + props.getValue("alphaName"), value: props.getValue("primaryAbNumber")}
        else 
        return ""
    }

    return (
        <div className="supplier-entry-modal-container">
            {/* {isLoadingPrimary && <Spinner text={"Fetching Data ..."} />} */}

            <Row>
                <Col md={12} className='mb-3'>
                    <Alert showAlert={errorSupplier} text={'Something went wrong please try after sometime'} dismissAlert={handleMessage} type="error" className="mb-2" />
                    <Alert showAlert={supplierSaved} text='Saved Successfully' dismissAlert={handleMessage} type="success" className="mb-2" />
                </Col>
                <Col md={4} className='mb-3'>
                    <div className="clearfix">
                        <div className="pull-left">
                            <Label required>Vendor</Label>
                        </div>

                        <div className="pull-right">
                            <a href="#" title="Add New Vendor" className="primaryABLink" onClick={() => onClickAddNew()}>Add New Vendor </a>
                        </div>
                    </div>
                    <div className="clearfix">
                        <div className={`${isAddNewAbnumber && 'pull-left primaryAbNumberLft'}`}>
                            <SearchSelectBox
                                placeholder="Select Existing"
                                options={props.supplierOptions}
                                filterOptionValue={3}
                                onChange={(item: any, e: any) => props.handleSupplier(e, item)}
                                value={props.supplierOptions?.find((i: any) => i.key === props.getValue("supplierId")) || (isNew ? addNew : "")}
                                name='supplierId'
                                className="search-dropdown"
                                target="modalPopupMasterContainer"
                                isDisabled={isAddNewAbnumber}
                            />
                        </div>
                        {isAddNewAbnumber && <div className="pull-right primaryAbNumberRgt">
                        <Tooltip content='Reset'><div onClick={() => onClickDeleteAddNew()} className="primaryAbNumberRgtBox">
                                <span className="fa fa-times"></span>
                            </div></Tooltip>
                        </div>}
                    </div>
                </Col>
                <Col md={4} className="PrimaryABnumberDiv">
                    
                    {isAddNewAbnumber ? (
                        <>
                            <Checkbox
                                checked={isNoAbNumber}
                                onChange={() => setIsNoAbNumber(!isNoAbNumber)}
                                className="PrimaryABnumbercheckbox"
                                label='No ABNumber'
                            />
                            <Label required={!isNoAbNumber}>Primary ABNumber</Label>
                            <div className="clearfix">
                                <SearchSelectBox
                                    placeholder="Enter Primary ABNumber"
                                    options={primaryAbnumberOptions}
                                    filterOptionValue={3}
                                    inputValue={primarySearchValue}
                                    onInputChange={(value: any) => setPrimarySearchValue(value)}
                                    onChange={(item: any, e: any) => props.handleInputChange(e, item)}
                                    value={getPrimaryAbnumber()}
                                    name='primaryAbNumber'
                                    className="search-dropdown"
                                    isDisabled={isNoAbNumber}
                                    isLoading = {isLoadingPrimary}
                                />
                                {getExternalError("primaryAbNumber")}
                            </div>

                        </>
                    ) : (
                        <>
                            <Label>Primary ABNumber</Label>
                            <span>{props.getValue("primaryAbNumber")}</span>
                        </>
                    )
                    }
                </Col>
                <Col md={4} className='mb-4'>
                    <div className="clearfix">
                        <Label className="float-left">Additional ABNumbers</Label>
                        <Tooltip content='Additional AbNumbers'><span className="fa fa-info-circle ms-1"></span></Tooltip>
                    </div>
                    <Tooltip content={(AdditionalAbnoSelected.length > 1) 
                                    ? `Total selected: ${AdditionalAbnoSelected.length} 
                                    \n${AdditionalAbnoSelected.map((ele:any) => `\u00A0${ele.label}`)}` 
                                    : '' }
                                    >
                    <div className='abNumberDropdown'>
                        <MultiSelectoption value={AdditionalAbnoSelected} onChange={handleAdditionalAbnumber} options={secondaryAbnumberOptions}
                            filterOptions={(option:any, filter:any)=>{
                                setSecondarySearchValue(filter)
                                return option;
                            }}
                            valueRenderer={(item:any)=> item.length ? item.map((selectedItem:any)=>selectedItem.label) : ""}
                            isLoading={isLoadingSecondary}
                            disabled={isNoAbNumber || parseInt(props.getValue("primaryAbNumber")) < 0}
                        />
                    </div>
                    
                    </Tooltip>
                    {/* {getExternalError("additionalAbnumber")}                                   */}
                </Col>
                <Col md={4} className='mb-3'>
                    <Label required>Name</Label>
                    <InputField name='supplierName' value={getValue("supplierName")} onChange={handleInputChange} errorMessage={getError("supplierName")} />
                </Col>
                <Col md={4} className='mb-3'>
                    <Label required>Address Line 1</Label>
                    <InputField name='addressLine1' value={getValue("addressLine1")} onChange={handleInputChange} errorMessage={getError("addressLine1")} />
                </Col>
                <Col md={4} className='mb-3'>
                    <Label>Address Line 2</Label>
                    <InputField name="addressLine2" value={getValue("addressLine2")} onChange={handleInputChange} errorMessage={getError("addressLine2")} />
                </Col>
                <Col md={4} className='mb-3'>
                    <Label required>City</Label>
                    <InputField name="city" value={getValue("city")} onChange={handleInputChange} errorMessage={getError("city")} />
                </Col>
                <Col md={4} className='mb-3'>
                    <Label required>State</Label>
                    <SearchSelectBox
                        placeholder="Select State"
                        options={stateOptions}
                        filterOptionValue={3}
                        onChange={(item: any, e: any) => props.handleInputChange(e, item)}
                        value={stateOptions?.find((i: any) => i.key === props.getValue("stateId")) || ""}
                        name='stateId'
                        className="search-dropdown"
                        target="modalPopupMasterContainer"
                    />
                    {getExternalError("stateId")}
                </Col>
                <Col md={4} className='mb-3'>
                    <Label required>Country</Label>
                    <SearchSelectBox
                        placeholder="Select Country"
                        options={countryOptions}
                        filterOptionValue={3}
                        onChange={(item: any, e: any) => props.handleInputChange(e, item)}
                        value={countryOptions?.find((i: any) => i.key === props.getValue("countryId")) || ""}
                        name='countryId'
                        className="search-dropdown"
                        target="modalPopupMasterContainer"
                    />
                    {getExternalError("countryId")}
                </Col>
                <Col md={4} className='mb-3'>
                    <Label required>Contact Name</Label>
                    <InputField name="contactName" value={getValue("contactName")} onChange={handleInputChange} errorMessage={getError("contactName")} />
                </Col>
                <Col md={4} className='mb-3'>
                    <Label>Contact Title</Label>
                    <InputField name="title" value={getValue("title")} onChange={handleInputChange} errorMessage={getError("title")} />
                </Col>
                <Col md={4} className='mb-3'>
                    <Label required>Contact Email</Label>
                    <InputField name="email" value={getValue("email")} onChange={handleInputChange} errorMessage={getError("email")} />
                </Col>
                <Col md={2} className='mb-4 radio-container'>
                    <Label required>Manufacturer</Label>
                    <div>
                        <RadioContainer selectedKey={getRadioSelectedKey('manufacturer')} options={manufacturerOptions} onChange={handleRadio} id={'manufacturer'} />
                        {getExternalError("manufacturer")}
                    </div>
                </Col>
                <Col md={2} className='mb-4'>
                    <Label required>Self Reported</Label>
                    <div>
                        <RadioContainer selectedKey={getRadioSelectedKey('isSelfReported')} options={selfReportedOptions} onChange={handleRadio} id={'isSelfReported'} />
                        {getExternalError("isSelfReported")}
                    </div>
                </Col>
                <Col md={4} className='mb-4'>
                    <Label required>Contact Telephone</Label>
                    <MaskedTextField className='form-input' mask="(999) 999-9999" name='telephone' value={getValue("telephone")} onChange={handleInputChange} errorMessage={getError("telephone")} />
                </Col>
                <Col md={4} className='mb-4'>
                    <Label required >Category Manager</Label>
                    <DropdownBox
                        placeholder="Select Category Manager"
                        options={categoryManagerOptions}
                        onChange={(e: any, options: any) => {
                            handleCategoryManager(options.key)
                        }}
                        value={getValue("categoryManagerId")}
                        selectedKey={getValue("categoryManagerId") || ""}
                        className="dropdown"
                        name="categoryManagerId"
                        errorMessage={getError("categoryManagerError")}
                    />
                </Col>
                <Col md={2}>
                    <Checkbox checked={getValue("isActive")} label='Enabled' onChange={handleInputChange} className='autoCorrect suppliermodal' name="isActive" disabled={!isAuthorized("Suppliers", "delete")} />
                </Col>
            </Row>
        </div>
    )
}

export default SupplierModal;
//import './Progressbar.scss';

interface IProps {
    progress?:any
}
const Progress_bar = ({progress}:IProps) => {   
    const Parentdiv = {
        height: '10px',
        width: '100%',
        backgroundColor: 'whitesmoke',
        borderRadius: '10px',
      }
      
      const Childdiv = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: '#8bc400',
        borderRadius:'10px',
        //textAlign: 'right'
      }
      
      const progresstext = {
        padding: '10px',
        color: 'black',
        fontWeight: 900
      }   
    return (
    <div style={Parentdiv} className="progressBar">
      <div style={Childdiv}>
        <span style={progresstext}></span>
      </div>
    </div>
    )
}
  
export default Progress_bar;
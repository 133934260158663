import React, { useEffect } from 'react'
import { Routes, Route, redirect } from 'react-router-dom'
import Layout from './client/Components/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { showMenu } from './state/features/Navigation/action'
import 'rsuite/dist/rsuite.min.css'
import './App.scss'
import { useIsAuthenticated, useMsal, AuthenticatedTemplate } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "./config/authConfig";

import SelfReportingDashboard from 'client/Components/SelfReportingDashboard';

import SupplierEntry from 'client/Components/RebateManagement/SupplierEntry';
import ProductEntry from 'client/Components/RebateManagement/ProductEntry';
import RebateProgramEntry from 'client/Components/RebateManagement/RebateProgramEntry';
import CollectionTracking from 'client/Components/RebateManagement/CollectionTracking'

import PaymentReconcillations from 'client/Components/RebateManagement/PaymentReconcillations'

import ProductCategoryEntry from 'client/Components/RebateManagement/ProductCategoryEntry';
import ProductSubCategoryEntry from 'client/Components/RebateManagement/ProductSubCategoryEntry';
import IndividualEntry from 'client/Components/RebateSpent/IndividualEntry'
import NewBulkUpload from 'client/Components/RebateSpent/BulkUpload/NewBulkUpload'
import Home from 'client/Components/Home';
import Report from 'client/Components/Report';
import ErrorHandling from "client/CommonComponents/ErrorHandling/ErrorHandling"
import userOperations from 'state/features/UserProfile/operations';


function App () {
  const dispatch = useDispatch()

  const { inProgress, instance,accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const dispatchGetUserRolesAndClaims = userOperations.dispatchGetUserRolesAndClaims(dispatch);

  useEffect(() => {
    if (!isAuthenticated && inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
      instance.loginRedirect(loginRequest);
    }
    dispatch(showMenu())
  })

  useEffect(() => {
    if (accounts.length > 0) {
      dispatchGetUserRolesAndClaims(accounts[0]?.username);
    }
  }, [accounts[0]?.username])

  const { permissions }: any = useSelector(
    (state: any) => ({
      permissions: state?.UserProfile?.rolesandClaims,
    }),
    // Added a custom equality function to avoid unnecessary re-renders
    (prev, curr) => prev.permissions === curr.permissions
  );

  return (
    <div className="App">
      {((permissions?.length > 0) || permissions?.status) ? 
        <AuthenticatedTemplate>
        <Layout>
          <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/self-reporting-dashboard' element={<SelfReportingDashboard/>} />            
            <Route path='/vendor-entry' element={<SupplierEntry />} />
            <Route path='/program-entry' element={<RebateProgramEntry/>} />
            <Route path='/product-entry' element={<ProductEntry/>} />
            <Route path='/collection-tracking' element={<CollectionTracking/>} />
            <Route path='/payment-reconcillations' element={<PaymentReconcillations/>} />

            <Route path='/category-entry' element={<ProductCategoryEntry />} />
            <Route path='/sub-category-entry' element={<ProductSubCategoryEntry />} />
            <Route path='/individual-entry' element={<IndividualEntry />} />
            <Route path='/bulk-upload' element={<NewBulkUpload/>} />
            <Route path='/report' element={<Report type='report'/>}/>
            <Route path='/rebatespendbyyear' element={<Report type='rebatespendbyyear'/>}/>
            <Route path='/manufacturerReport' element={<Report type='manufacturerReport'/>}/>
            <Route path='/AccountingAndFinance' element={<Report type='AccountingAndFinance'/>}/>

            <Route path='*' element={<ErrorHandling errorNumber='404'/>} />
          </Routes>
        </Layout>
      </AuthenticatedTemplate> : null}
    </div>
  )
}

export default App
import React from 'react'
import minus from '../../assets/images/minus.png'
import plus from '../../assets/images/plus.png'
import './Quantity.scss'

interface IProps {
  value?: any,
  increment?: any,
  decrement?: any,
  onChange?: any,
  disableFlg?:any,
  name?:string,
  type?: string;
}
const Quantity = ({name='', value = 0, increment, decrement, onChange, disableFlg = false, type}:IProps) => {
  return <div className='quantity-spinner'>
      <button disabled= {disableFlg} className='control' onClick={decrement}>
        <img src={minus} alt='minus' className='minus-icon'/>
      </button>
      <div><input type={type} name={name} value={value} className='form-control' onChange={onChange} disabled={disableFlg}/></div>
      <button className='control' onClick={increment} disabled={disableFlg}>
        <img src={plus} alt='plus' className='plus-icon'/>
      </button>
  </div>
}

export default Quantity

import { 
  GET_REBATESPENDDATA, 
  SET_REBATESPENDDATA,
  GET_REBATESPENDBYID,
  SET_REBATESPEND,
  GET_CREATEUPDATEREBATESPEND,
  GET_SAVEDREBATESPENDFLAG,
  SET_SAVEDREBATESPENDFLAG
} from './types'

export const getRebateSpendData = () => {
  return {
    type: GET_REBATESPENDDATA
  }
}

export const setRebateSpendData = (data: any) => {
  return {
    type: SET_REBATESPENDDATA,
    payload: data
  }
}

export const getRebateSpendById = (id: any) => {
  return {
    type: GET_REBATESPENDBYID,
    id: id
  }
}

export const setRebateSpend = (data:any) => {
  return {
    type: SET_REBATESPEND,
    payload: data
  }
}

export const getCreateUpdateRebateSpend = (data:any) => {
  return {
    type: GET_CREATEUPDATEREBATESPEND,
    payload: data
  }
}

export const getRebateSpendSavedFlag = () => {
  return {
    type: GET_SAVEDREBATESPENDFLAG
  }
}

export const setRebateSpendSavedFlag = (flag: any) => {
  return {
    type: SET_SAVEDREBATESPENDFLAG,
    payload: flag
  }
}
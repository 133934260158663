import {
  getSubCategoryData,
  getSubCategoryById,
  getCreateUpdateSubCategory,
  getSubCategorySavedFlag,
  setSubCategorySavedFlag,
  setSubCategory,
  setErrorSubCategory,
  setSubCategoryName
} from './action'

  const dispatchGetSubCategoryData = (dispatch:Function) => () => {
    dispatch(getSubCategoryData());
  };
  
  const dispatchGetSubCategoryById = (dispatch:Function) => (id: any) => {
    dispatch(getSubCategoryById(id));
  };

  const dispatchGetCreateUpdateSubCategory = (dispatch: Function) => (data: any) => {
    dispatch(getCreateUpdateSubCategory(data));
  }

  const dispatchGetSubCategorySavedFlag = (dispatch: Function) => () => {
    dispatch(getSubCategorySavedFlag());
  }

  const dispatchSetSubCategorySavedFlag = (dispatch: Function) => (flag: boolean) => {
    dispatch(setSubCategorySavedFlag(flag));
  }

  const dispatchSetErrorSubCategory = (dispatch: Function) => (data: any) => {
    dispatch(setErrorSubCategory(data));
  }

  const dispatchSetSubCategory = (dispatch: Function) => (data: any) => {
    dispatch(setSubCategory(data));
  }

  const dispatchSetSubCategoryName = (dispatch: Function) => (data: any) => {
    dispatch(setSubCategoryName(data));
  }


  const operations = {
    dispatchGetSubCategoryData,
    dispatchGetSubCategoryById,
    dispatchGetCreateUpdateSubCategory,
    dispatchGetSubCategorySavedFlag,
    dispatchSetSubCategorySavedFlag,
    dispatchSetSubCategory,
    dispatchSetErrorSubCategory,
    dispatchSetSubCategoryName
}

export default operations
import {
   getStates,
   getCountries,
   getSupplierdata,
   getCreateUpdateSupplier,
   getSupplierById,
   getManufacturerData,
   getSupplierSavedFlag,
   setSupplierSavedFlag,
   setSupplier,
   setErrorSupplier,
   setSupplierName,
   getCategoryManager,
   getPrimaryAbnumber,
   getSupplierDetailsByAbnumber,
   setSupplierDetailsByAbnumber,
   getSecondaryAbnumberBySupplier,
   getSecondaryAbnumber,   
} from './action'

  const dispatchGetStates = (dispatch:Function) => (countryId: any) => {
    dispatch(getStates(countryId));
  };

  const dispatchGetCountries = (dispatch:Function) => () => {
    dispatch(getCountries());
  };

  const dispatchGetSupplierdata = (dispatch: Function) => () => {
    dispatch(getSupplierdata());
  }

  const dispatchGetSupplierById = (dispatch: Function) => (id: any) => {
    dispatch(getSupplierById(id));
  }

  const dispatchGetCreateUpdateSupplier = (dispatch: Function) => (data: any) => {
    dispatch(getCreateUpdateSupplier(data));
  }

  const dispatchGetManufacturerData = (dispatch: Function) => () => {
    dispatch(getManufacturerData());
  }

  const dispatchGetSupplierSavedFlag = (dispatch: Function) => () => {
    dispatch(getSupplierSavedFlag());
  }

  const dispatchSetSupplierSavedFlag = (dispatch: Function) => (flag: boolean) => {
    dispatch(setSupplierSavedFlag(flag));
  }

  const dispatchSetErrorSupplier = (dispatch: Function) => (data: any) => {
    dispatch(setErrorSupplier(data));
  }

  const dispatchSetSupplier = (dispatch: Function) => (data: any) => {
    dispatch(setSupplier(data));
  }
  
  const dispatchSetSupplierName = (dispatch: Function) => (data: any) => {
    dispatch(setSupplierName(data));
  }

  const dispatchGetCategoryManager = (dispatch:Function) => () => {
    dispatch(getCategoryManager());
  };

  const dispatchGetPrimaryAbnumber = (dispatch:Function) => (payload: any) => {
    dispatch(getPrimaryAbnumber(payload));
  };

  const dispatchGetSecondaryAbnumber = (dispatch:Function) => (payload: any) => {
    dispatch(getSecondaryAbnumber(payload));
  };

  const dispatchGetSupplierDetailsByAbnumber = (dispatch:Function) => (payload: any) => {
    dispatch(getSupplierDetailsByAbnumber(payload));
  };

  const dispatchSetSupplierDetailsByAbnumber = (dispatch:Function) => (payload: any) => {
    dispatch(setSupplierDetailsByAbnumber(payload));
  };

  const dispatchGetSecondaryAbnumberBySupplier = (dispatch:Function) => (payload: any) => {
    dispatch(getSecondaryAbnumberBySupplier(payload));
  };

  const operations = {
    dispatchGetStates,
    dispatchGetCountries,
    dispatchGetSupplierdata,
    dispatchGetCreateUpdateSupplier,
    dispatchGetSupplierById,
    dispatchGetManufacturerData,
    dispatchGetSupplierSavedFlag,
    dispatchSetSupplierSavedFlag,
    dispatchSetSupplier,
    dispatchSetErrorSupplier,
    dispatchSetSupplierName,
    dispatchGetCategoryManager,
    dispatchGetPrimaryAbnumber,
    dispatchGetSecondaryAbnumber,
    dispatchGetSupplierDetailsByAbnumber,
    dispatchSetSupplierDetailsByAbnumber,
    dispatchGetSecondaryAbnumberBySupplier
}

export default operations
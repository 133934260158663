import React from 'react'
import { ReactTable } from '@softura/fluentui-reacttable'
import './Grid.scss'

interface IProps {
  className?: string,
  data?: any,
  columns?:any,
  sort?:boolean,
  defaultPageSize?: number,
  pageSizes?:any,
  globalSearch?: boolean
  selection?: string,
  pagination?: boolean,
  filter?:boolean,
  editable?:boolean,
  validation?:Function,
  rowClick?:boolean,
  onRowClick?:Function,
  nonEditableFields?:Array<string>,
  formatData?:boolean,
  format?:Function,
  getUpdatedValue?:any
  customAddRow?: any
  customDelete?: any;
  loading?:any;
  childComponent?:any;
  tableFooterData?:any
  fetchAction?: any
  serverTotal?: any
  isServerSide?: any
  pagesCount?: any
  fixedHeader?:any
}
const GridContainer = ({
  className,
  data,
  columns,
  sort,
  defaultPageSize,
  pageSizes,
  globalSearch,
  selection,
  pagination,
  filter,
  editable,
  validation,
  rowClick,
  onRowClick,
  nonEditableFields,
  formatData,
  format,
  getUpdatedValue,
  customAddRow,
  customDelete,
  loading,
  tableFooterData,
  fetchAction,
  serverTotal,
  isServerSide,
  pagesCount,
  fixedHeader
  // dataRow
}:IProps) => {
    return (
    <ReactTable
      className = {`${className} ${editable? 'editableTable': ''}`}
      globalSearch = {globalSearch}
      pageSizes = {pageSizes}
      defaultPageSize = {defaultPageSize}
      sort = {sort}
      columns = {columns}
      data = {data}
      selection = {selection}
      pagination={pagination}
      filter={filter}
      editable={editable}
      validation={validation}
      rowClick={rowClick}
      onRowClick={onRowClick}
      nonEditableFields={nonEditableFields}
      formatData={formatData}
      format={format}
      getUpdatedValue={getUpdatedValue}
      customAddRow={customAddRow}
      customDelete={customDelete}
      loading={loading}
      // dataRow={dataRow}
      fetchAction={fetchAction}
      serverTotal={serverTotal}
      isServerSide={isServerSide}
      pagesCount={pagesCount}
      tableFooterData={tableFooterData? tableFooterData:[]}
      fixedHeader={fixedHeader}
    />
  )
}

export default GridContainer

import { SET_PAYMENT_RECONCILLATION_DATA, SET_PAYMENT_REVERSE, SET_PAYMENT_RECONCILATION_LOADER, SET_UPDATE_CHECK_NUMBER } from './types'
const initialState = {
  paymentReconcillationData: [],
  paymentReverse: [],
  paymentReconcillationLoader: {}
}

const reducer = (state = initialState, action: any = {}) => {
  switch (action.type) {
    case SET_PAYMENT_RECONCILLATION_DATA:
      return {
        ...state,
        paymentReconcillationData: action.payload,
      }
    case SET_PAYMENT_REVERSE:
      return {
        ...state,
        paymentReverse: action.data,
      }
    case SET_PAYMENT_RECONCILATION_LOADER:
      return {
        ...state,
        paymentReconcillationLoader: action.data,
      }
    case SET_UPDATE_CHECK_NUMBER:
      return {
        ...state,
        updateCheckNumber: action.data,
      }
    default: return state
  }
}

export default reducer

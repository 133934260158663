import {
   nav,
   getHeight,
   navProgramEntry,
   navVendorEntry,
   navProductEntry
} from './action'

const dispatchNav = (dispatch:Function) => (data:any) => {
    dispatch(nav(data));
  };
  
const dispatchNavHeight = (dispatch:Function) => (height:any) => {
  dispatch(getHeight(height));
};

const dispatchNavProgramEntry = (dispatch: any) => (flag: any) => {
  dispatch(navProgramEntry(flag));
}

const dispatchNavVendorEntry = (dispatch: any) => (flag:any) => {
  dispatch(navVendorEntry(flag));
}

const dispatchNavProductEntry = (dispatch: any) => (flag:any) => {
  dispatch(navProductEntry(flag));
}

const operations = {
  dispatchNav,
  dispatchNavHeight,
  dispatchNavProgramEntry,
  dispatchNavVendorEntry,
  dispatchNavProductEntry
}

export default operations
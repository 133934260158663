import { ContextMenu } from '../../CommonComponents';
import './Navigation.scss'

interface IProps {
  headerNav?: any;
  ref?: any;
  menuList?: any;
  showContextualMenu?: any;
  spentRef?: any;
  managementRef?: any;
  handleItemClick?: any;
  onShowContextualMenu?: any;
  onHideContextualMenu?: any;
  nav?: any;
  getImageUrl?: any;
  getDireaction?: any;
  handleClick?: any;
  getHiddenState?: any;
  getSelectedRef?: any;
  getActiveClass?: any;
}
const NavigationContainer = (props: IProps) => {

  const {
    menuList,
    handleItemClick,
    onShowContextualMenu,
    onHideContextualMenu,
    getImageUrl,
    getDireaction,
    handleClick,
    getHiddenState,
    getSelectedRef,
    getActiveClass
  } = props;

  return (
    <div className='navigation-wrapper'  id='navigation-wrapper'>
      {}
      <nav className='header-navigation'>
        <ul className={`navmenu-list`}>
          {
            menuList?.length > 0 && menuList.map((menu: any, index: number) => {
              return menu.isContext ?
                <ContextMenu key={index}
                  items={menu.items}
                  hidden={getHiddenState(menu)}
                  target={getSelectedRef(menu)}
                  onItemClick = {(_e: any, item: any) => handleItemClick(item)}
                  onDismiss = {onHideContextualMenu}
                  className="nav-context"
                >          
                  <li 
                    key={index} 
                    ref={getSelectedRef(menu)} 
                    title={menu.Title} 
                    onClick={(e: any) => onShowContextualMenu(e, menu.text)}
                    className={getActiveClass(menu)}
                  >
                    <div onClick={(e:any) => e.preventDefault()}>
                      <img src={getImageUrl(menu.icon)} alt="" />
                      <span>{menu.Item}</span>
                      <span 
                        aria-hidden='true' 
                        className={`fa fa-angle-${getDireaction(menu.text)}`}
                      />
                    </div>
                  </li>
                </ContextMenu>
                :
                <li key={index} title={menu.Title} onClick={() => { handleClick(menu) }} className={getActiveClass(menu)}>
                  <div onClick={(e:any) => e.preventDefault()}>
                    <img src={getImageUrl(menu.icon)} alt="" />
                    <span>{menu.Item}</span>
                  </div>
              </li>
            })
          }
        </ul>
      </nav>
    </div>
  )
}

export default NavigationContainer

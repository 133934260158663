import {
    getCalculationFrequency,
    getCalculationMethod,
    getCalculationType,
    postRebateAdd,
    getRebateDeleteProgram,
    getRebateProgram,
    getRebateProgramById,
    getCreateUpdateRebateProgram,
    setRebateAddEditResponse,
    postRebateEdit,
    getRebatePeriod,
    getRebatePaymentMethod,
    setRebateProgramById,
    getAllContract,
    setAllContract,
    getAddContractName,
    getUpdateContractName,
    getDeleteContractName,
    getContractDetailsById,
    setContractDetailsById,
    getAllContractList,
    getProgramByVendorId,
    getContractByProgramId,
    getAllRebateTypes,
    setProgramError,
    setContractByIdLoading,
    getContractByIdLoading,
    getRebateProgramFieldInfo,
    getContractValidationFlag
 } from './action'
 

const dispatchGetCalculationFrequency= (dispatch:Function) => () => {
    dispatch(getCalculationFrequency());
};
const dispatchGetCalculationMethod= (dispatch:Function) => () => {
    dispatch(getCalculationMethod());
};

const dispatchGetCalculationType= (dispatch:Function) => () => {
    dispatch(getCalculationType());
};

const dispatchGetRebateProgram= (dispatch:Function) => () => {
    dispatch(getRebateProgram());
};

const dispatchGetRebateProgramById= (dispatch:Function) => (id:any) => {
    dispatch(getRebateProgramById(id));
};

const dispatchSetRebateProgramById= (dispatch:Function) => (id:any) => {
    dispatch(setRebateProgramById(id));
};

const dispatchRebateDelete= (dispatch:Function) => (id:any, vendorID: any) => {
    dispatch(getRebateDeleteProgram(id, vendorID));
};
const dispatchRebateAdd = (dispatch:Function) => (data:any, vendorID: any) => {
    dispatch(postRebateAdd(data, vendorID));
};

const dispatchRebateAddEditResponse = (dispatch:Function) => (data:any) => {
    dispatch(setRebateAddEditResponse(data));
};

const dispatchRebateEdit = (dispatch:Function) => (data:any) => {
    dispatch(postRebateEdit(data));
};

const dispatchGetCreateUpdateRebateProgram = (dispatch: Function) => (data: any) => {
    dispatch(getCreateUpdateRebateProgram(data));
}

const dispatchGetRebatePeriod = (dispatch: Function) => () => {
    dispatch(getRebatePeriod());
}

const dispatchGetRebatePaymentMethod = (dispatch: Function) => () => {
    dispatch(getRebatePaymentMethod());
}

const dispatchGetAllContract = (dispatch: Function) => () => {
    dispatch(getAllContract());
}

const dispatchSetAllContract = (dispatch: Function) => (data: any) => {
    dispatch(setAllContract(data));
}

const dispatchGetAddContractName = (dispatch: Function) => (data: any, programID: any) => {
    dispatch(getAddContractName(data, programID));
}

const dispatchGetUpdateContractName = (dispatch: Function) => (data: any) => {
    dispatch(getUpdateContractName(data));
}

const dispatchGetDeleteContractName = (dispatch: Function) => (data: any, programID: any) => {
    dispatch(getDeleteContractName(data, programID));
}

const dispatchGetContractDetailsById = (dispatch: Function) => (data: any) => {
    dispatch(getContractDetailsById(data));
}

const dispatchSetContractDetailsById = (dispatch: Function) => (data: any) => {
    dispatch(setContractDetailsById(data));
}

const dispatchGetAllContractList = (dispatch: Function) => () => {
    dispatch(getAllContractList());
}

const dispatchGetProgramByVendorId = (dispatch: Function) => (data: any) => {
    dispatch(getProgramByVendorId(data));
}

const dispatchGetContractByProgramId = (dispatch: Function) => (data: any) => {
    dispatch(getContractByProgramId(data));
}

const dispatchGetAllRebateTypes = (dispatch: Function) => () => {
    dispatch(getAllRebateTypes());
}

const dispatchSetProgramError = (dispatch: Function) => (data: any) => {
    dispatch(setProgramError(data));
}

const dispatchSetContractByIdLoading = (dispatch: Function) => (flag: any) => {
    dispatch(setContractByIdLoading(flag));
}

const dispatchGetContractByIdLoading = (dispatch: Function) => () => {
    dispatch(getContractByIdLoading());
}

const dispatchGetRebateProgramFieldInfo = (dispatch: Function) => () => {
    dispatch(getRebateProgramFieldInfo());
}

const dispatchGetContractValidation = (dispatch: Function) => (data: any) => {
    dispatch(getContractValidationFlag(data));
}

const operations = {
    dispatchGetCalculationFrequency,
    dispatchGetCalculationMethod,
    dispatchGetCalculationType,
    dispatchGetRebateProgram,
    dispatchGetRebateProgramById,
    dispatchRebateDelete,
    dispatchRebateAdd,
    dispatchRebateAddEditResponse,
    dispatchGetCreateUpdateRebateProgram,
    dispatchRebateEdit,
    dispatchGetRebatePeriod,
    dispatchGetRebatePaymentMethod,
    dispatchSetRebateProgramById,
    dispatchGetAllContract,
    dispatchSetAllContract,
    dispatchGetAddContractName,
    dispatchGetUpdateContractName,
    dispatchGetDeleteContractName,
    dispatchGetContractDetailsById,
    dispatchSetContractDetailsById,
    dispatchGetProgramByVendorId,
    dispatchGetContractByProgramId,
    dispatchGetAllContractList,
    dispatchGetAllRebateTypes,
    dispatchSetProgramError,
    dispatchSetContractByIdLoading,
    dispatchGetContractByIdLoading,
    dispatchGetRebateProgramFieldInfo,
    dispatchGetContractValidation
}
 
 export default operations
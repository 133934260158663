import React from 'react';
import {Search,Row,Col,InputField,Alert, Tooltip} from '../../../../CommonComponents'
import Add from '../../../../assets/images/Add.png';
import Delete from '../../../../assets/images/Delete.png'
import './AddEditContract.scss'
import Edit from '../../../../assets/images/edit.png';
import Save from '../../../../assets/images/Save.svg';
import { isAuthorized } from 'client/utils/Permissions';


interface Iprops {
    handleAddEditContractInputChange: Function,
    onAddContract: React.MouseEventHandler<HTMLDivElement>
    addEditContractList:any,
    addContractText:string,
    onDeleteContract:Function,
    onSearchContract: Function,
    onEditSave:React.MouseEventHandler<HTMLImageElement>,
    programSaved: boolean,
    programDeleted?: boolean,
    handleMessage:Function,
    selectedContract?: any;
    onEditClick?: any;
    handleEditContract?: any;
    programError?: any
}

const AddEditContract = (props:Iprops) => {
    return <div className='AddEditPopup'>
         <div className='clearfix'>
            <div className='float-left'>
                <p className='boldText mt-2'>Contract Name</p>
            </div>
            <div className='float-right'>
                <Search
                    className={''}
                    placeholder={'Search'}
                    onChange={props.onSearchContract}
                />
            </div>
        </div>
        <div className='mt-3' id='contract-list'>
        <Alert showAlert={props.programSaved} text='Saved Successfully' dismissAlert={props.handleMessage} type="success" className="mb-2" />
        <Alert showAlert={props.programDeleted} text='Disabled Successfully' dismissAlert={props.handleMessage} type="warning" className="mb-2" />
        <Alert showAlert={props.programError} text={props.programError || 'Something went wrong please try after sometime'} dismissAlert={props.handleMessage} type="error" className="mb-2" />
                <Row className='mb-2'>
                    <Col xs={12} lg={11}>
                        <InputField name="Program Name" placeholder='Enter contract name' value={props.addContractText} onChange={props.handleAddEditContractInputChange}/>
                    </Col>
                    <Col xs={12} lg={1}>
                    <Tooltip content='Add New Contract'>
                        <div className='addBtn'  onClick={props.onAddContract}>
                            <img src={Add} alt='addImg'/>
                        </div>
                    </Tooltip>
                    </Col>

                </Row>
                <div className='listBox'>
                {
                    props.addEditContractList.length ?
                    props.addEditContractList.map((item:any,index:number) => {
                        return   (
                        <Row className='mb-2' key={index}>
                            {
                                props?.selectedContract?.key === item.key && !props.programSaved ?
                                <>
                                    <Col xs={12} lg={11}>
                                        <InputField name="Program Name" value={props.selectedContract.label} onChange={props.handleEditContract} />
                                    </Col>
                                    <Col xs={12} lg={1}>
                                        <Tooltip content='Save' >
                                            <div className='deleteBtn' onClick={props.onEditSave}>
                                                <img src={Save} alt='Save'/>
                                            </div>
                                        </Tooltip>
                                    </Col>
                                </> :
                                <>
                                    <Col xs={12} lg={11}>
                                        <div className='list'>
                                            <div className='clearfix'>
                                                    <div className='float-left'>
                                                        {item.label}
                                                    </div>
                                                    <div className='float-right'>
                                                        {
                                                         isAuthorized("RebateAgreements", "update") &&       
                                                        <Tooltip content='Edit' >
                                                            <img src={Edit} className='editIcon' alt='edit' onClick={()=>props.onEditClick(item)}/>
                                                        </Tooltip>
                                                        }
                                                    </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={1}>
                                    {
                                        isAuthorized("RebateAgreements", "delete") && 
                                        <Tooltip content='Disable' >
                                            <div className='deleteBtn' onClick={()=> props.onDeleteContract(index,item)}>
                                                <img src={Delete} alt='deleteImg'/>
                                            </div>
                                        </Tooltip>
                                    }
                                    </Col>
                                </>
                            }
                        </Row>
                    )})
                    : null
                }
                </div>
            </div>
    </div>
}

export default AddEditContract;
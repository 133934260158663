import {
  GET_COUNTRY_DROPDOWN,
  SET_COUNTRY_DROPDOWN,
  GET_BRANCH_VALUES,
  SET_BRANCH_VALUES,
  INSERT_USER_DETAIL,
  SET_RESULT_MESSAGE,
  GET_TITLE_DETAILS,
  SET_TITLE_DETAILS,
  GET_DEPARTMENT_DETAILS,
  SET_DEPARTMENT_DETAILS,
  GET_USER_DETAIL,
  SET_USER_DETAIL,
  UPDATE_USER_DETAILS,
  SET_USER_NAME,
  SET_USER_ROLE,
  GET_USER_ROLES_CLAIMS,
  SET_USER_ROLES_CLAIMS
} from './types';

const getCountryDropdown = () => {
    return {
      type: GET_COUNTRY_DROPDOWN
    }
}

const setCountryDropdown = (countryDropdown:any) => {
    return {
      type: SET_COUNTRY_DROPDOWN,
      countryDropdown
    }
}
const getTitleDetails = () => {
  return {
    type: GET_TITLE_DETAILS
  }
}

const setTitleDetails = (titleDetails:any) => {
  return {
    type: SET_TITLE_DETAILS,
    titleDetails
  }
}
const getDepartmentDetails = () => {
  return {
    type: GET_DEPARTMENT_DETAILS
  }
}

const setDepartmentDetails = (departmentDetails:any) => {
  return {
    type: SET_DEPARTMENT_DETAILS,
    departmentDetails
  }
}
const getBranchValues = () => {
  return {
    type: GET_BRANCH_VALUES,
  }
}

const setBranchValues = (value:any) => {
return {
  type: SET_BRANCH_VALUES,
  value
}
}

const insertUserDetail = (value:any) => {
  return {
    type: INSERT_USER_DETAIL,
    value
  }
}
const getUserDetails =(user:any) => {
  return {
    type: GET_USER_DETAIL,
    user
  }
}
const setUserDetails =(value:any) => {
  return {
    type: SET_USER_DETAIL,
    value
  }
}
const setResultMessage = (value:string) => {
  return {
    type: SET_RESULT_MESSAGE,
    value
  }
}

const updateUserDetails =(value:any,id:any) => {
  return {
    type: UPDATE_USER_DETAILS,
    value,id
  }
}

const setUsername =(name:any) => {
  return {
    type: SET_USER_NAME,
    name
  }
}

const setUserRole =(role:any) => {
  return {
    type: SET_USER_ROLE,
    role
  }
}

const getUserRolesAndClaims = (userId: any) => {
  return {
    type: GET_USER_ROLES_CLAIMS,
    userId
  }
}

const setUserRolesAndClaims = (value:any) => {
  return {
    type: SET_USER_ROLES_CLAIMS,
    value
  }
}

export {
  getBranchValues,
  setBranchValues,
  insertUserDetail,
  setResultMessage,
  getTitleDetails,
  setTitleDetails,
  getDepartmentDetails,
  setDepartmentDetails,
  getUserDetails,
  setUserDetails,
  getCountryDropdown,
  setCountryDropdown,
  updateUserDetails,
  setUsername,
  setUserRole,
  getUserRolesAndClaims,
  setUserRolesAndClaims
}
import { SET_MANUFACTURER_REPORT_DATA, SET_REPORT_DATA, SET_REPORT_DATA_BY_YEAR, SET_SPEND_REBATE_DASHBOARD, SET_ACCOUNTANDFINANCE_REPORT } from './types'
const initialState = {
  reportData: {},
  manufacturerReportData: {},
  accountAndFinanceReport: {}
}

const reducer = (state = initialState, action:any = {}) => {
  switch (action.type) {
    case SET_REPORT_DATA:
      return {
        ...state,
        reportData: action.payload,
      };
    case SET_REPORT_DATA_BY_YEAR:
      return {
        ...state,
        reportDataByYear: action.payload,
      };
    case SET_SPEND_REBATE_DASHBOARD:
      return {
        ...state,
        spendRebateDashboard: action.payload,
      };
      case SET_MANUFACTURER_REPORT_DATA:
      return {
        ...state,
        manufacturerReportData: action.payload,
      };
      case SET_ACCOUNTANDFINANCE_REPORT:
        return {
          ...state,
          accountAndFinanceReport: action.payload,
        }
    default:
      return state
  }
}

export default reducer
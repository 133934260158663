import { combineReducers } from 'redux';

import {reducers as CommonDashboard} from './CommonDashboard';
import {reducers as nav} from './Navigation';
import {reducers as UserProfile} from './UserProfile';
import {reducers as SupplierEntry} from './RebateManagement/SupplierEntry';
import {reducers as CategoryEntry} from './RebateManagement/ProductCategoryEntry';
import {reducers as SubCategoryEntry} from './RebateManagement/ProductSubCategoryEntry';
import {reducers as ProductEntry} from './RebateManagement/ProductEntry';
import {reducers as RebateProgramEntry} from './RebateManagement/RebateProgramEntry';
import {reducers as RebateSpendIndividual} from './RebateSpent/IndividualEntry';
import {reducers as PaymentReconcillations} from './RebateManagement/PaymentReconcillations';
import {reducers as Reports} from './Reports';
import { reducers as BulkUpload } from "./RebateSpent/BulkUpload";
import { reducers as SelfReporting } from "./RebateManagement/SelfReporting";
import { reducers as CollectionTracking } from "./RebateManagement/CollectionTracking";

const reducers = combineReducers(
    {
        CommonDashboard,
        nav,
        UserProfile,
        SupplierEntry,
        CategoryEntry,
        SubCategoryEntry,
        ProductEntry,
        RebateProgramEntry,
        RebateSpendIndividual,
        PaymentReconcillations,
        Reports,
        BulkUpload,
        SelfReporting,
        CollectionTracking
    }
)

export default reducers

import React from 'react'
import { Contextmenu } from '@softura/fluentui-basiccomponents'
import './ContextMenu.scss'

interface IProps {
  className?: string | number,
  items?: any,
  hidden?: any,
  target?: any,
  onItemClick?: any,
  onDismiss?: any,
  children?: any,
  onClick?: any,
  ref?: any
}
const ContextMenuContainer = ({
  className,
  children,
  ref,
  onClick,
  items,
  hidden,
  target,
  onItemClick,
  onDismiss
}:IProps) => {
  return (<>
    <a ref={ref} onClick={onClick} href="/">
        {children}
    </a>
    <Contextmenu
      className = {`${className}`}
      items={items}
      hidden={!hidden}
      target={target}
      onItemClick={onItemClick}
      onDismiss={onDismiss}
    />
  </>)
}

export default ContextMenuContainer

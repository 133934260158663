import { SET_STATES, SET_COUNTRIES, SET_SUPPLIERDATA, SET_SUPPLIER, SET_MANUFACTURER, SET_SAVEDSUPPLIERFLAG, SET_ERRORSUPPLIER, SET_SUPPLIERNAME, SET_CATEGORY_MANAGER, SET_PRIMARY_ABNUMBER, SET_SUPPLIER_DETAILS_BY_ABNUMBER, SET_SECONDARY_ABNUMBER_BY_SUPPLIER, SET_SECONDARY_ABNUMBER } from './types'
const initialState = {
  states: [],
  countries: [],
  suppliersData: [],
  supplier: {},
  manufacturerdata: [],
  supplierSaved: false,
  errorSupplier: "",
  supplierName: "",
  categoryManager: []
}

const reducer = (state= initialState, action:any = {}) => {
  switch (action.type) {
    case SET_STATES:
      return {
        ...state,
        states: action.payload,
      }
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      }
    case SET_SUPPLIERDATA:
      return {
        ...state,
        suppliersData: action.payload,
      }
    case SET_SUPPLIER:
      return {
        ...state,
        supplier: action.payload,
      }
    case SET_MANUFACTURER:
      return {
        ...state,
        manufacturerdata: action.payload,
      }
    case SET_SAVEDSUPPLIERFLAG:
      return {
        ...state,
        supplierSaved: action.payload,
      }
    case SET_ERRORSUPPLIER:
      return {
        ...state,
        errorSupplier: action.payload
      }
    case SET_SUPPLIERNAME:
      return {
        ...state,
        supplierName: action.payload
      }
    case SET_CATEGORY_MANAGER:      
      return {
        ...state,
        categoryManager: action.payload,
      }
    case SET_PRIMARY_ABNUMBER:      
      return {
        ...state,
        primaryAbnumber: action.payload,
      }
    case SET_SECONDARY_ABNUMBER:      
      return {
        ...state,
        secondaryAbnumber: action.payload,
      }
    case SET_SUPPLIER_DETAILS_BY_ABNUMBER:      
      return {
        ...state,
        supplierDetailsByAbnumber: action.payload,
      }
    case SET_SECONDARY_ABNUMBER_BY_SUPPLIER:      
      return {
        ...state,
        secondaryAbnumberBySupplier: action.payload,
      }
    default: return state
  }
}

export default reducer

import { Radio } from '@softura/fluentui-basiccomponents'
import './Radio.scss';

interface IProps {
  options?: any,
  onChange?: Function,
  selectedKey?: any,
  id?:any
  size?:any
}
const RadioContainer = ({
    options,
    onChange,
    selectedKey,
    id,
    size
}:IProps) => {
  return (<Radio id={id} className={`${size}`} options={options} selectedKey={selectedKey} onChange={onChange} />)
}

export default RadioContainer

import { SET_PRODUCTDATA, SET_PRODUCT, SET_SAVEDPRODUCTFLAG, SET_ERRORPRODUCT, SET_PRODUCTNAME } from './types'
const initialState = {
  product: {},
  productData: [],
  productSaved: false,
  errorProduct: "",
  productName: "",
}

const reducer = (state = initialState, action:any = {}) => {
  switch (action.type) {
    case SET_PRODUCTDATA:
      return {
        ...state,
        productData: action.payload,
      }
    case SET_PRODUCT:
      return {
        ...state,
        product: action.payload,
      }
    case SET_SAVEDPRODUCTFLAG:
      return {
        ...state,
        productSaved: action.payload,
      }
    case SET_ERRORPRODUCT:
      return {
        ...state,
        errorProduct: action.payload
      }
    case SET_PRODUCTNAME:
        return {
          ...state,
          productName: action.payload
        }
    default: return state
  }
}

export default reducer

import React from 'react'
import { TreeviewDropdown } from '@softura/fluentui-basiccomponents'
import './TreeviewDropdown.scss'

interface IProps {
  onSelect?:any,
  placeholder?:any,
  maxHeight?:any,
  treeData?:any,
  className?:any
}

const TreeviewDropdownContainer = ({maxHeight, treeData, onSelect, className }:IProps) => {
  return (
    <TreeviewDropdown
      treeData={treeData}
      onSelect= {onSelect}
      maxHeight= {maxHeight}
      classname={`custom-treeview-dropdown ${className}`}
    />
  )
}

export default TreeviewDropdownContainer

import React, { useEffect, useState } from 'react';
import RebateProgramEntry, { IBreadcrumb } from './RebateProgramEntry';
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { SortDirection } from 'ka-table/enums';
import './RebateProgramEntry.scss';
import { operations,selectors } from 'state/features/RebateManagement/RebateProgramEntry';
import categoryEntrySelector from '../../../../state/features/RebateManagement/ProductCategoryEntry/selectors';
import categoryEntryOperations from '../../../../state/features/RebateManagement/ProductCategoryEntry/operations';
import subCategoryEntrySelector from '../../../../state/features/RebateManagement/ProductSubCategoryEntry/selectors';
import subCategoryEntryOperations from '../../../../state/features/RebateManagement/ProductSubCategoryEntry/operations';
import productEntrySelector from '../../../../state/features/RebateManagement/ProductEntry/selectors';
import productEntryOperations from '../../../../state/features/RebateManagement/ProductEntry/operations';
import supplierEntrySelectors from '../../../../state/features/RebateManagement/SupplierEntry/selectors';
import supplierEntryOperations from '../../../../state/features/RebateManagement/SupplierEntry/operations';
import userSelector from '../../../../state/features/UserProfile/selectors';
import navOperations from '../../../../state/features/Navigation/operations';
import navSelectors from '../../../../state/features/Navigation/selectors';
import Edit from '../../../assets/images/edit.png';
import { Search } from 'client/CommonComponents';
import moment from "moment";
import { ACCESS_DENIED, isAuthorized } from 'client/utils/Permissions';
import constants from 'client/utils/constants';
import ErrorHandling from "../../../CommonComponents/ErrorHandling/ErrorHandling"
import { setContractValidationFlag } from 'state/features/RebateManagement/RebateProgramEntry/action';


type gridColumnType = {
    key: string,
    title: string,
    width: string,
    FilterCell?: any,
    Cell?: any,
    sortDirection?:any
}

const RebateProgramEntryContainer = () => {
    const navigate = useNavigate();
    const handleHome = () => {
        navigate("/");
    }
    const today = new Date(moment().format("YYYY-MM-DD"))
    const [count,setCount] = useState(0);
    const [deadLineDays,setDeadLineDays] = useState(0);
    const [gridData, setGridData] = useState<any>([]);
    const [isEditMode, setEditMode] = useState(false);
    const [newProgram, setNewProgram] = useState(false);
    const [allContractDetails, setAllContractDetails] = useState<any>([]);
    const [allActiveContracts, setActiveContracts] = useState<any>([]);
    const [otherRebatePeriodDisabled, setOtherRebatePeriodDisabled] = useState(false);
    const [addTier, setAddTier] = useState(false);
    const [tierErrors, setTierErrors] = useState<any>({});
    const [saveTierBtn, setSaveTierBtn] = useState(false);
    const [tierDescription, setTierDescription] = useState<any>({});
    const [selectedCalculationType, setSelectedCalculationType] = useState();
    const [selectedProducts, setSelectedProducts] = useState<any>([]);
    const [selectedCategory, setSelectedCategory] = useState<any>([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState<any>([]);
    const [tableDataLoading, setTableDataLoading] = useState(false)
    const [isView, setIsView] = useState(false)
    const [currentRebateCheckDateData, setCurrentRebateCheckDateData] = useState<any>({})
    const [tempExtendPopup, setTempExtendPopup] = useState(false);
    const [contractValidation, setContractValidation] = useState(false);
    const [contractWarning, setContractWarning] = useState(false);
    const [values,setValues] = useState<any>({
        startDate: '',
        endDate: '',
        billingDate: '',
        withinTerms: '',
        expectedPaymentDate: '',
        productId: "",
        categoryId:"",
        subCategoryId:"",
        supplierId: '',
        active: true,
        programYear: null
    });

    useEffect(()=> {
        setValues({
            ...values,
            Quantity: count
        })
        handleNetDaysCheck(count)
    },[count]);

    useEffect(()=>{
        if(!gridData.length){
            setTableDataLoading(true)
        }else{
            setTableDataLoading(false)
        }
    }, [gridData.length])

    const increment = () => {
        if (count < 365) {
            setCount(count+1);
        }
    }

    const decrement = () => {
        if(count !== 0 ){
            setCount(count-1);
        }
    }

    const handleNetDays = (e: any) => {
        const {value} = e.target;
        const newValue = Number(value);
        if (newValue <= 365 && newValue >= 0) {
            setCount(newValue);
        }
    }

    const incrementDeadLine = () => {
        if (deadLineDays < 365) {
            setDeadLineDays(deadLineDays + 1);
        }
    }

    const decrementDeadLine = () => {
        if(deadLineDays !== 0 ){
            setDeadLineDays(deadLineDays - 1);
        }
    }
    
    const handleDeadLineDays = (e: any) => {
        const {value} = e.target;
        const newValue = Number(value);
        if (newValue <= 365 && newValue >= 0) {
            setDeadLineDays(newValue);
        }
    }

    const breadcrumlist: IBreadcrumb[] = [
        {
          text: "Rebate Management",
          key: "f1",
          // href: "/Home",
          // onClick: (e: any) => {
          //   e.preventDefault();
          //   handleHome();
          // },
        },
      ];
      if ((isEditMode || newProgram) && !isView) {
        breadcrumlist.push(
          {
            text: "Rebate Program List",
            key: "f2",
            onClick: () => cancelClick(),
          },
          { text: "Rebate Program Add/Edit", key: "f3" }
        );
      } else if(isEditMode && isView){
          breadcrumlist.push(  {
              text: "Rebate Program List",
              key: "f2",
              onClick: () => cancelClick(),
            },
            { text: 'Rebate Program', key: "f3" } 
          )
      } else {
        breadcrumlist.push({ text: "Rebate Program List", key: "f2" });
      }

    const [productDisabled,setProductDisabled] = useState(true);
    const [productCategoryDisabled,setproductCategoryDisabled] = useState(true);
    const [productSubCategoryDisabled,setproductSubCategoryDisabled] = useState(true);
    const [percentageDisabled,setPercentageDisabled] = useState(true);
    const [salesForceDisabled,setSalesForceDisabled] = useState(true);

    const [product,setProduct] = useState(false);
    const [productCategory,setProductCategory] = useState(false);
    const [productSubCategory,setProductSubCategory] = useState(false);
    const [growthTarget,setGrowthTarget] = useState(false);
    const [disabledPercentageText,setdisabledPercentageText] = useState(true);
    const [disabledSalesGoalText,setDisabledSalesGoalText] = useState(true);
    const [percentageCheck,setPercentageCheck] = useState(false);
    const [salesGoalCheck,setSalesGoalCheck] = useState(false);
    const [addEditList,setAddEditList] = useState<any>([]);
    const [addEditContractList,setAddEditContractList] = useState<any>([]);
    const [duplicateAddEditList,setDuplicateAddEditList] = useState<any>([]);
    const [duplicateAddEditContractList,setDuplicateAddEditContractList] = useState<any>([]);
    const [rebatePeriodOptions, setRebatePeriodOptions] = useState([]);
    const [rebatePaymentMethod, setRebatePaymentMethod] = useState([]);
    const [addEditContract, setAddEditContact] = useState(false);
    const [isShowDisabled, setIsShowDisabled] = useState(false);

    const onChangeProduct = (_ev:any,checked:boolean) => {
        setProduct(checked)
        setProductDisabled(!checked);
        setValues({
            ...values,
            productId: [],
        })
        setSelectedProducts([]);
        if (!checked && !productCategory && !productSubCategory) {
            onAllSpendCheck("", true)
        }
    }
    const onChangeproductCategory = (_ev:any,checked:boolean) => {
        setProductCategory(checked)
        setproductCategoryDisabled(!checked)
        setValues({
            ...values,
            categoryId: [],
        })
        setSelectedCategory([]);
        if (!checked && !product && !productSubCategory) {
            onAllSpendCheck("", true)
        }
    }
    const onChangeProductSubCategory = (_ev:any,checked:boolean) => {
        setProductSubCategory(checked)
        setproductSubCategoryDisabled(!checked)
        setValues({
            ...values,
            subCategoryId: [],
        })
        setSelectedSubCategory([]);
        if (!checked && !productCategory && !product) {
            onAllSpendCheck("", true)
        }
    }
    const onChangeGrowthTarget = (_ev:any,checked:boolean) => {
        setGrowthTarget(checked)
        setPercentageDisabled(!checked);
        setSalesForceDisabled(!checked)
        setPercentageCheck(checked);
        setSalesGoalCheck(checked);
    }
    const onChangePercentage = (_ev:any,checked:boolean) => {
        setPercentageCheck(checked);
        setSalesGoalCheck(!checked)
        setdisabledPercentageText(!checked)
        Object.keys(values).forEach((key)=> {
            getValidateData(key,values[key]);
        })
        if (checked) {
            setSalesGoal(0)
        } else {
            setPercentage(0);
        }
    }
    const onChangeSalesGoal = (_ev:any,checked:boolean) => {
        setSalesGoalCheck(checked);
        setPercentageCheck(!checked)
        setDisabledSalesGoalText(!checked)
        Object.keys(values).forEach((key)=> {
            getValidateData(key,values[key]);
        })
        if (checked) {
            setPercentage(0);
        } else {
            setSalesGoal(0)
        }
    }
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    const [percentage,setPercentage] = useState(0);
    const [salesGoal,setSalesGoal] = useState<any>(Number(0.00).toFixed(2));
    const [dollorAmount,setDollorAmount] = useState<any>(0);
    const [noOfYears,setNoOfYears] = useState<any>(0);
    const [id,setId]= useState(0);
    const [monthList,setMonthList] = useState(months[0]);
    const [year, setYear] = useState<any>();
 
    const [AllSpend,setAllSpend] = useState(false);
    const [enableAllSpend,setEnableAllSpend] = useState(true);
    const [showSupplier,setShowSupplier] = useState(false)
    const [showProduct,setShowProduct] = useState(false);
    const [showProductCategory,setShowProductCategory] = useState(false)
    const [showProductSubCategory,setShowProductSubCategory] = useState(false)
    const [submitClicked,setSubmitClicked] = useState(false);
    const [submitFlag,setSubmitFlag] = useState(false);
    const [rebatePercentage,setRebatePercentage] = useState(0);
    const [renewContractClicked, setRenewContractClicked] = useState<boolean>(false)
    const [isExcludeCreditCardSpend, setIsExcludeCreditCardSpend] = useState<boolean>(false);

    const onAllSpendCheck = (_ev:any,checked:boolean) => {
        setAllSpend(checked)
        setEnableAllSpend(checked)
        setProduct(!checked)
        setProductCategory(!checked);
        setProductSubCategory(!checked);
        setProductDisabled(checked);
        setproductCategoryDisabled(checked)
        setproductSubCategoryDisabled(checked)
        setSelectedProducts([]);
        setSelectedCategory([]);
        setSelectedSubCategory([]);
    }

    const excludeCreditCardSpendChange = (_ev:any,checked:boolean) => {
        setIsExcludeCreditCardSpend(checked)
    }

    const onUpdateAllSpendCheck = (_ev:any,checked:boolean) => {
        setAllSpend(checked)
        setEnableAllSpend(checked)
        setProductDisabled(checked);
        setproductCategoryDisabled(checked)
        setproductSubCategoryDisabled(checked)
    }
    
    useEffect(()=>{
        if (contractList?.length) {
            const rebateContractGridData = contractList?.map((item:any) => {
                return {
                    name: item?.contractName,
                    programName: item?.programName,
                    vendorName: item?.supplierName,
                    rebateType: item?.rebateType,
                    rebatePeriod: item?.rebatePeriod,
                    paymentMethod: item.paymentMethod,
                    id: item.contractId,
                    isActive: item.isActive,
                    everGreen: item.everGreen ? 'Yes' : 'No',
                    tempExtendContract: item.tempExtendContract ? 'Yes' : 'No'
                }
            })
            const list = rebateContractGridData?.filter((item:any)=>item.isActive == !isShowDisabled)
            setGridData(list);
        }

        if(isShowDisabled)
            {
                let activeContracts:any = allContractDetails.filter((o:any) => o.active === true)
                setActiveContracts(activeContracts);     
            }
            else
            {
                setActiveContracts(allContractDetails);
            }
    },[isShowDisabled])
    
    useEffect(()=>{
        setValues({
            ...values,
            OtherPeriodRebateStartMonth: monthList
        })
    },[monthList])
    
    useEffect(()=> {  
        setValues({
            ...values,
            Percentage: percentage
        })
        getValidateData("Percentage", percentage);
    },[percentage]);

    useEffect(()=> {  
        setValues({
            ...values,
            rebatePercentage: rebatePercentage
        })
        getValidateData("rebatePercentage", rebatePercentage);
    },[rebatePercentage]);

    useEffect(()=> {
        setValues({
            ...values,
            SalesGoal: salesGoal
        })
        getValidateData("SalesGoal", salesGoal);
    },[salesGoal]);
    
    useEffect(() => {
        if(!isShowDisabled){            
        let activeContracts:any = allContractDetails.filter((i:any) => i.active === true)
        setActiveContracts(activeContracts);     
        }
        else
        {
            setActiveContracts(allContractDetails)
        }   
    },[allContractDetails,isShowDisabled])

    const percentageIncrement = () => {
        if(percentage < 99) {
            setPercentage(Number(percentage)+1);
        }
    }
    const percentageDecrement = () => {
        if(percentage > 0 ){
            setPercentage(Number(percentage)-1);
        }
    }
    
    const onChangePercentageValue = (e:any) => {
        setPercentage(e.target.value);
    }

    const rebatePercentageIncrement = () => {
        if(rebatePercentage < 99) {
            setRebatePercentage(Number(rebatePercentage)+1);
        }
    }
    const rebatePercentageDecrement = () => {
        if(rebatePercentage > 0 ){
            setRebatePercentage(Number(rebatePercentage)-1);
        }
    }
    
    const onChangeRebatePercentageValue = (e:any) => {
        setRebatePercentage(e.target.value);
    }

    const onChangeDollorAmount = (e: any) => {
        setDollorAmount(e.target.value);
    }

    const dollorAmountIncrement = () => {
        setDollorAmount(Number(dollorAmount)+1);
    }

    const dollorAmountDecrement = () => {
        if(dollorAmount !== 0 ){
            setDollorAmount(Number(dollorAmount)-1);
        }
    }
    const onChangeNoOfYears = (e: any) => {
        setNoOfYears(e.target.value);
    }

    const noOfyearsIncrement = () => {
        setNoOfYears(Number(noOfYears)+1);
    }

    const noOfyearsDecrement = () => {
        if(noOfYears !== 0 ){
            setNoOfYears(Number(noOfYears)-1);
        }
    }

    useEffect(() => {
        setValues({
            ...values,
            dollor: dollorAmount
        })
        getValidateData("dollor", dollorAmount)
    }, [dollorAmount])
    
    useEffect(() => {
        setValues({
            ...values,
            noOfYears: noOfYears
        })
        getValidateData("noOfYears", noOfYears)
    }, [noOfYears])

    useEffect(() => {
        setValues({
            ...values,
            programYear: year
        })
        getValidateData("programYear", year)
    }, [year])

    const salesGoalIncrement = () => {
        if(salesGoal < 100000000) {
            setSalesGoal((Number(salesGoal)+1).toFixed(2));
        }
    }

    const salesGoalDecrement = () => {
        if(salesGoal > 0.00 ){
            setSalesGoal((Number(salesGoal)-1).toFixed(2));
        }
    }
    const onChangeSalesGoals = (e:any) => {
        setSalesGoal(e.target.value);
    }
    const onBlurSalesGoal = () => {
        setSalesGoal((Number(salesGoal).toFixed(2)));
    }

    const onMonthIncrement = () => {
        if(id !== months.length - 1) {
            let newId = id+1;
            setMonthList(months[newId]);
            setId(newId);
        }
        else {
            let newId = 0;
            setMonthList(months[newId]);
            setId(newId)
        }
    }
    const onMonthDecrement = () => {
        if(id !== 0) {
            let newId = id-1;
            setMonthList(months[newId]);
            setId(newId)
        }
        else {
            let newId = months.length -1 ;
            setMonthList(months[newId]);
            setId(newId)
        }
    }

    const onYearIncrement = () => { 
        const endYear = new Date(values.endDate).getFullYear();
        if (Number(year) + 1 > endYear) {
            return;
        } else {
            setYear(String(Number(year) + 1))
        }
    }

    const onYearDecrement = () => {
        const startYear = new Date(values.startDate).getFullYear();
        if (Number(year) - 1 < startYear) {
            return;
        } else {
            setYear(String(Number(year) - 1))
        }
    }

    const handleProgramYearChange = (e: any) => {
        const {value} = e.target;
        if (value.length <= 4 ) {
            setYear(e.target.value);
            checkForValidYear(e.target.value)
        }
    }

    const [idEnd,setIdEnd]= useState(11);
    const [monthListEnd,setMonthListEnd] = useState(months[11]);
    const onMonthIncrementEnd = () => {
        if(idEnd !== months.length - 1) {
            let newId = idEnd+1;
            setMonthListEnd(months[newId]);
            setIdEnd(newId);
        }
        else {
            let newId = 0;
            setMonthListEnd(months[newId]);
            setIdEnd(newId)
        }
    }
    const onMonthDecrementEnd = () => {
        if(idEnd !== 0) {
            let newId = idEnd-1;
            setMonthListEnd(months[newId]);
            setIdEnd(newId)
        }
        else {
            let newId = months.length -1 ;
            setMonthListEnd(months[newId]);
            setIdEnd(newId)
        }
    }
    useEffect(()=>{
        setValues({
            ...values,
            OtherPeriodRebateEndMonth: monthListEnd
        })
    },[monthListEnd])

    
    const initialdata:any = [
        {
            Tier: '1',
            MinValue: '$0',
            MaxValue: '$100,000',
            Percentage: '10%',
            id:0,
        }
      ]
    const [data, setData] = useState<any>([]);
    const [column, setColumn] = useState([]);
    const [showGrid, setShowGrid] = useState(false);
    const [selectedType, setselectedType] = useState("value");

    const getColTitle = (field: any, value: any) => {
        if (value) {
            return field?.replace("Value", value);
        } else {
            return field;
        }
    }

    const setInitialCol = (value?: any) => {
        setShowGrid(false)
        const tempColumn: any = [
            {
                key: "Tier",
                title: "Tier",
                width: "120px",
                editable: false,
                FilterCell: (props: any) => <CustomFilterCell {...props} />
            },
            {
                key: "MinValue",
                title: getColTitle("Min Value", value),
                width: "120px",
                FilterCell: (props: any) => <CustomFilterCell {...props} />
            },
            {
                key: "MaxValue",
                title: getColTitle("Max Value", value),
                width: "120px",
                FilterCell: (props: any) => <CustomFilterCell {...props} />
            },
            {
                key: "Percentage",
                title: "Rebate Percentage",
                width: "120px",
                FilterCell: (props: any) => <CustomFilterCell {...props} />
            },
            { key: 'editColumn',
              width: "80px",
              style: {textAlign: 'center'},
              FilterCell: null
            },
        ]
        setColumn(tempColumn);
        setTimeout(() => {
            setShowGrid(true);
        })
    }

    useEffect(() => {
        setData([])
        if (percentageCheck) {
            setselectedType("percentage");
            setInitialCol("Percentage")
        } else if (salesGoalCheck) {
            setselectedType("salesGoal");
            setInitialCol("SalesGoal")
        } else {
            setselectedType("value");
            setInitialCol()
        }
    }, [percentageCheck, salesGoalCheck])

    const dispatch = useDispatch();
    const getProductCategory = categoryEntryOperations.dispatchGetCategoryData(dispatch);
    const dispatchSetCategoryName = categoryEntryOperations.dispatchSetCategoryName(dispatch);
    const getProduct = productEntryOperations.dispatchGetProductData(dispatch);
    const dispatchSetProductName = productEntryOperations.dispatchSetProductName(dispatch);
    const getSubCategory = subCategoryEntryOperations.dispatchGetSubCategoryData(dispatch);
    const dispatchSetSubCategoryName = subCategoryEntryOperations.dispatchSetSubCategoryName(dispatch);
    const getSupplier = supplierEntryOperations.dispatchGetSupplierdata(dispatch);
    const dispatchSetSupplierName = supplierEntryOperations.dispatchSetSupplierName(dispatch);
    const getCalculationFrequency = operations.dispatchGetCalculationFrequency(dispatch);
    const getCalculationMethod = operations.dispatchGetCalculationMethod(dispatch);
    const getCalculationType = operations.dispatchGetCalculationType(dispatch);
    const dispatchGetCreateUpdateRebateProgram = operations.dispatchGetCreateUpdateRebateProgram(dispatch);
    const dispatchRebateDelete = operations.dispatchRebateDelete(dispatch)
    const dispatchRebateADD = operations.dispatchRebateAdd(dispatch)
    const dispatchAddEditResponse = operations.dispatchRebateAddEditResponse(dispatch)
    const dispatchRebateEdit = operations.dispatchRebateEdit(dispatch);
    const dispatchGetRebatePeriod = operations.dispatchGetRebatePeriod(dispatch);
    const dispatchGetRebatePaymentMethod = operations.dispatchGetRebatePaymentMethod(dispatch);
    const dispatchGetAddContractName = operations.dispatchGetAddContractName(dispatch);
    const dispatchGetUpdateContractName = operations.dispatchGetUpdateContractName(dispatch);
    const dispatchGetDeleteContractName = operations.dispatchGetDeleteContractName(dispatch);
    const dispatchGetContractDetailsById = operations.dispatchGetContractDetailsById(dispatch);
    const dispatchSetContractDetailsById = operations.dispatchSetContractDetailsById(dispatch);
    const dispatchGetAllContractList = operations.dispatchGetAllContractList(dispatch);
    const dispatchGetProgramByVendorId = operations.dispatchGetProgramByVendorId(dispatch);
    const dispatchGetContractByProgramId = operations.dispatchGetContractByProgramId(dispatch);
    const dispatchGetAllRebateTypes = operations.dispatchGetAllRebateTypes(dispatch);
    const dispatchSetProgramError = operations.dispatchSetProgramError(dispatch);
    const dispatchNavProgramEntry = navOperations.dispatchNavProgramEntry(dispatch);
    const dispatchGetRebateProgramFieldInfo = operations.dispatchGetRebateProgramFieldInfo(dispatch);
    const dispatchGetContractValidation = operations.dispatchGetContractValidation(dispatch);



    useEffect(()=> {
        onAllSpendCheck("", true);
        getInitialValues();
        dispatchGetAllContractList();
        dispatchSetProgramError(true);
        setInitialCol()
        return (() => {
            dispatchSetContractDetailsById({});
        })
    },[]);

    useEffect(()=> {
        if(values.contract && !renewContractClicked) {
            dispatchSetContractDetailsById({});
            dispatchGetContractDetailsById(values.contract)
        }
    },[values.contract])
    
    const  {
        productCategoryList,
        categoryName,
        productList,
        productName,
        productSubCategoryList,
        subCategoryName,
        supplierList,
        supplierName,
        calculationFrequencyList,
        calculationMethodList,
        calculationTypeList,
        RebateProgramList,
        RebateDelete,
        RebateSaved,
        RebateSavedEdit,
        rebatePeriods,
        paymentMethods,
        allContract,
        contractDetails,
        contractList,
        programError,
        username,
        navProgramEntry,
        contractByIdLoading,
        rebateProgramFieldInfo,
        contractValidationflag
    }:any  = useSelector(
        (state:any) => ({
            productCategoryList: categoryEntrySelector.getCategoryData(state),
            categoryName: categoryEntrySelector.getCategoryName(state),
            productList: productEntrySelector.getProductData(state),
            productName: productEntrySelector.getProductName(state),
            productSubCategoryList: subCategoryEntrySelector.getSubCategoryData(state),
            subCategoryName: subCategoryEntrySelector.getSubCategoryName(state),
            supplierList: supplierEntrySelectors.getSuppliersData(state),
            supplierName: supplierEntrySelectors.getSupplierName(state),
            calculationFrequencyList: selectors.getCalculationFrequency(state),
            calculationMethodList: selectors.getCalculationMethod(state),
            calculationTypeList: selectors.getCalculationType(state),
            RebateProgramList : selectors.getRebateProgram(state),
            RebateDelete : selectors.getDeleteProgram(state),
            RebateSaved : selectors.getRebateSaved(state),
            RebateSavedEdit : selectors.getRebateSavedEdit(state),
            rebatePeriods: selectors.getRebatePeriods(state),
            paymentMethods: selectors.getRebatePaymentMethod(state),
            allContract: selectors.getAllContract(state),
            contractDetails: selectors.getContractDetails(state),
            contractList: selectors.getContractList(state),
            programError: selectors.getProgramError(state),
            username: userSelector.getUsername(state),
            navProgramEntry: navSelectors.GetNavProgramEntry(state),
            contractByIdLoading: selectors.getContractByIdLoading(state),
            rebateProgramFieldInfo: selectors.getRebateProgramFieldsInfo(state),
            contractValidationflag: selectors.getContractValidationFlag(state)
        })
    )
    


    const renewContract = () => {
        setRenewContractClicked(true)
    }

    const closeRenewContract = () => {
        setRenewContractClicked(false)
    }
    
    const [programOptions,setProgramOptions] = useState<any>([]);

    const [productCategoryOptions,setProductCategoryOptions] = useState<any>([]);
    const [productOptions,setProductOptions] = useState<any>([]);
    const [productSubCategoryOption,setProductSubCategoryOption] = useState<any>([]);
    const [supplierOptions,setSupplierOptions] = useState<any>([]);
    const [calculationFrequency,setCalculationFrequency] = useState<any>([]);
    const [calculationMethod,setCalculationMethod] = useState<any>([]);
    const [calculationType,setCalculationType] = useState<any>([]);
    const [errors, setErrors] = useState<any>({})
    const [fieldInfoDescription, setFieldInfoDescription] = useState({});

    const handleProductCheck = (value: any) => {
        if (!value?.length && !AllSpend && product) {
            errors.productId='Please select Product'
        } else {
            errors.productId=''
        }
    }

    const handleCategoryCheck = (value: any) => {
        if (!value?.length && !AllSpend && productCategory) {
            errors.categoryId='Please select Product Categories'
        } else {
            errors.categoryId=''
        }
    }

    const handleSubCategoryCheck = (value: any) => {
        if (!value?.length && !AllSpend && productSubCategory) {
            errors.subCategoryId='Please select Product Subcategories'
        } else {
            errors.subCategoryId=''
        }
    }

    const checkforValidFlatPercentage = (value: any) => {
        if(Number(value) < 0 || Number(value) > 99) {
            errors.flatPercentage = 'Enter a value from 0 to 99';
        } else {
            errors.flatPercentage = '';
        }
    }

    const checkPercentageForFlatPercentage = (value: any) => {
        if(!value){
            errors.flatPercentage='Please provide Percentage'
        } else {
            checkforValidFlatPercentage(value)
        }
    }

    const checkifPercentageChecked = () => {
        if (!percentageCheck && !salesGoalCheck) {
            errors.Percentage='Please provide Percentage'
        } else {
            errors.Percentage=''
        }
    }

    const checkforValidGrowthPercentage = (value: any) => {
        if(Number(value) < 0 || Number(value) > 99) {
            errors.Percentage='Enter a value from 0 to 99'
        } else {
            checkifPercentageChecked()
        }
    }

    const checkPercentageForGrowthTarget = (value: any) => {
        if(!value && percentageCheck){
            errors.Percentage='Please provide Percentage'
        }
        else{
            checkforValidGrowthPercentage(value);
        }
    }

    const handlePercentageCheck = (value: any) => {
        if (values.calculationTypeId === 2) {
            checkPercentageForFlatPercentage(value);
        } else {
            errors.flatPercentage=''
        }
        if (values.calculationTypeId === 3) {
            checkPercentageForGrowthTarget(value);
        } else {
            errors.Percentage=''
        }
        if (values.calculationTypeId === 4) {
            checkifPercentageChecked()
        }
    }

    const checkforValidRebatePercentage = (value: any) => {
        if(Number(value) < 0 || Number(value) > 99) {
            errors.rebatePercentage='Enter a value from 0 to 99'
        } else {
            errors.rebatePercentage=''
        }
    }

    const checkRebatePercentage = (value: any) => {
        if(!value){
            errors.rebatePercentage='Please select Rebate Percentage'
        }
        else{
            checkforValidRebatePercentage(value);
        }
    }

    const handleRebatePercentageCheck = (value: any) => {
        if (values.calculationTypeId === 3) {
            checkRebatePercentage(value);
        } else {
            errors.rebatePercentage=''
        }
    }

    const checkifSalesGoalChecked = () => {
        if (!percentageCheck && !salesGoalCheck) {
            errors.SalesGoal = 'Please provide SalesGoal'
        } else {
            errors.SalesGoal = ''
        }
    }

    const checkForValidSalesGoal = (value: any) => {
        if(Number(value) < 0 || Number(value) > 100000000) {
            errors.SalesGoal = 'Enter a value from 0 to 100000000'
        } else {
            checkifSalesGoalChecked();
        }
    }

    const checkSalesGoalForGrowthTarget = (value: any) => {
        if(value == 0.00  && salesGoalCheck){                    
            errors.SalesGoal='Please provide SalesGoal'
        } else{
            checkForValidSalesGoal(value)
        }
    }

    const handleSalesGoalCheck = (value: any) => {
        if (values.calculationTypeId === 3) {
            checkSalesGoalForGrowthTarget(value);
        } else {
            errors.SalesGoal=''
        }
        if (values.calculationTypeId === 4) {
            checkifSalesGoalChecked();
        }
    }

    const handleSupplierCheck = (value: any) => {
        if (!value) {
            errors.supplierId = 'Please select Vendor'
        } else {
            errors.supplierId = ''
        }
    }

    const handleDollorCheck = (value: any) => {
        
        if ((values.calculationTypeId === 5 || values.calculationTypeId === 6 || values.calculationTypeId === 7) && (value <= 0 || value === '')) {
           
            errors.dollor = 'Dollar Amount must be greater than 0'
        } else if(value?.toString()?.split(".")[0]?.length >= 17) {
            errors.dollor = 'Dollar Amount must be under 16 digits'
        } else {
            errors.dollor = ''
        }
    }
    
    const handleNoOfyearsCheck = (value: any) => {
        if (values.calculationTypeId === 6 && (value <= 0 || value === '')) {
            
            errors.noOfYears = 'Number of Years must be greater than 0'
        } else if(value > 50) {
             errors.noOfYears = 'No.of Years must be 1 to 50 years'
        } else {
            errors.noOfYears = ''
        }
    }

    const checkRangeOverlap = (array: any) => {
        let overlap;
        let gap;
        array.forEach((obj: any, index: any) => {
            if (Number(obj.MinValue.split("$").join("").split(",").join("").split("%").join(""))
                >= Number(obj.MaxValue.split("$").join("").split(",").join("").split("%").join(""))) {
                overlap = `Tier ${obj.Tier} Max Value ${obj.MaxValue} and Min Value ${obj.MinValue} overlapping`;
            }
            if (Number(obj.MaxValue.split("$").join("").split(",").join("").split("%").join(""))
                >= Number(array[index + 1]?.MinValue.split("$").join("").split(",").join("").split("%").join(""))) {
                overlap = `Tier ${obj.Tier} Max Value ${obj.MaxValue} and Tier ${array[index + 1]?.Tier} Min Value ${array[index + 1]?.MinValue} overlapping`;
            }
            if (Number(array[index + 1]?.MinValue.split("$").join("").split(",").join("").split("%").join(""))
                - Number(obj.MaxValue.split("$").join("").split(",").join("").split("%").join("")) > 1) {
                gap = `Tier ${obj.Tier} Max Value ${obj.MaxValue} and Tier ${array[index + 1]?.Tier} Min Value ${array[index + 1]?.MinValue} having gap`;
            }
        });
        return { overlap, gap };
    }

    const checkForTierDescription = (value: any) => {
        if (!value?.length) {
            errors.tierDescription = "Please provide Tier description"
        } else {
            const result = checkRangeOverlap(value)
            if (result.overlap && result.gap) {
                errors.tierDescription = `${result.overlap} and ${result.gap}`
            } else if (result.overlap) {
                errors.tierDescription = result.overlap
            } else if (result.gap) {
                errors.tierDescription = result.gap
            } else {
                errors.tierDescription = ""
            }
        }
    }

    const handleTearDescriptionCheck = (value: any) => {
        if (values.calculationTypeId === 1 || values.calculationTypeId === 4) {
            checkForTierDescription(value)
        } else {
            errors.tierDescription = ""
        }
    }
    
    const handleBasedOnCheck = (value: any) => {
        if (!value) {
            errors.basedOn = 'Please select Based on'
        } else {
            errors.basedOn = ''
        }
    }

    const handleRebatePeriodIdCheck = (value: any) => {
        if (!value) {
            errors.rebatePeriodId = 'Please select Rebate Period'
        } else {
            errors.rebatePeriodId = ''
        }
    }

    const handleCalculationTypeIdCheck = (value: any) => {
        if (!value) {
            errors.calculationTypeId = 'Please select Calculation Type'
        } else {
            errors.calculationTypeId = ''
        }
    }

    const handleCalculationMethodIdCheck = (value: any) => {
        if ((values?.calculationTypeId == 4 || values?.calculationTypeId == 1) && !value) {
            errors.calculationMethodId = 'Please select Calculation Method'
        } else {
            errors.calculationMethodId = ''
        }
    }

    const handleRebateFrequencyIdCheck = (value: any) => {
        if (!value) {
            errors.rebateFrequencyId = 'Please select Frequency'
        } else {
            errors.rebateFrequencyId = ''
        }
    }

    const handleRebatePayMethodIdCheck = (value: any) => {
        if (!value) {
            errors.rebatePayMethodId = 'Please select Payment Method'
        } else {
            errors.rebatePayMethodId = ''
        }
    }

    const checkForValidYear = (value: any) => {
        const startYear = new Date(values.startDate).getFullYear();
        const endYear = new Date(values.endDate).getFullYear();
        if (value < startYear || (!getValue('evergreen') && value > endYear)) {
            errors.programYear = 'Please provide valid Program Year between Start Date and End Date';
        } else {
            errors.programYear = '';
        }
    }

    const handleProgramYearCheck = (value: any) => {
        if (!value || value.length < 4) {
            errors.programYear = 'Please provide valid Program Year in YYYY format'
        } else {
            const newValue = Number(year)
            checkForValidYear(newValue);
        }
    }

    const handleCheckReceivedCheck = (value: any) => {
        if(!value){
            errors.checkReceived = 'Please provide valid option for Check Received'
        } else {
            errors.checkReceived = ''
        }
    }

    const handleStartDateCheck = (value:any) => {
        if(!value){
            errors.startDate = 'Please provide Start Date'
        } else {
            errors.startDate = ''
        }
    }

    const handleEndDateCheck = (value:any) => {
        if(!value && !getValue('evergreen')){
            errors.endDate = 'Please provide End Date'
        } else {
            errors.endDate = ''
        }
    }

    const handleBillingDateCheck = (value:any) => {
        if(!value){
            errors.billingDate = 'Please provide Effective Date'
        } else {
            errors.billingDate = ''
        }
    }

    const handleRebateCheckdateCheck = (value:any) => {
        if(!value && getValue('checkReceived') === 'yes'){
            errors.rebateCheckdate = 'Please provide Rebate Check Received Date'
        } else {
            errors.rebateCheckdate = ''
        }
    }

    const handleNetDaysCheck = (value : any) => {
        if(getValue('withinTerms') && value <= 0){
            errors.Quantity = "NET Days must be greater than 0 for Within Terms agreements. Set NET Days value or uncheck Within Terms to continue"
        } else {
            errors.Quantity = ''
        }
    }

    const getValidateData = (name: any, value: any) => {
        switch (name) {
            case 'productId':
                handleProductCheck(value)
                break;
            case 'categoryId':
                handleCategoryCheck(value);
                break;
            case 'subCategoryId':
                handleSubCategoryCheck(value);
                break;
            case 'Percentage':
                handlePercentageCheck(value);
                break;
            case 'rebatePercentage':
                handleRebatePercentageCheck(value)
                break;
            case 'SalesGoal':
                handleSalesGoalCheck(value);
                break;
            case 'supplierId':
                handleSupplierCheck(value);
                break;
            case 'dollor':
                handleDollorCheck(value);
                break;
            case 'noOfYears':
                handleNoOfyearsCheck(value);
                break;
            case 'tierDescription':
                handleTearDescriptionCheck(value);
                break;
            case 'basedOn':
                handleBasedOnCheck(value);
                break;
            case 'rebatePeriodId':
                handleRebatePeriodIdCheck(value);
                break;
            case 'calculationTypeId':
                handleCalculationTypeIdCheck(value);
                break;
            case 'calculationMethodId':
                handleCalculationMethodIdCheck(value);
                break;
            case 'rebateFrequencyId':
                handleRebateFrequencyIdCheck(value);
                break;
            case 'rebatePayMethodId':
                handleRebatePayMethodIdCheck(value);
                break;
            case 'programYear':
                handleProgramYearCheck(value);
                break;
            case 'checkReceived':{
                handleCheckReceivedCheck(value)};    
                break;
            case 'startDate' : {
                handleStartDateCheck(value)};    
            break;
            case 'endDate' : {
                handleEndDateCheck(value)};    
            break;
            case 'billingDate' : {
                handleBillingDateCheck(value)};    
            break;
            case 'rebateCheckdate' : {
                handleRebateCheckdateCheck(value)};    
            break;
            case 'Quantity' : {
                handleNetDaysCheck(count)};
            break;
            default: break;
        }
    }

    useEffect(() => {
        if (navProgramEntry) {
            cancelClick();
            dispatchNavProgramEntry(false)
        }
    }, [navProgramEntry])

    useEffect(() => {
        if (allContract?.length) {
            const tempContractDetails = allContract?.map((item: any) => ({
                key: parseInt(item.value),
                text:item.text,
                label: item.text,
                value:item.text,
                active: item.disabled
            }))
            if (tempContractDetails?.length === 1 && newProgram && isAuthorized("RebateAgreements", "update")) {
                setValues({
                    ...values,
                    contract: tempContractDetails[0]?.key
                })
            }
            if(isAuthorized("RebateAgreements", "update") || isView){
                setAllContractDetails(tempContractDetails);
            } else if(addContractText) {
                setAllContractDetails([...allContractDetails, tempContractDetails.find((contract:any)=>contract.text===addContractText)])
            }
        } else {
            setAllContractDetails([]);
        }
    }, [allContract])

    useEffect(() => {
        if (contractList?.length) {
            const rebateContractGridData = contractList?.map((item:any) => {
                return {
                    name: item?.contractName,
                    programName: item?.programName,
                    vendorName: item?.supplierName,
                    rebateType: item?.rebateType,
                    rebatePeriod: item?.rebatePeriod,
                    paymentMethod: item.paymentMethod,
                    id: item.contractId,
                    isActive: item.isActive,
                    everGreen: item.everGreen ? 'Yes' : 'No',
                    tempExtendContract: item.tempExtendContract ? 'Yes' : 'No'
                }
            })
            const list = rebateContractGridData?.filter((item:any)=>item.isActive == !isShowDisabled)
            setGridData(list);
        }
    }, [contractList])

    useEffect(()=> {
        if(RebateProgramList?.length) {
            const rebateProgram = RebateProgramList.filter((i: any) => i.disabled)?.map((item:any) => {
                return {
                    key: parseInt(item.id || item.value),
                    label:item.name || item.text,
                    value:item.name || item.text,
                    active: item.disabled
                }
            })
            if (rebateProgram?.length === 1 && newProgram) {
                setValues({
                    ...values,
                    program: rebateProgram[0]?.key
                })
            }
            if(addText){
                setValues({
                    ...values,
                    program: rebateProgram?.find((program: any) => program?.label === addText)?.key,
                    startDate: '',
                    endDate: '',
                    billingDate: '',
                    withinTerms: '',
                    expectedPaymentDate: '',
                    productId: "",
                    categoryId:"",
                    subCategoryId:"",
                    active: true,
                    programYear: null,
                    rebatePeriodId: '',
                    calculationTypeId: '',
                    rebateFrequencyId: '',
                    rebatePayMethodId: '',
                })
                setAddText("")
                setYear(0);
                setId(0);
                setMonthList('');
                setIdEnd(0);
                setMonthListEnd('');
                setCount(0);
                setDeadLineDays(0);
            }
            setProgramOptions(rebateProgram)
        } else {
            setProgramOptions([])
        }
        setAddEditContractList([]);
    },[RebateProgramList])
    
    useEffect(()=> {
        if (productCategoryList?.length > 0) {
            const productCategoryOption = productCategoryList.filter((i: any) => i.isActive)?.map((item:any) => ({
                id: parseInt(item.categoryId),
                label:item.categoryName,
                value:item.categoryId
            }))
            setProductCategoryOptions(productCategoryOption)
        }
    },[productCategoryList])

    useEffect(()=>{
        moveToTopCategory(selectedCategory)    
    },[selectedCategory])
    
    useEffect(()=> {
        if (productList?.length > 0) {
            const productOption = productList.filter((i: any) => i.isActive)?.map((item:any) => ({
                id: parseInt(item.productId),
                label:item.productName,
                value:item.productId
            }))
            setProductOptions(productOption)
        }
    },[productList])

    useEffect(()=>{
        moveToTopProducts(selectedProducts)    
    },[selectedProducts])

    useEffect(()=> {
        if (productSubCategoryList?.length > 0) {      
                const SubCategoryOption = productSubCategoryList.filter((i: any) => i.isActive)?.map((item:any) => ({
                    id: parseInt(item.subCategoryId),
                    label:item.subCategoryName,
                    value:item.subCategoryId
                }))

            setProductSubCategoryOption(SubCategoryOption)
        }
    },[productSubCategoryList])

    useEffect(()=>{
        moveToTopSubCategory(selectedSubCategory)  
    },[selectedSubCategory])

    useEffect(()=> {
        if(supplierList?.length > 0) {
            const list = supplierList?.map((item:any) => ({
                key: parseInt(item.supplierId),
                label:item.supplierName,
                value:item.supplierName
            }))
            setSupplierOptions(list)
        }
    },[supplierList])

    const getOptionValue = (item: any) => (
        {
            key: parseInt(item.value),
            text:item.text,
            value:item.text 
        }
    )
    
    useEffect(()=> {
        if (calculationFrequencyList?.length) {
            const list = calculationFrequencyList?.map((item:any) => (
                getOptionValue(item)
            ))
            setCalculationFrequency(list)
        }
    },[calculationFrequencyList])

     useEffect(()=> {
        if (calculationMethodList?.length) {
            const list = calculationMethodList?.map((item:any) => (
                getOptionValue(item)
            ))
            setCalculationMethod(list)
        }
    },[calculationMethodList])

     useEffect(()=> {
        if (calculationTypeList?.length) {
            const list = calculationTypeList?.map((item:any) => (
                getOptionValue(item)
            ))
            setCalculationType(list)
        }
     },[calculationTypeList])

     useEffect(()=> {
        if (rebatePeriods?.length) {
            const list = rebatePeriods?.map((item:any) => (
                getOptionValue(item)
            ))
            setRebatePeriodOptions(list)
        }
     },[rebatePeriods])

     useEffect(()=> {
        if (paymentMethods?.length) {
            const list = paymentMethods?.map((item:any) => (
                getOptionValue(item)
            ))
            setRebatePaymentMethod(list)
        }
     },[paymentMethods])

     useEffect(()=> {
        if(!productCategory) {
            setErrors((prevState:any)=> ({...prevState,ProductCategory:''}))
        }
     },[productCategory])
     useEffect(()=> {
        if(!product) {
            setErrors((prevState:any)=> ({...prevState,Product:''}))
        }
     },[product])
     useEffect(()=> {
        if(!productSubCategory) {
            setErrors((prevState:any)=> ({...prevState,ProductSubCategory:''}))

        }
     },[productSubCategory])
 
     useEffect(()=> {
        if(!salesGoalCheck) {
            setErrors((prevState:any)=> ({...prevState,SalesGoal:''}))
        }
     },[salesGoalCheck])
     
     useEffect(()=> {
        if(!percentageCheck) {
            setErrors((prevState:any)=> ({...prevState,Percentage:''}))
        }
     },[percentageCheck])

     useEffect(()=>{
        if(rebateProgramFieldInfo.length){
            const infoList: any = rebateProgramFieldInfo.reduce((obj:any, info:any) => (obj[info.fieldName] = info.fieldDescription, obj) ,{});
            setFieldInfoDescription(infoList)
        }
     }, [rebateProgramFieldInfo])

    const checkforEndDate = (isEndDate: boolean) => {
            return "";
    }

    const getDateFromDetails = (date: any, isEndDate = false) => {
        if (date) {
            return new Date(date)
        } else {
           return checkforEndDate(isEndDate);
        }
    }

    const getBasedOn = () => {
        if (contractDetails?.calculateOnPaymentDate) {
            return 1;
        } else if (contractDetails?.calculateOnInvoiceDate) {
            return 2;
        } else {
            return "";
        }
    }

    const getNumberData = (tempData: any) => {
        if (tempData) {
            return tempData;
        } else {
            return 0;
        }
    }

    const getMonthData = (tempData: any) => {
        if(tempData || tempData === 0){
            return tempData
        } else {
          return  -1
        }
    }
     
    const getProgramYear = (tempYear: any) => {
        if (tempYear) {
            return tempYear;
        } else {
            return '';
        }
    }

    const getCheckBoxState = (tempData: any) => {
        return  !!tempData?.length
    }

    const getSelectedItems = (item: any, type: any) => {
        if (type === "productId") {
            const data = item?.contractDetailRebateTypeVM?.rebateProductTypes;
            const arr: any = [];
            data?.forEach((i: any) => {
                const ele = productOptions.find((option: any) => option.id === i.productId);
                if (ele) {
                    arr.push(ele);
                }
            })
            return arr;
        } else if (type === "categoryId") {
            const data = item?.contractDetailRebateTypeVM?.rebateProductCategoryTypes;
            const arr: any = [];
            data?.forEach((i: any) => {
                const ele = productCategoryOptions.find((option: any) => option.id === i.productCategoryId);
                if (ele) {
                    arr.push(ele);
                }
            })
            return arr;
        } else {
            const data = item?.contractDetailRebateTypeVM?.rebateProductSubCategoryTypes;
            const arr: any = [];
            data?.forEach((i: any) => {
                const ele = productSubCategoryOption.find((option: any) => option.id === i.productSubCategoryId);
                if (ele) {
                    arr.push(ele);
                }
            })
            return arr;
        }
    }

    const getRebateCheckdate = (tempData:any) => {
        if(Array.isArray(tempData) && tempData.length){
            const lastCheckDate =  tempData.reduce((a:any, b:any) => 
            moment(a.checkDate).isAfter(b.checkDate) ? a : b
            );
            setCurrentRebateCheckDateData(lastCheckDate)
            return lastCheckDate.checkDate ? new Date(lastCheckDate.checkDate) : null
        } else {
            return null
        }
    }

    const getCheckReceived = (tempData:any) => {
        if(Array.isArray(tempData) && tempData.length){
            const lastCheckDate =  tempData.reduce((a:any, b:any) => 
            moment(a.checkDate).isAfter(b.checkDate) ? a : b
            );
            setCurrentRebateCheckDateData(lastCheckDate)
            return lastCheckDate.isCheckRecieved
        } else {
            return false
        }
    }

    const checkReceivedOptions = [
        {key: "yes", value: "yes", text: "Yes"},
        {key: "no", value: "no", text: "No"}
    ]

    const handleCheckReceivedRadio = (_event: any, item: any) => {
        getValidateData('checkReceived', item.value)
        setValues({
            ...values,
            checkReceived: item.value
        })
    }

    const setContractData = () => {
 
        const tempPercentage = contractDetails?.calculationTypeGrowthTargetsVM?.basePercentage || contractDetails?.calculationTypeFlatPercentagesVM?.percentage;
        const tempDollorAmount = contractDetails?.calculationTypeLumSumsVM?.dollarAmount || contractDetails?.calculationTypePrePaidLumSumsVM?.dollarAmount || contractDetails?.calculationTypeLumpSumPerUnitVM?.dollarAmount;
      
      
        setValues({
            ...contractDetails,
            contract: contractDetails?.contractDetailId,
            program: contractDetails?.rebateProgram?.programId,
            startDate: getDateFromDetails(contractDetails.contractStartDate),
            endDate: getDateFromDetails(contractDetails.contractEndDate, true),
            billingDate: getDateFromDetails(contractDetails?.billingTerms?.effectiveDate),
            withinTerms: contractDetails.withinTerms,
            expectedPaymentDate: getDateFromDetails(contractDetails.paymentDueDate, true),
            supplierId: contractDetails?.rebateProgram?.supplierId,
            rebateCalculationId: contractDetails.rebateCalculationId,
            Quantity: contractDetails?.billingTerms?.paymentTerms?.netDays,
            productId: contractDetails?.contractDetailRebateTypeVM?.rebateProductTypes?.map((i: any) => i?.productId ),
            categoryId: contractDetails?.contractDetailRebateTypeVM?.rebateProductCategoryTypes?.map((i: any) => i?.productCategoryId),
            subCategoryId: contractDetails?.contractDetailRebateTypeVM?.rebateProductSubCategoryTypes?.map((i: any) => i?.productSubCategoryId),
            calculationTypeId: contractDetails?.rebateCalculations?.calculationTypeId,
            calculationMethodId: contractDetails?.rebateCalculations?.calculationMethodId,
            rebatePayMethodId: contractDetails?.rebatePaymentMethodId,
            rebateFrequencyId: contractDetails?.rebateFrequencyId,
            rebatePeriodId: contractDetails?.rebatePeriods?.periodMasterId,
            basedOn: getBasedOn(),
            evergreen: !!contractDetails?.everGreen,
            //dollor: getNumberData(contractDetails?.calculationTypeLumSumsVM?.dollarAmount),
            dollor: getNumberData(tempDollorAmount),
            noOfYears: getNumberData(contractDetails?.calculationTypePrePaidLumSumsVM?.numberOfYears),
            Percentage: getNumberData(tempPercentage),
            SalesGoal: Number(getNumberData(contractDetails?.calculationTypeGrowthTargetsVM?.salesGoal)).toFixed(2),
            rebatePercentage: Number(getNumberData(contractDetails?.calculationTypeGrowthTargetsVM?.rebatePercentage)),
            programYear: String(getProgramYear(contractDetails?.programYear)),
            active: contractDetails?.isActive,
            checkReceived: getCheckReceived(contractDetails?.rebateCheckdate) ? 'yes' : 'no',
            rebateCheckdate: getRebateCheckdate(contractDetails?.rebateCheckdate),
            rebateCheckDateData:contractDetails?.rebateCheckdate
        });
        setSelectedProducts(getSelectedItems(contractDetails, "productId"))
        setSelectedCategory(getSelectedItems(contractDetails, "categoryId"))
        setSelectedSubCategory(getSelectedItems(contractDetails, "subCategoryId"))
        setProduct(getCheckBoxState(contractDetails?.contractDetailRebateTypeVM?.rebateProductTypes?.map((i: any) => i?.productId )));
        setProductCategory(getCheckBoxState(contractDetails?.contractDetailRebateTypeVM?.rebateProductCategoryTypes?.map((i: any) => i?.productCategoryId)));
        setProductSubCategory(getCheckBoxState(contractDetails?.contractDetailRebateTypeVM?.rebateProductSubCategoryTypes?.map((i: any) => i?.productSubCategoryId)));
        setCount(Number(getNumberData(contractDetails?.billingTerms?.paymentTerms?.netDays)));
        setId(getNumberData(contractDetails?.rebatePeriods?.startMonth - 1));
        setMonthList(months[getMonthData(contractDetails?.rebatePeriods?.startMonth - 1)]);
        setIdEnd(getNumberData(contractDetails?.rebatePeriods?.endMonth - 1));
        setMonthListEnd(months[getMonthData((contractDetails?.rebatePeriods?.endMonth) - 1)]);
        setAllSpend(contractDetails?.contractDetailRebateTypeVM?.rebateTypeId === 1);
        onUpdateAllSpendCheck("", contractDetails?.contractDetailRebateTypeVM?.rebateTypeId === 1)
        setGrowthTarget(!!contractDetails?.contractDetailRebateTypeVM?.grouthTarget);
        setPercentageDisabled(!contractDetails?.contractDetailRebateTypeVM?.grouthTarget);
        setdisabledPercentageText(!contractDetails?.contractDetailRebateTypeVM?.grouthTarget)
        setSalesForceDisabled(!contractDetails?.contractDetailRebateTypeVM?.grouthTarget)
        setDisabledSalesGoalText(!contractDetails?.contractDetailRebateTypeVM?.grouthTarget)
        setPercentage(getNumberData(tempPercentage));
        setSalesGoal(Number(getNumberData(contractDetails?.calculationTypeGrowthTargetsVM?.salesGoal)).toFixed(2));
        setYear(String(getProgramYear(contractDetails?.programYear)))
        setDeadLineDays(getNumberData(contractDetails?.endofYearDaystoPay))
        // setDollorAmount(getNumberData(contractDetails?.calculationTypeLumSumsVM?.dollarAmount))
        setDollorAmount(getNumberData(tempDollorAmount))
        setNoOfYears(getNumberData(contractDetails?.calculationTypePrePaidLumSumsVM?.numberOfYears))
        setRebatePercentage(getNumberData(contractDetails?.calculationTypeGrowthTargetsVM?.rebatePercentage))
        let isPercentage = false;
        if (contractDetails?.calculationTypeGrowthTargetsVM?.basePercentage > 0) {
            setPercentageCheck(true);
            isPercentage = true
        } else {
            setPercentageCheck(false);
        }
        if (contractDetails?.calculationTypeGrowthTargetsVM?.salesGoal > 0) {
            setSalesGoalCheck(true)
        } else {
            setSalesGoalCheck(false)
        }
        if (contractDetails.calculationTypeGrowthTargetTieredVM?.isPercentage) {
            setPercentageCheck(true);
            isPercentage = true
        } else {
            setPercentageCheck(false);
        }
        if (contractDetails.calculationTypeGrowthTargetTieredVM?.isSalesGoal) {
            setSalesGoalCheck(true);
        } else {
            setSalesGoalCheck(false);
        }
        const {rebateTiers} = contractDetails
        if (rebateTiers && isPercentage) {
            const tier = rebateTiers.map((i: any) => (
                {
                    Tier: i.level,
                    MinValue: (i.minAmount)?.toLocaleString() + "%",
                    MaxValue: (i.maxAmount)?.toLocaleString() + "%",
                    Percentage: i.percentage + "%",
                    id: i.id,
                }
            ))
            setData(tier)
            getValidateData("tierDescription", tier);
        } else if (rebateTiers && !isPercentage) {
            const tier = rebateTiers.map((i: any) => (
                {
                    Tier: i.level,
                    MinValue: "$" + (i.minAmount)?.toLocaleString(),
                    MaxValue: "$" + (i.maxAmount)?.toLocaleString(),
                    Percentage: i.percentage + "%",
                    id: i.id,
                }
            ))
            setData(tier)
            getValidateData("tierDescription", tier);
        } else {
            setData([])
            getValidateData("tierDescription", []);
        }
        if(contractDetails?.vendorCreditCardSpend){
            setIsExcludeCreditCardSpend(contractDetails?.vendorCreditCardSpend[0]?.isExcludeCreditCardSpend)
        } else {
            setIsExcludeCreditCardSpend(false)
        }
        dispatchSetContractDetailsById({});
    }

     useEffect(()=> {
        if(Object.keys(contractDetails)?.length) {
            setContractData();
        }
    },[JSON.stringify(contractDetails)])

    const setInitialTierError = (intial: boolean = false) => {
        if (intial) {
            setTierErrors({
                minValue: "Please enter Min value",
                maxValue: "Please enter Max value",
                percentage: "Please enter Percentage"
            })
        } else {
            setTierErrors({
                maxValue: "Please enter Max value",
                percentage: "Please enter Percentage"
            })
        }
    }

    const getInitialValues = () => {
        setValues({
            active: true,
        });
        setPercentage(0);
        setSalesGoal(Number(0.00).toFixed(2))
        setSubmitClicked(false);
        setData([]);
        getValidateData("tierDescription", []);
        dispatchSetSubCategoryName("");
        dispatchSetCategoryName("");
        dispatchSetProductName("");
        dispatchSetSupplierName("");
        setSelectedProducts([]);
        setSelectedCategory([]);
        setSelectedSubCategory([]);
        setCurrentRebateCheckDateData({});
    }

    useEffect(() => {
        Object.keys(values).forEach((key)=> {
            getValidateData(key,values[key]);
        })
    }, [AllSpend, values, product, productCategory, productSubCategory, values.rebateCalculationId, values.calculationTypeId, percentageCheck, salesGoalCheck])

    useEffect(() => {
        if (RebateSavedEdit) {
            getInitialValues();
            dispatchSetContractDetailsById({});
            setEditMode(false)
            setAllContractDetails([]);
        }
    }, [RebateSavedEdit])

    useEffect(() => {
        if (AllSpend) {
            setValues({
                ...values,
                productId: [],
                categoryId: [],
                subCategoryId: [],
            })
        }
    }, [AllSpend])

    useEffect(() => {
        if (!product && !productCategory && !productSubCategory && !AllSpend) {
            setProduct(true);
            setProductCategory(true);
            setProductSubCategory(true);
        }
    }, [product, productCategory, productSubCategory])

    useEffect(() => {
        if(subCategoryName) {
            const subCategoryItem = productSubCategoryList.find((i: any) => i.subCategoryName === subCategoryName);
            if (subCategoryItem) {
                setValues({
                    ...values,
                    subCategoryId: Number(subCategoryItem.subCategoryId)
                })
                setSelectedSubCategory([{id: subCategoryItem.subCategoryId, value: subCategoryItem.subCategoryId, label: subCategoryItem.subCategoryName}]);
            }
        }
    }, [subCategoryName, productSubCategoryList]);

    useEffect(() => {
        if(categoryName) {
            const categoryItem = productCategoryList.find((i: any) => i.categoryName === categoryName);
            if (categoryItem) {
                setValues({
                    ...values,
                    categoryId: Number(categoryItem.categoryId)
                })
                setSelectedCategory([{id: categoryItem.categoryId, value: categoryItem.categoryId, label: categoryItem.categoryName}]);
            }
        }
    }, [categoryName, productCategoryList]);

    useEffect(() => {
        if(supplierName) {
            const supplierItem = supplierList.find((i: any) => i.supplierName === supplierName);
            if (supplierItem) {
                setValues({
                    ...values,
                    supplierId: Number(supplierItem.supplierId)
                })
            }
        }
    }, [supplierName, supplierList])

    useEffect(() => {
        if(productName) {
            const productItem = productList.find((i: any) => i.productName === productName);
            if (productItem) {
                setValues({
                    ...values,
                    productId: Number(productItem.productId)
                })
                setSelectedProducts([{id: productItem.productId, value: productItem.productId, label: productItem.productName}]);
            }
        }
    }, [supplierName, productList])

    useEffect(() => {
        if (values.rebatePeriodId == 1) {
            setOtherRebatePeriodDisabled(true)
            setId(0)
            setMonthList(months[0]);
            setIdEnd(11)
            setMonthListEnd(months[11]);
        }else if (values.rebatePeriodId == 2) {
            setOtherRebatePeriodDisabled(true)
            setId(9)
            setMonthList(months[9]);
            setIdEnd(8)
            setMonthListEnd(months[8]);
        } else if (values.rebatePeriodId == 3 && (!monthList && !monthListEnd)) {
            setOtherRebatePeriodDisabled(false)
            setId(0)
            setMonthList(months[0]);
            setIdEnd(0)
            setMonthListEnd(months[0]);
        } else {
            setOtherRebatePeriodDisabled(false)
        }
    }, [values.rebatePeriodId])

    const getSelectedKeys = (item:any,field:any) => {
        let fields = [];
        if (values[field]) {
            fields = values[field]
        }
        if(item.selected) {
            return [...fields,item.key as string]
        }
        else {
            return fields.filter((key:any) => key !== item.key)
        }
    }

    const getSelectedMultipleKeys = (item:any,field:any) => {
        return (item || [])?.map((i: any) => i?.id); 
    }

    const handleCalculationTypeId = (item: any) => {
        setSelectedCalculationType(item)
        setPercentage(0);
        setSalesGoal(0);
        setDollorAmount(0);
        setNoOfYears(0);
        setPercentageCheck(false);
        setSalesGoalCheck(false);
        Object.keys(values).forEach((key)=> {
            getValidateData(key,values[key]);
        })
        setData([]);
        getValidateData("tierDescription", []);
    }

    const moveToTopProducts = (item:any) => {
        setTimeout(()=>{
            for(let i=0; i<= item?.length ; i++){
             for(let j=0; j<= productList?.length; j++){
                     if(item[i]?.id === productList[j]?.productId){
                         productList.splice(j, 1)
                     }
             }
            }
            item.map((itemValue:any)=>{
                 productList.unshift({
                     productId: parseInt(itemValue.id),
                     productName:itemValue.label,
                 })
            })
            const productOption = productList.map((item:any) => ({
                 id: parseInt(item.productId),
                 label:item.productName,
                 value:item.productId
            }))
            setProductOptions(productOption)
     },500)
    }
    const handleProduct = (item: any) => {
        const value = getSelectedMultipleKeys(item,"productId")
        getValidateData("productId",value)
        setValues({
            ...values,
            productId: value
        })
        setSelectedProducts(item);
        moveToTopProducts(item)
    }

    const moveToTopCategory = (item:any) => {
        setTimeout(()=>{
            for(let i=0; i<= item?.length ; i++){
                for(let j=0; j<= productCategoryList?.length; j++){
                        if(item[i]?.id === productCategoryList[j]?.categoryId){
                            productCategoryList.splice(j, 1)
                        }
                }
            }
            item.map((itemValue:any)=>{
                productCategoryList.unshift({
                    categoryId: parseInt(itemValue.id),
                    categoryName:itemValue.label,
                })
            })
            const productCategoryOption = productCategoryList.map((item:any) => ({
                id: parseInt(item.categoryId),
                label:item.categoryName,
                value:item.categoryId
            }))
            setProductCategoryOptions(productCategoryOption)
        },500)
    }
    const handleCategory = (item: any) => {
        const value = getSelectedMultipleKeys(item,"categoryId")
        getValidateData("categoryId",value)
        setValues({
            ...values,
            categoryId: value
        })
        setSelectedCategory(item);
        moveToTopCategory(item)      
    }

    const moveToTopSubCategory = (item:any) => {
        setTimeout(()=>{
            for(let i=0; i<= item?.length ; i++){
                for(let j=0; j<= productSubCategoryList?.length; j++){
                        if(item[i]?.id === productSubCategoryList[j]?.subCategoryId){
                            productSubCategoryList.splice(j, 1)
                        }
                }
            }
            item.map((itemValue:any)=>{
                productSubCategoryList.unshift({
                    subCategoryId: parseInt(itemValue.id),
                    subCategoryName:itemValue.label,
                })
            })
            const SubCategoryOption = productSubCategoryList.map((item:any) => ({
                id: parseInt(item.subCategoryId),
                label:item.subCategoryName,
                value:item.subCategoryId
            }))
            setProductSubCategoryOption(SubCategoryOption)
        },500)     
    }

    const handleSubCategory = (item: any) => {
        const value = getSelectedMultipleKeys(item,"subCategoryId")
        getValidateData("subCategoryId",value)
        setValues({
            ...values,
            subCategoryId: value
        })
        setSelectedSubCategory(item);
        moveToTopSubCategory(item)  
    }
    
    const handleInputChange = (event: any, item: any) => {
        handleMessage()
        let name = event?.target?.getAttribute('name') || event.name;
        let value = item.key;
        dispatchSetSubCategoryName("");
        dispatchSetCategoryName("");
        dispatchSetProductName("");
        dispatchSetSupplierName("");
        if (
            name === 'productId' ||
            name === 'categoryId' ||
            name === 'subCategoryId'
          ) {
            value = getSelectedKeys(item,name)
            getValidateData(name,value)
        }
        if (name === "calculationTypeId") {
            handleCalculationTypeId(item)
        }
        if (
            name === "rebatePayMethodId" ||
            name === "rebateFrequencyId" ||
            name === "rebatePeriodId"
        ) {
            value = item.key;
            getValidateData(name,value);
        }
        if(name === 'eop' || name === "active" || name === "evergreen" || name === "tempExtendContract") {
            value= item
        }
        if(name === 'withinTerms'){
            value = item;
            errors.Quantity = '';
        }
        if(name === "program"){
            setAddEditContractList([]);
        }
        if(name === 'contract'){
            setRenewContractClicked(false) 
        }
        if (name === "calculationTypeId" && item?.text === constants.PRE_PAID_LUMP_SUM) {
            setValues({
                ...values,
                rebateFrequencyId: calculationFrequency?.find((frequency: any) => frequency?.text === constants?.ANNUALLY)?.key,
                [name]: value
            })
        } else {
            setValues({
                ...values,
                [name]: value
            })
        }
    }

    const handleDateChange = (item: any, name: any) => {
        let value = item;
        setValues({
            ...values,
            [name]: value
        })
    }
    const [addEditPopup,setAddEditPopup] = useState(false);
    const onShowAddEdit = () => {
        setAddEditPopup(true);
    }
    const onHideAddEdit = () => {
        setAddEditPopup(false);
        setAddEditContact(false);
        if(addText) {
            setAddText('')
            setAddContractText('');
        }
        handleMessage();
    }
    
    const onSearch = (_ev:any,text:any) => {
        let list = [...addEditList];
        const filteredList =  list.filter((item:any) => item?.label?.toLowerCase().includes(text));
        setAddEditList(filteredList);
        if(text === '') {
            setAddEditList(duplicateAddEditList)
        }
    }

    const onSearchContract = (_ev:any,text:any) => {
        let list = [...addEditContractList];
        const filteredList =  list.filter((item:any) => item?.label?.toLowerCase().includes(text));
        setAddEditContractList(filteredList);
        if(text === '') {
            setAddEditContractList(duplicateAddEditContractList)
        }
    }

    const onAddEditProgram = (_text:string) => {
        let list:any = [...programOptions.filter((i: any) => i.active)];
        setAddEditList(list);
        setDuplicateAddEditList(list)
        onShowAddEdit();
    }

    const onAddEditContract = (_text:string) => {
        if(allContractDetails?.length){
            let list:any = [...allContractDetails.filter((i: any) => i.active)];
            setAddEditContractList(list);
            setDuplicateAddEditContractList(list)
        }
        setAddEditContact(true);
    }

    const onHideAddEditContract= () => {
        setAddEditContact(false);
        if(addContractText) {
            setAddContractText('')
        }
        handleMessage();
    }

    const [addText,setAddText] = useState('');
    const handleAddEditInputChange = (e:any) => {
        handleMessage();
        setAddText(e.target.value)
    }

    const [addContractText,setAddContractText] = useState('');
    const handleAddEditContractInputChange = (e:any) => {
        handleMessage();
        setAddContractText(e.target.value)
    }



    useEffect(()=> {
        let list:any = [...programOptions.filter((i: any) => i.active)];
        setAddEditList(list);
        setDuplicateAddEditList(list)
    },[programOptions])

    useEffect(()=> {
        if(allContractDetails?.length){
            let list:any = [...allContractDetails.filter((i: any) => i.active)];
            setAddEditContractList(list);
            setDuplicateAddEditContractList(list)
        }
        if(addContractText && !programError){
            setValues({
                ...values,
                contract: allContractDetails.find((contract:any)=>contract.value === addContractText).key
            })
            setAddEditContact(false)
            setAddContractText('')
        }
    },[allContractDetails])

    useEffect(() => {
        getValidateData("tierDescription", data);
    }, [data])

    useEffect(() => {
        if (values.supplierId) {
            dispatchGetProgramByVendorId(values.supplierId)
            setValues({
                ...values,
                contract: ""
            })
        }
    }, [values.supplierId])

    useEffect(() => {
        if (values.program) {
            setValues({
                ...values,
                contract: ""
            })
            dispatchGetContractByProgramId(values.program)
        }
    }, [values.program])

    useEffect(() => {
        if (values.evergreen) {
                setValues({
                    ...values,
                    endDate: null
                })
        }
    }, [values.evergreen])
    
    const onAdd = () => {
        if(addText) {
        const payload = {
            programName: addText,
            supplierId: values.supplierId
        }
        dispatchRebateADD(payload, values.supplierId)
        setTimeout(()=> {
            dispatchAddEditResponse(false)
        },2500) 
        }
        
    }

    const onAddContract = () => {
        if(addContractText) {
            const payload = {
                contractName: addContractText,
                programId: values.program,
                modifiedBy: username
            }
            dispatchGetAddContractName(payload, values.program)
            setTimeout(()=> {
                dispatchAddEditResponse(false)
            },2500) 
        }
    }

    const onDelete = (_index:number,item:any) => {
        dispatchRebateDelete(item.key, values.supplierId)
        let list:any = [...programOptions];
        setAddEditList(list);
        setTimeout(()=> {
            dispatchAddEditResponse(false)
        }, 5000) 
    }
    
    const onDeleteContract = (_index:number,item:any) => {
        dispatchGetDeleteContractName(item.key, values.program)
        let list:any = [...allContractDetails.filter((i: any) => i.active)];
        setAddEditContractList(list);
        setTimeout(()=> {
            dispatchAddEditResponse(false)
        }, 5000) 
    }
    
    useEffect(() => {
        dispatchGetAllContractList();
    },[addEditContractList])

    const getUpdatedValue = (updatedData:any) => {
        if (updatedData?.length === 1) {
            updatedData[0].Tier = updatedData[0].Tier ?? updatedData.length;
            updatedData[0].MinValue = updatedData[0].MinValue ?? initialdata[0].MinValue;
            updatedData[0].MaxValue = updatedData[0].MaxValue ?? initialdata[0].MaxValue;
            updatedData[0].id = 1
        }
        if(updatedData?.length > data?.length && updatedData?.length !== 1){
            updatedData[0].Tier = updatedData?.length;
            let min = (updatedData[1] || initialdata[0]).MaxValue.toString().replace("$", "").replace(',', "").split('');
            if(min === '$') {
            min.shift()
            }
            min= min.join('')
            updatedData[0].MinValue = "$"+(Number(min) + 1)?.toLocaleString();
        }

        updatedData?.forEach((tier: any) => {
            let minValue = tier.MinValue.split("$").join("").split(",").join("").split("%").join("")
            let maxValue = tier.MaxValue.split("$").join("").split(",").join("").split("%").join("")
            let percentage = tier.Percentage.split("$").join("").split(",").join("").split("%").join("")
            if (selectedType === "percentage") {
                tier.MinValue = Number(minValue)?.toLocaleString() + "%"
                tier.MaxValue = Number(maxValue)?.toLocaleString() + "%"
                tier.Percentage = Number(percentage) + "%"
            } else {
                tier.MinValue = "$" + Number(minValue)?.toLocaleString()
                tier.MaxValue = "$" + Number(maxValue)?.toLocaleString()
                tier.Percentage = Number(percentage) + "%"
            }
        })

        setData(updatedData)
        getValidateData("tierDescription", updatedData);
        const ele: any = document.getElementsByClassName("ka-no-data-row");
        if (ele && ele?.length > 0) {
            for (let i of ele) {
                i.style.display = "none"
            }
        }
    }
    const showSupplierModal = () => {
        if (values.supplierId) {
            setShowSupplier(values.supplierId)
        } else {
            setShowSupplier(true)
        }
    }
    const hideSupplierModal = () => {
        setShowSupplier(false)
    }
    const showProductModal = () => {
        if (values.productId) {
            setShowProduct(values.productId)
        } else {
            setShowProduct(true)
        }
    }
    const hideProductModal = () => {
        setShowProduct(false)
    }
    const showProductCategoryModal = () => {
        if (values.categoryId) {
            setShowProductCategory(values.categoryId);
        } else {
            setShowProductCategory(true);
        }
    }
    const hideProductCategoryModal = () => {
        setShowProductCategory(false);
    }
    const showProductSubCategoryModal = () => {
        if (values.subCategoryId) {
            setShowProductSubCategory(values.subCategoryId);
        } else {
            setShowProductSubCategory(true);
        }
    }
    const hideProductSubCategoryModal = () => {
        setShowProductSubCategory(false);
    }

    const getProductMappedData = () => {
        if (values.productId?.length) {
            return values.productId.map((i: any) => ({"productId": i}));
        } else {
            return [];
        }
    }

    const getCategoryMappedData = () => {
        if (values.categoryId?.length) {
            return values.categoryId.map((i: any) => ({"productCategoryId": i}));
        } else {
            return [];
        }
    }

    const getSubCategoryMappedData = () => {
        if (values.subCategoryId?.length) {
            return values.subCategoryId.map((i: any) => ({"productSubCategoryId": i}));
        } else {
            return [];
        }
    }

    const getAllSpendId = () => {
        if (AllSpend) {
            return 1;
        } else {
            return null;
        }
    } 

    const getUpdatedRebateCheckDate = () => {
        if (values.rebateCheckDateData && values.rebateCheckdate && currentRebateCheckDateData?.rebateCheckId) {
            const updatedRebateCheckDateList = values.rebateCheckDateData.map((checkObj: any) =>
                checkObj.rebateCheckId === currentRebateCheckDateData?.rebateCheckId ? {
                    ...checkObj, 
                    isCheckRecieved: getValue('checkReceived') === 'yes' ? true : false,
                    checkDate: getValue('checkReceived') === 'yes' ? new Date(moment(values.rebateCheckdate).format("YYYY-MM-DD")) : null,
                    modifiedby: username,
                    modifiedDate: new Date(),
                    contractDetailId: values.contract,
                    programYear: year
                } : checkObj
            );
            return updatedRebateCheckDateList
        } else {
            const newRebateCheckDateList = values.rebateCheckDateData?.concat({
                isCheckRecieved: getValue('checkReceived') === 'yes' ? true : false,
                checkDate: getValue('checkReceived') === 'yes' ? new Date(moment(values.rebateCheckdate).format("YYYY-MM-DD")) : null,
                createdDate: new Date(),
                createdBy: username,
                modifiedby: username,
                modifiedDate: new Date(),
                contractDetailId: values.contract,
                programYear: year
            })
            return newRebateCheckDateList
        }
    }

    const getStartDayRebatePeriod = (id:number, year:any, monthList:any) => {
        if(year){
            return new Date(moment().year(id === 0 ? year: String(Number(year) - 1)).month(monthList).startOf("month").format("YYYY-MM-DD"))
        }
    }

    const getEndDayRebatePeriod = (year:any, monthListEnd:any) => {
        if(year){
            return new Date(moment().year(year).month(monthListEnd).endOf("month").format("YYYY-MM-DD"))
        }
    }

    useEffect(() => {
        const validationPayload: any = {
            programId: values.program,
            ContractId: values.contract,
            programYear: year
        }

        if((validationPayload.programId !== 0 && validationPayload.programId !== null && 
                    validationPayload.programId !== undefined) &&
            (validationPayload.ContractId !== 0 && validationPayload.ContractId !== null && 
                    validationPayload.ContractId !== undefined && validationPayload.ContractId !== '') &&
            (validationPayload.programYear !== 0 && validationPayload.programYear !== null
                     && validationPayload.programYear !== undefined && String(Number(year)).length === 4)) {
                dispatchGetContractValidation(validationPayload)
            }

    },[values,year])

    useEffect(() => {
        setContractValidation(contractValidationflag);
    },[contractValidationflag])

    const submitClick = () => {
        setSubmitClicked(true);
        setSubmitFlag(true);
        setRenewContractClicked(false);
        Object.keys(values).forEach((key)=> {
            getValidateData(key,values[key]);
        })
        const rebateTiers: any = data.map((i: any) => ({
            level: i.Tier,
            minAmount: Number(i.MinValue.split("$").join("").split(",").join("").split("%").join("")),
            maxAmount: Number(i.MaxValue.split("$").join("").split(",").join("").split("%").join("")),
            percentage: Number(i.Percentage.split("%").join("")),
            contractDetailId: values.contract
        }));

        const payload: any = {
            contractDetailId: values.contract,
            contractName: values.contractName,
            rebateProgramId: values.program,
            contractStartDate: new Date(moment(values.startDate).format("YYYY-MM-DD")),
            contractEndDate: new Date(moment(values.endDate).format("YYYY-MM-DD")),
            programYear: year,
            billingTermsId: values.billingTermsId,
            rebatePeriodId: getNumberData(values.rebatePeriodId),
            rebateTypesId: values.rebateTypesId,
            rebateCalculationId: values.rebateCalculationId,
            rebateFrequencyId: values.rebateFrequencyId,
            rebatePaymentMethodId: values.rebatePayMethodId,
            paymentDueDate: new Date(moment(values.expectedPaymentDate).format("YYYY-MM-DD")),
            rebateCheckdate: getUpdatedRebateCheckDate(),
            eop: !!values.eop,
            withinTerms: !!values.withinTerms,
            isActive: values.active,
            rebateTierId: values.rebateTierId,
            endofYearDaystoPay: deadLineDays,
            calculateOnInvoiceDate: values?.basedOn === 2,
            calculateOnPaymentDate: values?.basedOn === 1,
            everGreen: !!values.evergreen,
            tempExtendContract: !!values.tempExtendContract,
            rebateProgram: {
                programId: values?.program,
                programName: values.name,
                supplierId: values.supplierId,
                isActive: values.active,
            },
            billingTerms: {
                contractDetailId: values.contract,
                effectiveDate: new Date(moment(values.billingDate).format("YYYY-MM-DD")),
                createdOn: new Date(),
                modifiedOn: new Date(),
                paymentTerms: {
                    netDays: values.Quantity
                }
            },
            rebatePeriods: {
                startMonth: id + 1,
                startDay: getStartDayRebatePeriod(id, year, monthList),
                endMonth: idEnd + 1,
                endDay: getEndDayRebatePeriod(year, monthListEnd),
                calenderMonthOffset: 0,
                fiscalMonthOffset: 0,
                PeriodMasterId: getNumberData(values.rebatePeriodId),
            },
            rebateTiers: rebateTiers,
            rebateCalculations: {
                contractDetailId: values.contract,
                calculationTypeId: values.calculationTypeId,
                calculationMethodId: values.calculationMethodId
            },
            contractDetailRebateTypeVM: {
                contractDetailId: values.contract,
                rebateTypeId: getAllSpendId(),
                allSpend: AllSpend,
                productSpecific: product,
                productCategorySpecific: productCategory,
                productSubCategorySpecific: productSubCategory,
                grouthTarget: growthTarget,
                basePercentage: percentage,
                salesGoal: salesGoal,
                rebateProductCategoryTypes: getCategoryMappedData(),
                rebateProductSubCategoryTypes: getSubCategoryMappedData(),
                rebateProductTypes: getProductMappedData(),
            },
            calculationTypeFlatPercentagesVM: {
                contractDetailId: values.contract,
            },
            calculationTypeGrowthTargetsVM: {
                contractDetailId: values.contract,
                rebatePercentage: rebatePercentage
            },
            calculationTypeLumSumsVM: {
                contractDetailId: values.contract,
                dollarAmount: 0
            },
            calculationTypeLumpSumPerUnitVM: {
                contractDetailId: values.contract,
                dollarAmount: 0,
                CreatedBY: username
            },
            calculationTypePrePaidLumSumsVM: {
                contractDetailId: values.contract,
                dollarAmount: 0,
                numberOfYears: noOfYears
                
            },
            calculationTypeGrowthTargetTieredVM: {
                contractDetailId: values?.contract
            },
            vendorCreditCardSpend: [{
                  isExcludeCreditCardSpend: isExcludeCreditCardSpend
            }]
        }
        if (salesGoalCheck) {
            payload.calculationTypeGrowthTargetsVM.salesGoal = salesGoal;
        }
        if (percentageCheck) {
            payload.calculationTypeGrowthTargetsVM.basePercentage = percentage;
        }
        if (values.calculationTypeId === 2) {
            payload.calculationTypeFlatPercentagesVM.percentage = percentage;
        }
        if (values.calculationTypeId === 6) {
    
            payload.calculationTypePrePaidLumSumsVM.dollarAmount = dollorAmount;
        }
        if (values.calculationTypeId === 5) {
            payload.calculationTypeLumSumsVM.dollarAmount = dollorAmount;
        }
        if (values.calculationTypeId === 7) {
            payload.calculationTypeLumpSumPerUnitVM.dollarAmount = dollorAmount;
        }
        if (salesGoalCheck && values.calculationTypeId == 4) {
            payload.calculationTypeGrowthTargetTieredVM.isSalesGoal = true;
        }
        if (percentageCheck && values.calculationTypeId == 4) {
            payload.calculationTypeGrowthTargetTieredVM.isPercentage = true;
        }
        if (newProgram) {
            payload.createdBy = username;
        } else {
            payload.modifiedBy = username;
        }

        let error = Object.values(errors).filter((val:any) => val !== '');
        if (error?.length === 0) {
            if(contractValidation) {
                setNewProgram(false)
                dispatchGetCreateUpdateRebateProgram(payload);
            }
            else {
                setContractWarning(true);
            }
        }
    }
    const handleMessage = () => {
        dispatchAddEditResponse(false)
        dispatchSetProgramError(false)
        setSubmitFlag(false);
    }
    const onEdit = (item:any) => {
        const ele = document.getElementById("program-list");
        if (ele) {
            ele.scroll(0,0);
        }
        setAddText(item.label);
        
    }

    const onEditContract = (item:any) => {
        const ele = document.getElementById("contract-list");
        if (ele) {
            ele.scroll(0,0);
        }
        setAddContractText(item.label);
        
    }

    const submitAddEditClick =  (item: any) => {
        const payload = {
                programId: item.key,
                programName: item.label,
                supplierId: values.supplierId,
            }
        dispatchRebateEdit(payload)
        setAddText('');
        setTimeout(()=> {
            dispatchAddEditResponse(false)
        },5000)
    }

    const submitAddEditContactClick = (item: any) => {
        const payload = {
            contractDetailId: item.key,
            contractName: item.label,
            programId: values.program
        }
        dispatchGetUpdateContractName(payload)
        setAddContractText('');
        setAddEditContact(false)
        setValues({...values, contract: item.key})
        setTimeout(()=> {
            dispatchAddEditResponse(false)
        },5000)
    }

    const cancelClick = () => {
        getInitialValues();
        setValues({});
        dispatchAddEditResponse(false);
        dispatchSetContractDetailsById({});
        setEditMode(false);
        setNewProgram(false);
        setRenewContractClicked(false);
        setAllContractDetails([]);
        setCurrentRebateCheckDateData({});
        setIsView(false);
        setYear(0);
        setId(0);
        setMonthList('');
        setIdEnd(0);
        setMonthListEnd('');
        setCount(0);
        setDeadLineDays(0);
    }

    const handleEditContract = (contactId: any) => {
        dispatchGetContractDetailsById(contactId);
        getProductCategory();
        getProduct();
        getSubCategory();
        getSupplier()
        getCalculationFrequency()
        getCalculationMethod()
        getCalculationType()
        dispatchGetRebatePeriod();
        dispatchGetRebatePaymentMethod();
        dispatchGetAllRebateTypes()
        setEditMode(true);
        setNewProgram(false);
        dispatchGetRebateProgramFieldInfo()
        setIsView(false);
        handleMessage()
    }

    const CustomFilterCell = (props: any) => {
        const tempColumn = props.column;
        const { search } = props;
        return (
            <div className='grid-search'>
                <Search placeholder={`Search ${tempColumn.title}`} className="grid-input" onChange={(e: any, text: any) => search(text)}/>
            </div>)
    };

    const ShowRebateProgramDetails = (e:any, data: any) =>{
        e.preventDefault()
        dispatchGetContractDetailsById(data);
        getProductCategory();
        getProduct();
        getSubCategory();
        getSupplier()
        getCalculationFrequency()
        getCalculationMethod()
        getCalculationType()
        dispatchGetRebatePeriod();
        dispatchGetRebatePaymentMethod();
        dispatchGetAllRebateTypes()
        setEditMode(true);
        setIsView(true);
        setNewProgram(false);
        handleMessage()
    }

    const gridColumn:gridColumnType[] = [
        {
            key: "vendorName",
            title: "Vendor Name",
            width: "120px",
            sortDirection: SortDirection.Ascend,
            FilterCell: (props: any) => <CustomFilterCell {...props} />,
            Cell: (row: any) => {return (<a onClick={(e:any) => ShowRebateProgramDetails(e,row.rowData.id)} className="order-link-id">{row.rowData.vendorName}</a>);}
        },
        {
            key: "programName",
            title: "Program Name",
            width: "120px",
            FilterCell: (props: any) => <CustomFilterCell {...props} />,
        },
        {
            key: "name",
            title: "Contract Name",
            width: "120px",
            FilterCell: (props: any) => <CustomFilterCell {...props} />,
        },
        {
            key: "paymentMethod",
            title: "Payment Method",
            width: "120px",
            FilterCell: (props: any) => <CustomFilterCell {...props} />,
        },
        {
            key: "rebatePeriod",
            title: "Rebate Period",
            width: "120px",
            FilterCell: (props: any) => <CustomFilterCell {...props} />,
        },
        {
            key: "everGreen",
            title: "Evergreen",
            width: "120px",
            FilterCell: (props: any) => <CustomFilterCell {...props} />,
        },
        {
            key: "tempExtendContract",
            title: "Temp Extended",
            width: "120px",
            FilterCell: (props: any) => <CustomFilterCell {...props} />,
        }
    ]

    useEffect(() => {
        if(isAuthorized("RebateAgreements", "update")){
            gridColumn.unshift({
                key: "action",
                title: "Actions",
                width: "38px",
                FilterCell: null,
                Cell: (row: any) => {
                        return <p className='action-container'>
                            <span title='Edit' className={!isAuthorized("RebateAgreements", "view") ? "disableLink" : ""} onClick={() => handleEditContract(row.rowData.id)}><img src={Edit} alt="" /></span>
                        </p>
                    }
            })
        }
    },[])

    const basedOnOptions = [
        {key: 1, text: "Payment Date", value: 1},
        {key: 2, text: "Invoice Date", value: 2}
    ]

    const onAddNewProgram = () => {
        setEditMode(true);
        setNewProgram(true);
        getSupplier();
        getProduct();
        getProductCategory();
        getSubCategory();
        getCalculationFrequency()
        getCalculationMethod()
        getCalculationType()
        dispatchGetRebatePeriod();
        dispatchGetRebatePaymentMethod();
        dispatchGetAllRebateTypes()
        handleMessage();
        dispatchGetRebateProgramFieldInfo();
        setDeadLineDays(0);
        setYear(0);
        setMonthList('');
        setMonthListEnd('');
        setCount(0);
    }

    const handleAddRebateTier = () => {
        setAddTier(true);
        if (data.length) {
            setInitialTierError();
        } else {
            setInitialTierError(true)
        }
        if (data.length) {
            const maxValue = data[data.length - 1].MaxValue;
            if (maxValue) {
                const rowValue = maxValue.split("$").join("").split(",").join("").split("%").join("");
                setTierDescription({
                    minValue: Number(rowValue) + 1
                })
            }
        }
    }

    const onHideAddTier = () => {
        setAddTier(false);
        setInitialTierError();
        setSaveTierBtn(false);
        setTierDescription({});
    }

    const handleAddTierSave = () => {
        setSaveTierBtn(true)
        let error = Object.values(tierErrors).filter((val:any) => val !== '');
        if (error?.length === 0) {
            const windowObj: any = window
            const crypto = windowObj.crypto || windowObj.msCrypto;
            const array = new Uint32Array(1);
            crypto.getRandomValues(array);
            const tempId = array[0];
            let tempTier;
            if (selectedType === "percentage") {
                tempTier = {
                    Tier: data?.length + 1,
                    MinValue: Number(tierDescription.minValue)?.toLocaleString() + "%",
                    MaxValue: Number(tierDescription.maxValue)?.toLocaleString() + "%",
                    Percentage: Number(tierDescription.percentage) + "%",
                    id: tempId
                }
            } else {
                tempTier = {
                    Tier: data?.length + 1,
                    MinValue: "$" + Number(tierDescription.minValue)?.toLocaleString(),
                    MaxValue: "$" + Number(tierDescription.maxValue)?.toLocaleString(),
                    Percentage: Number(tierDescription.percentage) + "%",
                    id: tempId
                }
            }
            setData([...data, tempTier]);
            onHideAddTier();
        }
    }

    const validateMinValue = (value: any) => {
        const maxValue = parseInt(data[data?.length - 1 || 0]?.MaxValue?.split("$").join("").split(",").join("").split("%").join("") || "0")
        if (value <= maxValue) {
            tierErrors.minValue = "Please enter valid Min Value";
        } else {
            tierErrors.minValue = "";
        }
    }

    const checkMinValue = (value: any) => {
        if (data?.length) {
            validateMinValue(value)
        } else {
            tierErrors.minValue = "";
        }
    }

    const handleMinValueValidation = (value: any) => {
        if (value || value == 0) {
            checkMinValue(value)
        } else {
            tierErrors.minValue = "Please provide Min Value";
        }
    }

    const checkForIfDataLength = (value: any) => {
        if (data.length === 0) {
            handleMinValueValidation(value)
        } else {
            tierErrors.minValue = "";
        }
    }

    const handleMinValueCheck = (value: any) => {
        if (selectedType === "percentage" && (value < 0 || value >= 999)) {
            tierErrors.minValue = "Please enter valid Min Value";
        } else {
            checkForIfDataLength(value)
        }
    }

    const validateMaxValue = (value: any) => {
        const maxValue = parseInt(data[data?.length - 1 || 0]?.MaxValue?.split("$").join("").split(",").join("").split("%").join("") || "0")
        if (value <= maxValue || value <= Number(tierDescription?.minValue) || value >= 1000000000) {
            tierErrors.maxValue = "Please enter valid Max Value";
        } else {
            tierErrors.maxValue = "";
        }
    }

    const checkWithMinAmount = (value: any) => {
        if (value <= Number(tierDescription?.minValue)) {
            tierErrors.maxValue = "Please enter valid Max Value";
        } else {
            tierErrors.maxValue = "";
        }
    }

    const checkMaxValue = (value: any) => {
        if (data?.length) {
            validateMaxValue(value);
        } else {
            checkWithMinAmount(value);
        }
    }

    const checkWhenValue = (value: any) => {
        if (value) {
            checkMaxValue(value)
        } else {
            tierErrors.maxValue = "Please provide Max Value";
        }
    }

    const handleMaxValueCheck = (value: any) => {
        if (selectedType === "percentage" && (value < 0 || value > 999)) {
            tierErrors.maxValue = "Please enter valid Max Value";
        } else {
            checkWhenValue(value);
        }
    }

    const checkforValidPercentage = (value: any) => {
        if (value >= 100) {
            tierErrors.percentage = "Please enter valid Percentage";
        } else {
            tierErrors.percentage = "";
        }
    }

    const handleTierPercentageCheck = (value: any) => {
        if (value) {
            checkforValidPercentage(value);
        } else {
            tierErrors.percentage = "Please enter Percentage";
        }
    }

    const getValidateTierData = (name: any, value: any) => {
        const newValue = Number(value);
        switch (name) {
            case 'minValue':
                handleMinValueCheck(newValue);
                break;
            case 'maxValue':
                handleMaxValueCheck(newValue);
                break;
            case 'percentage':
                handleTierPercentageCheck(newValue);
                break;
            default:
                break;
        }
    }

    const handleTierDescriptionInput = (event: any, item: any) => {
        let name = event.target.getAttribute('name');
        let value = item;
        if (name === "percentage" && Number(value) >= 100) {
            return;
        }
        if(selectedType === "percentage" && name === "maxValue" && (Number(value) < 0 || Number(value) > 999) ){
            return;
        } 
        if ((value && name === "maxValue" && !Number(value)) || Number(value) >= 1000000000) {
            setTierDescription({
                ...tierDescription,
                [name]: tierDescription[name] || ""
            })
            return;
        }
        
        getValidateTierData(name, value)
        if (Number(value) >= 0) {
            setTierDescription({
                ...tierDescription,
                [name]: value
            })
        }

    }

    const validateTableData = (tempColumn: any, value:any) => {
        let tempValue = value.split("$").join("").split(",").join("").split("%").join("")
         if (tempColumn.key === "Percentage") {
            if (Number(tempValue) < 0 || Number(tempValue) > 100) {
                return 'Enter a value between 0 and 100'
            } else if (typeof Number(tempValue) !== 'number' || isNaN(Number(tempValue))) {
                return 'Percentage should be number only'
            }
            else if (!tempValue) {
                return 'Percentage is required'
            }        }
        if (tempColumn.key === "MaxValue") {
            if (!tempValue) {
                return 'MaxValue is required'
            } else if (typeof Number(tempValue) !== 'number' || isNaN(Number(tempValue))) {
                return 'MaxValue should be number only'
            }
        }
        if (tempColumn.key === "MinValue") {
            if (!tempValue) {
                return 'MinValue is required'
            } else if (typeof Number(tempValue) !== 'number' || isNaN(Number(tempValue))) {
                return 'MinValue should be number only'
            }
        }
    }

    const getValue = (name: any) => {
        if (values[name]) {          
            return values[name];
        } else {
            
            return "";
        }
    }

    const getError = (name: any) => {
        if (!submitClicked) {
            return "";
        }
        if (errors[name]) {
            return errors[name];
        } else {
            return ""
        }
    }

    const getExternalErrorMessage = (name: any) => {
        if (!submitClicked) {
            return "";
        } 
        if (errors[name]){
            return <p className='mt-1 requiredText'>{errors[name]}</p>
        }
        return "";
    }

    const showAlert = (saved: any) => {
        if (submitFlag && saved) {
            return true;
        }
        return false;
    }

    const getHeader = () => {
      if (isEditMode && !newProgram && !isView) {
        return "REBATE PROGRAM EDIT";
      } else if (isEditMode && newProgram) {
        return "REBATE PROGRAM ENTRY";
      } else if(isEditMode && isView) {
        return "REBATE PROGRAM"
      } else {
        return "REBATE PROGRAM LIST";
      }
    }

    const addEditSupplierLink = () => {
        if (values?.supplierId && isAuthorized("Suppliers", "update")) {
            return <a href="/" onClick={e => {e.preventDefault();showSupplierModal()}} className="float-right addEdit ">Edit</a>
        } else {
            return "";
        }
    }

    const AddEditProgramLinkText = () => {
        if(isAuthorized("RebateAgreements", "update")){
            return "Add/Edit"
        } else if(isAuthorized("RebateAgreements", "create")){
            return "Add"
        } else {
            return ""
        }
    }

    const customDelete = (flag: any) => {
        if (data.length === 1) {
            return true;
        }
        const lastData = data[data.length - 1];
        if (lastData.id === flag) {
            return true;
        }
        return false;
    }
    const [authorized, setAuthorized] = useState<any>(null);
    useEffect(() => {
        const checkPermission = isAuthorized("RebateAgreements", "view");
        setAuthorized(checkPermission);
        
    }, []);

    if (!(authorized) && authorized != null) {
        return <ErrorHandling errorNumber='403'/>
    }

 
    const checkforTempExtendPopup = () => {

        let isValid:boolean = true;
        let endDateOfContract:any = getValue("endDate"); 
        let ActiveContracts:any = allContractDetails?.filter((i:any) => i.key > getValue("contract") && i.active === true
                                                                        || i.key < getValue("contract") && i.active === true);

        for(let i=0 ; i<ActiveContracts.length ; i++){
            
            let id:any = ActiveContracts?.[i].key;

            for(let j=0 ; j<contractList.length ; j++){

                if(contractList[j].contractId === id){
                        
                   let contractDetail:any = contractList[j];
                   let ContractEndDate:any = getDateFromDetails(contractDetail.contractEndDate, true);

                   if((ContractEndDate > getValue("endDate") && 
                      contractDetail.programYear > parseInt(getValue("programYear"), 10))
                      || contractDetail.everGreen === true){

                      isValid = false;           
                      break;
                    }
                }
            }
        }

        let currentTime = new Date();

        if(endDateOfContract !== '' &&  (endDateOfContract > currentTime))
        {
            isValid = false;
        } 

        if(isValid){
         setTempExtendPopup(true);
        }
        else{
         setTempExtendPopup(false);
        }
       
    }

        
    return (
    <RebateProgramEntry
            handleHome={handleHome}
            count={count}
            increment={increment}
            decrement={decrement}
            productDisabled={productDisabled}
            productCategoryDisabled={productCategoryDisabled}
            productSubCategoryDisabled={productSubCategoryDisabled}
            monthList={monthList}
            onMonthIncrement={onMonthIncrement}
            onMonthDecrement={onMonthDecrement}
            breadcrumlist={breadcrumlist}
            onMonthIncrementEnd={onMonthIncrementEnd}
            onMonthDecrementEnd={onMonthDecrementEnd}
            monthListEnd={monthListEnd}
            percentageIncrement={percentageIncrement}
            percentageDecrement={percentageDecrement}
            percentage={percentage}
            salesGoal={salesGoal}
            salesGoalIncrement={salesGoalIncrement}
            salesGoalDecrement={salesGoalDecrement}
            column={column}
            data={data}
            addEditPopup={addEditPopup}
            onShowAddEdit={onShowAddEdit}
            onHideAddEdit={onHideAddEdit}
            programOptions={programOptions}
            handleInputChange={handleInputChange}
            handleDateChange={handleDateChange}
            supplierOptions={supplierOptions}
            values={values}
            RebatePeriodOption={rebatePeriodOptions} 
            enableAllSpend={enableAllSpend}
            onAllSpendCheck={onAllSpendCheck} 
            AllSpend={AllSpend}
            product={product}
            productCategory={productCategory}
            productSubCategory={productSubCategory}
            growthTarget={growthTarget}
            onChangeProduct={onChangeProduct}
            onChangeproductCategory={onChangeproductCategory}
            onChangeProductSubCategory={onChangeProductSubCategory}
            onChangeGrowthTarget={onChangeGrowthTarget}
            productOptions={productOptions}
            productCategoryOptions={productCategoryOptions}
            productSubCategoryOption={productSubCategoryOption}
            percentageDisabled={percentageDisabled}
            salesForceDisabled={salesForceDisabled}
            disabledPercentageText={disabledPercentageText}
            disabledSalesGoalText={disabledSalesGoalText}
            onChangePercentage={onChangePercentage}
            onChangeSalesGoal={onChangeSalesGoal}
            percentageCheck={percentageCheck}
            salesGoalCheck={salesGoalCheck}
            calculationFrequency={calculationFrequency}
            paymentMethod={rebatePaymentMethod}
            calculationType={calculationType}
            calculationMethod={calculationMethod}
            onAddEditProgram={onAddEditProgram}
            onAddEditContract={onAddEditContract}
            handleAddEditInputChange={handleAddEditInputChange}
            onAdd={onAdd}
            onDelete={onDelete}
            addEditList={addEditList}
            addText={addText}
            onChangeSalesGoals={onChangeSalesGoals}
            onBlurSalesGoal={onBlurSalesGoal}
            onChangePercentageValue={onChangePercentageValue}
            getUpdatedValue={getUpdatedValue}
            showSupplier={showSupplier}
            showSupplierModal={showSupplierModal}
            hideSupplierModal={hideSupplierModal}
            showProduct={showProduct}
            showProductModal={showProductModal}
            hideProductModal={hideProductModal}
            showProductCategory={showProductCategory}
            showProductCategoryModal={showProductCategoryModal}
            hideProductCategoryModal={hideProductCategoryModal}
            showProductSubCategory={showProductSubCategory}
            showProductSubCategoryModal={showProductSubCategoryModal}
            hideProductSubCategoryModal={hideProductSubCategoryModal}
            submitClick={submitClick}
            onSearch={onSearch}
            submitClicked={submitClicked}
            errors={errors}
            submitAddEditClick={submitAddEditClick}
            onEdit={onEdit}
            programSaved={RebateSaved || RebateSavedEdit}
            programDeleted={RebateDelete}
            handleMessage={handleMessage}
            cancelClick={cancelClick}
            submitFlag={submitFlag}
            gridData={gridData}
            gridColumn={gridColumn}
            isEditMode={isEditMode}
            year={year}
            onYearIncrement={onYearIncrement}
            onYearDecrement={onYearDecrement}
            addEditContract={addEditContract}
            onHideAddEditContract={onHideAddEditContract}
            deadLineDays={deadLineDays}
            incrementDeadLine={incrementDeadLine}
            decrementDeadLine={decrementDeadLine}
            dollorAmount={dollorAmount}
            onChangeDollorAmount={onChangeDollorAmount}
            dollorAmountIncrement={dollorAmountIncrement}
            dollorAmountDecrement={dollorAmountDecrement}
            noOfYears={noOfYears}
            onChangeNoOfYears={onChangeNoOfYears}
            noOfyearsIncrement={noOfyearsIncrement}
            noOfyearsDecrement={noOfyearsDecrement}
            allContractDetails={allContractDetails}
            handleAddEditContractInputChange={handleAddEditContractInputChange}
            onAddContract={onAddContract}
            onDeleteContract={onDeleteContract}
            addEditContractList={addEditContractList}
            addContractText={addContractText}
            onSearchContract={onSearchContract}
            onEditContract={onEditContract}
            submitAddEditContactClick={submitAddEditContactClick}
            basedOnOptions={basedOnOptions}
            onAddNewProgram={onAddNewProgram}
            newProgram={newProgram}
            otherRebatePeriodDisabled={otherRebatePeriodDisabled}
            handleProgramYearChange={handleProgramYearChange}
            handleDeadLineDays={handleDeadLineDays}
            handleNetDays={handleNetDays}
            programError={programError}
            handleAddRebateTier={handleAddRebateTier}
            addTier={addTier}
            onHideAddTier={onHideAddTier}
            handleAddTierSave={handleAddTierSave}
            handleTierDescriptionInput={handleTierDescriptionInput}
            tierErrors={tierErrors}
            saveTierBtn={saveTierBtn}
            tierDescription={tierDescription}
            selectedCalculationType={selectedCalculationType}
            validateTableData={validateTableData}
            getValue={getValue}
            getError={getError}
            getExternalErrorMessage={getExternalErrorMessage}
            showAlert={showAlert}
            getHeader={getHeader}
            addEditSupplierLink={addEditSupplierLink}
            customDelete={customDelete}
            rebatePercentage={rebatePercentage}
            onChangeRebatePercentageValue={onChangeRebatePercentageValue}
            rebatePercentageIncrement={rebatePercentageIncrement}
            rebatePercentageDecrement={rebatePercentageDecrement}
            showGrid={showGrid}
            handleProduct={handleProduct}
            handleCategory={handleCategory}
            handleSubCategory={handleSubCategory}
            selectedProducts={selectedProducts}
            selectedCategory={selectedCategory}
            selectedSubCategory={selectedSubCategory}
            tableDataLoading={tableDataLoading}
            contractByIdLoading={contractByIdLoading}
            AddEditProgramLinkText={AddEditProgramLinkText}
            checkReceivedOptions={checkReceivedOptions}
            handleCheckReceivedRadio={handleCheckReceivedRadio}
            fieldInfoDescription={fieldInfoDescription}
            isView={isView}
            renewContract={renewContract}
            renewContractClicked={renewContractClicked}
            closeRenewContract={closeRenewContract}
            excludeCreditCardSpendChange = {excludeCreditCardSpendChange}
            isExcludeCreditCardSpend = {isExcludeCreditCardSpend}
            isShowDisabled = {isShowDisabled}
            setIsShowDisabled = {setIsShowDisabled}
            tempExtendPopup={tempExtendPopup}
            setTempExtendPopup={setTempExtendPopup}
            checkforTempExtendPopup={checkforTempExtendPopup}
            allActiveContracts={allActiveContracts}
            contractWarning={contractWarning}
            setContractWarning={setContractWarning}
        />)
}

export default RebateProgramEntryContainer
import React from 'react';
import { Breadcrumb,Card} from '../../CommonComponents';
import {Report as ReportComponent} from '@softura/reactpowerbi';
import "./Report.scss";
import CONSTANTS from 'client/utils/constants';



interface Iprops {
    breadcrumlist?:any;
    reportsData?: any;
    getNewAccessToken?: any;
}

const Report = (props:Iprops) => {
    const {
        breadcrumlist,
        reportsData,
        getNewAccessToken
    } = props;

    return (
        <div>
            <Breadcrumb items={breadcrumlist} maxDisplayedItems='5' />
            {
                reportsData?.id ? 
                    <Card>
                        {/* <Alert showAlert={true} text= 'Work in progress' className='alert mb-3' ></Alert> */}
                        <ReportComponent
                            getAccessToken={getNewAccessToken}
                            embedUrl= {reportsData?.embedUrl}
                            accessToken= {reportsData?.token}
                            reportId= {reportsData?.id}
                            Aad={false}
                            pageNavigationVisibile
                            pageNavigationLeft
                            type={CONSTANTS?.REPORT_TYPES?.REPORT}
                        />
                    </Card> :
                    ""
            }
        </div>
    )
};

export default Report;
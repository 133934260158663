import React, { useEffect,useState } from 'react';
import {useNavigate} from 'react-router-dom';
import ProductEntry from './ProductEntry';
import productEntrySelector from '../../../../state/features/RebateManagement/ProductEntry/selectors';
import productEntryOperations from '../../../../state/features/RebateManagement/ProductEntry/operations';
import subCategoryEntryOperations from '../../../../state/features/RebateManagement/ProductSubCategoryEntry/operations';
import categoryEntryOperations from '../../../../state/features/RebateManagement/ProductCategoryEntry/operations';
import supplierEntryOperations from '../../../../state/features/RebateManagement/SupplierEntry/operations';
import navOperations from '../../../../state/features/Navigation/operations';
import navSelectors from '../../../../state/features/Navigation/selectors';
import userSelector from '../../../../state/features/UserProfile/selectors';
import { useSelector, useDispatch } from "react-redux";
import { isAuthorized } from 'client/utils/Permissions';
import ErrorHandling from "../../../CommonComponents/ErrorHandling/ErrorHandling"

interface Iprops {
    showProduct?:boolean,
    hideProductModal?:Function
}

const ProductEntryContainer = (props:Iprops) => {
    const [productEntry,setProductEntry] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isSaveClicked, setSaveClicked] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [values, setValues] = useState<any>({
        isActive: true,
        agency: false
    });
    const [popupType, setPopupType] = useState("")
    const [showSupplier, setShowSupplier] = useState(false);
    const [showAddNew, setShowAddNew] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const dispatchGetProductById = productEntryOperations.dispatchGetProductById(dispatch);
    const dispatchGetCreateUpdateProduct = productEntryOperations.dispatchGetCreateUpdateProduct(dispatch);
    const dispatchsetProduct = productEntryOperations.dispatchsetProduct(dispatch);
    const dispatchSetProductSavedFlag = productEntryOperations.dispatchSetProductSavedFlag(dispatch);
    const dispatchSetProductName = productEntryOperations.dispatchSetProductName(dispatch);
    const dispatchSetSubCategoryName = subCategoryEntryOperations.dispatchSetSubCategoryName(dispatch);
    const dispatchSetCategoryName = categoryEntryOperations.dispatchSetCategoryName(dispatch);
    const dispatchSetSupplierName = supplierEntryOperations.dispatchSetSupplierName(dispatch);
    const dispatchSetErrorProduct = productEntryOperations.dispatchSetErrorProduct(dispatch);
    const dispatchNavProductEntry = navOperations.dispatchNavProductEntry(dispatch);
    
    const  {
        productData,
        product,
        productSaved,
        errorProduct,
        username,
        navProductEntry
    }:any  = useSelector(
        (state:any) => ({
            productData: productEntrySelector.getProductData(state),
            product: productEntrySelector.getProduct(state),
            productSaved: productEntrySelector.getProductSaved(state),
            errorProduct: productEntrySelector.getErrorProduct(state),
            username: userSelector.getUsername(state),
            navProductEntry: navSelectors.GetNavProductEntry(state)
        })
    )

    const getInitialError = () => {
        setErrors({
            productName:'Please provide Name',
            sku:'Please provide SKU',
            mfgSku:'',
        });
    }

    useEffect(()=> {
        setProductEntry(true);
        if (props.showProduct && typeof props.showProduct !== "boolean") {
            dispatchGetProductById(props.showProduct);
        }
        getInitialError()
    },[])

    useEffect(() => {
        if (navProductEntry) {
            dispatchNavProductEntry(false);
            setProductEntry(true);
        }
    }, [navProductEntry])

    useEffect(() => {
        if (product?.productId) {
            for (const key in product) {
                getValidateData(key, product[key])
            }
            setValues(product);
        }
    }, [product])

    useEffect(() => {
        if (productSaved) {
            const element = document.getElementsByClassName("modalBodyContainer");
            element[0]?.scroll(0,0);
            setValues({
                isActive: true,
                agency: false
            });
            dispatchsetProduct({});
            getInitialError();
            setSaveClicked(false);
            setTimeout(() => {
                closeProduct()
            }, 2000);
        }
    }, [productSaved])

    const hideSupplierModal = () => {
        setShowSupplier(false)
        setProductEntry(true)
        setShowAddNew(false)
    }
    const showSupplierModal = (type: string) => {
        setPopupType(type)
        if (type === "vendor" && values.supplierId && isAuthorized("Suppliers", "update")) {
            setShowSupplier(values.supplierId);
            setShowAddNew(true);
        } else if (type === "manufacturer" && values.manufacturerId && isAuthorized("Suppliers", "update")){
            setShowSupplier(values.manufacturerId);
            setShowAddNew(true);
        }else {
            setShowSupplier(true)
        }
        setProductEntry(false)
    }
    const [showProductCategory, setShowProductCategory] = useState(false);
    const hideCategoryModal = () => {
        setProductEntry(true)
        setShowProductCategory(false)
    }
    const showCategoryModal = () => {
        if (values.categoryId) {
            setShowProductCategory(values.categoryId)
        } else {
            setShowProductCategory(true);
        }
        setProductEntry(false)
    }

    const [showProductSubCategory, setShowSubProductCategory] = useState(false);
    const hideSubCategoryModal = () => {
        setShowSubProductCategory(false)
        setProductEntry(true)
    }
    const showSubCategoryModal = () => {
        if (values.subCategoryId) {
            setShowSubProductCategory(values.subCategoryId);
        } else {
            setShowSubProductCategory(true)
        }
        setProductEntry(false)
    }
  
    const hideProductEntry = () => {
      setProductEntry(false)
      setValues({
        isActive: true,
        agency: false
      })
      dispatchsetProduct({});
      dispatchSetProductSavedFlag(false);
      dispatchSetSubCategoryName("")
      dispatchSetCategoryName("");
      dispatchSetSupplierName("");
      if(props.hideProductModal) {
        props.hideProductModal()
      } else {
        handleClose()
      }
    }

    const handleClose = () => {
        navigate("/product-entry")
    }
    
    const closeProduct = () => {
        if (props.hideProductModal) {
            props.hideProductModal();
            dispatchSetProductSavedFlag(false);
        }
    }

    const handleSave = () => {
        setSaveClicked(true);
        dispatchSetSubCategoryName("")
        dispatchSetCategoryName("");
        dispatchSetSupplierName("");
        for (const key in values) {
            getValidateData(key, values[key])
        }
        let error = Object.values(errors).filter((val:any) => val !== '');
        if (error?.length === 0) {
            if (props.showProduct && !values.productId) {
                dispatchSetProductName(values.productName);
            }
            const payload = {
                ...values,
                IsActive: values.isActive
            };
            if (!payload.description) {
                payload.description= ""
            }
            if (payload.productId) {
                payload.modifiedBy = username;
            } else {
                payload.createdBy = username;
            }
            dispatchGetCreateUpdateProduct(payload);
            setShowProductCategory(false);
            setIsNew(false)
        }
    }
    
    const checkforName = (value: any) => {
        const istrue = productData?.length && productData.find((i: any) => i.productName.toLowerCase() === value.toLowerCase())
        if (istrue && !values.productId) {
            errors.productName='Name already exists'
        } else {
            errors.productName=''
        }
    }

    const handleNameCheck = (value: any) => {
        if(!value){
            errors.productName='Please provide Name'
        } else if (value?.length > 2 && value?.length < 250) {
            checkforName(value);
        } else if (value?.length >= 250) {
            errors.productName='Maximum 250 characters allowed'
        } else {
            errors.productName='Please provide Valid Name'
        }
    }

    const handleDescriptionCheck = (value: any) => {
        if (value && value?.length >= 500) {
            errors.description = "Maximum 500 characters allowed"
        } else {
            errors.description = ""
        }
    }

    const handleSKUCheck = (value: any) => {
        if(!value){
            errors.sku='Please provide SKU'
        }else if (value?.length >= 50) {
            errors.sku= 'Maximum 50 characters allowed'
        } else {
            errors.sku=''
        }
    }

    const handleMfgSKUCheck = (value: any) => {
        if(!value && values.manufacturerId){
            errors.mfgSku='Please provide Manufacturer SKU'
        }else if (value?.length >= 50 && values.manufacturerId) {
            errors.mfgSku= 'Maximum 50 characters allowed'
        } else {
            errors.mfgSku=''
        }
    }

    const checkForMfgSkuValue = () => {
        if (!values.mfgSku) {
            errors.mfgSku='Please provide Manufacturer SKU'
        } else {
            errors.mfgSku=''
        }
    }

    const handleManufacturerCheck = (value: any) => {
        if (value) {
            checkForMfgSkuValue()
        } else {
            errors.mfgSku=''
        }
    }

    const getValidateData = (name: any, value: any) => {
        switch (name) {
            case 'productName':
                handleNameCheck(value);
                break;
            case 'description':
                handleDescriptionCheck(value);
                break;
            case 'sku':
                handleSKUCheck(value);
                break;
            case 'mfgSku':
                handleMfgSKUCheck(value);
                break;
            case 'manufacturerId':
                handleManufacturerCheck(value);
                break;
            default:
                break;
        }
    }

    const handleInputChange = (event: any, item: any) => {
        if (productSaved || errorProduct) {
            handleMessage();
        }
        let name = event?.target?.getAttribute('name') || event?.target?.title || event?.name;
        dispatchSetSubCategoryName("")
        dispatchSetCategoryName("");
        dispatchSetSupplierName("");
        let value = item;
        if (
            name === "supplierId" ||
            name === "categoryId" ||
            name === "subCategoryId" ||
            name === "manufacturerId"
        ) {
            value = item.key
        }

        if (name === "isActive" || name === "agency") {
            value = item;
        }
        getValidateData(name, value);
        
        if (
            ((name === "sku" || name === "mfgSku") && value?.length > 50) ||
            (name === "description" && value?.length > 500) ||
            (name === "productName" && value?.length > 250)
        ) {
            return;
        }

        setValues({
            ...values,
            [name]: value
        })
    }

    const handleProduct = (_evt: any, item: any) => {
        if (productSaved || errorProduct) {
            handleMessage();
        }
        if (item.key === "addNew") {
            setValues({
                isActive: true,
                agency: false
            });
            dispatchsetProduct({})
            getInitialError();
            setSaveClicked(false);
            setIsNew(true);
        } else {
            dispatchGetProductById(item.key);
            setIsNew(false)
        }
    }

    const handleMessage = () => {
        dispatchSetProductSavedFlag(false);
        dispatchSetErrorProduct("");
    }


    const breadcrumlist = [
        { 
          text: 'Rebate Management', 
          key: 'f1', 
          // href: '/Home', 
          // onClick: (e: any) => { 
          //     e.preventDefault(); handleHome() 
          // } 
        },
        { text: 'Product Entry', key: 'f2' }
    ]
    
    const getFooterPrimaryBtnTxt = () => {
        if (values.productId) {
            return "Update";
        } else {
            return "Save";
        }
    }
    const [authorized, setAuthorized] = useState<any>(null);
    useEffect(() => {
        const checkPermission = isAuthorized("Products", "view");
        setAuthorized(checkPermission);
        
    }, []);

    if (!(authorized) && authorized != null) {
        return <ErrorHandling errorNumber='403'/>
    } 
    
    return (
        <ProductEntry
            hideProductEntry={hideProductEntry} 
            productEntry={productEntry} 
            breadcrumlist={breadcrumlist}
            handleSave={handleSave}
            isSaveClicked={isSaveClicked}
            handleInputChange={handleInputChange}
            values={values}
            errors={errors}
            showProduct={props.showProduct}
            showSupplierModal={showSupplierModal}
            showSupplier={showSupplier}
            hideSupplierModal={hideSupplierModal}
            hideCategoryModal={hideCategoryModal}
            showProductCategory={showProductCategory}
            showCategoryModal={showCategoryModal}
            showSubCategoryModal={showSubCategoryModal}
            showProductSubCategory={showProductSubCategory}
            hideSubCategoryModal={hideSubCategoryModal}
            handleProduct={handleProduct}
            errorProduct={errorProduct}
            setValues={setValues}
            popupType={popupType}
            handleMessage={handleMessage}
            FooterPrimaryBtnTxt={getFooterPrimaryBtnTxt}
            isNew={isNew}
            showAddNew={showAddNew}
        />
    )
}

export default ProductEntryContainer;

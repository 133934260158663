import { put, call, takeEvery, all } from 'redux-saga/effects';  
import api from '../../../../client/utils/clientApiHelper';
import {
    GET_CATEGORYDATA,
    GET_CATEGORYBYID,
    GET_CREATEUPDATECATEGORY
} from './types'

import * as categoryEntryActions from './action';

function* getCategoryData():any {
    try {
        const response = yield call(api.callGet, `ProductCatogories/GetAllCategoriesData`);
        if (response) {
            yield put(categoryEntryActions.setCategoryData(response));
        } else {
            yield put(categoryEntryActions.setCategoryData([]));
        }
    } catch (error) {
        yield put(categoryEntryActions.setCategoryData([]));
    }
    
}

function* getCategoryById(data: any): any {
    try {
        const response = yield call(api.callGet, `ProductCatogories/GetCategoriesById?CategoryId=${data.id}`);
        yield put(categoryEntryActions.setCategory(response));
    } catch (error) {
        yield put(categoryEntryActions.setCategory({}));
    }
}

function* getCreateUpdateCategory(data: any): any {
    try {
        const response = yield call(api.callPost, `ProductCatogories/CreateUpdateCategories`, data.payload);
        if (response) {
            yield put(categoryEntryActions.getCategoryData());
            yield put(categoryEntryActions.setCategorySavedFlag(true))
        }
    } catch (error) {
        yield put(categoryEntryActions.setErrorCategory("Something went wrong please try after sometime"));
        yield put(categoryEntryActions.setCategory({}));
    }
}

function* watchGetCommonDashboard() {
    yield takeEvery(GET_CATEGORYDATA, getCategoryData);
    yield takeEvery(GET_CATEGORYBYID, getCategoryById);
    yield takeEvery(GET_CREATEUPDATECATEGORY, getCreateUpdateCategory);
}

export default function* sagas() {
    yield all([
        watchGetCommonDashboard()
    ])
}
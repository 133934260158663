import { NAVIGATION, SHOW_MENU, SHOW_ORDER_MANAGEMENT, GET_HEIGHT, NAV_PROGRAM_ENTRY, NAV_VENDOR_ENTRY, NAV_PRODUCT_ENTRY } from './types'
const initialState = {
  nav: 'Home',
  height:null,
  showOrder: false,
  showMenu: false,
  navProgramEntry: false,
  navVendorEntry: false,
  navProductEntry: false
}

const reducer = (state = initialState, action:any  = {}) => {
  switch (action.type) {
    case NAVIGATION:
      return {
        ...state,
        nav: action.payload,
      }
      case GET_HEIGHT:
        return {
          ...state,
          height: action.payload,
        }
    case SHOW_ORDER_MANAGEMENT:
      return {
        ...state,
        showOrder: action.payload
      }
    case SHOW_MENU:
      return {
        ...state,
        showMenu: true
      }
    case NAV_PROGRAM_ENTRY:
      return {
        ...state,
        navProgramEntry: action.payload
      }
    case NAV_VENDOR_ENTRY:
      return{
        ...state,
        navVendorEntry: action.payload
      }  
      case NAV_PRODUCT_ENTRY:
        return{
          ...state,
          navProductEntry: action.payload
        }   
    default: return state
  }
}

export default reducer

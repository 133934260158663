import  { useEffect,useState,useCallback } from 'react'
import UserProfile from './UserProfile'
import { useSelector, useDispatch } from 'react-redux';
import userOperations from '../../../state/features/UserProfile/operations';
import userSelectors from '../../../state/features/UserProfile/selectors';
import { useMsal } from "@azure/msal-react";
import { PrimaryButton } from '../../CommonComponents'
import uploadimg from '../../assets/images/Upload.svg'
import {setResultMessage} from '../../../state/features/UserProfile/actions'

const UserProfileContainer = ({closeModalPopup}:any) => {
  const { accounts } = useMsal();
  const accountName = accounts[0]?.name;
  const dispatch = useDispatch();
  const [userName, setUserName] = useState({firstName : '', lastName : '', preferredName : '', userEmail:''});
  const setUserDetails = userOperations.dispatchUserDetail(dispatch);
  const dispatchGetCountryDropdown = userOperations.dispatchGetCountryDropdown(dispatch);
  const dispatchGetTitleDetails = userOperations.dispatchGetTitleDetails(dispatch);
  const dispatchGetDepartmentDetails = userOperations.dispatchGetDepartmentDetails(dispatch);
  const UpdateUserDetails = userOperations.dispatchUpdateUserDetails(dispatch)
  const {
    countryDropdown,
    titleDetails,
    departmentDetails
  } = useSelector(
    state => ({
      countryDropdown: userSelectors.countryDropdown(state),
      titleDetails: userSelectors.titleDetails(state),
      departmentDetails: userSelectors.departmentDetails(state),
    })
  );
  const [form, setForm] = useState<any>({
    preferredName:accountName,
    emailId:"",
    personalEmailId:"",
    contactNo:"",
    addressLine1:"",
    addressLine2:"",
    city:"",
    state:"",
    roleId:null,
    countryId:null,
    zipCode:"",
    profileBlobUrl:"",
    imageBase64:"",
    branchIds: [],
    id: ''
  });

  useEffect(()=>{
    if(accountName){
      dispatchGetCountryDropdown();
      dispatchGetTitleDetails();
      dispatchGetDepartmentDetails();
      let arr = {...userName};
      const nameList = accountName.split(" ");
      arr['firstName'] = nameList[0];
      arr['lastName'] = nameList[1];
      arr['preferredName'] = accountName;
      arr['userEmail'] = accounts[0].username ?? ''
      setUserName(arr);
    } 
  },[accountName]);
  const [userprofileinfo, setuserprofileinfo] = useState<any>({
    imagename:"",
    imguploaded:false,
    profileImageError:""
  });
  useEffect(()=>{
    if(countryDropdown){
      countryDropdown.forEach((x:any,i:any)=>{
        x['key'] = i;
      })
    }
  },[countryDropdown]);
    const getbranches = userOperations.dispatchBrahchValues(dispatch);
    const { getBranchValues } = useSelector(
        state => ({
            getBranchValues: userSelectors.getBranchValues(state),
        })
    )

  useEffect(()=>{
    getbranches();
  },[]);

const[checked, setChecked] = useState<any>([])
const OnCheckboxClick = (item:any) =>{
  setChecked(item)
  let arr = item.map((x:any)=> parseInt(x));
  setForm({...form,branchIds:arr})
}
const initialError = {
  employeeIdError:'',
  titleIdError:'',
  departmentIdError:'',
  branchError:'',
  zipCodeError:'',
  personalEmailIdError:'',
  contactNoError:'',
profileImageError:''
}
const [formError,setFormError]=useState<any>(initialError)
const [selectedCountry, setSelectedCountry] = useState<any>('');
const [uploadedImage, setUploadedImage] = useState<string>('');

const onChangeProfile = (e:any) => {
    const imgsrc =URL.createObjectURL(e.target.files[0])
    if(e.target.files[0]) {
      const filesize = Math.round((e.target.files[0].size * 0.001)*100)/100;
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("fileName", e.target.files[0].name);
      if (filesize < 150) {
        const imagenameTemp= e.target.files[0].name
        const IsprofileuploadedTemp=true
        setForm({...form,profileBlobUrl:imgsrc});
        setUploadedImage(imgsrc);
        
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (event:any) => {
          const image: any = new Image();
          image.src = event.target.result;
          image.onload = function () {
            const height = this.height;
            const width = this.width;
            if (height > 400 || width > 400) {
              const Isprofileuploaded=false
              const imagename='Upload Your Profile'
              setForm({...form,profileBlobUrl:uploadimg});
              setUploadedImage(uploadimg);
              setuserprofileinfo({...userprofileinfo,imagename:imagename,imguploaded:Isprofileuploaded,profileImageError:"Profile image's height and width must not exceed 400px."})
              return false;
            }
          };
          setForm({...form,imageBase64:event?.target?.result.split(',')[1]}); 
        }
        setuserprofileinfo({...userprofileinfo,imagename:imagenameTemp,imguploaded:IsprofileuploadedTemp,profileImageError:""})
      } else {
        const Isprofileuploaded=false
        const imagename='Upload Your Profile'
        setForm({...form,profileBlobUrl:uploadimg});
        setUploadedImage(uploadimg);
        setuserprofileinfo({...userprofileinfo,imagename:imagename,imguploaded:Isprofileuploaded,profileImageError:"Profile image shoule be less than 150kb"})
      }        
    }   
}

const onChangeCountry = (event?: any, item?:any): void => {
  setSelectedCountry(item);
  const value = item.value;
  const name = event.target.getAttribute('name')
  setForm({...form,[name]:value});
};



  const dispatchGetUserDetails = userOperations.dispatchGetUserDetail(dispatch);
  const {
    userDetails,
    resultMessage
  }:any = useSelector(
    state => ({
      userDetails: userSelectors.getUserDetails(state),
      resultMessage: userSelectors.getResultMessage(state)
    })
  );
  useEffect(()=>{
    if(userDetails){
      countryDropdown.forEach((x:any)=>{
        if(x?.value == userDetails.countryId){
          setSelectedCountry(x);
          const value = x?.value;
          setForm({...form,countryId:value});
        }
      })
    }
  },[userDetails])
  const User_id = userDetails.id;
  useEffect(() => {
    dispatchGetUserDetails({user:accounts[0].username});
  }, []);

useEffect(() => {
   setForm({...form,emailId:userName.userEmail})  
},[userName]);


useEffect (()=>{
    if(userDetails.emailId) {    
      let arr = userDetails.branches.map((x:any)=> x?.branchId);
      setChecked(arr)
      setForm({...userDetails, branchIds:arr})
    }
},[userDetails])

const isValidate = () => {
  let valid = true;
  let formValError:any = {};
  setFormError({...formValError})
  return valid
}
  
const onUpdateField = (e:any) => {
  const {name,value} = e.target;
  setForm({...form,[name]:value});
  if(e.target.name == 'zipCode' || e.target.name == 'contactNo'){
    const result = e.target.value.replace(/\D/g, '');

    setForm({...form,[name]:result});
  }
};


const hideDialogAndUserProfile =()=> {
  setIsConfirmationDialogVisible(false)
  closeModalPopup();
  dispatch(setResultMessage(""))
}

  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState(false)
  const hideConfirmationDialog = useCallback(() => setIsConfirmationDialogVisible(false), [])
  
  const confirmationDialogContentProps = {
      subText: <>
      <div className='dialog-msg mb-3'>
          {resultMessage} 
      </div>
      <div className='dialog-btn-header text-center'>
      <PrimaryButton text='Ok' onClick={hideDialogAndUserProfile} className='successdialog-btn'/>
    </div>
      </>
  }  
const onSubmitForm = (_e:any) => {
      if(form?.branchIds?.length === 0 || form?.branchIds?.length === undefined){
        let error = {...formError}
        error.branchError = "Branch Id is required";
        setFormError({...error});
      }
      else{
        let error = {...formError}
        setFormError({...error});
        error.branchError = "";   
       if(form.countryId == ""){
        form.country = null;
          form.countryId = null;
        }
        if(isValidate()){
          setIsConfirmationDialogVisible(true)
          if(form.id){
            UpdateUserDetails(form,form.id)            
          }else{
            setUserDetails(form)  
          }
         
          setFormError(initialError)
        }           
      }
};
const [showTab, setShowTab] = useState(false);
useEffect (() => {
  function handleResize() {
    const ResizeWidth = window.innerWidth;
      if(ResizeWidth > 600) {                 
        setShowTab(false)
      }
      else{
        setShowTab(true)
      }
  }
  handleResize();
  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);

  return (<>
    <UserProfile 
      userName = {userName}
      countryDropdown = {countryDropdown}
      form={form}
      formError={formError}
      onUpdateField={onUpdateField}
      onSubmitForm={onSubmitForm}
      BranchDetails = {getBranchValues}
      titleDetails = {titleDetails}
      departmentDetails ={departmentDetails}
      selectedCountry={selectedCountry}
      onChangeCountry={onChangeCountry}
      OnCheckboxClick={OnCheckboxClick}
      checked={checked}
      onChangeProfile={onChangeProfile}
      closeModalPopup={closeModalPopup}
      isConfirmationDialogVisible={isConfirmationDialogVisible}
      hideConfirmationDialog={hideConfirmationDialog}
      confirmationDialogContentProps={confirmationDialogContentProps}
      userprofileinfo={userprofileinfo}
      User_id = {User_id}
      showTab={showTab}
      uploadedImage={uploadedImage}
    />
    
  </>)
}

export default UserProfileContainer
import {
  SET_CONTACT_HISTORY_GRID_DATA,
  SET_CONTRACT_DROPDOWN_DATA,
  SET_DELETE_CONTACT_HISTORY_BY_ID,
  SET_INSERT_CONTACT_HISTORY_DATA,
  SET_PAID_EXPECTED_GRID_DATA,
  SET_PAYMENT_HISTORY_GRID_DATA,
  SET_SAVE_PAYMENT_COMPLETION,
  SET_SUPPLIER_DROPDOWN_DATA,
  SET_YEAR_DROPDOWN_DATA,
  SET_COLLECTIONS_HISTORY_LOADER
} from './types'

const initialState = {
  contactHistoryGridData: [],
  insertContactHistoryData: false,
  deleteContactHistoryById: false,
  collectionHistoryLoader: [],
}

const reducer = (state = initialState, action: any = {}) => {
  switch (action.type) {
    case SET_CONTACT_HISTORY_GRID_DATA:
      return {
        ...state,
        contactHistoryGridData: action.data,
      }
    case SET_INSERT_CONTACT_HISTORY_DATA:
      return {
        ...state,
        insertContactHistoryData: action.data,
      }
    case SET_DELETE_CONTACT_HISTORY_BY_ID:
      return {
        ...state,
        deleteContactHistoryById: action.data,
      }
    case SET_PAYMENT_HISTORY_GRID_DATA:
      return {
        ...state,
        paymentHistoryGridData: action.data,
      }
    case SET_PAID_EXPECTED_GRID_DATA:
      return {
        ...state,
        paidExpectedGridData: action.data,
      }
    case SET_SUPPLIER_DROPDOWN_DATA:
      return {
        ...state,
        supplierDropdownData: action.data,
      }
    case SET_CONTRACT_DROPDOWN_DATA:
      return {
        ...state,
        contractDropdownData: action.data,
      }
    case SET_YEAR_DROPDOWN_DATA:
      return {
        ...state,
        yearDropdownData: action.data,
      }
    case SET_SAVE_PAYMENT_COMPLETION:
      return {
        ...state,
        savePaymentCompletion: action.data,
      }
    case SET_COLLECTIONS_HISTORY_LOADER:
      return {
        ...state,
        collectionHistoryLoader: {...state.collectionHistoryLoader,...action.data},
      }
    default: return state
  }
}

export default reducer

import React from "react";
import AddTierDescription from "./addTierDescription";

interface IProps {
    handleTierDescriptionInput?: any;
    tierErrors?: any;
    saveTierBtn?: any;
    tierDescription?: any
    data?: any;
    fieldInfoDescription?:any;
}

const AddTierDescriptionContainer = (props: IProps) => {
    const {
        handleTierDescriptionInput,
        tierErrors,
        saveTierBtn,
        tierDescription,
        data,
        fieldInfoDescription
    } = props;

    return (
        <AddTierDescription
            handleTierDescriptionInput={handleTierDescriptionInput}
            tierErrors={tierErrors}
            saveTierBtn={saveTierBtn}
            tierDescription={tierDescription}
            data={data}
            fieldInfoDescription={fieldInfoDescription}
        />
    )
}

export default AddTierDescriptionContainer;
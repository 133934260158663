import { put, call, takeEvery, all } from 'redux-saga/effects';  
import api from '../../../../client/utils/clientApiHelper';
import {
    GET_STATES,
    GET_COUNTRIES,
    GET_SUPPLIERDATA,
    GET_SUPPLIER,
    GET_CREATEUPDATESUPPLIER,
    GET_MANUFACTURER,
    GET_CATEGORY_MANAGER,
    GET_PRIMARY_ABNUMBER,
    GET_SUPPLIER_DETAILS_BY_ABNUMBER,
    GET_SECONDARY_ABNUMBER_BY_SUPPLIER,
    GET_SECONDARY_ABNUMBER
} from './types'

import * as supplierEntryActions from './action';

function* getStateData(req: any):any {
    try {
        const response = yield call(api.callGet, `LookupData/dropdown/statesByCountry?countryId=${req.countryId}`);
        yield put(supplierEntryActions.setStates(response));
    } catch (error) {
        yield put(supplierEntryActions.setStates([]));
    }
    
}

function* getCountryData(): any {
    try {
        const response = yield call(api.callGet, `LookupData/dropdown/countries`);
        yield put(supplierEntryActions.setCountries(response));
    } catch (error) {
        yield put(supplierEntryActions.setCountries([]));
    }
}

function* getSupplierData():any {
    try {
        const response = yield call(api.callGet, `Supplier/GetAllSupplierData`);
        if (response) {
            yield put(supplierEntryActions.setSupplierdata(response));
        } else {
            yield put(supplierEntryActions.setSupplierdata([]));
        }
    } catch (error) {
        yield put(supplierEntryActions.setSupplierdata([]));
    }
    
}

function* getSupplierById(data: any): any {
    try {
        const response = yield call(api.callGet, `Supplier/GetSupplierById?supplierId=${data.id}`);
        yield put(supplierEntryActions.setSupplier(response));
    } catch (error) {
        yield put(supplierEntryActions.setSupplier({}));
    }
}

function* getCreateUpdateSupplier(data: any): any {
    try {
        const response = yield call(api.callPost, `Supplier/CreateUpdateSuppliers`, data.payload);
        if (response) {
            yield put(supplierEntryActions.getSupplierdata());
            yield put(supplierEntryActions.setSupplierSavedFlag(true))
        }
    } catch (error) {
        yield put(supplierEntryActions.setErrorSupplier("Something went wrong please try after sometime"))
    }
}

function* getManufacturerData():any {
    try {
        const response = yield call(api.callGet, `Supplier/GetAllManufacturerData`);
        if (response) {
            yield put(supplierEntryActions.setManufacturer(response));
        } else {
            yield put(supplierEntryActions.setManufacturer([]));
        }
    } catch (error) {
        yield put(supplierEntryActions.setManufacturer([]));
    }
    
}

function* getCategoryManagerSaga(): any {
    try{
        const response = yield call(api.callGet, `CategoryManager/GetAllCategoryManagers`);
        yield put(supplierEntryActions.setCategoryManager(response));    
    }
    catch(error){
        yield put(supplierEntryActions.setCategoryManager([]));
    }
}

function* getPrimaryAbnumber(payload: any): any {
    try{
        const response = yield call(api.callPost, `E1AddressBook/GetAPVendorsBySupplierId`, payload?.payload);
        if(response){
            yield put(supplierEntryActions.setPrimaryAbnumber(response));    
        }else{
            yield put(supplierEntryActions.setPrimaryAbnumber([]));    
        }
    }
    catch(error){
        yield put(supplierEntryActions.setPrimaryAbnumber([]));
    }
}

function* getSecondaryAbnumber(payload: any): any {
    try{
        const response = yield call(api.callPost, `E1AddressBook/GetAPVendorsBySupplierId`, payload?.payload);
        if(response){
            yield put(supplierEntryActions.setSecondaryAbnumber(response));    
        }else{
            yield put(supplierEntryActions.setSecondaryAbnumber([]));    
        }
    }
    catch(error){
        yield put(supplierEntryActions.setSecondaryAbnumber([]));
    }
}

function* getSupplierDetailsByAbnumber(payload: any): any {
    try{
        const response = yield call(api.callGet, `E1AddressBook/GetSuppliersByAbNumber?abNumber=${payload?.payload}`);
        yield put(supplierEntryActions.setSupplierDetailsByAbnumber(response));    
    }
    catch(error){
        yield put(supplierEntryActions.setSupplierDetailsByAbnumber([]));
    }
}

function* getSecondaryAbnumberBySupplier(payload: any): any {
    try{
        const response = yield call(api.callGet, `E1AddressBook/GetAllSecondaryNumberBySupplierId?supplierId=${payload?.payload}`);
        yield put(supplierEntryActions.setSecondaryAbnumberBySupplier(response));    
    }
    catch(error){
        yield put(supplierEntryActions.setSecondaryAbnumberBySupplier([]));
    }
}


function* watchGetCommonDashboard() {
    yield takeEvery(GET_STATES, getStateData);
    yield takeEvery(GET_COUNTRIES, getCountryData);
    yield takeEvery(GET_SUPPLIERDATA, getSupplierData)
    yield takeEvery(GET_SUPPLIER, getSupplierById)
    yield takeEvery(GET_CREATEUPDATESUPPLIER, getCreateUpdateSupplier)
    yield takeEvery(GET_MANUFACTURER, getManufacturerData)
    yield takeEvery(GET_CATEGORY_MANAGER, getCategoryManagerSaga)
    yield takeEvery(GET_PRIMARY_ABNUMBER, getPrimaryAbnumber)
    yield takeEvery(GET_SECONDARY_ABNUMBER, getSecondaryAbnumber)
    yield takeEvery(GET_SUPPLIER_DETAILS_BY_ABNUMBER, getSupplierDetailsByAbnumber)
    yield takeEvery(GET_SECONDARY_ABNUMBER_BY_SUPPLIER, getSecondaryAbnumberBySupplier)
}

export default function* sagas() {
    yield all([
        watchGetCommonDashboard()
    ])
}
import * as R from 'ramda';

const getRebateSpendData = (state:any) => R.pathOr('', ['RebateSpendIndividual', 'rebateSpendData'], state);
const getRebateSpend = (state:any) => R.pathOr('', ['RebateSpendIndividual', 'rebateSpend'], state);
const getRebateSpendSaved = (state:any) => R.pathOr('', ['RebateSpendIndividual', 'rebateSpendSaved'], state);
const selectors = {
  getRebateSpendData,
  getRebateSpend,
  getRebateSpendSaved
}

export default selectors

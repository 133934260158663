import React from 'react';
import {Report as ReportComponent} from '@softura/reactpowerbi';
import "./Home.scss";
import CONSTANTS from 'client/utils/constants';

interface IBreadcrumb {
    text: string,
    key: string,
    href?: string,
    onClick?: React.MouseEventHandler<HTMLDivElement>,
  }
interface Iprops {
    breadcrumlist: IBreadcrumb[],
    dashboardData: any,
    getNewAccessToken: any
}

const Home = (props:Iprops) => {
    const { dashboardData, getNewAccessToken} = props
    return <div>
        <ReportComponent
            getAccessToken={getNewAccessToken}
            embedUrl={dashboardData?.embedUrl}
            accessToken={dashboardData?.token}
            reportId={dashboardData?.id}
            Aad={false}
            pageNavigationVisibile
            pageNavigationLeft
            type={CONSTANTS?.REPORT_TYPES?.DASBOARD}
        />
    </div>
}

export default Home
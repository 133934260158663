import React, { useEffect, useState, useRef } from 'react'
import Index from './index'
import userProfileSelectors from '../../../state/features/UserProfile/selectors'
import { operations } from 'state/features/Navigation';
import { useMsal } from "@azure/msal-react";
import { useDispatch,useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const HeaderContainer = (props:any) => {
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const [showLocationDropdown, setShowLocationDropdown] = useState(false)
  const [showUserProfilePopup, setShowUserProfilePopup] = useState(false)
  const [showBranchDrpdown, setshowBranchDrpdown] = useState(false)

  const wrapperRef = useRef<any>(null);
    const navDispatch = operations.dispatchNav(dispatch);
    let navigate = useNavigate(); 
  const {
    userDetails,
    menu,
  } = useSelector(
    (state: any) => ({
      userDetails: userProfileSelectors.getUserDetails(state),
      menu: state.nav.showMenu,
    }));

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleLogout = (e: any) => {
    e.preventDefault()
    instance.logoutRedirect();
  }

  const hidePopup = () => {
    setShowUserProfilePopup(false)
  }
  const ShowPopup = (e: any) => {
    e.preventDefault()
    setShowUserProfilePopup(true)
  }

  const handleClickBranch = () => {
    setshowBranchDrpdown(true)
  }

  const handleClick = (item: any) => {
    console.info(item);
  }

  const cartClick = (e: any) => {
    e.preventDefault()
    if(e.currentTarget.baseURI.includes('OrderManagement')){
      navDispatch('Customer My Orders')
    }else{
      navDispatch('Customer My Orders')
      let path = `OrderManagement`; 
      navigate(path);
    }
  }

  const handleClickOutside = (event: any) => {
    if (wrapperRef?.current?.contains(event.target.closest('.branch-drpdown'))) {
      setshowBranchDrpdown(true)
    }
    else {
      setshowBranchDrpdown(false)
    }
  }
 

  const handleClickLocation = () => {
    setShowLocationDropdown(true)
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideLocation, false);
    return () => {
      document.removeEventListener("click", handleClickOutsideLocation, false);
    };
  }, []);
  
  const handleClickOutsideLocation = (event:any) =>{
    if(wrapperRef?.current?.contains(event.target.closest('.location-section'))) {
      setShowLocationDropdown(true)
    }
    else{
      setShowLocationDropdown(false)
    }
  }
 
  return (
    <Index
      userDetails={userDetails}
      handleClickBranch={handleClickBranch}
      accounts={accounts}
      menu={menu}
      wrapperRef={wrapperRef}
      showBranchDrpdown={showBranchDrpdown}
      handleClick={handleClick}
      ShowPopup={ShowPopup}
      handleLogout={handleLogout}
      showUserProfilePopup={showUserProfilePopup}
      hidePopup={hidePopup}
      heading={props?.heading}
      showMenu={props?.showMenu}
      toggleDropdown={props?.toggleDropdown}
      userMenuList={props?.userMenuList}
      handleClickLocation={handleClickLocation}
      showLocationDropdown={showLocationDropdown}
      cartClick={cartClick}
    />
  )
}

export default HeaderContainer

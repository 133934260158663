import { put, call, takeEvery, all } from 'redux-saga/effects';  
import api from '../../../client/utils/clientApiHelper';
import {
    GET_ACCOUNTANDFINANCE_REPORT,
    GET_MANUFACTURER_REPORT_DATA,
    GET_REPORT_DATA, GET_REPORT_DATA_BY_YEAR, GET_SPEND_REBATE_DASHBOARD,
} from './types'

import * as reportActions from './action';

function* getReportData(data: any) : any {
    try {
        const response = yield call(api.callGet, `Reports/GetReportID?userEmail=${data?.userEmail}`);
        if (response) {
            yield put(reportActions.setReportData(response));
        } else {
            yield put(reportActions.setReportData({}));
        }
    } catch (error) {
        yield put(reportActions.setReportData({}));
    }
    
}

function* getReportByYear(data: any): any {
    try {
        const response = yield call(api.callGet, `Reports/GetRebateSpendByYear?userEmail=${data?.userEmail}`);
        if (response) {
            yield put(reportActions.setReportDataByYear(response));
        } else {
            yield put(reportActions.setReportDataByYear({}));
        }
    } catch (error) {
        yield put(reportActions.setReportDataByYear({}));
    }
}
function* getSpendRebateDashboard(data: any): any {
    try {
        const response = yield call(api.callGet, `Reports/GetSpendRebateDashBorad?userEmail=${data?.userEmail}`);
        if (response) {
            yield put(reportActions.setSpendRebateDashboard(response));
        } else {
            yield put(reportActions.setSpendRebateDashboard({}));
        }
    } catch (error) {
        yield put(reportActions.setSpendRebateDashboard({}));
    }
}
function* getManufacturerReportData(data: any) : any {
    try {
        const response = yield call(api.callGet, `Reports/GetManifacturerReport?userEmail=${data?.userEmail}`);
        if (response) {
            yield put(reportActions.setManufacturerReportData(response));
        } else {
            yield put(reportActions.setManufacturerReportData({}));
        }
    } catch (error) {
        yield put(reportActions.setManufacturerReportData({}));
    }
}

    function* getAccountandFinanceReport(data: any) : any {
    try {
        const response = yield call(api.callGet, `Reports/GetOrgLevelVarianceReport?userEmail=${data?.userEmail}`);
        if (response) {
            yield put(reportActions.setAccountandFinanceReport(response));
        } else {
            yield put(reportActions.setAccountandFinanceReport({}));
        }
    } catch (error) {
        yield put(reportActions.setAccountandFinanceReport({}));
    }
}
    
function* watchGetCommonDashboard() {
    yield takeEvery(GET_REPORT_DATA, getReportData);
    yield takeEvery(GET_REPORT_DATA_BY_YEAR, getReportByYear);
    yield takeEvery(GET_SPEND_REBATE_DASHBOARD, getSpendRebateDashboard);
    yield takeEvery(GET_MANUFACTURER_REPORT_DATA, getManufacturerReportData);
    yield takeEvery(GET_ACCOUNTANDFINANCE_REPORT, getAccountandFinanceReport);
}

export default function* sagas() {
    yield all([
        watchGetCommonDashboard()
    ])
}
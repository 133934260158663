import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { operations, selectors } from 'state/features/Reports'
import Home from './Home'
import userSelector from '../../../state/features/UserProfile/selectors';
import ErrorHandling from "../../CommonComponents/ErrorHandling/ErrorHandling"


const HomeContainer = () => {
  const [accessAllowed, setAccessAllowed] = useState<any>(null)

  const { permissions }: any = useSelector((state: any) => ({
    permissions: state?.UserProfile?.rolesandClaims
  }))

  useEffect(() => {
    if (permissions.length) {
      setAccessAllowed(true)
    } else {
      setAccessAllowed(false)
    }
  }, [JSON.stringify(permissions?.length)])

  const breadcrumlist = [
    { text: 'Home', key: 'f1', href: '/Home', onClick: (e: any) => { e.preventDefault() } },
    { text: '', key: 'f2' }
  ]

  const dispatch = useDispatch();

  const [dashboardData, setDashboardData] = useState<any>({});

  const dispatchGetDashboardData = operations.dispatchGetSpendRebateDashboard(dispatch)

  const {
    spendRebateDashboard,
    username
  }: any = useSelector(
    (state: any) => ({
      spendRebateDashboard: selectors.getSpendRebateDashboard(state),
      username: userSelector.getUsername(state),
    })
  )

  useEffect(() => {
    if (username) {
      dispatchGetDashboardData(username)
    }
  }, [username])

  useEffect(() => {
    setDashboardData(spendRebateDashboard);
  }, [spendRebateDashboard])

  let getNewAccessToken = async () => {
    dispatchGetDashboardData(username)
    return dashboardData?.token;
  };

  if (!(accessAllowed) && accessAllowed != null) {
      return <ErrorHandling errorNumber='403'/>
  } 

  return <Home
    breadcrumlist={breadcrumlist}
    dashboardData={dashboardData}
    getNewAccessToken={getNewAccessToken}
  />

}

export default HomeContainer;

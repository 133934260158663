import React, { useEffect, useState } from "react";
import SelfReportingDashboard from "./SelfReportingDashboard";
import { useDispatch, useSelector } from "react-redux";
import selfReportingSelector from '../../../state/features/RebateManagement/SelfReporting/selectors';
import selfReportingOperations from '../../../state/features/RebateManagement/SelfReporting/operations';
import supplierEntrySelectors from '../../../state/features/RebateManagement/SupplierEntry/selectors';
import supplierEntryOperations from '../../../state/features/RebateManagement/SupplierEntry/operations';
import userSelector from '../../../state/features/UserProfile/selectors';
import { isAuthorized } from "client/utils/Permissions";
import ErrorHandling from "client/CommonComponents/ErrorHandling/ErrorHandling";
import { useMsal } from "@azure/msal-react";

const months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"]
let currentTime = new Date();
let Month = Math.max(0,currentTime.getMonth() - 2);

const SelfReportingDashboardContainer = () => {

    const dispatch = useDispatch();
    const { accounts } = useMsal();
    const [gridData, setGridData] = useState<any>([]);
    const [gridHeaderData, setGridHeaderData] = useState<any>([]);
    const [isCompactView, setIsCompactView] = useState<any>(false);
    const [categoryManagerList, setCategoryManagerList] = useState<any>([]);
    const [vendorTypeList, setVendorTypeList] = useState<any>([])
    const [statusList, setStatusList] = useState<any>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState<any>(10);
    const [pageIndex, setPageIndex] = useState<any>(1);
    const [vendorSearch, setVendorSearch] = useState<any>("");
    const [debouncedValue, setDebouncedValue] = useState<any>("");
    const [selectedYear, setSelectedYear] = useState<any>(currentTime.getFullYear());
    const [currentMonth, setCurrentMonth] = useState(Month);
    const [fileNotReceivedList, setFileNotReceivedList] = useState<any>([]);
    const [selectedCategoryManger, setSelectedCategoryManger] = useState([]);
    const [selectedVendorType, setSelectedVendorType] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [columnListToHide, setColumnListToHide] = useState([]);
    
  const [showReceivedColumn, setshowReceivedColumn] = useState(true);
  const [showPreppedColumn, setshowPreppedColumn] = useState(true);
  const [showProccessedColumn, setshowProccessedColumn] = useState(true);
  
    const [noteValue, setNoteValue] = useState<any>({
        "supplierId": 0,
        "categoryManager": "",
        "notes": ""
      });
    const [notReceivedMonths, setNotReceivedMonths] = useState<any>({
        monthList: [],
        supplierId: 0
    });
    const [showUploadFilePopup, setShowUploadFilePopup] = useState(false);
    const [isNotReceivedCheckbox, setIsNotReceivedCheckbox] = useState(false);
    const [sortingData, setSortingData] = useState<any>({
        "isSort": false,
        "sortColumn": "",
        "sortStatusColumn": "",
        "sortDirection": ""
    });
    const [updateStatusData, setUpdateStatusData] = useState<any>({
        "month": "",
        "status": "",
        "supplierId": "",
        "stage": ""
    });
    const [emailReminderPayload, setEmailReminderPayload] = useState<any>({
        "supplierId": 0,
        "month": "",
        "year": 0,
    });
    const [emailReminderPopup, setEmailReminderPopup] = useState<any>({
        "isPopupVisible": false
    });
    const [columnsToMap, setColumnsToMap] = useState<string[]>([]);

    const [sendEmailPopup, setsendEmailPopup] = useState(false);
    const [emailTemplateData, setEmailTemplateData] = useState({
        to: "",
        subject: "",
        message: ""
    })

    const dispatchGetSelfReportingGridData = selfReportingOperations.dispatchGetSelfReportingGridData(dispatch);
    const dispatchGetSelfReportingExcelData = selfReportingOperations.dispatchGetSelfReportingExcelData(dispatch);
    const dispatchSetSelfReportingExcelData = selfReportingOperations.dispatchSetSelfReportingExcelData(dispatch);
    const dispatchGetSelfReportingGridHeaderData = selfReportingOperations.dispatchGetSelfReportingGridHeaderData(dispatch);
    const dispatchGetCategoryManager = supplierEntryOperations.dispatchGetCategoryManager(dispatch);
    const dispatchGetVendorHistoryData = selfReportingOperations.dispatchGetVendorHistoryData(dispatch);
    const dispatchGetVendorTypeDropdownList = selfReportingOperations.dispatchGetVendorTypeDropdownList(dispatch);
    const dispatchGetStatusDropdownList = selfReportingOperations.dispatchGetStatusDropdownList(dispatch);
    const dispatchGetFileCompletionData = selfReportingOperations.dispatchGetFileCompletionData(dispatch);
    const dispatchGetValidateEmailReminder = selfReportingOperations.dispatchGetValidateEmailReminder(dispatch);
    const dispatchSetValidateEmailReminder = selfReportingOperations.dispatchSetValidateEmailReminder(dispatch);
    const dispatchGetEmailReminder = selfReportingOperations.dispatchGetEmailReminder(dispatch);
    const dispatchSetUpdateFileStatus = selfReportingOperations.dispatchSetUpdateFileStatus(dispatch);
    const dispatchGetVendorFileCount = selfReportingOperations.dispatchGetVendorFileCount(dispatch);
    const dispatchGetAddSelfReportingNotes = selfReportingOperations.dispatchGetAddSelfReportingNotes(dispatch);
    const dispatchGetSelfReportingNotes = selfReportingOperations.dispatchGetSelfReportingNotes(dispatch);
    const dispatchGetEmailTemplate = selfReportingOperations.dispatchGetEmailTemplate(dispatch);
    const dispatchSetEmailTemplate = selfReportingOperations.dispatchSetEmailTemplate(dispatch);

    const {
        selfReportingGridData,
        selfReportingExcelData,
        selfReportingGridHeaderData,
        categoryManagerdata,
        vendorHistoryData,
        vendorTypeDropdownList,
        statusDropdownList,
        fileCompletionData,
        validateEmailReminder,
        emailReminder,
        username,
        updateFileStatus,
        selfReportingLoader,
        selfReportingNotes,
        addSelfReportingNotes,
        emailTemplate
    }: any = useSelector(
        (state: any) => ({
            selfReportingGridData: selfReportingSelector.getSelfReportingGridData(state),
            selfReportingExcelData: selfReportingSelector.getSelfReportingExcelData(state),
            selfReportingGridHeaderData: selfReportingSelector.getSelfReportingGridHeaderData(state),
            categoryManagerdata: supplierEntrySelectors.getCategoryManagerData(state),
            vendorHistoryData: selfReportingSelector.getVendorHistoryData(state),
            vendorTypeDropdownList: selfReportingSelector.getVendorTypeDropdownList(state),
            statusDropdownList: selfReportingSelector.getStatusDropdownList(state),
            fileCompletionData: selfReportingSelector.getFileCompletionData(state),
            validateEmailReminder: selfReportingSelector.getValidateEmailReminder(state),
            emailReminder: selfReportingSelector.getEmailReminder(state),
            username: userSelector.getUsername(state),
            updateFileStatus: selfReportingSelector.getUpdateFileStatus(state),
            selfReportingLoader: selfReportingSelector.getSelfReportingLoader(state),
            selfReportingNotes: selfReportingSelector.getSelfReportingNotes(state),
            addSelfReportingNotes: selfReportingSelector.getAddSelfReportingNotes(state),
            emailTemplate: selfReportingSelector.getEmailTemplate(state),
        })
    )

    useEffect(() => {
        if (updateFileStatus == "success") {
            getGridData();
            dispatchGetFileCompletionData(selectedYear)
            dispatchGetSelfReportingGridHeaderData(selectedYear);
            dispatchGetVendorFileCount({
                month: currentMonth + 1,
                year: selectedYear
            })
            setTimeout(() => {
                onCloseUpdatePopup();
            }, 2000);   
        }
    }, [updateFileStatus])

    useEffect(()=> {
        setColumnsToMap([])
        if(showReceivedColumn) setColumnsToMap( Previtems => [...Previtems,"Received"])
        if(showPreppedColumn) setColumnsToMap( Previtems => [...Previtems,"Prepped"])
        if(showProccessedColumn) setColumnsToMap( Previtems => [...Previtems,"Processed"])
    },[showReceivedColumn,showPreppedColumn,showProccessedColumn])

    useEffect(() => {
        if (validateEmailReminder?.emailSentCount === 0) {
            getEmailTemplate();
        } else if (validateEmailReminder?.emailSentCount > 0) {
            setEmailReminderPopup({ ...emailReminderPopup, isPopupVisible: true, responseMessage: validateEmailReminder.response })
        }
    }, [validateEmailReminder])

    useEffect(() => {
        dispatchGetCategoryManager();
        dispatchGetVendorTypeDropdownList();
        dispatchGetStatusDropdownList();
    }, []);

    useEffect(()=>{
        if(emailTemplate?.created){
            setsendEmailPopup(true)
            setEmailTemplateData({
                to: emailTemplate?.supplierEmailId,
                subject: emailTemplate?.description,
                message: emailTemplate?.value
            })
        }
    },[emailTemplate])

    useEffect(()=>{
        if(addSelfReportingNotes){
            dispatchGetSelfReportingNotes(noteValue?.supplierId)
        }
    },[addSelfReportingNotes])

    const base64ToArrayBuffer = (base64: any) => {
        const binaryString = window.atob(base64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    const saveByteArray = (fileName: any, byteData: any, fileType: any) => {
        const blob = new Blob([byteData], { type: fileType });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    };

    useEffect(() => {
        if (selfReportingExcelData?.length) {
            const bytes = base64ToArrayBuffer(selfReportingExcelData);
            saveByteArray("selfReportingData", bytes, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
            dispatchSetSelfReportingExcelData([])
        }
    }, [selfReportingExcelData])

    useEffect(() => {
        if (vendorHistoryData?.length > 0) {
            const monthList = vendorHistoryData?.filter((item: any) => item.received === 'Not Received').map((filteredItem: any) => filteredItem.calendarMonth)
            setNotReceivedMonths({ ...notReceivedMonths, monthList });
        } else {
            setNotReceivedMonths({ ...notReceivedMonths, monthList: [] })
        }
    }, [vendorHistoryData]);

    useEffect(() => {
        if (categoryManagerdata?.length > 0) {
            let list = categoryManagerdata.map((item: any) => {
                return { ...item, value: item.categoryManagerId, label: item.categoryManagerName }
            })
            setCategoryManagerList(list)
            setSelectedCategoryManger(list)
        }
    }, [categoryManagerdata])

    useEffect(() => {
        if (vendorTypeDropdownList?.length > 0) {
            let list = vendorTypeDropdownList.map((item: any) => {
                return { ...item, value: item.vendorTypeId, label: item.vendorTypeName }
            })
            setVendorTypeList(list)
            setSelectedVendorType(list)
        }
    }, [vendorTypeDropdownList])

    useEffect(() => {
        if (statusDropdownList?.length > 0) {
            let list = statusDropdownList.map((item: any) => {
                return { ...item, value: item.statusId, label: item.statusName }
            })
            setStatusList(list)
            // setSelectedStatus(list)
        }
    }, [statusDropdownList])

    useEffect(() => {
        dispatchGetSelfReportingGridHeaderData(selectedYear);
        dispatchGetFileCompletionData(selectedYear)
        setSortingData({
            "isSort": false,
            "sortColumn": "",
            "sortStatusColumn": "",
            "sortDirection": ""
        })
    }, [selectedYear])

    useEffect(() => {
        const otherProps = ['preppedData', 'processedData', 'receivedData']
        if (selfReportingGridHeaderData?.length > 0) {
            const updatedList = selfReportingGridHeaderData?.map((item: any) => {
                let list: any = []
                otherProps?.map((prop: any) => {
                    list[prop] = JSON.parse(item[prop])[0]
                })
                list['monthYear'] = item.monthYear
                return list
            })
            setGridHeaderData(updatedList)
        } else {
            setGridHeaderData([])
        }
    }, [selfReportingGridHeaderData]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(vendorSearch);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [JSON.stringify(vendorSearch)]);

    useEffect(() => {
        getGridData();
    }, [pageIndex, pageSize, debouncedValue, selectedYear, currentMonth, sortingData, selectedCategoryManger, selectedVendorType, selectedStatus, isNotReceivedCheckbox])

    const getGridData = () => {
        let showList = []
        if(showReceivedColumn) showList.push('received')
        if(showPreppedColumn) showList.push('prepped')
        if(showProccessedColumn) showList.push('proccessed')

        const payload: any = {
            "pageIndex": pageIndex,
            "pageSize": pageSize,
            "isSort": sortingData.isSort,
            "sortColumn": sortingData.sortColumn.replace(selectedYear, ''),
            "sortStatusColumn": sortingData.sortStatusColumn,
            "supplierName": debouncedValue,
            "calendarYear": selectedYear,
            "categoryManagerIdList": null,
            "sortDirection": sortingData.sortDirection,
            "vendorTypeList": null,
            "statusFilter": null,
            "isFilterByOverallNotReceived": isNotReceivedCheckbox,
            "columnsToShow": showList
        }
        
        if (selectedCategoryManger?.length > 0) {
            let list = selectedCategoryManger.map((item: any) => item.value)
            payload.categoryManagerIdList = list.join(',')
        }
        if (selectedVendorType?.length > 0) {
            let list = selectedVendorType.map((item: any) => item.value)
            payload.vendorTypeList = list.join(',')
        }
        if (selectedStatus?.length > 0) {
            let list = selectedStatus.map((item: any) => item.label)
            payload.statusFilter = list.join(',')
        }
        if (pageIndex && pageSize)
            dispatchGetSelfReportingGridData(payload)
    }

    useEffect(() => {
        const otherProps = ['categoryManager', 'supplierName', 'supplierId', 'vendorType']
        if (selfReportingGridData?.selfReportedVendorList?.length > 0) {
            let fileNotReceivedListTemp: any = []
            const updatedList = selfReportingGridData?.selfReportedVendorList?.map((item: any) => {
                let list: any = []
                let notReceivedList: any = []
                months?.map((month: any) => {
                    list[month] = JSON.parse(item[month])[0]
                    if (list[month]?.Received === "Not Received") {
                        notReceivedList.push(month)
                    }
                })
                otherProps?.map((prop: any) => {
                    list[prop] = item[prop]
                })
                if (notReceivedList?.length > 0) {
                    fileNotReceivedListTemp.push({ list: notReceivedList, supplierId: item?.supplierId, isVisible: false })
                }
                return list;
            })
            setFileNotReceivedList(fileNotReceivedListTemp)
            setGridData(updatedList)
            setTotalCount(selfReportingGridData?.totalRecord)
            setColumnListToHide(selfReportingGridData?.columnListToHide)
        } else {
            setGridData([])
            setTotalCount(0)
            setColumnListToHide([])
        }
    }, [selfReportingGridData])

    const onPageSizechange = (size: any) => { setPageSize(size) }
    const onNext = (index: any) => { setPageIndex(index) }
    const onPrevious = (index: any) => { setPageIndex(index) }
    const onLast = (index: any) => { setPageIndex(index) }
    const onFirst = (index: any) => { setPageIndex(index) }

    const onChangeVendor = (value: any) => {
        setVendorSearch(value);
    }

    const onSortData = (monthYear: any, status: any) => {
        let sortDir = 'asc'
        if (sortingData?.sortColumn === monthYear && sortingData?.sortStatusColumn === status) {
            sortDir = sortingData?.sortDirection === 'asc' ? 'desc' : 'asc'
        }
        setSortingData({
            "isSort": true,
            "sortColumn": monthYear,
            "sortStatusColumn": status,
            "sortDirection": sortDir
        })
    }

    const showHistoryData = (supplierId: any) => {
        setNotReceivedMonths({ supplierId, monthList: [] })
        dispatchGetVendorHistoryData({ supplierId, year: selectedYear })
    }

    const onClickExcel = () => {
        const payload: any = {
            "pageIndex": 0,
            "pageSize": 0,
            "isSort": sortingData.isSort,
            "sortColumn": sortingData.sortColumn.replace(selectedYear, ''),
            "sortStatusColumn": sortingData.sortStatusColumn,
            "supplierName": debouncedValue,
            "calendarYear": selectedYear,
            "categoryManagerIdList": null,
            "sortDirection": sortingData.sortDirection,
            "vendorTypeList": null,
            "statusFilter": null,
            "columnsToShow": columnsToMap
        }

        if (selectedCategoryManger?.length > 0) {
            let list = selectedCategoryManger.map((item: any) => item.value)
            payload.categoryManagerIdList = list.join(',')
        }
        if (selectedVendorType?.length > 0) {
            let list = selectedVendorType.map((item: any) => item.value)
            payload.vendorTypeList = list.join(',')
        }
        if (selectedStatus?.length > 0) {
            let list = selectedStatus.map((item: any) => item.label)
            payload.statusFilter = list.join(',')
        }
        dispatchGetSelfReportingExcelData(payload)
    }

    const onClickGridIcons = (stage: any, status: any, supplierId: any, month: any) => {
        setShowUploadFilePopup(true)
        setUpdateStatusData({
            month,
            status,
            stage,
            supplierId
        })
    }

    const onClickSendReminder = (monthList: any, supplierId: any) => {
        let list = monthList.map((item: string) => item?.toLowerCase())
        const payload = {
            "supplierId": supplierId,
            "month": list.join(','),
            "year": selectedYear
        }
        dispatchGetValidateEmailReminder(payload)
        setEmailReminderPayload(payload)
    }

    const getEmailTemplate = () =>{        
        const payload = {
            "supplierId": emailReminderPayload.supplierId,
            "monthsList": emailReminderPayload.month,
            "year": emailReminderPayload.year,
            "userEmailId": username
        }
        dispatchGetEmailTemplate(payload);
    }

    const sendReminder = (data:any) => {                  
        let str = data?.message;
        str = str + "\n\n{AutoreplyMessage}"        
        const payload = {
            "supplierId": emailReminderPayload?.supplierId,
            "monthsList": emailReminderPayload?.month,
            "year": emailReminderPayload?.year,
            "userEmailId": username,
            "to": data?.to,
            "subject": data?.subject,
            "message": str
        }
        dispatchGetEmailReminder(payload)
        setEmailReminderPayload({
            "supplierId": 0,
            "month": "",
            "year": 0,
        })
        onCloseSendEmailPopup();
    }

    const hidePopup = () => {
        setEmailReminderPopup({ ...emailReminderPopup, isPopupVisible: false })
        dispatchSetValidateEmailReminder({})
    }

    const onCloseUpdatePopup = () => {
        setUpdateStatusData({});
        setShowUploadFilePopup(false);
        dispatchSetUpdateFileStatus("")
    }

    const [authorized, setAuthorized] = useState<any>(null);
    useEffect(() => {
        const checkPermission = isAuthorized("SelfReportingDashboard", "view");
        setAuthorized(checkPermission);
    }, []);

    if (!(authorized) && authorized != null) {
        return <ErrorHandling errorNumber='403'/>
    }

    const getNotes = (supplierId:any) => {
        dispatchGetSelfReportingNotes(supplierId);
    }

    const addNotes = () => {
        if(noteValue.notes?.trim() != ""){
            const payload = {
                "supplierId": noteValue?.supplierId,
                "categoryManager": noteValue?.categoryManager,
                "notes": noteValue?.notes,
                "notesAddedAt": new Date(),
                "notesAddedBy": accounts[0]?.name,
                "userEmailId": username
            }
            dispatchGetAddSelfReportingNotes(payload)                        
            setNoteValue({
                ...noteValue, notes: ""
            })
        }
    }

    const onCloseSendEmailPopup = () => { 
        setsendEmailPopup(false);
        setEmailTemplateData({
          to: '',
          subject: '',
          message: ''
        })
        dispatchSetValidateEmailReminder({})
        dispatchSetEmailTemplate({})
      }      

    return (
        <>
            <SelfReportingDashboard
                gridData={gridData}
                gridHeaderData={gridHeaderData}
                months={months}
                totalCount={totalCount}
                pageSize={pageSize}
                pageIndex={pageIndex}
                onPageSizechange={onPageSizechange}
                onNext={onNext}
                onPrevious={onPrevious}
                onLast={onLast}
                onFirst={onFirst}
                onChangeVendor={onChangeVendor}
                fileNotReceivedList={fileNotReceivedList}
                setSelectedYear={setSelectedYear}
                selectedYear={selectedYear}
                onSortData={onSortData}
                sortingData={sortingData}
                setIsCompactView={setIsCompactView}
                isCompactView={isCompactView}
                categoryManagerList={categoryManagerList}
                vendorTypeList={vendorTypeList}
                statusList={statusList}
                selectedVendorType={selectedVendorType}
                setSelectedVendorType={setSelectedVendorType}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                selectedCategoryManger={selectedCategoryManger}
                setSelectedCategoryManger={setSelectedCategoryManger}
                showHistoryData={showHistoryData}
                vendorHistoryData={vendorHistoryData}
                notReceivedMonths={notReceivedMonths}
                showUploadFilePopup={showUploadFilePopup}
                setShowUploadFilePopup={setShowUploadFilePopup}
                onClickExcel={onClickExcel}
                fileCompletionData={fileCompletionData}
                onClickSendReminder={onClickSendReminder}
                emailReminderPopup={emailReminderPopup}
                sendReminder={sendReminder}
                hidePopup={hidePopup}
                onClickGridIcons={onClickGridIcons}
                updateStatusData={updateStatusData}
                onCloseUpdatePopup={onCloseUpdatePopup}
                currentMonth={currentMonth}
                setCurrentMonth={setCurrentMonth}
                selfReportingLoader={selfReportingLoader}
                sendEmailPopup = {sendEmailPopup}
                setsendEmailPopup = {setsendEmailPopup}
                getNotes={getNotes}
                addNotes={addNotes}
                selfReportingNotes={selfReportingNotes}
                setNoteValue={setNoteValue}
                noteValue={noteValue}
                isNotReceivedCheckbox={isNotReceivedCheckbox}
                setIsNotReceivedCheckbox={setIsNotReceivedCheckbox}
                columnListToHide={columnListToHide}
                setshowReceivedColumn={setshowReceivedColumn}
                setshowPreppedColumn={setshowPreppedColumn}
                setshowProccessedColumn={setshowProccessedColumn}
                showReceivedColumn={showReceivedColumn}
                showPreppedColumn={showPreppedColumn}
                showProccessedColumn={showProccessedColumn}
                emailTemplateData={emailTemplateData}
                setEmailTemplateData={setEmailTemplateData}
                getEmailTemplate={getEmailTemplate}
                onCloseSendEmailPopup={onCloseSendEmailPopup}
            />
        </>
    );

}

export default SelfReportingDashboardContainer;


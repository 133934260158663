import React from "react";
import { Col, InputField, Label, Row, Tooltip } from "client/CommonComponents";  

interface IProps {
    handleTierDescriptionInput?: any;
    tierErrors?: any;
    saveTierBtn?: any;
    tierDescription?: any;
    data?: any;
    fieldInfoDescription?:any;
}

const AddTierDescription = (props: IProps) => {
    const {
        handleTierDescriptionInput,
        tierErrors,
        saveTierBtn,
        tierDescription,
        data,
        fieldInfoDescription
    } = props;
    return (
        <Row>
            <Col className="mb-3">
                <div className="clearfix">
                    <Label className="float-left" required>Min Value</Label>
                    <Tooltip content={`${fieldInfoDescription.minValue ? fieldInfoDescription.minValue : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                </div>
                <InputField type="number" value={tierDescription.minValue} name="minValue" placeholder="Min Value" onChange={handleTierDescriptionInput} errorMessage={saveTierBtn ? tierErrors.minValue : "" } disabled={data?.length}/>
            </Col>
            <Col className="mb-3">
                <div className="clearfix">
                    <Label className="float-left" required>Max Value</Label>
                    <Tooltip content={`${fieldInfoDescription.maxValue ? fieldInfoDescription.maxValue : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                </div>
                <InputField type="text" value={tierDescription.maxValue} name="maxValue" placeholder="Max Value" onChange={handleTierDescriptionInput} errorMessage={saveTierBtn ? tierErrors.maxValue : "" } />
            </Col>
            <Col className="mb-3">
                <div className="clearfix">
                    <Label className="float-left" required>Percentage</Label>
                    <Tooltip content={`${fieldInfoDescription.Percentage ? fieldInfoDescription.Percentage : ''}`} ><span className="fa fa-info-circle ms-1"></span></Tooltip>
                </div>
                <InputField type="number" value={tierDescription.percentage} name="percentage" placeholder="Percentage" onChange={handleTierDescriptionInput} errorMessage={saveTierBtn ? tierErrors.percentage : "" } />
            </Col>
        </Row>
    )
}

export default AddTierDescription;
import { put, call, takeLatest, all } from "redux-saga/effects";
import api from "../../../../client/utils/clientApiHelper";

import { SET_BULKUPLOADFILES } from "./types";

import * as bulkUploadActions from "./action";

function* setBulkUploadFiles(data: any): any {
  try {
    const response = yield call(
      api.callPost,
      `FileUpload/upload/attachments?uploadedBy=${data.payload.username}&supplierId=${data.payload.supplierId}`,
      data.payload.data
    );
    if (response) {
      yield put(bulkUploadActions.setBulkUploadFlag(true));
    }
  } catch (error) {
    yield put(bulkUploadActions.setBulkUploadError("Something went wrong please try after sometime"));
  }
}

function* watchBulkUploadFiles() {
  yield takeLatest(SET_BULKUPLOADFILES, setBulkUploadFiles);
}

export default function* sagas() {
  yield all([watchBulkUploadFiles()]);
}

import { createStore,applyMiddleware, compose} from 'redux'

import createSagaMiddleware from 'redux-saga';
import rootReducer from './features/reducers';
import saga from './features/sagas';

const sagaMiddleware = createSagaMiddleware();

export const store = compose(applyMiddleware(sagaMiddleware))(createStore)(rootReducer)

sagaMiddleware.run(saga)


export default store

import * as R from 'ramda';

const getSelfReportingGridData = (state:any) => R.pathOr('', ['SelfReporting', 'selfReportingGridData'], state);
const getSelfReportingExcelData = (state:any) => R.pathOr('', ['SelfReporting', 'selfReportingExcelData'], state);
const getSelfReportingGridHeaderData = (state:any) => R.pathOr('', ['SelfReporting', 'selfReportingGridHeaderData'], state);
const getVendorFileCount = (state:any) => R.pathOr('', ['SelfReporting', 'vendorFileCount'], state);
const getVendorHistoryData = (state:any) => R.pathOr('', ['SelfReporting', 'vendorHistoryData'], state);
const getFileStatusDropdownList = (state:any) => R.pathOr('', ['SelfReporting', 'fileStatusDropdownList'], state);
const getCalendarMonthDropdownList = (state:any) => R.pathOr('', ['SelfReporting', 'calendarMonthDropdownList'], state);
const getVendorTypeDropdownList = (state:any) => R.pathOr('', ['SelfReporting', 'vendorTypeDropdownList'], state);
const getFileCompletionData = (state:any) => R.pathOr('', ['SelfReporting', 'fileCompletionData'], state);
const getVendorListByStatus = (state:any) => R.pathOr('', ['SelfReporting', 'vendorListByStatus'], state);
const getValidateEmailReminder = (state:any) => R.pathOr('', ['SelfReporting', 'validateEmailReminder'], state);
const getEmailReminder = (state:any) => R.pathOr('', ['SelfReporting', 'emailReminder'], state);
const getVendorDropdownList = (state:any) => R.pathOr('', ['SelfReporting', 'vendorDropdownList'], state);
const getStatusDropdownList = (state:any) => R.pathOr('', ['SelfReporting', 'statusDropdownList'], state);
const getUpdateFileStatus = (state:any) => R.pathOr('', ['SelfReporting', 'updateFileStatus'], state);
const getSelfReportingLoader = (state:any) => R.pathOr('', ['SelfReporting', 'selfReportingLoader'], state);
const getSelfReportingNotes = (state:any) => R.pathOr('', ['SelfReporting', 'selfReportingNotes'], state);
const getAddSelfReportingNotes = (state:any) => R.pathOr('', ['SelfReporting', 'addSelfReportingNotes'], state);
const getEmailTemplate = (state:any) => R.pathOr('', ['SelfReporting', 'emailTemplate'], state);

const selectors = {
  getSelfReportingGridData,
  getSelfReportingExcelData,
  getSelfReportingGridHeaderData,
  getVendorFileCount,
  getVendorHistoryData,
  getFileStatusDropdownList,
  getCalendarMonthDropdownList,
  getVendorTypeDropdownList,
  getFileCompletionData,
  getVendorListByStatus,
  getValidateEmailReminder,
  getEmailReminder,
  getVendorDropdownList,
  getStatusDropdownList,
  getUpdateFileStatus,
  getSelfReportingLoader,
  getSelfReportingNotes,
  getAddSelfReportingNotes,
  getEmailTemplate
}

export default selectors

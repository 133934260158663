import React from 'react'
import { PivotTab } from '@softura/fluentui-basiccomponents'
import './Tabs.scss'

interface IProps {
  className?: string,
  linkFormat?: string,
  tabItems?: any
}

const Tabs = ({ linkFormat, tabItems, className }:IProps) => {

  return <div className='pivot'>
    <PivotTab
      className = {`custom-tab ${className}`}
      linkFormat = {linkFormat}
      tabItems = {tabItems}
    />
  </div>
}

export default Tabs

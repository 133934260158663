import { setBulkUploadFiles, getBulkUploadFlag, setBulkUploadError, setBulkUploadFlag } from "./action";

const dispatchSetBulkUploadFiles = (dispatch: Function) => (data: any, username:any, supplierId:any) => {
  dispatch(setBulkUploadFiles(data, username, supplierId));
};

const dispatchSetBulkUploadFlag = (dispatch: Function) => (flag: boolean) => {
  dispatch(setBulkUploadFlag(flag));
};

const dispatchGetBulkUploadFlag = (dispatch: Function) => () => {
  dispatch(getBulkUploadFlag());
};

const dispatchsetBulkUploadError = (dispatch: Function) => (error: string) => {
  dispatch(setBulkUploadError(error));
};


const operations = {
  dispatchSetBulkUploadFiles,
  dispatchSetBulkUploadFlag,
  dispatchGetBulkUploadFlag,
  dispatchsetBulkUploadError
};

export default operations;

import { Row, Col, Label, SearchSelectBox, MultiSelectoption, PrimaryButton, Alert, Tooltip } from '../../../CommonComponents'
import './UploadFileStatus.scss'


const UploadFileStatus = (props: any) => {

  const {
    statusList,
    monthList,
    vendorList,
    selectedVendorList,
    onChangeStatus,
    onChangeMonth,
    onChangeVendor,
    onClickUpdate,
    selectedMonth,
    selectedStatus,
    updateStatusData,
    updateFileStatus,
    StatusResponse,
    Updatebutton
  } = props;
  
  return (
    <div className='UploadFileStatusContainer'>
      <div>
          {StatusResponse && <>
          {updateFileStatus == 'success' ?
           <Alert showAlert={true} type='success' text='File Updated Successfully'/>
          : <Alert showAlert={true} type='error' text='Something went wrong please try after sometime'/>}<br></br></>}
        </div>
      <Row className="mb-3">
        <Col lg={3} md={6} sm={12} className='pe-0'>
          <Label>Month</Label>
          <SearchSelectBox
            placeholder="Select"
            name='Rebate Contract'
            className="search-dropdown"
            options={monthList}
            value={selectedMonth}
            isDisabled={updateStatusData?.month}
            onChange={(item: any) => { onChangeMonth(item) }}
          />
        </Col>
        
        <Col lg={4} md={6} sm={12} className=''>
          <Label>Vendor</Label>
          <Tooltip content={(selectedVendorList.length > 1) 
          ? `Total selected: ${selectedVendorList.length}
          \n${selectedVendorList.map((ele:any) => `\u00A0${ele.label}` )}` 
          : '' } > 
            <MultiSelectoption
            className="search-dropdown"
            options={vendorList}
            overrideStrings='Select All'
            onChange={onChangeVendor}
            disabled={updateStatusData?.supplierId}
            value={selectedVendorList}
          />
            </Tooltip> 
          
        </Col>
        <Col lg={3} md={6} sm={12} className='pe-0'>
          <Label>Status</Label>
          <SearchSelectBox
            placeholder="Select"
            name='Quarter Month'
            className="search-dropdown"
            options={statusList}
            value={selectedStatus}
            onChange={(item: any) => { onChangeStatus(item) }}
          />
        </Col>

        <Col lg={2}>
          <PrimaryButton disabled={!(selectedMonth?.label && selectedStatus?.label && selectedVendorList?.length > 0 && Updatebutton)} text='Update' className='updateBtn' onClick={onClickUpdate} />
        </Col>
      </Row>
    </div>
  )
}

export default UploadFileStatus
export const GET_SELF_REPORTING_GRID_DATA = 'GET_SELF_REPORTING_GRID_DATA';
export const SET_SELF_REPORTING_GRID_DATA = 'SET_SELF_REPORTING_GRID_DATA';

export const GET_SELF_REPORTING_GRID_HEADER_DATA = 'GET_SELF_REPORTING_GRID_HEADER_DATA';
export const SET_SELF_REPORTING_GRID_HEADER_DATA = 'SET_SELF_REPORTING_GRID_HEADER_DATA';

export const GET_VENDOR_FILE_COUNT = 'GET_VENDOR_FILE_COUNT';
export const SET_VENDOR_FILE_COUNT = 'SET_VENDOR_FILE_COUNT';

export const GET_VENDOR_HISTORY_DATA = 'GET_VENDOR_HISTORY_DATA';
export const SET_VENDOR_HISTORY_DATA = 'SET_VENDOR_HISTORY_DATA';

export const GET_FILE_STATUS_DROPDOWN_LIST = 'GET_FILE_STATUS_DROPDOWN_LIST';
export const SET_FILE_STATUS_DROPDOWN_LIST = 'SET_FILE_STATUS_DROPDOWN_LIST';

export const GET_CALENDAR_MONTH_DROPDOWN_LIST = 'GET_CALENDAR_MONTH_DROPDOWN_LIST';
export const SET_CALENDAR_MONTH_DROPDOWN_LIST = 'SET_CALENDAR_MONTH_DROPDOWN_LIST';

export const GET_UPDATE_FILE_STATUS = 'GET_UPDATE_FILE_STATUS';
export const SET_UPDATE_FILE_STATUS = 'SET_UPDATE_FILE_STATUS';

export const GET_VENDOR_TYPE_DROPDOWN_LIST = 'GET_VENDOR_TYPE_DROPDOWN_LIST';
export const SET_VENDOR_TYPE_DROPDOWN_LIST = 'SET_VENDOR_TYPE_DROPDOWN_LIST';

export const GET_SELF_REPORTING_EXCEL_DATA = 'GET_SELF_REPORTING_EXCEL_DATA';
export const SET_SELF_REPORTING_EXCEL_DATA = 'SET_SELF_REPORTING_EXCEL_DATA';

export const GET_FILE_COMPLETION_DATA = 'GET_FILE_COMPLETION_DATA';
export const SET_FILE_COMPLETION_DATA = 'SET_FILE_COMPLETION_DATA';

export const GET_VENDOR_LIST_BY_STATUS = 'GET_VENDOR_LIST_BY_STATUS';
export const SET_VENDOR_LIST_BY_STATUS = 'SET_VENDOR_LIST_BY_STATUS';

export const GET_VALIDATE_EMAIL_REMINDER = 'GET_VALIDATE_EMAIL_REMINDER';
export const SET_VALIDATE_EMAIL_REMINDER = 'SET_VALIDATE_EMAIL_REMINDER';

export const GET_EMAIL_REMINDER = 'GET_EMAIL_REMINDER';
export const SET_EMAIL_REMINDER = 'SET_EMAIL_REMINDER';

export const GET_VENDOR_DROPDOWN_LIST = 'GET_VENDOR_DROPDOWN_LIST';
export const SET_VENDOR_DROPDOWN_LIST = 'SET_VENDOR_DROPDOWN_LIST';


export const GET_STATUS_DROPDOWN_LIST = 'GET_STATUS_DROPDOWN_LIST';
export const SET_STATUS_DROPDOWN_LIST = 'SET_STATUS_DROPDOWN_LIST';

export const SET_SELF_REPORTING_LOADER = 'SET_SELF_REPORTING_LOADER';

export const GET_ADD_SELF_REPORTING_NOTES = 'GET_ADD_SELF_REPORTING_NOTES';
export const SET_ADD_SELF_REPORTING_NOTES = 'SET_ADD_SELF_REPORTING_NOTES';

export const GET_SELF_REPORTING_NOTES = 'GET_SELF_REPORTING_NOTES';
export const SET_SELF_REPORTING_NOTES = 'SET_SELF_REPORTING_NOTES';

export const GET_EMAIL_TEMPLATE = 'GET_EMAIL_TEMPLATE';
export const SET_EMAIL_TEMPLATE = 'SET_EMAIL_TEMPLATE';

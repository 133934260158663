import state from "state";

export const ACCESS_DENIED = "You're not allowed to access this feature";

export const isAuthorized = (area: string, permission: string) => {
  
    const tempState = state.getState();
    const checkPermission = tempState?.UserProfile?.userRole?.permissions?.[area]?.[permission];
  
    if (checkPermission) {
        return true;
    }
    return false
};
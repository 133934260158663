import React, { useState, useEffect, useMemo } from 'react';
// import { useTranslation } from 'react-i18next';
// import { RESOURCE } from '../../../const/resource';
import './custompagination.scss';
interface IProps {
    total: number
    pageSize: number
    dropdownOptions: any
    pageIndex: number
    onNext: Function
    onPageSizechange: Function
    onPrevious: Function
    onLast: Function
    onFirst: Function
    hidePageSize: boolean
}
const CustomPagination = (props: IProps) => {
    // const { t } = useTranslation();
    const [pagecount, setPageCount] = useState(props.pageSize);
    const [currentPage, setCurrentPage] = useState(props.pageIndex);

    useEffect(() => {
        setCurrentPage(props.pageIndex)
    }, [props.pageIndex]);

    useEffect(() => {
        setPageCount(props.pageSize)
    }, [props.pageSize]);


    const onOptionChangeHandler = (event: any) => {
        props.onPageSizechange(event.target.value);
    }

    const pageSize = pagecount;


    const totalPages = Math.ceil((props.total) as any / pageSize); //4

    const isDisableNext = useMemo(() => {
        if (totalPages === currentPage) {
            return true;
        }
    }, [currentPage, totalPages]);

    const [disablePrev, setDisablePrev] = useState(currentPage > 1 ? false : true);
    const [disableNext, setDisableNext] = useState(isDisableNext);

    useEffect(() => {
        setDisableNext(isDisableNext)
    }, [isDisableNext])

    useEffect(() => {
        if (currentPage <= 1) {
            setDisablePrev(true);
        }
        else {
            setDisablePrev(false);
        }
    }, [currentPage]);

    const onFirstPage = (e: any) => {
        e.preventDefault();
        setDisablePrev(true);
        setDisableNext(false);
        setCurrentPage(1);
        props.onFirst(1);
    }

    const onLastPage = (e: any) => {
        e.preventDefault();
        setDisablePrev(false);
        setDisableNext(true);
        setCurrentPage(totalPages);
        props.onLast(totalPages);
    }


    const onPrevPage = (e: any) => {
        e.preventDefault();
        let current = currentPage - 1;
        if (current >= 1) {
            setDisablePrev(false);
            setCurrentPage(current);
            props.onPrevious(current);
        }
        if (current == 1) {
            setDisablePrev(true);
        }
        setDisableNext(false);
    }

    const onNextPage = (e: any) => {
        e.preventDefault();
        let current = currentPage + 1;
        if (current <= totalPages) {
            setDisableNext(false);
            setCurrentPage(current);
            props.onNext(current);
        }
        if (current == totalPages) {
            setDisableNext(true);
        }
        setDisablePrev(false);

    }

    const endIndex = useMemo(() => {
        let endItem = currentPage * pageSize;
        if (endItem > (props.total)) {
            return props.total
        }
        return endItem;
    }, [currentPage, pageSize, props.total])

    const startIndex = useMemo(() => {
        return ((currentPage - 1) * pageSize) + 1;
    }, [currentPage, pageSize, props.total]);

    return (
        <div className='mylocationpagging paginationStyle'>
            {/* playBtn NextPage */}
            <div className={`paginationIcon paginationItem`}>
                <a href="/" className={`${disablePrev ? 'disableLink' : ''}`} title={'pager First Page'} onClick={onFirstPage}>
                    <span className='forwardIcon fa fa-step-backward playBtn'></span>
                </a>
                <a href="/" className={`${disablePrev ? 'disableLink' : ''}`} title={'pager Previous Page'} onClick={onPrevPage}>
                    <span className='fa fa-caret-left playBtn'></span>
                </a>
                <a  href="/" title='pager index' className='pagerIndexCount'>{currentPage}</a>
                <a href="/" className={`${disableNext ? 'disableLink' : ''}`} title={'pager Next Page'} onClick={onNextPage}>
                    <span className='fa fa-caret-right playBtn'></span>
                </a>
                <a href="/" className={`${disableNext ? 'disableLink' : ''}`} title={'pager Last Page'} onClick={onLastPage}>
                    <span className='forwardIcon fa fa-step-forward playBtn'></span>
                </a>
            </div>
            {!props.hidePageSize && < div className='paginationDropdown'>
                <select value={pageSize} onChange={onOptionChangeHandler}>
                    {props.dropdownOptions.map((option: any, index: any) => {
                        return <option key={option} >
                            {option}
                        </option>
                    })}
                </select>
                <span className='ml-1'>{'Items per page'}</span>
            </div>}         
            {
                props.total ?
                    <div className='paginationItem'>{startIndex} - {endIndex} {'of'} {(props?.total)} {'items'}</div> : <span>{'No of item to display'}</span>
            }
        </div>
    )
}


export default CustomPagination

import { Message } from '@softura/fluentui-basiccomponents'
import './Alert.scss'

interface IProps {
  className?:string,
  text?:any,
  type?:string,
  truncated?:any,
  isMultiline?:any,
  showAlert?:any,
  dismissAlert?:any,
  actions?: any
}
const AlertContainer = ({showAlert, dismissAlert, className, text, actions, type='info', truncated="false", isMultiline="false"}:IProps ) => {
  return (<>
    <Message
    className={`alert-msg ${className}`}
    show={showAlert} 
    onDismiss={dismissAlert}
    text={text}
    type={type}
    truncated={truncated}
    isMultiline={isMultiline}
    actions= {actions}
    />
  </>)
}

export default AlertContainer

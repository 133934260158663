import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Navigation from '../../Components/Navigation'
import logo from '../../assets/images/LogoLarge.png'
import hamburger from '../../assets/images/Menu.svg'
import ModalPopup from '../ModalPopup/ModalPopup';
import UserProfile from '../../Components/UserProfile'
import TreeviewDropdown from '../TreeviewDropdown'
import './Header.scss'
import userOperations from '../../../state/features/UserProfile/operations';
import avatar from '../../assets/images/avatar.png';
import Settings from "../../assets/images/Settings.png";

interface IProps {
  heading?: any,
  showMenu?: boolean,
  userMenuList?: any,
  toggleDropdown?: any,
  BranchList?: any,
  userDetails?: any,
  handleClickBranch?: any,
  accounts?: any,
  menu?: any,
  wrapperRef?: any,
  activeNavigation?: any,
  showBranchDrpdown?: any,
  handleClick?: any,
  ShowPopup?: any,
  handleLogout?: any,
  showUserProfilePopup?: any,
  hidePopup?: any,
  selectedVehiclesCount?: any,
  handleClickLocation?: any,
  showLocationDropdown?: any,
  cartClick?: any
}
const Header = ({
  showMenu = false,
  toggleDropdown,
  accounts = [],
  menu,
  ShowPopup,
  handleLogout,
  showUserProfilePopup,
  hidePopup,
}: IProps) => {

  const treeData: any = [
    {
      title: "Division",
      value: "Division",
      children: {
        title: "SuperRegion",
        value: "SuperRegion",
        children: {
          title: "Region",
          value: "Region",
          children: {
            title: "Market",
            value: "Market",
            children: {
              title: "BVD Orane Country",
              value: "BVD Orange Country",
            }
          }
        }
      }
    }
  ];
  const dispatch = useDispatch();
  const dispatchUserDetails = userOperations.dispatchGetUserDetail(dispatch)
  const dispatchSetUsername = userOperations.dispatchSetUsername(dispatch)
  useEffect(() => {
    dispatchUserDetails({
      user: accounts[0].username
    });
    dispatchSetUsername(accounts[0]?.username);
  }, []);
  
  const { getUserProfileBlobUrl, userRole } = useSelector((state: any) =>
  ({
    getUserProfileBlobUrl: state.UserProfile.getUserDetails.profileBlobUrl ?? '',
    userRole: state.UserProfile?.userRole?.role?.name
  }))
  
  return (<>
    <div className='newClass'>
      <div className='header-wrapper'>
        <div className='headerlogo-section'>
          <img src={logo} alt="logo" /><p className='headText'>Rebate Insight</p>
        </div>
        <div className='userprofile-section'>
          <div className='location-dropdown'>
            <TreeviewDropdown treeData={treeData} maxHeight='400' />
          </div>
          <div className='user-name user-menu'>
            <div className='setting-container'>
              <img src={Settings} alt="settings" />
            </div>
            <div className='user-detail'>
              <div>{accounts.length > 0 ? accounts[0].name : ''}</div>
              <span>{userRole ?? ''}</span>
            </div>
            <div className='profile-image-block' onClick={toggleDropdown}>
              <img src={getUserProfileBlobUrl} onError={(e: any) => {e.target.onerror = null; e.target.src = avatar}} alt="user" title='My Profile' />
              <span aria-hidden='true' className='fa fa-caret-down down-arrow' />
            </div>
            {showMenu && (
              <div className='custom-dropdown-menu'>
                <ul className='usernavname-dropdown'>
                  <div className='userprofile-detail'>
                    <div>{accounts.length > 0 ? accounts[0].name : ''}</div>
                    <span>{userRole}</span>
                  </div>
                  <li onClick={(e: any) => handleLogout(e)}><a href='/'><span className="user-dropdown-icon logout"></span><span className="ps-4">Logout</span></a></li>
                </ul>
              </div>
            )}
          </div>
          <div className='hamburger-section' onClick={ShowPopup}>
            <a href='/' onClick={(e: any) => e.preventDefault()}><img src={hamburger} alt='menu' /></a>
          </div>
        </div>
      </div>
      {
        menu && <Navigation />
      }
    </div>
    <div className='myprofile-userprofile'>
      {
        showUserProfilePopup
          ? <ModalPopup
            ShowModalPopupFooter={false}
            ModalPopupTitle="USER MANAGEMENT"
            ModalPopupType="large"
            closeModalPopup={hidePopup}
          >
            <UserProfile closeModalPopup={hidePopup} />
          </ModalPopup>
          : null
      }
    </div>
  </>)
}

export default Header

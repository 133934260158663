import {
    getCommonDashboard,
    addFavoriteApp,
    removeFavoriteApp,
    getNewsAndAnnouncementDetails
} from './actions'

const dispatchCommonDashboard = (dispatch:Function) => (user:any) => {
    dispatch(getCommonDashboard(user));
  };

  const dispatchAddFavoriteApp = (dispatch:Function) => (favApp:any) => {
    dispatch(addFavoriteApp(favApp));
  };

  const dispatchRemoveFavoriteApp = (dispatch:Function) => (favApp:any) => {
    dispatch(removeFavoriteApp(favApp));
  };
  const dispatchGetNewsAndAnnouncementDetail = (dispatch:Function) => (reqBody:any) => {
    dispatch(getNewsAndAnnouncementDetails(reqBody));
  };
const operations = {
    dispatchCommonDashboard,
    dispatchAddFavoriteApp,
    dispatchRemoveFavoriteApp,
    dispatchGetNewsAndAnnouncementDetail
}

export default operations
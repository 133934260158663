import React from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Card,
  FileUpload,
  Row,
  Col,
  SearchSelectBox,
  Label,
  PrimaryButton,
  Badge,
  Alert,
} from "../../../../CommonComponents";
import Download from "../../../../assets/images/Download.png";
import DemoTemplate from "../../../../assets/demoFiles/VendorSelfReportTemplate.xlsx";
import "./NewBulkUpload.scss";

interface IBreadcrumb {
  text: string;
  key: string;
  href?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

interface Iprops {
  breadcrumlist: IBreadcrumb[];
  handleHome: Function;
  onFileChange: Function;
  files: any[];
  noFiles: boolean;
  fileError: string;
  vendorOptions: any;
  vendorValue: any;
  handleVendorChange: Function;
  vendorError: string;
  handleUploadFiles: Function;
  bulkUploadSuccess: boolean;
  bulkUploadFailed: string;
  handleMessage: Function
}
const NewBulkUpload = (props: Iprops) => {
  const {
    breadcrumlist,
    onFileChange,
    files,
    noFiles,
    fileError,
    handleUploadFiles,
    vendorOptions,
    vendorValue,
    handleVendorChange,
    vendorError,
    bulkUploadSuccess,
    handleMessage,
    bulkUploadFailed
  } = props;
  return (
    <div>
      <Breadcrumb items={breadcrumlist} maxDisplayedItems="5" />
      <Card>
        <div className="bulkUpload">
          <div className="clearfix">
            <div className="float-left">
              <p className="heading">BULK UPLOAD</p>
            </div>

            <div className="float-right rightTools">
              <Link
                to={DemoTemplate}
                target="_blank"
                download="sample-template.xlsx"
              >
                <PrimaryButton>
                  <img src={Download} alt="download" className="download" />&nbsp;
                  Download Template
                </PrimaryButton>
              </Link>
            </div>
          </div>

          <Row className="mt-2">
            <Col>
              <Alert showAlert={bulkUploadSuccess} dismissAlert={handleMessage} type="success" text={"Files uploaded successfully"} className="mb-2" />
              <Alert showAlert={bulkUploadFailed} dismissAlert={handleMessage} type="error" text={bulkUploadFailed} className="mb-2" />
            </Col>
          </Row>
          <Row>
            <Col lg={3} className="mb-3">
              <Label>Vendor</Label>
              <SearchSelectBox
                placeholder="Select"
                options={vendorOptions}
                filterOptionValue={3}
                onChange={(item: any) => handleVendorChange(item)}
                value={vendorValue ? vendorOptions?.find((i: any) => i.key ===vendorValue) : ""}
                name="vendorId"
                className="search-dropdown"
                target="content-wrapper"
              />
              {vendorError ? (
                <p className="required mt-1 float-left">{vendorError}</p>
              ) : null}
            </Col>

            <Col lg={4} className="file-upload-col mb-3">
              <Label>Upload</Label>
              <FileUpload
                onChange={onFileChange}
                noFiles={noFiles}
                file={files.map((f) => (
                  <Badge className="badge-success" key={f.name}>
                    {f.name}
                  </Badge>
                ))}
                multiple={true}
                onClick={handleUploadFiles}
              />
              {fileError.length ? (
                <p className="required mt-1 float-left">{fileError}</p>
              ) : null}
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default NewBulkUpload;

import React, { useEffect, useState } from 'react'
import AssignPopup from './AssignPopup'
import api from 'client/utils/clientApiHelper'

const AssignPopupContainer = (props: any) => {

  const {
    selectedRowData,
    getFormattedValue,
    assignFormData,
    setAssignFormData,
    comment,
    onDismissAlert,    
    showErrorMessage,
    getContractCollectionFrequency,
    addFormSection,
    getContractDetails,
    getContractProgramYears,
    handleClose,
    showValidationPopup,
    setShowValidationPopup,
    initialRow,
    checkNumber,
    setCheckNumber,
    saveCheckNumber,
    validateCheckNumber,
    updateCheckNumber,
    getCompletePeriod
  } = props

  const [supplierOptions, setSupplierOptions] = useState<any>([]);
  const [isCheckNumberUpdated, setIsCheckNumberUpdated] = useState(false)

  useEffect(() => {
    getSupplierData()
  }, []);

  useEffect(()=>{
    setIsCheckNumberUpdated(updateCheckNumber)
  },[updateCheckNumber])

  useEffect(()=>{
    if(selectedRowData?.checkNumber){
      setCheckNumber(selectedRowData?.checkNumber)
    }else{
      setCheckNumber("")
    }
  },[selectedRowData?.checkNumber])

  const getSupplierData = async () => {
    const supplierResponse = await api.callGet(`Supplier/DropDown`)
    let supplierOptions: any = []
    if (supplierResponse?.length > 0) {
      supplierOptions = supplierResponse?.map((item: any) => (
        {
          value: item.supplierName,
          key: item.id,
          label: item.supplierName,
        }
      ))
    }
    setSupplierOptions(supplierOptions)
  }

  const removeFormSection = (rowId: string, splitUpid?: number) => {
    if (splitUpid !== 0) {
      const tempData = [...assignFormData].map(data => {
        if (data.rowId === rowId) {
          return {
            ...data,
            rowValues: { ...data.rowValues, isActive: false },
          }
        }
        return data
      })
      setAssignFormData(tempData)
    }
    else {
      setAssignFormData((prevData: any) => prevData.filter((data: any) => data.rowId !== rowId));
    }
  };  

  const updateInput= (amount: number, rowId :any)=>{
    let amt : any
    if(amount!=undefined && amount!=null ){
    amt = "$"+ parseFloat(amount.toString().replace("$","").replaceAll(",","")).toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, '$&,'); 

    const tempdata = [...assignFormData].map(data => {
      if(data.rowId === rowId){
        return{
          ...data,
          rowValues: { ...data.rowValues, amount : amt }
        }                
         
      }
      return data
    })    
    setAssignFormData(tempdata)
  }
  };

  const updateInputOnFocus= (amount: number, rowId :any)=>{
    let amt : any
    if(amount!=undefined && amount!=null ){
      amt = parseFloat(amount.toString().replace("$","").replaceAll(",","")).toFixed(2)
  
      const tempdata = [...assignFormData].map(data => {
        if(data.rowId === rowId){
          return{
            ...data,
            rowValues: { ...data.rowValues, amount : amt }
          }                
           
        }
        return data
      })    
      setAssignFormData(tempdata)
    } 
  };

  const handleInputChange = (rowId: number, event: any, input: any) => {
    let fieldName = event?.name || event?.target?.getAttribute('name') || event?.target?.title;
    let value = input;
    

    if (fieldName === "amount") 
    {
      value = event?.target?.value
    }

    if (fieldName === "vendorSupplierId" && value) {
      let contractId = ""
      let contractListTotal = 0
      let year = ""   
      let yearlist=0   
      let collectnfrequencyId=0
      let collectionfrequency=""
      let collectionFrequencyList :any
      let CompletePeriod: boolean
      getContractDetails(value).then((contractResult: any) => {
        const tempData = [...assignFormData].map(data => {          
          if (data.rowId === rowId) {
            if(contractResult?.length === 1) {
 
              contractId = contractResult[0].key 
              contractListTotal = contractResult?.length
 
              getContractProgramYears(contractId).then((programResult: any) => {
                const tempData = [...assignFormData].map(data => {
                  if (data.rowId === rowId) {
                    programResult?.length === 1 ? year = programResult[0].value : year = ""

                    if(programResult?.length===1){
                      year=programResult[0].value
                      yearlist=programResult?.length
                      getContractCollectionFrequency(contractId,year ).then((collectionfreqresult: any) => {
                        const tempData = [...assignFormData].map(data => {
                          if (data.rowId === rowId) {
                            collectionfreqresult?.length === 1 ? collectionfrequency = collectionfreqresult[0].value : collectionfrequency = ""
                              collectnfrequencyId=collectionfreqresult.collectionFrequencyId
                              collectionFrequencyList=collectionfreqresult?.collectionFrequencyOptions
                                if(collectionFrequencyList?.length===1){
                                  collectionfrequency=collectionFrequencyList[0].value
                                  getCompletePeriod(value,contractId,year,collectionfrequency).then((CompletedPeriodResult: any) => {
                                    const tempData = [...assignFormData].map(data => {
                                      if (data.rowId === rowId) {
                                        CompletePeriod = CompletedPeriodResult
                                        return {
                                          ...data,
                                          rowValues: { ...data.rowValues, [fieldName]: value, contractDetailId: contractId, programYear: year, collectionFrequencyId: collectnfrequencyId , collectionFrequencyValue: collectionfrequency,completePeriod: CompletePeriod },
                                          contractOptions: contractResult,
                                          programYearOptions:programResult,
                                          collectionFrequencyOption: collectionFrequencyList
                                        }
                                      }
                                      return data
                                    })
                                    setAssignFormData(tempData)
                                  })
                                }
                            return {
                              ...data,
                              rowValues: { ...data.rowValues, [fieldName]: value, contractDetailId: contractId, programYear: year, collectionFrequencyId: collectnfrequencyId , collectionFrequencyValue: collectionfrequency, },
                              contractOptions: contractResult,
                              programYearOptions:programResult,
                              collectionFrequencyOption: collectionFrequencyList
                            }
                          }
                          return data
                        })
                        setAssignFormData(tempData)
                      });

                    }

                    return {
                      ...data,
                      rowValues: { ...data.rowValues, [fieldName]: value, contractDetailId: contractId, programYear: year, collectionFrequencyId: collectnfrequencyId, collectionFrequencyValue: collectionfrequency, },
                      contractOptions: contractResult,
                      programYearOptions: programResult,
                      collectionFrequencyOption: collectionFrequencyList
                    }
                  }
                  return data
                })
                setAssignFormData(tempData)
              });
            }
            return {
              ...data,
              rowValues: { ...data.rowValues, [fieldName]: value, contractDetailId: contractId, programYear: "", collectionFrequencyId: collectnfrequencyId, collectionFrequencyValue: collectionfrequency, },
              contractOptions: contractResult,
              programYearOptions: [],
              collectionFrequencyOption: collectionFrequencyList
            }
          }
          return data
        })        
        setAssignFormData(tempData)
      }); 
    }

    if (fieldName === "contractDetailId" && value) {
      let programyear = ""
      let programyearList = 0
      let collectnfrequencyId=0
      let collectionfrequency=""
      let collectionFrequencyList :any
      let CompletePeriod: boolean

      getContractProgramYears(value).then((programResult: any) => {
        const tempData = [...assignFormData].map(data => {
          if (data.rowId === rowId) {
            programResult?.length === 1 ? programyear = programResult[0].value : programyear = ""

            if(programResult?.length===1){
              programyear=programResult[0].value
              programyearList=programResult?.length
              getContractCollectionFrequency(value,programyear ).then((collectionfreqresult: any) => {
                const tempData = [...assignFormData].map(data => {
                  if (data.rowId === rowId) {
                    collectionfreqresult?.length === 1 ? collectionfrequency = collectionfreqresult[0].value : collectionfrequency = ""
                        collectionFrequencyList=collectionfreqresult?.collectionFrequencyOptions
                        collectnfrequencyId=collectionfreqresult.collectionFrequencyId
                        if(collectionFrequencyList?.length===1){                 
                          collectionfrequency=collectionFrequencyList[0].value
                          getCompletePeriod(data?.rowValues?.vendorSupplierId,value,programyear,collectionfrequency).then((CompletedPeriodResult: any) => {
                            const tempData = [...assignFormData].map(data => {
                              if (data.rowId === rowId) {
                                CompletePeriod = CompletedPeriodResult
                                return {
                                  ...data,
                                  rowValues: { ...data.rowValues, [fieldName]: value, contractDetailId: value, programYear: programyear, collectionFrequencyId: collectnfrequencyId , collectionFrequencyValue: collectionfrequency,completePeriod: CompletePeriod },
                                  programYearOptions:programResult,
                                  collectionFrequencyOption: collectionFrequencyList
                                }
                              }
                              return data
                            })                                                    
                            setAssignFormData(tempData)
                        });
                        } 
                  return {
                    ...data,
                    rowValues: { ...data.rowValues, [fieldName]: value, contractDetailId: value, programYear: programyear, collectionFrequencyId: collectnfrequencyId , collectionFrequencyValue: collectionfrequency, },
                    programYearOptions:programResult,
                    collectionFrequencyOption: collectionFrequencyList
                  }                  
                 }
                 return data
                })
                setAssignFormData(tempData)
              });
            }
            return {
              ...data,
              rowValues: { ...data.rowValues, [fieldName]: value, contractDetailId: value, programYear: programyear, collectionFrequencyId: collectnfrequencyId, collectionFrequencyValue: collectionfrequency, },
              programYearOptions: programResult,
              collectionFrequencyOption: collectionFrequencyList
            }
          }
          return data
        })
        setAssignFormData(tempData)
      });

    }
    if (fieldName === "programYear" && value) {
      const data = assignFormData?.find((x: any) => x.rowId === rowId)
      let contractId = data?.rowValues?.contractDetailId
      let SupplierId = data?.rowValues?.vendorSupplierId
      let collectnfrequencyId=0
      let collectionfrequency=""
      let collectionFrequencyList :any
      let CompletePeriod: boolean

      getContractCollectionFrequency(contractId,value ).then((collectionfreqresult: any) => {
        const tempData = [...assignFormData].map(data => {
          if (data.rowId === rowId) {
            collectionfreqresult?.length === 1 ? collectionfrequency = collectionfreqresult[0].value : collectionfrequency = ""
            collectnfrequencyId=collectionfreqresult.collectionFrequencyId
                collectionFrequencyList=collectionfreqresult?.collectionFrequencyOptions
                if(collectionFrequencyList?.length===1){             
                  collectionfrequency=collectionFrequencyList[0].value
                  getCompletePeriod(SupplierId,contractId,value,collectionfrequency).then((CompletedPeriodResult: any) => {
                    const tempData = [...assignFormData].map(data => {
                      if (data.rowId === rowId) {
                        CompletePeriod = CompletedPeriodResult
                        return {
                          ...data,
                          rowValues: { ...data.rowValues, [fieldName]: value, collectionFrequencyId: collectnfrequencyId , collectionFrequencyValue: collectionfrequency,completePeriod: CompletePeriod },
                          collectionFrequencyOption: collectionFrequencyList
                        }
                      }
                      return data
                    })
                    setAssignFormData(tempData)
                })
                }
            return {
              ...data,
              rowValues: { ...data.rowValues, [fieldName]: value, collectionFrequencyId: collectnfrequencyId , collectionFrequencyValue: collectionfrequency, },
              collectionFrequencyOption: collectionFrequencyList
            }
          }
          return data
        })
        setAssignFormData(tempData)
      });

    }

    if (fieldName === "collectionFrequencyValue" && value) {
      const data = assignFormData?.find((x: any) => x.rowId === rowId)
      let contractId = data?.rowValues?.contractDetailId
      let SupplierId = data?.rowValues?.vendorSupplierId
      let ProgramYear = data?.rowValues?.programYear
      let CompletePeriod: boolean
      
                getCompletePeriod(SupplierId,contractId,ProgramYear,value).then((CompletedPeriodResult: any) => {
                  const tempData = [...assignFormData].map(data => {
                    if (data.rowId === rowId) {
                      CompletePeriod = CompletedPeriodResult
                      return {
                        ...data,
                        rowValues: { ...data.rowValues, [fieldName]: value,completePeriod: CompletePeriod}
                      }
                  }
                    return data
                })
                  setAssignFormData(tempData)
              })        
    }

    if (fieldName === "completePeriod") {

      const data = assignFormData?.find((x: any) => x.rowId === rowId)
        const tempData = [...assignFormData].map(data => {
          if (data.rowId === rowId) {
            return {
              ...data,
              rowValues: { ...data.rowValues, [fieldName]:input },                          
            }
          }
          return data
        })
        setAssignFormData(tempData)
    }

    const tempData = [...assignFormData].map(data => {
      if (data.rowId !== rowId) {
        return data;
      }

      const updatedRowValues = {
        ...data.rowValues,
        [fieldName]: value,
        ...(fieldName === "vendorSupplierId" && {
          contractDetailId: "",
          programYear: "",
          collectionFrequencyId: "",
          collectionFrequencyValue: "",
        }),
        ...(fieldName === "contractDetailId" && {
          programYear: "",
          collectionFrequencyId: "",
          collectionFrequencyValue: "",
        }),
        ...(fieldName === "programYear" && {
          collectionFrequencyId: "",
          collectionFrequencyValue: "",
        }),
      };

      return {
        ...data,
        rowValues: updatedRowValues,
      };
    })
    setAssignFormData(tempData)    
  };

  const extractNumbers = (input: any) => {
    return String(input)?.replace(/[^\d.]/g, '')
  };

  const getExternalError = (fieldName: string, rowId: any, inputData?: any) => {

    let validate = false
    assignFormData.forEach((data: any) => {
      if (data.rowId === rowId) {
        if (data?.rowValues[fieldName] === undefined || data?.rowValues[fieldName] === "") {
          validate = true
        }
      }
    });
    return validate ? <div className="error-message">{"This is required"}</div> : undefined
  }

  const hideValidatePopup = () => {
    setShowValidationPopup(false)
  }  

  const onClickPrimaryButton: any = () => {
    const tempData = [...assignFormData]
      tempData.unshift(initialRow);
      setAssignFormData(tempData);
  };
  

  return (
    <div>
      <AssignPopup
        selectedRowData={selectedRowData}
        getFormattedValue={getFormattedValue}
        formData={assignFormData}
        addFormSection={addFormSection}
        removeFormSection={removeFormSection}
        handleInputChange={handleInputChange}
        updateInput={updateInput}
        comment={comment}
        onDismissAlert={onDismissAlert}
        extractNumbers={extractNumbers}
        getExternalError={getExternalError}
        showErrorMessage={showErrorMessage}
        supplierOptions={supplierOptions}
        handleClose={handleClose}
        hideValidatePopup={hideValidatePopup}
        showValidationPopup={showValidationPopup}
        onClickPrimaryButton = {onClickPrimaryButton}
        updateInputOnFocus={updateInputOnFocus}
        checkNumber = {checkNumber}
        setCheckNumber = {setCheckNumber}
        saveCheckNumber = {saveCheckNumber}
        validateCheckNumber={validateCheckNumber}
        isCheckNumberUpdated = {isCheckNumberUpdated}
        setIsCheckNumberUpdated = {setIsCheckNumberUpdated}
      />
    </div>
  )
}

export default AssignPopupContainer
import './UserProfile.scss'
import { Row, Col, Card, Tabs, Label, InputField, DropdownBox, DialogBox, PrimaryButton, CustomTreeview, OutlineButton} from '../../CommonComponents'
import upload from '../../assets/images/Upload.svg'
import {setResultMessage} from '../../../state/features/UserProfile/actions'
import { useDispatch, useSelector } from 'react-redux';

interface IProps {
  userName?: any,
  onChangeProfile?: any,
  file?: any,
  multiple?: boolean
  countryDropdown?: any,
  formError?:any,
  onSubmitForm?:any,
  onUpdateField?:any,
  form?: any,
  BranchDetails?: any,
  titleDetails?:any,
  departmentDetails?:any,
  selectedCountry?:any,
  onChangeCountry?:any,
  OnCheckboxClick?:any,
  checked?:any,
  closeModalPopup?:any,
  hideConfirmationDialog?:any,
  isConfirmationDialogVisible?:any,
  confirmationDialogContentProps?:any,
  resultMessage?: any
  userprofileinfo?: any,
  User_id?: number,
  showTab?:any,
  uploadedImage?: string,
}
const UserProfile = ({
    userName,
    onChangeProfile,
    file,
    countryDropdown,
    onSubmitForm,
    formError,
    checked,
    form,
    onUpdateField,
    BranchDetails,
    selectedCountry,
    onChangeCountry,
    OnCheckboxClick,
    closeModalPopup,
    hideConfirmationDialog,
    isConfirmationDialogVisible,
    confirmationDialogContentProps,
    userprofileinfo,
    showTab,
    uploadedImage
  }:IProps) => {

let branchObject = Object.values(BranchDetails)?.map( (data:any)=> {return {value: data.value,label:data.text}})
  const treeviewData = [
    {
      branch: 'branch 1',
      branchSet: [
        {
          label: 'Division 1',
          value: 'Division 1',
          children: branchObject,
        }
      ]
    }
  ]

const dispatch = useDispatch();
const onClick = () => {
  closeModalPopup();
  dispatch(setResultMessage(""))
}
const {getResultMessage} = useSelector((state:any) => ({
    getResultMessage:state.UserProfile.resultMessage
}))

const branchTabContent =() => {
  return(<>
    <p  className='profile'>BRANCH</p>
    <span className='errorMsg'>{formError.branchError}</span>
    {
    treeviewData.map((item:any) => { 
      return (
        <CustomTreeview nodes={item.branchSet} checked={checked} onCheck={OnCheckboxClick} showNodeIcon={false}/>
    )
  })
  }          
  </>)
}

const getImage = () => {
  if (uploadedImage) {
    return uploadedImage;
  }
  if (form.profileBlobUrl !== "") {
    return form.profileBlobUrl;
  }
  return upload
}
const profileTabContent =()=> {
  return(<>
    <p className='profile'>PROFILE</p>
     <Row>
       <Col lg={2} xs={4} className='fileupload-userprofile mb-3'>
       <div className='file-upload-wrapper'>
          <button className='choose-file'>
          <img height={40} width={40} src={getImage()} title='file-upload' alt={userprofileinfo.imagename} className={!userprofileinfo.imguploaded ? 'img-nonactive' : 'img-active'}/>
          </button>
          {
              userprofileinfo.imguploaded ? <span className='nofile-chosen'>No file chosen</span> : <span className='file-name'>{file}</span>
          }
          <input type="file" name="myfile" className='file-upload' onChange={onChangeProfile}  accept="image/png, image/jpeg"/>
        </div>
       </Col>
       <Col lg={10} xs={8} className='mt-lg-3 mt-0 ps-0'>  
         <div className='profile-requirement'>Supported format PNG, JPG, / Dimensions Max 400x400 / Size Max 150kb.</div>
         <span className='validation-errorMsg'>{userprofileinfo.profileImageError}</span>
       </Col>
     </Row>
     <Row>
       <Col lg={6} className='mb-3'>
       <Label>First Name</Label>
       <InputField name='firstName' value={userName?.firstName} readOnly/>
       </Col>
       <Col lg={6} className='mb-3'>
       <Label>Last Name</Label>
       <InputField name='lastName' value={userName?.lastName} readOnly/>
       </Col>
       <Col lg={6} className='mb-3'>
       <Label>Preferred Name</Label>
       <InputField name='preferredName' value={form?.preferredName} onChange={onUpdateField}/>
       </Col>
       <Col lg={6} className='mb-3'>
       <Label required>Email Address</Label>
       <InputField name="emailId" value={userName?.userEmail} readOnly />
       </Col>
     </Row>
     <p className='mb-2 driver-Info'>Driver Information</p>
     <Row>
       <Col lg={6} className='mb-3'>
       <Label>Personal Email Address</Label>
       <InputField placeholder='Personal Email Id' errorMessage={formError.personalEmailIdError} name='personalEmailId' value={form.personalEmailId} onChange={onUpdateField}/>
       </Col>
       <Col lg={6} className='mb-3'>
       <Label>Contact #</Label>
       {/* <MaskedTextField placeholder='Contact' className='form-input' errorMessage={formError.contactNoError} mask="(999) 999-9999" name='contactNo' value={form.contactNo} onChange={onUpdateField}/> */}
       </Col>
       <Col lg={6} className='mb-3'>
       <Label>Address</Label>
       <InputField placeholder='Address Line 1' name='addressLine1' errorMessage={formError.addressLine1Error} value={form.addressLine1} onChange={onUpdateField}/>
       </Col>
       <Col lg={6} className='mb-3 mt-lg-3'>
       <InputField className='mt-lg-3' placeholder='Address Line 2' errorMessage={formError.addressLine2Error} name='addressLine2' value={form.addressLine2} onChange={onUpdateField}/>
       </Col>           
       <Col lg={6} className='mb-3'>
       <InputField placeholder='City' name='city' errorMessage={formError.cityError} value={form.city} onChange={onUpdateField}/>
       </Col>
       <Col lg={6} className='mb-3'>
       <InputField placeholder='State/Region' name='state' errorMessage={formError.stateError} value={form.state} onChange={onUpdateField} />
       </Col>
       <Col lg={6} className='mb-3'>
       <InputField placeholder='ZIP/Postal Code' errorMessage={formError.zipCodeError} name='zipCode' value={form.zipCode} onChange={onUpdateField}/>
       </Col>
       <Col lg={6} className='mb-3'>
       <DropdownBox placeholder='Select' options={countryDropdown} name='countryId'  value={selectedCountry ? selectedCountry : {key:null, text:'Select'}}  onChange={onChangeCountry}/>
       </Col>
       <Col lg={6} className='mb-3 hidden-field'>
       <InputField type='hidden' name='User_id' value={form.id} className= 'hidden' />
       </Col>
       <p className='privacy-info mb-3'><b>Privacy Note : </b> This information is confidential and will not be shared or used for reporting purpose. This information will used for vehicle information or insurance.</p>
       <div className='pull-right'>
        <PrimaryButton text='Save' className='pull-right' onClick={onSubmitForm} />
       
       <OutlineButton text='Cancel' className='pull-right cancelUser me-2' onClick={onClick}/>
       </div>
     </Row>
     </>)
}
const iconItems = [
  {
    headerText:'Branch',
    content: branchTabContent()
  },
  {
    headerText:'Profile',
    content:profileTabContent()
  }
]

return (
    <div className='user-profile-container'>
      {
        isConfirmationDialogVisible && getResultMessage!='' &&
        <DialogBox dialogContentProps={confirmationDialogContentProps} hideDialog={!isConfirmationDialogVisible} onDismiss={hideConfirmationDialog} />
      }
      <Row>
      {
        showTab ?      
        <Card><Tabs tabItems = {iconItems} linkFormat='links' className='userprofile-tab'/></Card>
        :<>        
          <Col xs={12} sm={12} md={6} lg={5} xl={5} className='mb-0'>
            <Card> {branchTabContent()} </Card>
          </Col>
          <Col xs={12} sm={12} md={6} lg={7} xl={7}>
            <Card>  {profileTabContent()}</Card>
          </Col>
        </>
      }
      </Row>
    </div>
  )
}

export default UserProfile

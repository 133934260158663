import { GET_CALCULATION_FREQUENCY,SET_CALCULATION_FREQUENCY, GET_CALCULATION_METHOD,
   SET_CALCULATION_METHOD, GET_CALCULATION_TYPE, SET_CALCULATION_TYPE,GET_REBATE_PROGRAM,
   SET_REBATE_PROGRAM,GET_REBATE_PROGRAM_BY_ID,SET_REBATE_PROGRAM_BY_ID,
    GET_CREATEUPDATE_REBATE_PROGRAM, GET_REBATE_DELETE,
    SET_REBATE_DELETE, POST_REBATE_ADD, SET_REBATE_ADD, SET_REBATE_ADDEDIT_RESPONSE,POST_REBATE_EDIT,SET_REBATE_EDIT,
    GET_REBATEPERIOD, SET_REBATEPERIOD, GET_REBATEPAYMENTMETHOD, SET_REBATEPAYMENTMETHOD, GET_ALL_CONTRACT, SET_ALL_CONTRACT,
    GET_ADD_CONTRACT_NAME,
    GET_UPDATE_CONTRACT_NAME,
    GET_DELETE_CONTRACT_NAME,
    GET_CONTRACT_DETAILS_BY_ID,
    SET_CONTRACT_DETAILS_BY_ID,
    GET_ALL_CONTRACT_LIST,
    SET_ALL_CONTRACT_LIST,
    GET_CONTRCT_BY_PROGRAM_ID,
    GET_PROGRAM_BY_VENDOR_ID,
    GET_ALL_REBATE_TYPES,
    SET_ALL_REBATE_TYPES,
    SET_PROGRAM_ERROR,
    SET_CONTRACT_BY_ID_LOADING,
    GET_CONTRACT_BY_ID_LOADING,
    SET_REBATE_PROGRAM_FIELD_INFO,
    GET_REBATE_PROGRAM_FIELD_INFO,
    GET_CONTRACT_VALIDATION_FLAG,
    SET_CONTRACT_VALIDATION_FLAG
  } from './types'


export const getCalculationFrequency = () => {
  return {
    type: GET_CALCULATION_FREQUENCY
  }
}

export const setCalculationFrequency = (data:any) => {
  return {
    type: SET_CALCULATION_FREQUENCY,
    payload: data
  }
}

export const getCalculationMethod= () => {
  return {
    type: GET_CALCULATION_METHOD
  }
}

export const setCalculationMethod = (data:any) => {
  return {
    type: SET_CALCULATION_METHOD,
    payload: data
  }
}

export const getCalculationType= () => {
  return {
    type: GET_CALCULATION_TYPE
  }
}

export const setCalculationType = (data:any) => {
  return {
    type: SET_CALCULATION_TYPE,
    payload: data
  }
}

export const getRebateProgram= () => {
  return {
    type: GET_REBATE_PROGRAM
  }
}

export const setRebateProgram = (data:any) => {
  return {
    type: SET_REBATE_PROGRAM,
    payload: data
  }
}

export const getRebateProgramById= (id:any) => {
  return {
    type: GET_REBATE_PROGRAM_BY_ID,
    payload: id
  }
}

export const setRebateProgramById = (data:any) => {
  return {
    type: SET_REBATE_PROGRAM_BY_ID,
    payload: data
  }
}

export const getRebateDeleteProgram= (name:any, vendorID: any) => {
  return {
    type: GET_REBATE_DELETE,
    payload: name,
    vendorID
  }
}

export const setRebateDeleteProgram = (data:any) => {
  return {
    type: SET_REBATE_DELETE,
    payload: data
  }
}

export const postRebateAdd= (data:any, vendorID: any) => {
  return {
    type: POST_REBATE_ADD,
    payload: data,
    vendorID
  }
}

export const setRebateAdd = (data:any) => {
  return {
    type: SET_REBATE_ADD,
    payload: data
  }
}

export const postRebateEdit= (data:any) => {
  return {
    type: POST_REBATE_EDIT,
    payload: data
  }
}

export const setRebateEdit = (data:any) => {
  return {
    type: SET_REBATE_EDIT,
    payload: data
  }
}

export const setRebateAddEditResponse = (data:any) => {
  return {
    type: SET_REBATE_ADDEDIT_RESPONSE,
    payload: data
  }
}


export const getCreateUpdateRebateProgram = (data:any) => {
  return {
    type: GET_CREATEUPDATE_REBATE_PROGRAM,
    payload: data
  }
}

export const getRebatePaymentMethod = () => {
  return {
    type: GET_REBATEPAYMENTMETHOD
  }
}

export const setRebatePaymentMethod = (data: any) => {
  return {
    type: SET_REBATEPAYMENTMETHOD,
    payload: data
  }
}

export const getRebatePeriod = () => {
  return {
    type: GET_REBATEPERIOD
  }
}

export const setRebatePeriod = (data: any) => {
  return {
    type: SET_REBATEPERIOD,
    payload: data
  }
}

export const getAllContract = () => {
  return {
    type: GET_ALL_CONTRACT
  }
}

export const setAllContract = (data: any) => {
  return {
    type: SET_ALL_CONTRACT,
    payload: data
  }
}

export const getAddContractName = (data: any, programID: any) => {
  return {
    type: GET_ADD_CONTRACT_NAME,
    payload: data,
    programID
  }
}

export const getUpdateContractName = (data: any) => {
  return {
    type: GET_UPDATE_CONTRACT_NAME,
    payload: data
  }
}

export const getDeleteContractName = (data: any, programID: any) => {
  return {
    type: GET_DELETE_CONTRACT_NAME,
    payload: data,
    programID
  }
}

export const getContractDetailsById = (data: any) => {
  return {
    type: GET_CONTRACT_DETAILS_BY_ID,
    payload: data
  }
}

export const setContractDetailsById = (data: any) => {
  return {
    type: SET_CONTRACT_DETAILS_BY_ID,
    payload: data
  }
}

export const getAllContractList = () => {
  return {
    type: GET_ALL_CONTRACT_LIST,
  }
}

export const setAllContractList = (data: any) => {
  return {
    type: SET_ALL_CONTRACT_LIST,
    payload: data
  }
}

export const getProgramByVendorId = (data: any) => {
  return {
    type: GET_PROGRAM_BY_VENDOR_ID,
    payload: data
  }
}

export const getContractByProgramId = (data: any) => {
  return {
    type: GET_CONTRCT_BY_PROGRAM_ID,
    payload: data
  }
}

export const getAllRebateTypes = () => {
  return {
    type: GET_ALL_REBATE_TYPES
  }
}

export const setAllRebateTypes = (data: any) => {
  return {
    type: SET_ALL_REBATE_TYPES,
    payload: data
  }
}

export const setProgramError = (data: any) => {
  return {
    type: SET_PROGRAM_ERROR,
    payload: data
  }
}

export const setContractByIdLoading = (flag: any) => {
  return {
    type: SET_CONTRACT_BY_ID_LOADING,
    payload: flag
  }
}

export const getContractByIdLoading = () => {
  return {
    type: GET_CONTRACT_BY_ID_LOADING
  }
}

export const getRebateProgramFieldInfo = () => {
  return {
      type : GET_REBATE_PROGRAM_FIELD_INFO
  }
}

export const setRebateProgramFieldInfo = (data: any) => {
  return {
      type : SET_REBATE_PROGRAM_FIELD_INFO,
      payload: data
  }
}

export const getContractValidationFlag = (data: any) => {
  return {
    type: GET_CONTRACT_VALIDATION_FLAG,
    payload: data         
  }
}

export const setContractValidationFlag = (data: any) => {
  return {
    type: SET_CONTRACT_VALIDATION_FLAG,
    payload: data
  }
}


import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Search, Callout, DirectionalHint, Card, Row, Col, SwitchToggle, MultiSelectoption, PrimaryButton, CustomPaginationServerSide, ModalPopup, OutlineButton, Spinner,Label, Tooltip, InputField, Checkbox } from '../../CommonComponents';
import "./SelfReportingDashboard.scss";
import Icon_Checked from "../../assets/images/Icon_Checked.svg";
import Icon_NotReceived from "../../assets/images/Icon_NotReceived.svg";
import Icon_FileError from "../../assets/images/Icon_FileError.svg";
import Icon_NoSpend from "../../assets/images/Icon_NoSpend.svg";
import Icon_FileNotReceived from "../../assets/images/Icon_FileNotReceived.svg";
import Icon_Report from "../../assets/images/Icon_Report.svg";
import notesIcon from "../../assets/images/notesIcon.svg";
import Icon_NotReceivedInfo from "../../assets/images/Icon_NotReceivedInfo.svg";
import close from "../../assets/images/close.png";
import Icon_ReportReceived from "../../assets/images/Icon_ReportReceived.svg";
import Icon_ReportPrepped from "../../assets/images/Icon_ReportPrepped.svg";
import ColumnUpArrow from "../../assets/images/ColumnUpArrow.svg";
import ColumnDownArrow from "../../assets/images/ColumnDownArrow.svg";
import sortIcon from "../../assets/images/sortIcon.png";
import VendorFiles from "./VendorFiles";
import PrintIcon from '../../assets/images/selfReporting/PrintIcon.svg';
import ExcelIcon from '../../assets/images/selfReporting/ExcelIcon.svg';
import NoSpend from '../../assets/images/selfReporting/NoSpend.svg';
import FileError from '../../assets/images/selfReporting/FileError.svg';
import ColumnUpArrowWhite from "../../assets/images/ColumnUpArrowWhite.svg";
import ColumnDownArrowWhite from "../../assets/images/ColumnDownArrowWhite.svg";
import UploadFileStatus from "./UploadFileStatus";
import ColumnArrowBlack from "../../assets/images/ColumnArrowBlack.svg";
import Icon_Prepared from "../../assets/images/Icon_Prepared.svg";
import Icon_Processed from "../../assets/images/Icon_Processed.svg";
import moment from "moment";
import { isAuthorized } from "client/utils/Permissions";

const dropdownOptions = ['5', '10', '25', '50', '100']
const monthsList: any = {
  January: "Jan",
  February: "Feb",
  March: "Mar",
  April: "Apr",
  May: "May",
  June: "Jun",
  July: "Jul",
  August: "Aug",
  September: "Sep",
  October: "Oct",
  November: "Nov",
  December: "Dec"
}

const fileStatus = {
  NOT_RECEIVED: "Not Received",
  RECEIVED: "Received",
  PREPPED: "Prepped",
  PROCESSED: "Processed",
  NO_SPEND: "No Spend",
  FILE_ERROR: "File Error"
}

const fileStatusArray = ["Not Received", "Received", "Prepped", "Processed", "No Spend", "File Error"]
interface Iprops {
  isTextVisible?: any,
  handleToggleChange?: any,
  gridData?: any,
  gridHeaderData?: any,
  months?: any,
  totalCount?: any,
  pageSize?: any,
  pageIndex?: any,
  onPageSizechange?: any,
  onNext?: any,
  onPrevious?: any,
  onLast?: any,
  onFirst?: any,
  onChangeVendor?: any,
  fileNotReceivedList?: any,
  setSelectedYear?: any
  selectedYear?: any
  onSortData?: any
  sortingData?: any
  setIsCompactView?: any
  isCompactView?: any
  categoryManagerList?: any
  vendorTypeList?: any
  selectedCategoryManger?: any
  setSelectedCategoryManger?: any
  selectedVendorType?: any
  setSelectedVendorType?: any
  showHistoryData?: any
  vendorHistoryData?: any
  notReceivedMonths?: any
  showUploadFilePopup?: any
  setShowUploadFilePopup?: any
  onClickExcel?: any
  fileCompletionData?: any
  onClickSendReminder?: any
  emailReminderPopup?: any
  hidePopup?: any
  sendReminder?: any
  onClickGridIcons?: any
  updateStatusData?: any
  onCloseUpdatePopup?: any
  currentMonth?: any
  setCurrentMonth?: any
  selfReportingLoader?: any
  statusList?: any
  setSelectedStatus?: any
  selectedStatus?: any
  sendEmailPopup?: any
  setsendEmailPopup?: any
  getNotes?: any
  addNotes?:any
  selfReportingNotes?: any
  setNoteValue?: any
  noteValue?: any
  isNotReceivedCheckbox?: any
  setIsNotReceivedCheckbox?: any
  columnListToHide?: any
  setshowReceivedColumn?: any
  setshowPreppedColumn?: any
  setshowProccessedColumn?: any
  showReceivedColumn?: any
  showPreppedColumn?: any
  showProccessedColumn?: any
  emailTemplateData?: any
  setEmailTemplateData?: any
  getEmailTemplate?: any
  onCloseSendEmailPopup?: any
}
const SelfReportingDashboard = (props: Iprops) => {
  const {
    gridData,
    gridHeaderData,
    months,
    totalCount,
    pageSize,
    pageIndex,
    onPageSizechange,
    onNext,
    onPrevious,
    onLast,
    onFirst,
    onChangeVendor,
    fileNotReceivedList,
    setSelectedYear,
    selectedYear,
    onSortData,
    sortingData,
    setIsCompactView,
    isCompactView,
    categoryManagerList,
    vendorTypeList,
    selectedCategoryManger,
    setSelectedCategoryManger,
    selectedVendorType,
    setSelectedVendorType,
    showHistoryData,
    vendorHistoryData,
    notReceivedMonths,
    showUploadFilePopup,
    setShowUploadFilePopup,
    onClickExcel,
    fileCompletionData,
    onClickSendReminder,
    emailReminderPopup,
    hidePopup,
    sendReminder,
    onClickGridIcons,
    updateStatusData,
    onCloseUpdatePopup,
    currentMonth,
    setCurrentMonth,
    selfReportingLoader,
    statusList,
    setSelectedStatus,
    selectedStatus,
    sendEmailPopup,
    setsendEmailPopup,
    getNotes,
    addNotes,
    selfReportingNotes,
    setNoteValue,
    noteValue,
    isNotReceivedCheckbox,
    setIsNotReceivedCheckbox,
    columnListToHide,
    setshowReceivedColumn,
    setshowPreppedColumn,
    setshowProccessedColumn,
    showReceivedColumn,
    showPreppedColumn,
    showProccessedColumn,
    emailTemplateData,
    setEmailTemplateData,
    getEmailTemplate,
    onCloseSendEmailPopup
  } = props;
  const breadcrumlist: any = [
    {
      text: "Self Reporting Dashboard",
      key: "f1",
    }
  ];

  const [isfileNotReceivedVisible, setfileNotReceivedVisible] = useState('');
  const [isreportTimelineVisible, setreportTimelineVisible] = useState('');
  const [selectedPaymentMethod, setselectedPaymentMethod] = useState([]);
  const [showBackdrop, setShowBackdrop] = useState(false);

  const [isNotesVisible, setNotesVisible] = useState(false);
  
  const toggleIsCalloutVisible = (supplierId: any) => {
    setShowBackdrop(!showBackdrop);
    const fileList: any = fileNotReceivedList?.find((file: any) => file?.supplierId == supplierId)
    setFileNotReceivedMonthList({
      monthList: fileList?.list?.length ? fileList?.list : [],
      supplierId
    })
    setfileNotReceivedVisible((prevNestedQuote) => {
      return prevNestedQuote === supplierId ? null : supplierId;
    });

  };

  const reportTimelineVisible = (supplierId: any) => {
    setShowBackdrop(!showBackdrop);
    setreportTimelineVisible((prevNestedQuote2) => {
      return prevNestedQuote2 === supplierId ? null : supplierId;
    });
  };

  const wrapperRef = useRef<any>(null);

  const [fileNotReceivedMonthList, setFileNotReceivedMonthList] = useState<any>([]);

  const monthsdata = ['January', 'February', 'March', "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const querySelectref: any = useRef(null);
  const headerSelectref = querySelectref?.current?.querySelector(`.selfTrackboardVendor .${monthsdata[currentMonth]}`);
  const headerScrolVal = headerSelectref?.offsetLeft;
  const VendorTablescroll: any = document.querySelector('.selfTrackboard');
  const VendorscolWidth: any = document.querySelector('.selfTrackboard .FixedlftColumn');
  const GetVendorsColWidth = VendorscolWidth?.clientWidth;

  if (headerSelectref && headerScrolVal !== undefined) {
    VendorTablescroll.scrollLeft = headerScrolVal - GetVendorsColWidth;
  }

  const categoryMangerPlaceholder = (selectedCategoryManger:any) => {
    if (!selectedCategoryManger.length) {
      return "Select Category Manager";
    }
  };

  const vendorTypePlaceholder = (selectedCategoryManger:any) => {
    if (!selectedCategoryManger.length) {
      return "Select Vendor Type";
    }
  };  


  const toggleNotesVisible = (supplierIdData:any, isClose:boolean) => {
    console.info(supplierIdData, "supplierIdData");
    setShowBackdrop(!showBackdrop);
    //setNotesVisible(!isNotesVisible);

    setNotesVisible((prevNestedQuote3) => {
      return prevNestedQuote3 === supplierIdData?.supplierId ? null : supplierIdData?.supplierId;
    });

    if(supplierIdData){
      getNotes(supplierIdData?.supplierId)
      setNoteValue({
        ...noteValue,
        supplierId: supplierIdData?.supplierId,
        categoryManager: supplierIdData?.categoryManager
      })
    }

    if(isClose){      
      setNoteValue({})
      setShowBackdrop(false)
    }
  }

  const validateEmail = (email: any) => {
    // let emailReg: any = /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*(;|,)\s*|\s*$))+$/
    // let isValid = emailReg.test(email);
    // return isValid
    const at = email.indexOf("@");
    const dot = email.lastIndexOf("\.");
    return email.length > 0 &&
      at > 0 &&
      dot > at + 1 &&
      dot < email?.length - 2 &&
      email[at + 1] !== "." &&
      email.indexOf(" ") === -1 &&
      email.indexOf("..") === -1;
  }

  const EmailTemplate = () => {
    const [data, setData] = useState({
      to: '',
      subject: '',
      message: ''
    })
    const [error, setError] = useState({
      toError: '',
      subjectError: ''
    })
    useEffect(()=>{
      setData(emailTemplateData)
    },[])
    return (<>
          <Row className="mb-2">
            <Col lg={2} sm={2} xs={12} >
              <label className="label">To: </label>
            </Col>
            <Col lg={10} sm={10} xs={12}>
              <InputField name="ToField" value={data?.to} onChange={(item:any)=>{
                setData({...data, to:item?.target?.value})
                if(!validateEmail(item?.target?.value.replaceAll(' ', '')))setError({...error, toError: "Please enter valid email address"})
                else setError({...error, toError: ""})
              }}/>
              {error?.toError && <div className="error-message">{error?.toError}</div>}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg={2} sm={2} xs={12} >
              <label className="label">Subject: </label>
            </Col>
            
            <Col lg={10} sm={10} xs={12}>
              <InputField name="SubjectField" value={data?.subject} onChange={(item:any)=>{
                setData({...data, subject:item?.target?.value})
                if(item?.target?.value?.length > 250)setError({...error, subjectError: "Please enter less than 250 characters"})
                else setError({...error, subjectError: ""})
              }}/>
              {error?.subjectError && <div className="error-message">{error?.subjectError}</div>}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg={2} sm={2} xs={12} >
              <label className="label">Message: </label>
            </Col>
            <Col lg={10} sm={10} xs={12} className="messageField">
              <InputField row={4} multiline={true} resizable={false} name='Message' value={data?.message} onChange={(item:any)=>setData({...data, message:item?.target?.value})}/>
            </Col>
          </Row>
          <div className="clearfix mb-2">
            <div className="pull-right">
              <OutlineButton text='Cancel' className="cancelBtn" onClick={()=>onCloseSendEmailPopup()}/>
              <PrimaryButton disabled={error?.subjectError?.length > 0 || error?.toError?.length > 0} text='Send' className="sendBtn" onClick={()=>{
                sendReminder(data);
              }}/>
            </div>
          </div>
    </>)
  }


  const getHeaderClassName = (monthName: any) => {
    let count = 0;
    if(selectedStatus.length > 0){
      const columnValue = columnListToHide[monthName]
      if(columnListToHide[monthName]){
        if(showReceivedColumn && !columnValue['received']) count++;
        if(showPreppedColumn && !columnValue['prepped']) count++;
        if(showProccessedColumn && !columnValue['processed']) count++;
      }
    }else{
      if(showReceivedColumn) count++;
      if(showPreppedColumn) count++;
      if(showProccessedColumn) count++;
    }
    return count == 1 ? 'MonthQuarterWidth' : count == 2 ? 'MonthHalfWidth' : count == 3 ? 'MonthFullWidth' : 'MonthNoWidth'
  }

  const getHideColumnValue = (monthName:any, columnName: any) => {
    if(columnListToHide[monthName] && selectedStatus.length > 0){
      const columnValue = columnListToHide[monthName]
      if(columnName == 'received') return showReceivedColumn && !columnValue[columnName]
      else if(columnName == 'prepped') return showPreppedColumn && !columnValue[columnName]
      else if(columnName == 'processed') return showProccessedColumn && !columnValue[columnName]
    }else{
      if(columnName == 'received') return showReceivedColumn
      else if(columnName == 'prepped') return showPreppedColumn
      else if(columnName == 'processed') return showProccessedColumn    
    }
  }

  return (
    <div className="SelfReportingDashboardContainer">
      <div className={`backdrop ${showBackdrop ? 'visible' : ''}`}></div>
      <div className="fixedTopSection">
        <Breadcrumb items={breadcrumlist} maxDisplayedItems='5' />
        <VendorFiles
          setSelectedYear={setSelectedYear}
          selectedYear={selectedYear}
          fileCompletionData={fileCompletionData}
          currentMonth={currentMonth}
          setCurrentMonth={setCurrentMonth}
          onClickSendReminder={onClickSendReminder}
        />
      </div>

      <div className="bottomGridSection">
        <Card>
          <Row className="mb-3">
            <Col lg={2} sm={4} xs={12} >
            <Label>Category Manager</Label>
              <div className='DropdownContainer'>
                <div className='Dropdown'>
                <Tooltip content={(selectedCategoryManger.length > 1) 
                                    ? `Total selected: ${selectedCategoryManger.length} 
                                    \n${selectedCategoryManger.map((ele:any) => `\u00A0${ele.label}`)}` 
                                    : '' }
                                    >
                  <MultiSelectoption
                    options={categoryManagerList}
                    onChange={setSelectedCategoryManger}
                    value={selectedCategoryManger}
                    className='search-dropdown'
                    valueRenderer={categoryMangerPlaceholder}                    
                  />
                  </Tooltip>
                </div>
              </div>
            </Col>
            <Col lg={2} sm={4} xs={12} className="px-0">
            <Label>Vendor Type</Label>
              <div className='DropdownContainer'>
                <div className='Dropdown'>
                <Tooltip content={(selectedVendorType.length > 1) 
                                    ? `Total selected: ${selectedVendorType.length} 
                                    \n${selectedVendorType.map((ele:any) => `\u00A0${ele.label}`)}` 
                                    : '' }
                                    >
                  <MultiSelectoption
                    options={vendorTypeList}
                    onChange={setSelectedVendorType}
                    value={selectedVendorType}
                    className='search-dropdown'
                    valueRenderer={vendorTypePlaceholder}
                  />
                  </Tooltip>
                </div>
              </div>
            </Col>
            <Col lg={2} sm={4} xs={12} className="px-0">
            <Label>Status</Label>
                <div className='DropdownContainer'>
                  <div className='Dropdown'>
                  <Tooltip content={(selectedStatus.length > 1) 
                                    ? `Total selected: ${selectedStatus.length} 
                                    \n${selectedStatus.map((ele:any) => `\u00A0${ele.label}`)}` 
                                    : '' }
                                    >
                    <MultiSelectoption
                      options={statusList}
                      overrideStrings='Select All'
                      onChange={setSelectedStatus}
                      value={selectedStatus}
                      className='search-dropdown'
                    />
                     </Tooltip>
                  </div>
                </div>
            </Col>
            {/* <Col lg={2} sm={4} xs={12}>
            <div className='DropdownContainer'>
                  <div className='Dropdown'>
                    <MultiSelectoption
                      options={PaymentMethods}
                      overrideStrings='Select All'
                      onChange={setselectedPaymentMethod}
                      value={selectedPaymentMethod}
                      className='search-dropdown'
                    />
                  </div>
                </div>
            </Col> */}

            <Col lg={6} sm={12} xs={12}>
              <div className='ToggleContainer'>
                <div className="me-2">
                  <ul className="columnsStatus">
                      <li> 
                          <Checkbox name='Received' label='Received' type='small' checked={showReceivedColumn} onChange={() => setshowReceivedColumn(!showReceivedColumn)} /> 
                      </li>
                      <li> 
                         <Checkbox name='Prepped' label='Prepped' type='small' checked={showPreppedColumn} onChange={() => setshowPreppedColumn(!showPreppedColumn)}  /> 
                      </li>
                      <li> 
                         <Checkbox name='Processed' label='Processed' type='small' checked={showProccessedColumn} onChange={() => setshowProccessedColumn(!showProccessedColumn)} /> 
                      </li>
                  </ul>                
                </div>
                <div className="ToggleSwitchTxts me-2">
                  <SwitchToggle className='switchToggle pt-2 me-2 pull-left' onChange={() => setIsCompactView(!isCompactView)} />
                  {isCompactView ? (
                    <p className='Text mt-sm-2 pt-sm-1 mt-0 pt-0 pull-left'>Compact View</p>
                  ) : (
                    <p className='Text mt-sm-2 pt-sm-1 mt-0 pt-0 pull-left'>Default View</p>
                  )}
                </div>

                {isAuthorized("SelfReportingDashboard", "create") && <PrimaryButton text='Update File Status' className='UpdateBtn me-4' onClick={() => setShowUploadFilePopup(true)} />}
                {/* <button className="btn me-2" ><img src={PrintIcon} alt='print icon' /></button> */}
                <button className="btn me-2" onClick={onClickExcel} ><img src={ExcelIcon} alt='excel icon' /></button>
                {/* <button className="btn" ><img src={ActionMenu} alt='action menu' /></button> */}
              </div>
            </Col>
          </Row>

          <div className="selfTrackboard">
            <div className="selfTrackboardMain">
              <div className="selfTrackboardVendor" ref={querySelectref}>
                <div className="selfTrackHead VendorsColumn FixedlftColumn">
                  <div onClick={() => { onSortData('Vendors', '') }}>Vendors {<img src={sortingData.sortColumn === 'Vendors' ? (sortingData.sortDirection === 'asc' ? ColumnDownArrowWhite : ColumnUpArrowWhite) : sortIcon} alt="" className="selfTracInnerSecondIcon" />}</div>
                  <div className="notReceivedCheckbox"> 
                    <Checkbox name='NotReceived' label='Not Received' type='small' checked={isNotReceivedCheckbox} onChange={(item:any)=>setIsNotReceivedCheckbox(item.target.checked)}/> 
                  </div>
                </div>
                {gridHeaderData?.map((monthsdatavalue: any) => {
                  return getHeaderClassName(monthsdatavalue?.monthYear.replace(selectedYear,'').toLowerCase().trim()) != "MonthNoWidth" ? <div className={`selfTrackHead ${getHeaderClassName(monthsdatavalue?.monthYear.replace(selectedYear,'').toLowerCase().trim())} ${monthsdata[currentMonth] + ` ${selectedYear}`
                    === `${monthsdatavalue?.monthYear}` ? monthsdata[currentMonth] : ''}`}>{monthsdatavalue?.monthYear}</div> : null
                })}

              </div>
              <div className="selfTrackboardVendor">  
                <div className="selfTrackboardVendorInner SearchBoxArea bglightBlue FixedlftColumn">
                  <Search
                    className={''}
                    placeholder={'Search by Vendor'}
                    onChange={(item?: any) => onChangeVendor(item?.target?.value)}
                    onSearch={(item?: any) => onChangeVendor(item?.target?.value)}
                    onBlur={(item?: any) => onChangeVendor(item?.target?.value)}
                  />
                </div>
                <div className="selfTrackboardVendorInner">
                  <div className="bglightBlue">
                    <div className="selfTracInnerSecond">
                      {gridHeaderData?.map((monthsdatavalue: any, index3: any) => {
                        return getHeaderClassName(monthsdatavalue?.monthYear.replace(selectedYear,'').toLowerCase().trim()) != "MonthNoWidth" ? (
                          <div className={`TopHeaderRow ${getHeaderClassName(monthsdatavalue?.monthYear.replace(selectedYear,'').toLowerCase().trim())}`}>
                            {getHideColumnValue(monthsdatavalue?.monthYear.replace(selectedYear,'').toLowerCase().trim(), 'received') ? 
                            <div>
                              <div key={index3} className="TopHeaderRowCell">{monthsdatavalue.receivedData.ReceivedPercentage}</div>
                            </div>
                          : null}
                            
                            {getHideColumnValue(monthsdatavalue?.monthYear.replace(selectedYear,'').toLowerCase().trim(), 'prepped') ? 
                            <div>
                              <div key={index3} className="TopHeaderRowCell">{monthsdatavalue.preppedData.PreppedPercentage}</div>
                            </div>
                            : null}

                            {getHideColumnValue(monthsdatavalue?.monthYear.replace(selectedYear,'').toLowerCase().trim(), 'processed') ? 
                            <div>
                              <div key={index3} className="TopHeaderRowCell">{monthsdatavalue.processedData.ProcessedPercentage}</div>
                            </div>
                            : null}
                            </div>
                        ) : null
                      })}
                    </div>
                    <div className="selfTracInnerSecond">
                      {gridHeaderData?.map((monthsdatavalue: any, index3: any) => {
                        return getHeaderClassName(monthsdatavalue?.monthYear.replace(selectedYear,'').toLowerCase().trim()) != "MonthNoWidth" ? (
                          <>
                            <div className={`TopHeaderRow ${getHeaderClassName( monthsdatavalue?.monthYear.replace(selectedYear,'').toLowerCase().trim())}`}>
                                  
                            {getHideColumnValue(monthsdatavalue?.monthYear.replace(selectedYear,'').toLowerCase().trim(), 'received') ? (
                                    <div>
                                        <div className="TopHeaderRowCell" key={index3} onClick={() => { onSortData(monthsdatavalue.monthYear, monthsdatavalue.receivedData.ReceivedTxt) }}>
                                      {monthsdatavalue.receivedData.ReceivedTxt}
                                      {<img src={(sortingData.sortColumn === monthsdatavalue.monthYear && sortingData.sortStatusColumn === monthsdatavalue.receivedData.ReceivedTxt)
                                        ? (sortingData.sortDirection === 'asc' ? ColumnDownArrow : ColumnUpArrow)
                                        : ColumnArrowBlack} alt="" className="selfTracInnerSecondIcon" />}
                                      </div>
                                    </div>
                                    ): null}
                                  
                            {getHideColumnValue(monthsdatavalue?.monthYear.replace(selectedYear,'').toLowerCase().trim(), 'prepped') ? (
                                    <div>
                                    <div className="TopHeaderRowCell" key={index3} onClick={() => { onSortData(monthsdatavalue.monthYear, monthsdatavalue.preppedData.PreppedTxt) }}>
                                    {monthsdatavalue.preppedData.PreppedTxt}
                                    {<img src={(sortingData.sortColumn === monthsdatavalue.monthYear && sortingData.sortStatusColumn === monthsdatavalue.preppedData.PreppedTxt)
                                      ? (sortingData.sortDirection === 'asc' ? ColumnDownArrow : ColumnUpArrow)
                                      : ColumnArrowBlack} alt="" className="selfTracInnerSecondIcon" />}
                                    </div>
                                    </div>
                                  ): null}
                                  
                            {getHideColumnValue(monthsdatavalue?.monthYear.replace(selectedYear,'').toLowerCase().trim(), 'processed') ? (
                                      <div>
                                        <div className="TopHeaderRowCell" key={index3} onClick={() => { onSortData(monthsdatavalue.monthYear, monthsdatavalue.processedData.ProcessedTxt) }}>
                                        {monthsdatavalue.processedData.ProcessedTxt}
                                        {<img src={(sortingData.sortColumn === monthsdatavalue.monthYear && sortingData.sortStatusColumn === monthsdatavalue.processedData.ProcessedTxt)
                                          ? (sortingData.sortDirection === 'asc' ? ColumnDownArrow : ColumnUpArrow)
                                          : ColumnArrowBlack} alt="" className="selfTracInnerSecondIcon" />}
                                        </div>
                                      </div>
                                    ): null}
                                  
                            </div>
                          </>
                        ) : null
                      })}
                    </div>
                  </div>
                </div>
              </div>
              {
                selfReportingLoader.gridData ?
                  <Spinner text={"Fetching Data ..."} />
                  : gridData.map((DashboardDatavalue: any, index4: any) => {
                    const fileList: any = fileNotReceivedList?.find((file: any) => file?.supplierId == DashboardDatavalue?.supplierId)
                    return (
                      <>
                        <div className="selfTrackboardVendor selfTrackboardVendorRow">

                          <div className={`selfTrackRowName FixedlftColumn ${fileList?.list?.length > 0 ? "notReceivedBorder" : ""}`} key={index4}>
                            <div>
                              <p>{DashboardDatavalue.supplierName}</p>
                              {!isCompactView && <p className="selfTrackRowCategory">{`${DashboardDatavalue.vendorType} | ${DashboardDatavalue.categoryManager}`}</p>}
                            </div>
                            <div>
                              <ul className="selfTrackboardVendorIcons">
                                {fileList?.list?.length > 0 && <li>
                                  <img src={Icon_FileNotReceived} className="reportTimelinestyle" ref={wrapperRef} alt="" id={`fileNotRecivedCallout_${DashboardDatavalue?.supplierId}`} onClick={() => toggleIsCalloutVisible(DashboardDatavalue?.supplierId)} />

                                  {DashboardDatavalue?.supplierId == isfileNotReceivedVisible && <Callout targetId={`fileNotRecivedCallout_${DashboardDatavalue?.supplierId}`} toggleIsCalloutVisible={() => toggleIsCalloutVisible('')} DirectionalHint={DirectionalHint.rightTopEdge}>
                                    <div className="FilereceivedPopup">
                                      <h2 className="callOutHeader">File not received details <img src={close} alt='' onClick={() => toggleIsCalloutVisible('')} className="closeIcon" /> </h2>
                                      <div className="callOutContent">
                                        <div className="callOutContentInner clearfix">
                                          <div className="callOutContentInnerleft">
                                            {fileNotReceivedMonthList?.monthList?.map((list: any) => {
                                              return <h3 className="callOutInnerHeader">{list[0].toUpperCase() + list.slice(1) + " " + selectedYear}</h3>
                                            })}
                                            <p className="FilentrecivdTxts"><img src={Icon_NotReceivedInfo} alt="" />File Not Received</p>
                                          </div>
                                          <div className="callOutContentInnerright">
                                            <a title="" className="reminderLink" onClick={() => {
                                              onClickSendReminder(fileNotReceivedMonthList?.monthList, fileNotReceivedMonthList?.supplierId)
                                              toggleIsCalloutVisible('')
                                            }
                                            }> Send Reminder </a>

                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </Callout>}
                                </li>}
                                <li>
                                  <img src={Icon_Report} alt="" className="Calloutstyle" ref={wrapperRef} id={`reportTimelineCallout_${DashboardDatavalue?.supplierId}`} onClick={() => {
                                    // toggleIsCalloutVisible(DashboardDatavalue?.supplierId)
                                    reportTimelineVisible(DashboardDatavalue?.supplierId);
                                    showHistoryData(DashboardDatavalue?.supplierId)
                                  }} />

                                  {DashboardDatavalue?.supplierId == isreportTimelineVisible && <Callout targetId={`reportTimelineCallout_${DashboardDatavalue?.supplierId}`} toggleIsCalloutVisible={() => reportTimelineVisible('')} DirectionalHint={DirectionalHint.rightTopEdge}>
                                    <h2 className="callOutHeader">Report Timeline: CY {selectedYear} <img src={close} alt='' onClick={() => reportTimelineVisible('')} className="closeIcon" /> </h2>
                                    <div className="callOutContent ReportTimelineCallout">
                                      <div className="callOutContentInner">
                                        {notReceivedMonths?.monthList?.length > 0 && <div className="AlertMessage notReceivedMessageBorder mb-3ss">
                                          <p className="FilentrecivdTxts mb-1"><img src={Icon_NotReceivedInfo} alt="" />File Not Received</p>
                                          <div className="clearfix">
                                            <div className="pull-left">
                                              {notReceivedMonths?.monthList?.map((item: any, index: any) => {
                                                let str = monthsList[item] + " " + selectedYear
                                                return index === notReceivedMonths?.monthList?.length - 1 ? str : str + ", ";
                                              })}
                                            </div>
                                            <div className="pull-right">
                                              <a title="" className="reminderLink" onClick={() => {
                                                onClickSendReminder(notReceivedMonths?.monthList, notReceivedMonths?.supplierId)
                                                reportTimelineVisible('')
                                              }
                                              }> Send Reminder </a>
                                            </div>
                                          </div>
                                        </div>}

                                        <h3 className="callOutContentHeader">Report File Summary</h3>

                                        <div className="callOutContentInnerScroll">
                                          {
                                            vendorHistoryData?.map((historyItem: any) => {
                                              if (historyItem?.received === fileStatus.NOT_RECEIVED) {
                                                return <div className="callOutMonthBlock callOutMonthErrorBorder">
                                                  <h4 className="callOutContentMonth">{historyItem?.calendarMonth} {selectedYear}</h4>
                                                  <div className="AlertMessage notReceivedMessageBorder">
                                                    <div className="notReceivedMessageAlert clearfix">
                                                      <div>
                                                        <p className="FilentrecivdTxts mb-1"><img src={Icon_NotReceivedInfo} alt="" />File Not Received</p>
                                                      </div>
                                                      <div className="reminderLinkBlock">
                                                        <a title="" className="reminderLink" onClick={() => {
                                                          onClickSendReminder([historyItem?.calendarMonth], notReceivedMonths?.supplierId)
                                                          reportTimelineVisible('')
                                                        }}> Send Reminder </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              } else {
                                                return <div className="callOutMonthBlock callOutMonthWarningBorder">
                                                  <h4 className="callOutContentMonth">{historyItem?.calendarMonth} {selectedYear}</h4>
                                                  <div className="AlertMessage infoReceivedBorder">
                                                    <div className="PendingMessageAlert">
                                                      <div className="PendingMessageAlertBlk">
                                                        <div className="PendingMessageHead clearfix">
                                                          <img src={
                                                            historyItem?.received === fileStatus.NO_SPEND
                                                              ? NoSpend
                                                              : historyItem?.received === fileStatus.FILE_ERROR
                                                                ? FileError
                                                                : historyItem?.received
                                                                  ? Icon_ReportReceived
                                                                  : Icon_ReportPrepped
                                                          } alt="" className="PendingMessageHeadImg" />
                                                          <span className="PendingMessageHeadtxts"> Received </span>
                                                        </div>
                                                        <div className="PendingMessagContent">
                                                          <p>{historyItem?.categoryManager}</p>
                                                          <p>{
                                                            historyItem?.received === fileStatus.NO_SPEND
                                                              ? 'No Spend'
                                                              : historyItem?.received === fileStatus.FILE_ERROR
                                                                ? 'File Error'
                                                                : historyItem?.received
                                                                  ? moment(historyItem?.received).format('MM/DD/YYYY')
                                                                  : ''
                                                          }</p>
                                                        </div>
                                                      </div>
                                                      <div className="PendingMessageAlertBlk">
                                                        <div className="PendingMessageHead clearfix">
                                                          <img src={
                                                            historyItem?.prepped === fileStatus.NO_SPEND
                                                              ? NoSpend
                                                              : historyItem?.prepped === fileStatus.FILE_ERROR
                                                                ? FileError
                                                                : historyItem?.prepped
                                                                  ? Icon_ReportReceived
                                                                  : Icon_ReportPrepped
                                                          } className="PendingMessageHeadImg" alt="" />
                                                          <span className="PendingMessageHeadtxts"> Prepped </span>
                                                        </div>
                                                        <div className="PendingMessagContent">
                                                          <p>{
                                                            historyItem?.prepped === fileStatus.NO_SPEND
                                                              ? 'No Spend'
                                                              : historyItem?.prepped === fileStatus.FILE_ERROR
                                                                ? 'File Error'
                                                                : historyItem?.prepped
                                                                  ? moment(historyItem?.prepped).format('MM/DD/YYYY')
                                                                  : ''
                                                          }</p>
                                                        </div>
                                                      </div>
                                                      <div className="PendingMessageAlertBlk">
                                                        <div className="PendingMessageHead clearfix">
                                                          <img src={
                                                            historyItem?.processed === fileStatus.NO_SPEND
                                                              ? NoSpend
                                                              : historyItem?.processed === fileStatus.FILE_ERROR
                                                                ? FileError
                                                                : historyItem?.processed
                                                                  ? Icon_ReportReceived
                                                                  : Icon_ReportPrepped
                                                          } className="PendingMessageHeadImg" alt="" />
                                                          <span className="PendingMessageHeadtxts"> Processed </span>
                                                        </div>
                                                        <div className="PendingMessagContent">
                                                          <p>{historyItem?.processed === fileStatus.NO_SPEND
                                                            ? 'No Spend'
                                                            : historyItem?.processed === fileStatus.FILE_ERROR
                                                              ? 'File Error'
                                                              : historyItem?.processed
                                                                ? moment(historyItem?.processed).format('MM/DD/YYYY')
                                                                : ''
                                                          }</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              }

                                            })
                                          }
                                        </div>

                                      </div>
                                    </div>
                                  </Callout>}
                                </li>
                                <li>
                                  <img src={notesIcon} alt="" ref={wrapperRef} onClick={()=>toggleNotesVisible(DashboardDatavalue, false)}  id={`NotesCallout_${DashboardDatavalue?.supplierId}`} className="NotesCalloutpopup" />
                                  {DashboardDatavalue?.supplierId == isNotesVisible && <Callout targetId={`NotesCallout_${DashboardDatavalue?.supplierId}`} toggleIsCalloutVisible={() => toggleNotesVisible(null, true)} DirectionalHint={DirectionalHint.rightTopEdge}>
                                    <div className="AddNotesPopup">
                                      <h2 className="callOutHeader">Add Note <img src={close} alt='' onClick={() => toggleNotesVisible(null, true)} className="closeIcon" /> </h2>
                                        <div className="callOutContent NotesCallout">
                                          <div className="callOutContentInner">
                                            <div>
                                              <div className="mb-2">
                                                <InputField multiline={true} row="4" resizable={false} name='description' value={noteValue?.notes} onChange={(val:any)=>setNoteValue({...noteValue, notes: val.target.value})}/>
                                              </div>
                                              <div className="clearfix">
                                                <PrimaryButton disabled={noteValue?.notes?.trim() == ""} text='Submit' className="InputBtns" onClick={()=>addNotes()}/>
                                              </div>
                                            </div>
                                            <h3 className="callOutContentHeader">Notes</h3>
                                            <div className="callOutContentInnerScroll">
                                            {selfReportingNotes.length === 0 ?<p>No Data Found</p>:''}
                                            {selfReportingNotes?.map((note:any)=>{
                                              return (<div className="callOutMonthBlock callOutMonthWarningBorder">
                                                  <h4 className="callOutContentMonth">
                                                    {moment.utc(note.notesAddedAt).format('MM/DD/YYYY')} 
                                                    <span style={{ fontSize: '10px', fontWeight: 'normal' }}>{` ${moment.utc(note.notesAddedAt).format('HH:mm:ss')} (UTC)`}</span>                                                    
                                                    <span className="callOutContentHead">{note?.notesAddedBy}</span></h4>                                                  
                                                  <div className="AlertMessage infoReceivedBorder">
                                                    {note?.notes}
                                                  </div>
                                                </div>)
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </Callout>}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <>
                            {months.map((month: any, index: any) => {
                              return (
                                <div className={`selfTrackboardRowData ${getHeaderClassName(month)}`}>
                                  {getHideColumnValue(month, 'received') && (
                                  <div onClick={() => {
                                    if (DashboardDatavalue[month]?.EditReceived && isAuthorized("SelfReportingDashboard", "create")) {
                                      let status = fileStatusArray.includes(DashboardDatavalue[month]?.Received) ? DashboardDatavalue[month]?.Received : fileStatus.RECEIVED
                                      onClickGridIcons(fileStatus.RECEIVED, status, DashboardDatavalue.supplierId, index + 1)
                                    }
                                  }}>
                                    <div className="selfTrackboardCelldata">
                                      {(DashboardDatavalue[month]?.Received === fileStatus.NOT_RECEIVED)
                                        ? <img src={Icon_NotReceived} alt="" />
                                        : (DashboardDatavalue[month]?.Received === fileStatus.NO_SPEND)
                                          ? <img src={Icon_NoSpend} alt="" />
                                          : (DashboardDatavalue[month]?.Received === fileStatus.FILE_ERROR)
                                            ? <img src={Icon_FileError} alt="" />
                                            : DashboardDatavalue[month]?.Received
                                              ? <img src={Icon_Checked} alt="" /> : null}
                                    </div>
                                    {!isCompactView && <div className="selfTrackboardCelldata">
                                      {(DashboardDatavalue[month]?.Received === fileStatus.NOT_RECEIVED)
                                        ? (<span className="notReceivedtxts">{`Not Received`}</span>)
                                        : (DashboardDatavalue[month]?.Received === fileStatus.NO_SPEND)
                                          ? (<span className="noSpendtxts">{`No Spend`}</span>)
                                          : (DashboardDatavalue[month]?.Received === fileStatus.FILE_ERROR)
                                            ? (<span className="fileErrortxts">{`File error`}</span>)
                                            : DashboardDatavalue[month]?.Received
                                              ? `(${DashboardDatavalue[month]?.Received})`
                                              : null}
                                    </div>}
                                  </div>
                                  )}
                                  
                                  {getHideColumnValue(month, 'prepped') && (
                                    <div onClick={() => {
                                    if (DashboardDatavalue[month]?.EditPrepped && isAuthorized("SelfReportingDashboard", "update_prepped")) {
                                      let status = fileStatusArray.includes(DashboardDatavalue[month]?.Prepped) ? DashboardDatavalue[month]?.Prepped : fileStatus.PREPPED
                                      onClickGridIcons(fileStatus.PREPPED, status, DashboardDatavalue.supplierId, index + 1)
                                    }
                                  }}>
                                    <div className="selfTrackboardCelldata">
                                      {(DashboardDatavalue[month]?.Prepped === fileStatus.FILE_ERROR)
                                        ? <img src={Icon_FileError} alt="" />
                                        : (DashboardDatavalue[month]?.Prepped === fileStatus.NO_SPEND)
                                          ? <img src={Icon_NoSpend} alt="" />
                                          : DashboardDatavalue[month]?.Prepped
                                            ? <img src={Icon_Prepared} alt="" />
                                            : null}
                                    </div>

                                    {!isCompactView && <div className="selfTrackboardCelldata">
                                      {(DashboardDatavalue[month]?.Prepped === fileStatus.FILE_ERROR)
                                        ? (<span className="fileErrortxts">{`File error`}</span>)
                                        : (DashboardDatavalue[month]?.Prepped === fileStatus.NO_SPEND)
                                          ? (<span className="noSpendtxts">{`No Spend`}</span>)
                                          : DashboardDatavalue[month]?.Prepped
                                            ? `(${DashboardDatavalue[month]?.Prepped})`
                                            : null}
                                    </div>}
                                  </div>
                                  )}
                                  
                                  {getHideColumnValue(month, 'processed') && (
                                    <div onClick={() => {
                                    if (DashboardDatavalue[month]?.EditProcessed && isAuthorized("SelfReportingDashboard", "update")) {
                                      let status = fileStatusArray.includes(DashboardDatavalue[month]?.Processed) ? DashboardDatavalue[month]?.Processed : fileStatus.PROCESSED
                                      onClickGridIcons(fileStatus.PROCESSED, status, DashboardDatavalue.supplierId, index + 1)
                                    }
                                  }}>
                                    <div className="selfTrackboardCelldata">
                                      {(DashboardDatavalue[month]?.Processed === fileStatus.FILE_ERROR)
                                        ? <img src={Icon_FileError} alt="" />
                                        : (DashboardDatavalue[month]?.Processed === fileStatus.NO_SPEND)
                                          ? <img src={Icon_NoSpend} alt="" />
                                          : DashboardDatavalue[month]?.Processed ?
                                            <img src={Icon_Processed} alt="" /> : null}
                                    </div>
                                    {!isCompactView && <div className="selfTrackboardCelldata">
                                      {(DashboardDatavalue[month]?.Processed === fileStatus.FILE_ERROR)
                                        ? (<span className="fileErrortxts">{`File error`}</span>)
                                        : (DashboardDatavalue[month]?.Processed === fileStatus.NO_SPEND)
                                          ? (<span className="noSpendtxts">{`No Spend`}</span>)
                                          : DashboardDatavalue[month]?.Processed ?
                                            `(${DashboardDatavalue[month]?.Processed})` : null}
                                    </div>}
                                  </div>
                                  )}
                                  
                                </div>
                              )
                            })}
                          </>
                        </div>
                      </>
                    )
                  })
              }
            </div>
          </div>
        </Card>
        {emailReminderPopup?.isPopupVisible && (
          <ModalPopup
            // ShowModalPopupFooter={true}
            ModalPopupTitle={"CONFIMATION"}
            ModalPopupType="small"
            closeModalPopup={hidePopup}
            SecondryBtnOnclick={hidePopup}
          >
            <>
              <div className="mb-3">
                <p>{emailReminderPopup?.responseMessage}</p>
                <p>Do you want to send it again?</p>
              </div>

              <div className="clearfix mb-3">
                <div className="pull-right">
                  <OutlineButton text='No' className='updateBtn' onClick={hidePopup} />
                  <PrimaryButton text='Yes' className='updateBtn' onClick={() => {
                    getEmailTemplate();
                    hidePopup();
                  }} />
                </div>
              </div>

            </>
          </ModalPopup>
        )}
        {showUploadFilePopup && (
          <ModalPopup
            ShowModalPopupFooter={true}
            ModalPopupTitle={"UPDATE FILE STATUS"}
            ModalPopupType="large"
            closeModalPopup={() => onCloseUpdatePopup()}
            SecondryBtnOnclick={() => onCloseUpdatePopup()}
          >
            <UploadFileStatus selectedYear={selectedYear} onCloseUpdatePopup={onCloseUpdatePopup} updateStatusData={updateStatusData} />
          </ModalPopup>
        )}

        {
          sendEmailPopup && (
            <ModalPopup
            ModalPopupTitle={"Send Email"}
            ModalPopupType="medium"
            closeModalPopup={() => onCloseSendEmailPopup()}
            ModalPopupName= "sendEmailModal"
          >
            <EmailTemplate />
          </ModalPopup>
          )
        }
      </div>

      <CustomPaginationServerSide
        dropdownOptions={dropdownOptions}
        total={totalCount}
        pageSize={pageSize}
        onPageSizechange={onPageSizechange}
        pageIndex={pageIndex}
        onNext={onNext}
        onPrevious={onPrevious}
        onLast={onLast}
        onFirst={onFirst}
        hidePageSize={false}
      />
    </div>
  )
}

export default SelfReportingDashboard;


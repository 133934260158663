import {
  SET_BULKUPLOADFILES,
  SET_BULKUPLOADERROR,
  SET_BULKUPLOADFLAG,
  GET_BULKUPLOADFLAG
} from "./types";

export const setBulkUploadFiles = (data: any, username:any, supplierId: any) => {
  return {
    type: SET_BULKUPLOADFILES,
    payload: {data, username, supplierId},
  };
};

export const setBulkUploadFlag = (flag: boolean) => {
  return {
    type: SET_BULKUPLOADFLAG,
    payload: flag,
  };
};

export const getBulkUploadFlag = () => {
  return {
    type: GET_BULKUPLOADFLAG,
  };
};

export const setBulkUploadError = (error: string) => {
  return {
    type: SET_BULKUPLOADERROR,
    payload: error,
  };
};

import * as R from 'ramda';

const countryDropdown = (state: any) => R.pathOr([], ['UserProfile', 'countryDropdown'], state);
const titleDetails = (state:any) => R.pathOr([], ['UserProfile', 'titleDetails'], state);
const getUserDetails = (state:any) => R.pathOr({}, ['UserProfile', 'getUserDetails'], state);
const departmentDetails = (state:any) => R.pathOr([], ['UserProfile', 'departmentDetails'], state);
const getBranchValues = (state:any) => R.pathOr(false, ['UserProfile', 'Branchvalues'], state);
const getResultMessage = (state:any) => R.pathOr(false, ['UserProfile', 'resultMessage'], state);
const UserProfileApps = (state:any) => R.pathOr({branchIds:[]}, ['UserProfile', 'UserProfileDetails'], state);
const getUsername = (state:any) => R.pathOr([], ['UserProfile', 'username'], state);
const getUserRole = (state:any) => R.pathOr([], ['UserProfile', 'userRole'], state);
const getRolesandClaims = (state:any) => R.pathOr([], ['UserProfile', 'rolesandClaims'], state);

const selectors = {
    countryDropdown,
  getBranchValues,
  UserProfileApps,
  titleDetails,
  departmentDetails,
  getResultMessage,
  getUserDetails,
  getUsername,
  getUserRole,
  getRolesandClaims
}

export default selectors

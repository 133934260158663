import { SET_ADD_SELF_REPORTING_NOTES, SET_CALENDAR_MONTH_DROPDOWN_LIST, SET_EMAIL_REMINDER, SET_EMAIL_TEMPLATE, SET_FILE_COMPLETION_DATA, SET_FILE_STATUS_DROPDOWN_LIST, SET_SELF_REPORTING_EXCEL_DATA, SET_SELF_REPORTING_GRID_DATA, SET_SELF_REPORTING_GRID_HEADER_DATA, SET_SELF_REPORTING_LOADER, SET_SELF_REPORTING_NOTES, SET_STATUS_DROPDOWN_LIST, SET_UPDATE_FILE_STATUS, SET_VALIDATE_EMAIL_REMINDER, SET_VENDOR_DROPDOWN_LIST, SET_VENDOR_FILE_COUNT, SET_VENDOR_HISTORY_DATA, SET_VENDOR_LIST_BY_STATUS, SET_VENDOR_TYPE_DROPDOWN_LIST } from './types'
const initialState = {
  selfReportingGridData: [],
  selfReportingGridHeaderData: [],
  vendorFileCount: [],
  vendorHistoryData: [],
  vendorTypeDropdownList: [],
  fileCompletionData: [],
  vendorListByStatus: [],
  vendorDropdownList: [],
  updateFileStatus: "",
  selfReportingLoader: [],
  statusDropdownList: [],
  selfReportingNotes: [],
  addSelfReportingNotes: false
}

const reducer = (state = initialState, action: any = {}) => {
  switch (action.type) {
    case SET_SELF_REPORTING_GRID_DATA:
      return {
        ...state,
        selfReportingGridData: action.data,
      }
    case SET_SELF_REPORTING_EXCEL_DATA:
      return {
        ...state,
        selfReportingExcelData: action.data,
      }
    case SET_SELF_REPORTING_GRID_HEADER_DATA:
      return {
        ...state,
        selfReportingGridHeaderData: action.data,
      }
    case SET_VENDOR_FILE_COUNT:
      return {
        ...state,
        vendorFileCount: action.data,
      }
    case SET_VENDOR_HISTORY_DATA:
      return {
        ...state,
        vendorHistoryData: action.data,
      }
    case SET_FILE_STATUS_DROPDOWN_LIST:
      return {
        ...state,
        fileStatusDropdownList: action.data,
      }
    case SET_CALENDAR_MONTH_DROPDOWN_LIST:
      return {
        ...state,
        calendarMonthDropdownList: action.data,
      }
    case SET_UPDATE_FILE_STATUS:
      return {
        ...state,
        updateFileStatus: action.data,
      }
    case SET_VENDOR_TYPE_DROPDOWN_LIST:
      return {
        ...state,
        vendorTypeDropdownList: action.data,
      }
    case SET_FILE_COMPLETION_DATA:
      return {
        ...state,
        fileCompletionData: action.data,
      }
    case SET_VENDOR_LIST_BY_STATUS:
      return {
        ...state,
        vendorListByStatus: action.data,
      }
    case SET_VALIDATE_EMAIL_REMINDER:
      return {
        ...state,
        validateEmailReminder: action.data,
      }
    case SET_EMAIL_REMINDER:
      return {
        ...state,
        emailReminder: action.data,
      }
    case SET_VENDOR_DROPDOWN_LIST:
      return {
        ...state,
        vendorDropdownList: action.data,
      }
    case SET_STATUS_DROPDOWN_LIST:
      return {
        ...state,
        statusDropdownList: action.data,
      }
    case SET_SELF_REPORTING_LOADER:
      return {
        ...state,
        selfReportingLoader: { ...state.selfReportingLoader, ...action.data },
      }
    case SET_ADD_SELF_REPORTING_NOTES:
      return {
        ...state,
        addSelfReportingNotes: action.data,
      }
    case SET_SELF_REPORTING_NOTES:
      return {
        ...state,
        selfReportingNotes: action.data,
      }
    case SET_EMAIL_TEMPLATE:
      return {
        ...state,
        emailTemplate: action.data,
      }
    default: return state
  }
}

export default reducer

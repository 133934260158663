import React, {useState} from 'react';
import AddEditPopup from './AddEditPopup';

interface Iprops {
    handleAddEditInputChange:Function,
    onAdd:React.MouseEventHandler<HTMLDivElement>,
    addEditList:any,
    addText:string,
    onDelete:Function,
    onSearch:any,
    submitAddEditClick:any,
    programSaved:boolean,
    programDeleted?: boolean,
    handleMessage:Function
    programError?: any;
}

const AddEditPopupContainer = (props:Iprops) => {
    const [selectedProgram, setSelectedProgram] = useState({});
    const handleEditProgram = (e: any) => {
        setSelectedProgram({
            ...selectedProgram,
            label: e.target.value
        })
    }
    return <AddEditPopup
    handleAddEditInputChange={props.handleAddEditInputChange}
    onAdd={props.onAdd}
    addEditList={props.addEditList}
    addText={props.addText}
    onDelete={props.onDelete}
    onSearch={props.onSearch}
    onEditClick={(i: any) => {setSelectedProgram(i); props.handleMessage()}}
    onEditSave={() => {props.submitAddEditClick(selectedProgram); setSelectedProgram({})}}
    programSaved={props.programSaved}
    programDeleted={props.programDeleted}
    handleMessage={props.handleMessage}
    selectedProgram={selectedProgram}
    handleEditProgram={handleEditProgram}
    programError={props.programError}
    />
}

export default AddEditPopupContainer;
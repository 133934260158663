import {
  getReportData,
  setReportData,
  getReportDataByYear,
  setReportDataByYear,
  getSpendRebateDashboard,
  setSpendRebateDashboard,
  setManufacturerReportData,
  getManufacturerReportData,
  setAccountandFinanceReport,
  getAccountandFinanceReport,
} from './action'

  const dispatchGetReportData = (dispatch:Function) => (userEmail: any) => {
    dispatch(getReportData(userEmail));
  };
  
  const dispatchSetReportData = (dispatch:Function) => (data: any) => {
    dispatch(setReportData(data));
  };

  const dispatchGetReportDataByYear = (dispatch:Function) => (userEmail: any) => {
    dispatch(getReportDataByYear(userEmail));
  };
  
  const dispatchSetReportDataByYear = (dispatch:Function) => (data: any) => {
    dispatch(setReportDataByYear(data));
  };

  const dispatchGetSpendRebateDashboard = (dispatch:Function) => (userEmail: any) => {
    dispatch(getSpendRebateDashboard(userEmail));
  };

  const dispatchSetSpendRebateDashboard = (dispatch:Function) => (data: any) => {
    dispatch(setSpendRebateDashboard(data));
  };
  const dispatchGetManufacturerReportData = (dispatch:Function) => (userEmail: any) => {
    dispatch(getManufacturerReportData(userEmail));
  };
  
  const dispatchSetManufacturerReportData = (dispatch:Function) => (data: any) => {
    dispatch(setManufacturerReportData(data));
  };

  const dispatchGetAccountandFinanaceReport = (dispatch:Function) => (data: any) => {
    dispatch(getAccountandFinanceReport(data))
  }

  const dispatchSetAccountandFinanaceReport = (dispatch:Function) => (data: any) => {
    dispatch(setAccountandFinanceReport(data))
  }




  const operations = {
    dispatchGetReportData,
    dispatchSetReportData,
    dispatchGetReportDataByYear,
    dispatchSetReportDataByYear,
    dispatchGetSpendRebateDashboard,
    dispatchSetSpendRebateDashboard,
    dispatchGetManufacturerReportData,
    dispatchSetManufacturerReportData,
    dispatchGetAccountandFinanaceReport,
    dispatchSetAccountandFinanaceReport
}

export default operations
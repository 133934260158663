import { put, call, takeEvery, all } from 'redux-saga/effects';
import api from '../../../../client/utils/clientApiHelper';
import { GET_CONTACT_HISTORY_GRID_DATA, GET_CONTRACT_DROPDOWN_DATA, GET_DELETE_CONTACT_HISTORY_BY_ID, GET_INSERT_CONTACT_HISTORY_DATA, GET_PAID_EXPECTED_GRID_DATA, GET_PAYMENT_HISTORY_GRID_DATA, GET_SAVE_PAYMENT_COMPLETION, GET_SUPPLIER_DROPDOWN_DATA, GET_YEAR_DROPDOWN_DATA } from './types'

import * as contactHistoryActions from './action';

function* getContactHistoryGridData(payload: any): any {
    try {
        yield put(contactHistoryActions.setCollectionsHistoryLoader( {Loading:true}))
        const response = yield call(api.callPost, `ContactHistory/ContactHistory/Grid`, payload?.payload);
        if (response) {
            yield put(contactHistoryActions.setContactHistoryGridData(response));
        } else {
            yield put(contactHistoryActions.setContactHistoryGridData([]));
        }
        yield put(contactHistoryActions.setCollectionsHistoryLoader( {Loading:false}))
    } catch (error) {
        yield put(contactHistoryActions.setCollectionsHistoryLoader( {Loading:false}))
        yield put(contactHistoryActions.setContactHistoryGridData([]));
    }
}

function* getInsertContactHistoryData(payload: any): any {
    try {
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:true}))
        const response = yield call(api.callPost, `ContactHistory/InsertContactHistory`, payload?.payload);
        if (response) {
            yield put(contactHistoryActions.setInsertContactHistoryData(response));
        } else {
            yield put(contactHistoryActions.setInsertContactHistoryData([]));
        }
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:false}))
    } catch (error) {
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:false}))
        yield put(contactHistoryActions.setInsertContactHistoryData([]));
    }
}

function* getDeleteContactHistoryById(payload: any): any {
    try {
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:true}))
        const response = yield call(api.callPost, `ContactHistory/DeleteContactDetailById/?ContactId=${payload?.payload}`);
        if (response) {
            yield put(contactHistoryActions.setDeleteContactHistoryById(response));
        } else {
            yield put(contactHistoryActions.setDeleteContactHistoryById([]));
        }
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:false}))
    } catch (error) {
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:false}))
        yield put(contactHistoryActions.setDeleteContactHistoryById([]));
    }
}

function* getPaymentHistoryGridData(payload: any): any {
    try {
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:true}))
        const response = yield call(api.callPost, `PaymentHistory/PaymentHistory`, payload?.payload);
        if (response) {
            yield put(contactHistoryActions.setPaymentHistoryGridData(response));
        } else {
            yield put(contactHistoryActions.setPaymentHistoryGridData([]));
        }
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:false}))
    } catch (error) {
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:false}))
        yield put(contactHistoryActions.setPaymentHistoryGridData([]));
    }
}

function* getPaidExpectedGridData(payload: any): any {
    try {
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:true}))
        const response = yield call(api.callPost, `PaymentHistory/PaidExpected`, payload?.payload);
        if (response) {

            yield put(contactHistoryActions.setPaidExpectedGridData(response));
        } else {
            yield put(contactHistoryActions.setPaidExpectedGridData([]));
        }
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:false}))
    } catch (error) {
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:false}))
        yield put(contactHistoryActions.setPaidExpectedGridData([]));
    }
}

function* getSupplierDropdownData(): any {
    try {
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:true}))
        const response = yield call(api.callGet, `Supplier/Dropdown`);
        if (response) {
            yield put(contactHistoryActions.setSupplierDropdownData(response));
        } else {
            yield put(contactHistoryActions.setSupplierDropdownData([]));
        }
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:false}))
    } catch (error) {
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:false}))
        yield put(contactHistoryActions.setSupplierDropdownData([]));
    }
}

function* getContractDropdownData(payload: any): any {
    try {
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:true}))
        const response = yield call(api.callGet, `RebateContract/GetRebateContractNameByVendorId?vendorId=${payload.payload}`);
        if (response) {
            yield put(contactHistoryActions.setContractDropdownData(response));
        } else {
            yield put(contactHistoryActions.setContractDropdownData([]));
        }
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:false}))
    } catch (error) {
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:false}))
        yield put(contactHistoryActions.setContractDropdownData([]));
    }
}

function* getYearDropdownData(payload: any): any {
    try {
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:true}))
        const response = yield call(api.callGet, `RebateContract/GetProgramYears?contractDetailId=${payload.payload}`);
        if (response) {
            yield put(contactHistoryActions.setYearDropdownData(response));
        } else {
            yield put(contactHistoryActions.setYearDropdownData([]));
        }
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:false}))
    } catch (error) {
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:false}))
        yield put(contactHistoryActions.setYearDropdownData([]));
    }
}

function* getSavePaymentCompletion(payload: any): any {
    try {
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:true}))
        const response = yield call(api.callPost, `PaymentHistory/Update/PaymentCompletion`, payload?.payload);
        if (response) {
            yield put(contactHistoryActions.setSavePaymentCompletion(response));
        } else {
            yield put(contactHistoryActions.setSavePaymentCompletion([]));
        }
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:false}))
    } catch (error) {
        yield put(contactHistoryActions.setCollectionsHistoryLoader({Loading:false}))
        yield put(contactHistoryActions.setSavePaymentCompletion([]));
    }
}

function* watchGetCommonDashboard() {
    yield takeEvery(GET_CONTACT_HISTORY_GRID_DATA, getContactHistoryGridData);
    yield takeEvery(GET_INSERT_CONTACT_HISTORY_DATA, getInsertContactHistoryData);
    yield takeEvery(GET_DELETE_CONTACT_HISTORY_BY_ID, getDeleteContactHistoryById);
    yield takeEvery(GET_PAYMENT_HISTORY_GRID_DATA, getPaymentHistoryGridData);
    yield takeEvery(GET_PAID_EXPECTED_GRID_DATA, getPaidExpectedGridData);
    yield takeEvery(GET_SUPPLIER_DROPDOWN_DATA, getSupplierDropdownData);
    yield takeEvery(GET_CONTRACT_DROPDOWN_DATA, getContractDropdownData);
    yield takeEvery(GET_YEAR_DROPDOWN_DATA, getYearDropdownData);
    yield takeEvery(GET_SAVE_PAYMENT_COMPLETION, getSavePaymentCompletion);
}

export default function* sagas() {
    yield all([
        watchGetCommonDashboard()
    ])
}
import {
  getAddSelfReportingNotes,
  getCalendarMonthDropdownList,
  getEmailReminder,
  getFileCompletionData,
  getFileStatusDropdownList,
  getSelfReportingExcelData,
  getSelfReportingGridData,
  getSelfReportingGridHeaderData,
  getStatusDropdownList,
  getSelfReportingNotes,
  getUpdateFileStatus,
  getValidateEmailReminder,
  getVendorDropdownList,
  getVendorFileCount,
  getVendorHistoryData,
  getVendorListByStatus,
  getVendorTypeDropdownList,
  setSelfReportingExcelData,
  setUpdateFileStatus,
  setValidateEmailReminder,
  getEmailTemplate,
  setEmailTemplate
} from './action'

const dispatchGetSelfReportingGridData = (dispatch: Function) => (payload: any) => {
  dispatch(getSelfReportingGridData(payload));
};

const dispatchGetSelfReportingExcelData = (dispatch: Function) => (payload: any) => {
  dispatch(getSelfReportingExcelData(payload));
};

const dispatchSetSelfReportingExcelData = (dispatch: Function) => (payload: any) => {
  dispatch(setSelfReportingExcelData(payload));
};

const dispatchGetSelfReportingGridHeaderData = (dispatch: Function) => (payload: any) => {
  dispatch(getSelfReportingGridHeaderData(payload));
};

const dispatchGetVendorFileCount = (dispatch: Function) => (payload: any) => {
  dispatch(getVendorFileCount(payload));
};

const dispatchGetVendorHistoryData = (dispatch: Function) => (payload: any) => {
  dispatch(getVendorHistoryData(payload));
};

const dispatchGetFileStatusDropdownList = (dispatch: Function) => (payload: any) => {
  dispatch(getFileStatusDropdownList(payload));
};

const dispatchGetCalendarMonthDropdownList = (dispatch: Function) => (payload: any) => {
  dispatch(getCalendarMonthDropdownList(payload));
};

const dispatchGetUpdateFileStatus = (dispatch: Function) => (payload: any) => {
  dispatch(getUpdateFileStatus(payload));
};

const dispatchSetUpdateFileStatus = (dispatch: Function) => (payload: any) => {
  dispatch(setUpdateFileStatus(payload));
};

const dispatchGetVendorTypeDropdownList = (dispatch: Function) => () => {
  dispatch(getVendorTypeDropdownList());
};

const dispatchGetFileCompletionData = (dispatch: Function) => (payload: any) => {
  dispatch(getFileCompletionData(payload));
};

const dispatchGetVendorListByStatus = (dispatch: Function) => (payload: any) => {
  dispatch(getVendorListByStatus(payload));
};

const dispatchGetValidateEmailReminder = (dispatch: Function) => (payload: any) => {
  dispatch(getValidateEmailReminder(payload));
};

const dispatchSetValidateEmailReminder = (dispatch: Function) => (payload: any) => {
  dispatch(setValidateEmailReminder(payload));
};

const dispatchGetEmailReminder = (dispatch: Function) => (payload: any) => {
  dispatch(getEmailReminder(payload));
};

const dispatchGetVendorDropdownList = (dispatch: Function) => (payload: any) => {
  dispatch(getVendorDropdownList(payload));
};

const dispatchGetStatusDropdownList = (dispatch: Function) => () => {
  dispatch(getStatusDropdownList());
}

const dispatchGetAddSelfReportingNotes = (dispatch: Function) => (payload:any) => {
  dispatch(getAddSelfReportingNotes(payload));
};

const dispatchGetSelfReportingNotes = (dispatch: Function) => (payload:any) => {
  dispatch(getSelfReportingNotes(payload));
};

const dispatchGetEmailTemplate = (dispatch: Function) => (payload:any) => {
  dispatch(getEmailTemplate(payload));
};

const dispatchSetEmailTemplate = (dispatch: Function) => (payload:any) => {
  dispatch(setEmailTemplate(payload));
};

const operations = {
  dispatchGetSelfReportingGridData,
  dispatchGetSelfReportingExcelData,
  dispatchSetSelfReportingExcelData,
  dispatchGetSelfReportingGridHeaderData,
  dispatchGetVendorFileCount,
  dispatchGetVendorHistoryData,
  dispatchGetFileStatusDropdownList,
  dispatchGetCalendarMonthDropdownList,
  dispatchGetUpdateFileStatus,
  dispatchSetUpdateFileStatus,
  dispatchGetVendorTypeDropdownList,
  dispatchGetFileCompletionData,
  dispatchGetVendorListByStatus,
  dispatchGetValidateEmailReminder,
  dispatchSetValidateEmailReminder,
  dispatchGetEmailReminder,
  dispatchGetVendorDropdownList,
  dispatchGetStatusDropdownList,
  dispatchGetAddSelfReportingNotes,
  dispatchGetSelfReportingNotes,
  dispatchGetEmailTemplate,
  dispatchSetEmailTemplate
}

export default operations
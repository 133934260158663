import { useEffect, useState } from 'react'
import ReversePopup from './ReversePopup'
import { useDispatch, useSelector } from 'react-redux';
import PaymentReconcillationsSelector from '../../../../../state/features/RebateManagement/PaymentReconcillations/selectors';

const ReversePopupContainer = (props: any) => {
  const {
    selectedRowData,
    getFormattedValue,
    setComment,
    comment,
    checkNumber,
    setCheckNumber,
    saveCheckNumber,
    setFiles,
    files,
    validateCheckNumber,
    updateCheckNumber
  } = props

  const [fileError, setFileError] = useState('')
  const [isCheckNumberUpdated, setIsCheckNumberUpdated] = useState(false)
  useEffect(()=>{
    if(selectedRowData?.checkNumber){
      setCheckNumber(selectedRowData?.checkNumber)
    }else{
      setCheckNumber("")
    }
  },[selectedRowData?.checkNumber])

  useEffect(()=>{
    setIsCheckNumberUpdated(updateCheckNumber)
  },[updateCheckNumber])

  const onChangeComment = (value: any) => {
    setComment({
      ...comment,
      comment: value
    })
  }

  const onDismissAlert = (value: any) => {
    setComment({
      ...comment,
      isError: false
    })
  }

  const getCheckValid = (element: any) => {
    switch (element.type) {
        case "text/csv":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        case "application/vnd.ms-excel":
        case "application/pdf":
        case "image/png":
        case "image/jpeg":
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        case "image/gif":
        case "text/plain":
        case "application/vnd.oasis.opendocument.text":
        case "image/tiff":
        case "image/bmp":
        case "image/webp":
        case "image/svg+xml":
          return true
        default:
            return false
    }
  }

  const onFileChange = (e: any) => {
    let list:any = []
    let isError = '';
    let totalSize = 0;
    for(let element of files) {
      totalSize += element?.size 
    }
    for(let element of e.target.files) {
      if(getCheckValid(element)){
        totalSize += element?.size
        if(totalSize <= 5000000){  
          list.push(element)
        }else{
          isError = 'Please Upload files without exceeding maximum size of 5MB';
          break;
        } 
      }else{
        isError = 'Upload only pdf, xls, xlsx, csv, jpg, tiff, bmp, gif, jpeg, png, webp, svg, txt, rtf, doc, docx file';
      }
    }

    setFileError(isError);
    setFiles([...files, ...list])
  }

  const handleClose = (name:any) => {
    let tempFile = files.filter((item:any)=>item.name != name)
    setFiles(tempFile)
  }

  return (
    <ReversePopup
      selectedRowData={selectedRowData}
      getFormattedValue={getFormattedValue}
      onChangeComment={onChangeComment}
      comment={comment}
      onDismissAlert={onDismissAlert}
      checkNumber={checkNumber}
      setCheckNumber={setCheckNumber}
      saveCheckNumber={saveCheckNumber}
      onFileChange={onFileChange}
      files={files}
      handleClose={handleClose}
      fileError={fileError}
      validateCheckNumber={validateCheckNumber}
      isCheckNumberUpdated = {isCheckNumberUpdated}
      setIsCheckNumberUpdated = {setIsCheckNumberUpdated}
    />
  )
}

export default ReversePopupContainer
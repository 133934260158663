import { isAuthorized } from "client/utils/Permissions";
import React from "react";
import {ModalPopup,Breadcrumb} from '../../../CommonComponents';
import ProductCategoryEntry from "../ProductCategoryEntry";
import ProductSubCategoryEntry from "../ProductSubCategoryEntry";
import SupplierEntry from "../SupplierEntry";
import ProductEntryModal from "./ProductEntryModal";
interface IBreadcrumb {
  text: string,
  key: string,
  href?: string,
  onClick?: React.MouseEventHandler<HTMLDivElement>,
}
interface Iprops {
  breadcrumlist: IBreadcrumb[],
  productEntry: boolean,
  hideProductEntry:Function,
  handleSave?: any;
  isSaveClicked?: boolean;
  handleInputChange?: any;
  values?: any;
  errors?: any;
  showProduct?:boolean;
  showSupplierModal:Function;
  showSupplier:boolean;
  hideSupplierModal:Function;
  hideCategoryModal:Function;
  showProductCategory:boolean;
  showCategoryModal:Function;
  showSubCategoryModal:Function;
  showProductSubCategory:boolean;
  hideSubCategoryModal:Function;
  handleProduct?: Function;
  errorProduct?: any;
  setValues?: any;
  popupType?: any;
  handleMessage?: Function;
  FooterPrimaryBtnTxt?: any;
  isNew?: boolean;
  showAddNew?:boolean

}
const ProductEntry = (props:Iprops) => {
    return <>
       {!props.showProduct ?<Breadcrumb items={props.breadcrumlist} maxDisplayedItems='5' />: null}
        {
        props.productEntry
        ? <ModalPopup
              ShowModalPopupFooter={true}
              ModalPopupTitle="Product Entry"
              ModalPopupType="medium"
              closeModalPopup={props.hideProductEntry}
              FooterSecondaryBtnTxt='Cancel'
              FooterPrimaryBtnTxt={props.FooterPrimaryBtnTxt()}
              PrimaryBtnOnclick={props.handleSave}
              SecondryBtnOnclick={props.hideProductEntry}
              FooterPrimaryDisabled={props.FooterPrimaryBtnTxt() === "Update" ? !isAuthorized("Products", "update") : !isAuthorized("Products", "create")}
          >
            <ProductEntryModal
              isSaveClicked={props.isSaveClicked}
              handleInputChange={props.handleInputChange}
              values={props.values}
              errors={props.errors}
              showSupplierModal={props.showSupplierModal}
              showCategoryModal={props.showCategoryModal}
              showSubCategoryModal={props.showSubCategoryModal}
              handleProduct={props.handleProduct}
              errorProduct={props.errorProduct}
              setValues={props.setValues}
              popupType={props.popupType}
              handleMessage={props.handleMessage}
              isNew={props.isNew}
            />
      </ModalPopup>
        : null
      }
      {
          props.showSupplier ? <SupplierEntry showSupplier={props.showSupplier} hideSupplierModal={props.hideSupplierModal} showAddNew={props.showAddNew}/>: null
      }
      {
          props.showProductCategory ? <ProductCategoryEntry showProductCategory={props.showProductCategory} hideProductCategoryModal={props.hideCategoryModal}/>: null
      }
      {
          props.showProductSubCategory ? <ProductSubCategoryEntry showProductSubCategory={props.showProductSubCategory} hideProductSubCategoryModal={props.hideSubCategoryModal}/>: null
      }
    </>
}

export default ProductEntry;
import { 
  GET_STATES,
  SET_STATES,
  GET_COUNTRIES,
  SET_COUNTRIES,
  GET_SUPPLIERDATA,
  GET_CREATEUPDATESUPPLIER,
  SET_SUPPLIERDATA,
  SET_SUPPLIER,
  GET_SUPPLIER,
  GET_MANUFACTURER,
  SET_MANUFACTURER,
  GET_SAVEDSUPPLIERFLAG,
  SET_SAVEDSUPPLIERFLAG,
  SET_ERRORSUPPLIER,
  SET_SUPPLIERNAME,
  GET_CATEGORY_MANAGER,
  SET_CATEGORY_MANAGER,
  GET_PRIMARY_ABNUMBER,
  SET_PRIMARY_ABNUMBER,
  GET_SUPPLIER_DETAILS_BY_ABNUMBER,
  SET_SUPPLIER_DETAILS_BY_ABNUMBER,
  GET_SECONDARY_ABNUMBER_BY_SUPPLIER,
  SET_SECONDARY_ABNUMBER_BY_SUPPLIER,
  GET_SECONDARY_ABNUMBER,
  SET_SECONDARY_ABNUMBER
} from './types'

export const getStates = (countryId: any) => {
  return {
    type: GET_STATES,
    countryId
  }
}

export const setStates = (data: any) => {
  return {
    type: SET_STATES,
    payload: data
  }
}

export const getCountries = () => {
  return {
    type: GET_COUNTRIES
  }
}

export const setCountries = (data:any) => {
  return {
    type: SET_COUNTRIES,
    payload: data
  }
}

export const getSupplierdata = () => {
  return {
    type: GET_SUPPLIERDATA
  }
}

export const setSupplierdata = (data: any) => {
  return {
    type: SET_SUPPLIERDATA,
    payload: data
  }
}

export const getSupplierById = (id: any) => {
  return {
    type: GET_SUPPLIER,
    id
  }
}

export const setSupplier = (data: any) => {
  return {
    type: SET_SUPPLIER,
    payload: data
  }
}

export const getCreateUpdateSupplier = (data: any) => {
  return {
    type: GET_CREATEUPDATESUPPLIER,
    payload: data
  }
}

export const getManufacturerData = () => {
  return {
    type: GET_MANUFACTURER
  }
}

export const setManufacturer = (data: any) => {
  return {
    type: SET_MANUFACTURER,
    payload: data
  }
}

export const getSupplierSavedFlag = () => {
  return {
    type: GET_SAVEDSUPPLIERFLAG
  }
}

export const setSupplierSavedFlag = (flag: any) => {
  return {
    type: SET_SAVEDSUPPLIERFLAG,
    payload: flag
  }
}

export const setErrorSupplier = (data: any) => {
  return {
    type: SET_ERRORSUPPLIER,
    payload: data
  }
}

export const setSupplierName = (data: any) => {
  return {
    type: SET_SUPPLIERNAME,
    payload: data
  }
}

export const getCategoryManager = () => {
  return {
    type: GET_CATEGORY_MANAGER
  }
}

export const setCategoryManager = (data: any) => {
  return {
    type: SET_CATEGORY_MANAGER,
    payload: data
  }
}

export const getPrimaryAbnumber = (payload: any) => {
  return {
    type: GET_PRIMARY_ABNUMBER,
    payload
  }
}

export const setPrimaryAbnumber = (data: any) => {
  return {
    type: SET_PRIMARY_ABNUMBER,
    payload: data
  }
}

export const getSecondaryAbnumber = (payload: any) => {
  return {
    type: GET_SECONDARY_ABNUMBER,
    payload
  }
}

export const setSecondaryAbnumber = (data: any) => {
  return {
    type: SET_SECONDARY_ABNUMBER,
    payload: data
  }
}

export const getSupplierDetailsByAbnumber = (payload: any) => {
  return {
    type: GET_SUPPLIER_DETAILS_BY_ABNUMBER,
    payload
  }
}

export const setSupplierDetailsByAbnumber = (data: any) => {
  return {
    type: SET_SUPPLIER_DETAILS_BY_ABNUMBER,
    payload: data
  }
}

export const getSecondaryAbnumberBySupplier = (payload: any) => {
  return {
    type: GET_SECONDARY_ABNUMBER_BY_SUPPLIER,
    payload
  }
}

export const setSecondaryAbnumberBySupplier = (data: any) => {
  return {
    type: SET_SECONDARY_ABNUMBER_BY_SUPPLIER,
    payload: data
  }
}
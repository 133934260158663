import React from 'react'
import { CheckboxComponent } from '@softura/fluentui-basiccomponents'
import './Checkbox.scss'

interface IProps {
  label?: string,
  className?: string | number,
  onChange?: any,
  defaultChecked?: boolean,
  disabled?: boolean,
  key?: any,
  checked?:any,
  title?:any,
  type?:string,
  name?:string
}
const Checkbox = ({
  label = '',
  className = '',
  onChange,
  defaultChecked = false,
  disabled = false,
  key,
  checked,
  title,
  type,
  name
}:IProps) => {
  return <CheckboxComponent
  className={`form-checkbox ${type ==='small'?'smallCheckbox': ''} ${className}`}
  label = {label}
  onChange = {onChange}
  defaultChecked = {defaultChecked}
  disabled = {disabled}
  key={key}
  checked={checked}
  title={title}
  name={name}
  />
}

export default Checkbox

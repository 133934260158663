import { 
  GET_PRODUCTDATA, 
  SET_PRODUCTDATA,
  GET_PRODUCTBYID,
  SET_PRODUCT,
  GET_CREATEUPDATEPRODUCT,
  GET_SAVEDPRODUCTFLAG,
  SET_SAVEDPRODUCTFLAG,
  SET_ERRORPRODUCT,
  SET_PRODUCTNAME
} from './types'

export const getProductData = () => {
  return {
    type: GET_PRODUCTDATA
  }
}

export const setProductData = (data: any) => {
  return {
    type: SET_PRODUCTDATA,
    payload: data
  }
}

export const getProductById = (id: any) => {
  return {
    type: GET_PRODUCTBYID,
    id: id
  }
}

export const setProduct = (data:any) => {
  return {
    type: SET_PRODUCT,
    payload: data
  }
}

export const getCreateUpdateProduct = (data:any) => {
  return {
    type: GET_CREATEUPDATEPRODUCT,
    payload: data
  }
}

export const getProductSavedFlag = () => {
  return {
    type: GET_SAVEDPRODUCTFLAG
  }
}

export const setProductSavedFlag = (flag: any) => {
  return {
    type: SET_SAVEDPRODUCTFLAG,
    payload: flag
  }
}

export const setErrorProduct = (data: any) => {
  return {
    type: SET_ERRORPRODUCT,
    payload: data
  }
}

export const setProductName = (data: any) => {
  return {
    type: SET_PRODUCTNAME,
    payload: data
  }
}


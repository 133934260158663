import React from "react";
import { Breadcrumb, ModalPopup } from "client/CommonComponents";
import SupplierModal from "./SupplierModal";
import "./SupplierEntry.scss";
import { isAuthorized } from "client/utils/Permissions";

interface IProps {
    breadcrumlist?: any;
    showSupplierModal?: any;
    hidePopup?: any;
    handleSave?: any;
    handleInputChange?: any;
    getCheckboxLabel?: any;
    supplierOptions?: any;
    stateOptions?: any;
    countryOptions?: any;
    manufacturerOptions?: any;
    handleRadio?: any;
    showSupplier?:boolean
    handleSupplier?: any;
    supplierSaved?: any;
    handleMessage?: Function;
    errorSupplier?: any;
    FooterPrimaryBtnTxt?: any;
    getRadioSelectedKey?: any;
    getValue?: any;
    getError?: any;
    getExternalError?: any;
    isNew?: boolean;
    categoryManagerOptions?: any;
    handleCategoryManager?: any;
    primaryAbnumberOptions?: any;
    secondaryAbnumberOptions?: any
    AdditionalAbnoSelected?: any
    selfReportedOptions?: any
    onClickAddNew?: any
    onClickDeleteAddNew?: any
    isAddNewAbnumber?: any
    handleAdditionalAbnumber?: any
    primarySearchValue?: any
    setPrimarySearchValue?: any
    setSecondarySearchValue?: any
    isLoadingPrimary?: any
    isLoadingSecondary?: any
    isNoAbNumber?: any
    setIsNoAbNumber?: any
}

const SupplierEntry = (props: IProps) => {
    const {
        breadcrumlist,
        showSupplierModal,
        hidePopup,
        handleSave,
        handleInputChange,
        getCheckboxLabel,
        supplierOptions,
        stateOptions,
        countryOptions,
        manufacturerOptions,
        handleRadio,
        showSupplier,
        handleSupplier,
        supplierSaved,
        handleMessage,
        errorSupplier,
        FooterPrimaryBtnTxt,
        getRadioSelectedKey,
        getValue,
        getError,
        getExternalError,
        isNew,
        categoryManagerOptions,
        handleCategoryManager,
        primaryAbnumberOptions,
        secondaryAbnumberOptions,
        AdditionalAbnoSelected,
        selfReportedOptions,
        onClickAddNew,
        onClickDeleteAddNew,
        isAddNewAbnumber,
        handleAdditionalAbnumber,
        primarySearchValue,
        setPrimarySearchValue,
        setSecondarySearchValue,
        isLoadingPrimary,
        isLoadingSecondary,
        isNoAbNumber,
        setIsNoAbNumber
    } = props;
    
    return (
        <div className="supplier-entry-container">
            { showSupplier ? null :
            <Breadcrumb items={breadcrumlist} maxDisplayedItems='5' />
            }
            {
                showSupplierModal
                ? <ModalPopup
                    ShowModalPopupFooter={true}
                    ModalPopupTitle="Vendor Entry"
                    ModalPopupType="large"
                    closeModalPopup={hidePopup}
                    FooterSecondaryBtnTxt={"Cancel"}
                    ModalPopupName = "vendorEntryPopup"
                    SecondryBtnOnclick={hidePopup}
                    FooterPrimaryBtnTxt={FooterPrimaryBtnTxt()}
                    PrimaryBtnOnclick={handleSave}
                    FooterPrimaryDisabled={props.FooterPrimaryBtnTxt() === "Update" ? !isAuthorized("Suppliers", "update") : !isAuthorized("Suppliers", "create")}
                >
                    <SupplierModal
                        handleInputChange={handleInputChange}
                        getCheckboxLabel={getCheckboxLabel}
                        supplierOptions={supplierOptions}
                        stateOptions={stateOptions}
                        countryOptions={countryOptions}
                        manufacturerOptions={manufacturerOptions}
                        handleRadio={handleRadio}
                        handleSupplier={handleSupplier}
                        supplierSaved={supplierSaved}
                        handleMessage={handleMessage}
                        errorSupplier={errorSupplier}
                        getRadioSelectedKey={getRadioSelectedKey}
                        getValue={getValue}
                        getError={getError}
                        getExternalError={getExternalError}
                        isNew={isNew}
                        categoryManagerOptions={categoryManagerOptions}
                        handleCategoryManager={handleCategoryManager}
                        primaryAbnumberOptions = {primaryAbnumberOptions}
                        secondaryAbnumberOptions = {secondaryAbnumberOptions}
                        AdditionalAbnoSelected = {AdditionalAbnoSelected}
                        selfReportedOptions = {selfReportedOptions}
                        onClickAddNew={onClickAddNew}
                        onClickDeleteAddNew = {onClickDeleteAddNew}
                        isAddNewAbnumber = {isAddNewAbnumber}
                        handleAdditionalAbnumber = {handleAdditionalAbnumber}
                        primarySearchValue = {primarySearchValue}
                        setPrimarySearchValue={setPrimarySearchValue}
                        setSecondarySearchValue={setSecondarySearchValue}
                        isLoadingPrimary={isLoadingPrimary}
                        isLoadingSecondary={isLoadingSecondary}
                        isNoAbNumber={isNoAbNumber}
                        setIsNoAbNumber={setIsNoAbNumber}
                    />
                </ModalPopup>
                : null
            }
        </div>
    )
}

export default SupplierEntry;
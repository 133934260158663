import {SET_CALCULATION_FREQUENCY, SET_CALCULATION_METHOD, SET_CALCULATION_TYPE,
  SET_REBATE_PROGRAM,SET_REBATE_PROGRAM_BY_ID,SET_REBATE_DELETE, SET_REBATE_ADD,
  SET_REBATE_ADDEDIT_RESPONSE,SET_REBATE_EDIT, SET_REBATEPERIOD, SET_REBATEPAYMENTMETHOD, 
  SET_ALL_CONTRACT, SET_CONTRACT_DETAILS_BY_ID, SET_ALL_CONTRACT_LIST, SET_ALL_REBATE_TYPES,
SET_PROGRAM_ERROR, 
SET_CONTRACT_BY_ID_LOADING,
SET_REBATE_PROGRAM_FIELD_INFO,
SET_CONTRACT_VALIDATION_FLAG} from './types'
const initialState = {
 productCategory: [],
 product:[],
 productSubCategory:[],
 supplier: [],
 calculationFrequency: [],
 calculationMethod: [],
 calculationType: [],
 rebateProgram: [],
 rebateProgramById: {},
 rebateDelete:false,
 rebateSaved: false,
 rebateSavedEdit: false,
 rebatePeriods: [],
 paymentMethod: [],
 allContract: [],
 contractDetails: {},
 contractList: [],
 rebateTypes:[],
 programError: false,
 contractByIDloading: false,
 rebateProgramFieldInfo: [],
 contractValidation: false
}

const reducers = (state = initialState, action:any = {}) => {
  switch (action.type) {
    case SET_CALCULATION_FREQUENCY:
      return {
        ...state,
        calculationFrequency: action.payload,
      }
    case SET_CALCULATION_METHOD:
      return {
        ...state,
        calculationMethod: action.payload,
      }
    case SET_CALCULATION_TYPE:
      return {
        ...state,
        calculationType: action.payload,
      }
    case SET_REBATE_PROGRAM:
      return {
        ...state,
        rebateProgram: action.payload,
      }
    case SET_REBATE_PROGRAM_BY_ID:
      return {
        ...state,
        rebateProgramById: action.payload,
      }
    case SET_REBATE_DELETE:
      return {
        ...state,
        rebateDelete: action.payload,
      }
    case SET_REBATE_ADD:
      return {
        ...state,
        rebateSaved: action.payload,
      }
    case SET_REBATE_EDIT:
      return {
        ...state,
        rebateSavedEdit: action.payload,
      }
    case SET_REBATE_ADDEDIT_RESPONSE: 
      return {
        ...state,
        rebateSaved: action.payload,
        rebateDelete: action.payload,
        rebateSavedEdit: action.payload
      }
    case SET_REBATEPERIOD:
      return {
        ...state,
        rebatePeriods: action.payload
      }
    case SET_REBATEPAYMENTMETHOD:
      return {
        ...state,
        paymentMethod: action.payload
      }
    case SET_ALL_CONTRACT:
      return {
        ...state,
        allContract: action.payload
      }
    case SET_CONTRACT_DETAILS_BY_ID:
      return {
        ...state,
        contractDetails: action.payload
      }
    case SET_ALL_CONTRACT_LIST:
      return {
        ...state,
        contractList: action.payload
      }
    case SET_ALL_REBATE_TYPES:
      return {
        ...state,
        rebateTypes: action.payload
      }
    case SET_PROGRAM_ERROR:
      return {
        ...state,
        programError: action.payload
      }
      case SET_CONTRACT_BY_ID_LOADING:
        return {
          ...state,
          contractByIDloading: action.payload
        }
      case SET_REBATE_PROGRAM_FIELD_INFO:
        return {
          ...state,
          rebateProgramFieldInfo: action.payload
        }
      case SET_CONTRACT_VALIDATION_FLAG:
        return {
          ...state,
          contractValidation: action.payload
        }
    default: return state
  }
}

export default reducers
